import React, {useEffect, useState, useReducer, useRef} from 'react';
import Header from '../components/Header';
import {  useHistory } from 'react-router-dom';
import Webcam from 'react-webcam'
import imageCompression from "browser-image-compression";
import {Buffer} from "buffer";

import {
    updateCustomerInfo,
    fetchSalaryInfo,
    customerAddnlDocsUpload, customerFormUpload,
    updateNextLevel, UpdateNonSTP, createAccessLog
} from "../actions/Authorization";
import ListCustInfo from "./ListCustInfo" ;
import Editform1 from "./Editform1" ;
import Editform2 from "./Editform2" ;
import Editform3 from "./Editform3" ;
import Editform4 from "./Editform4" ;
import Editform5 from "./Editform5" ;
import Editform6 from "./Editform6" ;
import Editform7 from "./Editform7" ;
import Editform8 from "./Editform8" ;
import {useDispatch, useSelector} from "react-redux";
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import Loader from './Loader'
import Popup from "./Popup";
import {kycStore} from "../reducers/KYCState";

const videoConstraints = {
    height: 720, //set pic resolution
    width: 1280, //set pic resolution
    facingMode: "environment" //use back camera
    //   facingMode: "user" //use front camera
};
const webcamRef = React.createRef();

const CustomerDetails = () => {
    const showAllBox = true;
    const lang = getToken().lang;
    const livenessstatus = getToken().livenessstatus;
    const [boxcount, setBoxcount] = useState(1);
    const [jumpcount] = useState(1);
    const [residentonon, setResidentonon] = useState(false);
    const [maxboxcount, setMaxboxcount] = useState(1);
    const [custdata, setCustdata] = useState([]);
    const [boxtitle, setBoxtitle] = useState(data[lang]['personalInformation']);
    const [search, setSearch] = useState(false);
    const [modalIn, setModalIn] = useState(false);
    const [outerModal, setOuterModal] = useState(false);
    const [pepModal, setpepModal] = useState(false);
    const [outerModalSearch, setOuterModalSearch] = useState(true);
    const [outerModalFull, setOuterModalFull] = useState(true);
    const [outerModalItem, setOuterModalItem] = useState('');
    const [crsSelected, setcrsSelected] = useState('');
    const [outerModalList, setOuterModalList] = useState([]);
    const [curstep, setCurstep] = useState(1);
    const [journeyid] = useState(getToken().journeyid);
    const [client] = useState(getToken().client);
    const [nonstp, setNonstp] = useState(getToken().nonstp);
    const [renewType] = useState(getToken().renewType);
    const [userFile, setUserFile] = useState({name:'', value: '', filenamelabel: '', filename: ''});

    const [editform, setEditform] = useState(true);
    const [loader, setLoader] = React.useState(false);
    const [next, setNext] = React.useState(false);
    const [errorMessages] = React.useState('');
    const [submitErrorMessages, setSubmitErrorMessages] = React.useState('');
    const [customerErrorMessages, setCustomerErrorMessages] = React.useState('');
    const [loaderMessage, setLoaderMesssage] = React.useState('');

    const [showCamera, setShowCamera] = React.useState(false);
    const [cameraFieldName, setCameraFieldName] = React.useState(false);
    const [compressedPhoto, setCompressedPhoto] = React.useState('');
    const [crsReasonSelected, setcrsReasonSelected] = React.useState(['', '', '']);


    const [cif] = useState(getToken().cif);
    const [addressproofstatus] = useState(getToken().addressproofstatus);
    const [section, setSection] = useState([]);
    const [crsfields, setcrsfields] = useState([]);
    const [listmaritalstatus, setMaritalstatus] = useState([]);
    const [listpostalcode, setPostalCode] = useState([]);
 //   const [maritalstatus, setMaritalstatus] = useState([{"option":"DIVORCED"},{"option":"MARRIED"},{"option":"UNMARRIED"},{"option":"WIDOWED"}]);
    const [listcountry, setListcountry] = useState([]);
    const [listSearchcountry, setListsearchcountry] = useState([]);
    const [listSearchPcode, setListsearchPcode] = useState([]);
    const [listincome, setListincome] =  useState([]);
    //    const [listincome, setListincome] = useState([{"option":"Below 500 OMR"},{"option":"500-1,000 OMR"},{"option":"1,001 - 2,500 OMR"},{"option":"2,501 - 5000 OMR"},{"option":"More than 5000 OMR"}]);
    const [listempstatus, setListempstatus] = useState([]);

    // const [listempstatus, setListempstatus] = useState([{"option":"Job Seeker"},{"option":"Housewife"},{"option":"Student"},{"option":"Retired"},{"option":"Household worker"},{"option":"Minor (Age 15 to 17)"},{"option":"Child (Age below 17)"}]);
    const [listrelatioshipaddrs, setListrelatioshipaddrs] = useState([]);
    //const [listemploymentstatus] = useState([]);
    //const [empDropdown, setEmpDropdown] = useState([]);
    //const [empDropdownStatus, setEmpDropdownStatus] = useState(false);
    //  const [listemploymentstatus, setListemploymentstatus] = useState([{"option":"Employed"},{"option":"Self Employed"},{"option":"Unemployed"}]);
    const [pepRelativePositions, setPepRelativePositions] = useState([]);
    const [idtype] = useState(getToken().idtype);
    const [custrisk] = useState(getToken().custrisk);
    const [yesnovalue] = useState([{"label": "Yes", "value": true},{"label": "No", "value": false}]);
    //const [w8form, setW8form] = useState(false);
    //const [w9form, setW9form] = useState(false);
    const[cid,setCid] = useState("") ;
    const [salarydata, setSalarydata] = useState([]);
    const [errors, setErrors] = useState([]);
    const [employmentSectors, setEmploymentSectors] = useState([]);
    const [empProofs, setempProofs] = useState([]);
    const [selfEmpProofs, setselfEmpProofs] = useState([]);
    const [addProofs, setaddProofs] = useState([]);
    const errorRef = useRef(null)
    const previewRef = useRef(null)
    //const [countryCode, setcountryCode] = useState(getToken().country_code || '');
    //const [nationality, setnationality] = useState(getToken().nationality || '');
    //const [countryResidence, setcountryResidence] = useState(getToken().country_residence || '');
    //const [previousSalaryRange, setPreviousSalaryRange] = useState('');
    const [countDown, setCountDown] = useState(4);
    /*const executeErrorScroll = () => {
        if(errorRef.current !== null) {errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
    }*/


    const customerdata =  getToken().custinfo ;//console.log(customerdata)
    const customerupdatestats = useSelector(state => state.AppReducer.cInfoResp  ) ;
    const customerSalaryData = useSelector(state => state.AppReducer.customerSalaryData  ) ;
    const countries = useSelector(state => state.AppReducer.countries  ) ;
    const maritalstatus = useSelector(state => state.AppReducer.maritalstatus  ) ;
    const unemployedstatus = useSelector(state => state.AppReducer.unemployedstatus  ) ;
    //const employmentstatus = useSelector(state => state.AppReducer.employmentstatus  ) ;
    const incomelist = useSelector(state => state.AppReducer.listIncome  ) ;
    const listPostalcode = useSelector(state => state.AppReducer.listPostalcode  ) ;
    const listPep = useSelector(state => state.AppReducer.listPep  ) ;
    const listRelationships = useSelector(state => state.AppReducer.listRelationships  ) ;
    const allEmploymentSectors = useSelector(state => state.AppReducer.listEmploymentSectors  ) ;
    const empProofCategories = useSelector(state => state.AppReducer.listempproof  ) ;
    const selfEmpProofCategories = useSelector(state => state.AppReducer.listselfempproof  ) ;
    const addProofCategories = useSelector(state => state.AppReducer.listaddproof  ) ;

    //const uploadAddnlDocResponsedata = useSelector(state => state.AppReducer.uploadAddnlDocResponsedata  ) ;
    const uploadFormResponsedata = useSelector(state => state.AppReducer.uploadFormResponsedata  ) ;
    const apiRetry = useSelector(state => state.AppReducer.apiRetry  ) ;
    let history = useHistory();
    const dispatch = useDispatch();

    const [htoken] = useState(getToken().token);
    const crsNoTinReasons = [
        {
            key: 'A',
            label: data['En']['crsReasonA'],
            label_ar: data['Ar']['crsReasonA'],
        },
        {
            key: 'B',
            label: data['En']['crsReasonB'],
            label_ar: data['Ar']['crsReasonB'],
        },
        {
            key: 'C',
            label: data['En']['crsReasonC'],
            label_ar: data['Ar']['crsReasonC'],
        }
    ]
    const pepCategories = [
        {
            value: data['En']['pepquestion1a'],
            option: data[lang]['pepquestion1a']
        },
        {
            value: data['En']['pepquestion1b'],
            option: data[lang]['pepquestion1b']
        },
        {
            value: data['En']['pepquestion1c'],
            option: data[lang]['pepquestion1c']
        },
        {
            value: data['En']['pepquestion1d'],
            option: data[lang]['pepquestion1d']
        }
    ];
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthNames_Ar = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];
    const formatDate = (str) => {
        let dt = '';
        if(str !== '') {
            let patrs = str.split('/');
            let m = parseInt(patrs[0]) - 1;
            let y = parseInt(patrs[1]);
            dt = lang === 'En' ? monthNames[m] + ' ' + y : monthNames_Ar[m] + ' ' + y
        }
        return dt
    }

    /*const covertDate = (str) => {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let m = str.substr(4,1) === '0' ? str.substr(5,1) : str.substr(4,2);
        return str.substr(0, 4) + ' ' + months[m];
    }*/
    let countDownTimer = 4;
    const showPepInfo = () => {
        setpepModal(true);
    }

    useEffect(() => {
        setLoader(true);
        //dispatch(createAccessLog('CustomerDetails' , 'Launched', 'Customer Details Page Launched',cif));
        dispatch(updateNextLevel(htoken, 4));
        const params = (new URL(document.location)).searchParams;
        let ref = params.get("ref");

        if (ref === 'otp') {
            console.log(kycStore.getState().KYCState.inputValues)
            setInputValues(kycStore.getState().KYCState.inputValues)
            setEditform(false)
            setCurstep(0);
            setModalIn(false)

        }else {dispatch(fetchSalaryInfo(cif, journeyid, client));}
    },[dispatch, htoken, cif, journeyid, client] );

    useEffect(() => {
        //console.log(apiRetry)
       if(apiRetry === true) {

           setInterval(function () {
               //console.log(countDown)
               if(countDownTimer > 0) {

                   countDownTimer = (countDownTimer - 1)
                   setCountDown(countDownTimer)
               }

           }, 1000);
       }
        if(apiRetry === false) {
            setCountDown(4)
        }
    }, [apiRetry]);

    const [inputValues, setInputValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            country_birth: '',
            marital_status: '',
            name: '' ,
            email:'',
            mobile :'',
            address:'',
            landmark:'',
            house:'',
            building:'',
            po_box:'',
            way:'',
            city:'',
            wilayat:'',
            //picode:(idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT') ? '999' : '',
            picode: '',
            monthly_income:'',
            employed:'',
            employed_cur_status: '',
            employment_sector: '',
            sector_code: '',
            ustax_resident:'',
            usaddress_tel:'',
            us_nationality:'',
            us_payments:'',
            w8form: '',
            w9form: '',
            taxresident_not_oman:'',
            taxresident_count: 1 ,
            showtaxresidence1: false,
            country_taxresidence1:'',
            tinnumber1:'',
            reason1:'',
            details1:'',
            showtaxresidence2: false,
            country_taxresidence2:'',
            tinnumber2:'',
            reason2:'',
            details2:'',
            showtaxresidence3: false,
            country_taxresidence3:'',
            tinnumber3:'',
            reason3:'',
            details3:'',
            showtaxresidence4: false,
            country_taxresidence4:'',
            tinnumber4:'',
            current_former_pep: '',
            pep_category: '',
            pep_relation: '',
            pep_name: '',
            pep_period_from: '',
            pep_period_to: '',
            pep_country: '',
            pep_relative: '',
            pep_busns_associate: '',
            pep_orgname_directv:'',
            pep_orgposition_relative:'',
            ownproperty_oman:'',
            relative_family_oman:'',
            own_business_oman:'',
            benefit_from_emplyr:'',
            authorise_bank_credit:'',
            other_reason:'',
            showdocresidenttononresident:false,
            filenamedocresidenttononresident:'',
            docresidenttononresident:'',
            showadditionaldoc:false,
            filenameadditionaldoc:'',
            additionaldoc:'',
            accholding_reason1:'',
            accholding_reason2:'',
            showempselection:'',
            showempsector: '',
            showcrsselection:'',
            cust_id_type: '',
            nonstp:'',
            pep:'',
            updation_type: '',
            address_proof:'' ,
            addressProofCategory:'' ,
            employmentProofCategory:'' ,
            selfemploymentProofCategory:'' ,
            address_proof_own_name: '',
            relatioshipaddrs: '',
            employmentStatus: '',
            employment_proof: '',
            salary_bom: '',
            showsalary_bom:false,
            showW8: false,
            selectedfilenamew8: '',
            showW9: false,
            selectedfilenamew9: '',
            showaddress_proof: true,
            selectedfilenameaddress_proof: '',
            showlistrelatioshipaddrs: false,
            showemploymentStatus: true,
            showemployment_proof: true,
            selectedfilenameemployment_proof: '',
            authorisation_letter: '',
            showauthorisation_letter: false,
            selectedfilenameauthorisation_letter: '',
            selfemployment_proof: '',
            showselfemployment_proof: true,
            selectedfilenameselfemployment_proof: '',
            preview: '',
            nameofemployer: '',
            employersaddress: '',
            pcCode: '',
            location: '',
            presentposition: '',
            reasonBDetails:''

        }
    );


    useEffect(() => {

        if(idtype === "Non-Resident"){
            setMaxboxcount(8) ;
            setResidentonon(true) ;
        }else{
            /* setMaxboxcount(7);  */
            setMaxboxcount(8);
        }

    },[journeyid, cif, idtype]);

    useEffect(() => {
        //console.log(inputValues)

            if (inputValues.employed.toUpperCase() === "YES" && inputValues.salary_bom.toUpperCase() !== "YES") {
                handleChange("selfemployment_proof", '');
                handleChange("showselfemployment_proof", false);
                handleChange("selectedfilenameselfemployment_proof", '');
                handleChange("showemployment_proof", true);
                handleChange({"showempselection": false});
                handleChange({"showempsector": true});
                handleChange({'employed_cur_status': '' });
                let empSector = inputValues.employment_sector? inputValues.employment_sector.toUpperCase() : '';
                let customerProof = employmentSectors.filter(a => a.value.toUpperCase() === empSector.toUpperCase())[0]
                customerProof = customerProof ? customerProof['child'] : '';
                if(customerProof === 'Self Employment Proof') {
                    handleChange("showselfemployment_proof", true);
                    handleChange("showemployment_proof", false);
                }
            } else if (inputValues.employed.toUpperCase() === "YES" && inputValues.salary_bom.toUpperCase() === "YES") {

                handleChange("showemployment_proof", false);
                handleChange("selectedfilenameemployment_proof", '');
                handleChange("selfemployment_proof", '');
                handleChange("showselfemployment_proof", false);
                handleChange("selectedfilenameselfemployment_proof", '');

            } else if (inputValues.employed.toUpperCase() === "NO") {

                handleChange("showemployment_proof", false);
                handleChange("selectedfilenameemployment_proof", '');
                handleChange("selfemployment_proof", '');
                if(inputValues.employed_cur_status.toUpperCase() === 'SELF EMPLOYED') {
                    handleChange("showselfemployment_proof", true);
                } else {
                    handleChange("showselfemployment_proof", false);
                    handleChange("selectedfilenameselfemployment_proof", '');
                }

            }

    },[inputValues.employed, inputValues.salary_bom, inputValues.employment_sector]);

    useEffect(() => {

        if(customerSalaryData !== undefined  && customerSalaryData.status){
            //console.log(customerSalaryData) ;
            if(customerSalaryData.status === "success" ){
                //dispatch(createAccessLog('FetchSalary', 'Success', 'Customer has salary from bank', cif));
                let prevSalary = customerSalaryData.response || []
                setSalarydata(prevSalary) ;
                setInputValues({ employed: 'YES'});
                setInputValues({ salary_bom : 'Yes'});
                setInputValues({ showsalary_bom : true});
                setInputValues({ showemployment_proof : false});
                setInputValues({showempselection: false});
                setInputValues({showempsector: true});
                setInputValues({'employed_cur_status': '' });

                //let monthlyIncome = inputValues.monthly_income;


                if(prevSalary[0]) {
                    listincome.map((inc, incInx) => {
                        let income = inc['value'].toLowerCase().replace('omr', '').replace('below', '').replace('more than', '').replace(/ /g, '').replace(/,/g, '').split('-')
                        if(
                            (income[1] && (parseInt(income[0]) <= parseInt(prevSalary[0]['PrevSalary'])) && (parseInt(income[1]) >= parseInt(prevSalary[0]['PrevSalary']))) ||
                            (!income[1] && (parseInt(income[0]) > parseInt(prevSalary[0]['PrevSalary'])) && inc['value'].toLowerCase().indexOf('below') > -1) ||
                            (!income[1] && (parseInt(income[0]) < parseInt(prevSalary[0]['PrevSalary'])) && inc['value'].toLowerCase().indexOf('more') > -1)) {
                                setInputValues({monthly_income: inc['value']})
                            return;
                        }
                    });
                }

            } else {
                //dispatch(createAccessLog('FetchSalary', 'Error', 'No bank salary for this customer', cif));
                setInputValues({ salary_bom : 'No'});
                //console.log(inputValues.employed_cur_status)
                /*if(inputValues.employed_cur_status.toUpperCase() !== 'SELF EMPLOYED') {
                    setInputValues({ showemployment_proof : true});
                }*/
            }
        }

    },[customerSalaryData]);

    useEffect(() => {
        var ctemp = [];
        for(var i in countries)
            ctemp.push(countries[i]);

        if(ctemp !== undefined && ctemp.length > 0  ){
            let countrieslist = [];
            ctemp.map((country, indx)=> {

                    let cName = (lang === 'Ar' && country.CName_Ar !== null) ? country.CName_Ar : country.CName;
                    countrieslist.push({option: cName, value: country.CCode})

            });
            //console.log(countrieslist)
            setListcountry(countrieslist) ;
            setListsearchcountry(countrieslist) ;
        }
    },[countries]);

    useEffect(() => {
        var mtemp = [];
        for(var i in maritalstatus)
            mtemp.push(maritalstatus[i]);

        if(mtemp !== undefined && mtemp.length > 0 ){
            let maritalstatuslist = [];
            mtemp.map((maritalstatus)=> {

                let mStatus = (lang === 'Ar' && maritalstatus.MStatus_Ar !== null) ? maritalstatus.MStatus_Ar : maritalstatus.MStatus;
                maritalstatuslist.push({option: mStatus, value: maritalstatus.MStatus})

            });
            setMaritalstatus(maritalstatuslist) ;
        }
    },[maritalstatus, lang]);

    useEffect(() => {
        //console.log(" Unemployed status list : " +unemployedstatus) ;
        var utemp = [];
        for(var i in unemployedstatus)
            utemp.push(unemployedstatus[i]);
        if(utemp !== undefined && utemp.length > 0){
            let listUnemployedStatus = [];
            utemp.map((unemployedstatus, indx)=> {

                let Title = (lang === 'Ar' && unemployedstatus.Title_Ar !== null) ? unemployedstatus.Title_Ar : unemployedstatus.Title;
                listUnemployedStatus.push({option: Title, value:unemployedstatus.Title})

            });
            setListempstatus(listUnemployedStatus) ;
        }
    },[unemployedstatus, lang]);
    useEffect(() => {
        //console.log(listPostalcode) ;
        var ptemp = [];
        for(var i in listPostalcode)
            ptemp.push(listPostalcode[i]);
        if(ptemp !== undefined && ptemp.length > 0){
            let listPostalcodedata = [];
            ptemp.map((listPostalcode, indx)=> {

                listPostalcodedata.push({option: listPostalcode.code + ' - ' + listPostalcode.description, value:listPostalcode.code})

            });
            setPostalCode(listPostalcodedata) ;
            setListsearchPcode(listPostalcodedata) ;
        }
    },[listPostalcode]);


    useEffect(() => {

        var itemp = [];
        for(var i in incomelist)
            itemp.push(incomelist[i]);
        if(itemp !== undefined && itemp.length > 0){
            let incomeList = [];
            itemp.map((incomelist, indx)=> {

                let incomeRange = (lang === 'Ar' && incomelist.IncomeRange_Ar !== null) ? incomelist.IncomeRange_Ar : incomelist.IncomeRange;
                incomeList.push({option: incomeRange, value: incomelist.IncomeRange})

            });

            setListincome(incomeList) ;
        }
    },[incomelist, lang]);

    useEffect(() => {
        var itemp = [];
        for(var i in listPep)
            itemp.push(listPep[i]);
        if(itemp !== undefined && itemp.length > 0){
            let pepList = [];
            itemp.map((pep, indx)=> {
                if(pep.Label) {
                    let pepItem = (lang === 'Ar' && pep.Label_Ar !== null) ? pep.Label_Ar : pep.Label;
                    pepList.push({option: pepItem, value: pep.Label})
                }
            });
            setPepRelativePositions(pepList) ;
        }
    },[listPep, lang]);

    useEffect(() => {
        var itemp = [];
        for(var i in listRelationships)
            itemp.push(listRelationships[i]);
        if(itemp !== undefined && itemp.length > 0){
            let relashionshipList = [];
            itemp.map((relation, indx)=> {
                if(relation.Label) {
                    let relationItem = (lang === 'Ar' && relation.Label_Ar !== null) ? relation.Label_Ar : relation.Label;
                    relashionshipList.push({option: relationItem, value: relation.Label})
                }
            });
            setListrelatioshipaddrs(relashionshipList) ;
        }
    },[listRelationships, lang]);

    useEffect(() => {
        var itemp = [];
        for(var i in allEmploymentSectors)
            itemp.push(allEmploymentSectors[i]);
        if(itemp !== undefined && itemp.length > 0){
            let sectorsList = [];
            itemp.map((sector, indx)=> {
                if(sector.Label) {
                    let sectorItem = (lang === 'Ar' && sector.Label_Ar !== null) ? sector.Label_Ar : sector.Label;
                    sectorsList.push({option: sectorItem, value: sector.Label, child: sector.Customer_proof})
                }
            });
            //console.log(sectorsList)
            setEmploymentSectors(sectorsList) ;
        }
    },[allEmploymentSectors, lang]);


    useEffect(() => {
        var itemp = [];
        for(var i in empProofCategories)
            itemp.push(empProofCategories[i]);
        if(itemp !== undefined && itemp.length > 0){
            let empProofList = [];
            itemp.map((emp, indx)=> {
                if(emp.Label) {
                    let empItem = (lang === 'Ar' && emp.Label_Ar !== null) ? emp.Label_Ar : emp.Label;
                    empProofList.push({option: empItem, value: emp.Label})
                }
            });
            setempProofs(empProofList) ;
        }
    },[empProofCategories, lang]);

    useEffect(() => {
        var itemp = [];
        for(var i in selfEmpProofCategories)
            itemp.push(selfEmpProofCategories[i]);
        if(itemp !== undefined && itemp.length > 0){
            let empProofList = [];
            itemp.map((emp, indx)=> {
                if(emp.Label) {
                    let empItem = (lang === 'Ar' && emp.Label_Ar !== null) ? emp.Label_Ar : emp.Label;
                    empProofList.push({option: empItem, value: emp.Label})
                }
            });
            setselfEmpProofs(empProofList) ;
        }
    },[selfEmpProofCategories, lang]);

    useEffect(() => {
        var itemp = [];
        for(var i in addProofCategories)
            itemp.push(addProofCategories[i]);
        if(itemp !== undefined && itemp.length > 0){
            let addProofList = [];
            itemp.map((emp, indx)=> {
                if(emp.Label) {
                    let empItem = (lang === 'Ar' && emp.Label_Ar !== null) ? emp.Label_Ar : emp.Label;
                    addProofList.push({option: empItem, value: emp.Label})
                }
            });
            setaddProofs(addProofList) ;
        }
    },[addProofCategories, lang]);


    useEffect(() => {
        setCustdata(customerdata)

        if(getToken().customer_id !== undefined) {
            setCid(getToken().customer_id) ;
        }
        const params = (new URL(document.location)).searchParams;
        let ref = params.get("ref");


        let addtnl_info = {} ;
        if(customerdata !== undefined && customerdata !== null && (customerdata.name !== null && customerdata.name !== undefined) && ref !== 'otp'){
            setLoader(false)
            //console.log(customerdata)
            let showempselection ;
            let showempsector ;
            let  showsalary_bom ;
            let showselfemployment_proof  = false;
            let showemployment_proof ;
            let emplyd = customerdata.employed ? customerdata.employed.toUpperCase() : 'No';


            let employed_cur_status = customerdata.employed_cur_status ? customerdata.employed_cur_status.toUpperCase() : '';
            //employed_cur_status = listempstatus.filter(a => a.value.toUpperCase() === employed_cur_status)[0];
            //employed_cur_status = (employed_cur_status && employed_cur_status.value) ? employed_cur_status.value : '';
            let curStatus = employed_cur_status;

            if(curStatus === 'SELF EMPLOYED') {
                //emplyd = 'YES';
            }
            if(inputValues.salary_bom.toUpperCase() === 'YES') {
                emplyd = 'YES';
            }
            if(customerdata.sector_code === '1002') {
                emplyd = 'YES';
            }
            if(emplyd.toUpperCase() === "YES"){
                showempsector = true;
                showempselection = false ;
                showsalary_bom = true ;
                showselfemployment_proof = false ;
                showemployment_proof = true ;
            }else{
                showempsector = false;
                showempselection = true ;
                showsalary_bom = false ;
                //showselfemployment_proof = true ;
                showemployment_proof = false ;

            }
            if(inputValues.salary_bom.toUpperCase() === 'YES') {
                showemployment_proof = false ;
                showselfemployment_proof = false ;
                showsalary_bom = true ;
                showempselection = false ;
                showempsector = true
            }
            //console.log(emplyd)
            //console.log(curStatus)
            if(emplyd.toUpperCase() === 'NO' && curStatus === 'SELF EMPLOYED') {
                showselfemployment_proof = true ;
                showemployment_proof = false ;
            }
            if(emplyd.toUpperCase() === 'NO' && curStatus !== 'SELF EMPLOYED') {
                showselfemployment_proof = false ;
            }
            //console.log(showselfemployment_proof)
            let crs = 0;
            let crsDetails = [];
            if(customerdata.country_taxresidence){
                //console.log(customerdata.country_taxresidence)
                let slctd = ['', '', ''];
                Object.keys(customerdata.country_taxresidence).map(function(key) {
                    if((customerdata.country_taxresidence[key]['tinnumber'] !== '' || customerdata.country_taxresidence[key]['reason'] !== '') && customerdata.country_taxresidence[key]['country_taxresidence']) {
                        let country_crs = customerdata.country_taxresidence[key]['country_taxresidence'] ? customerdata.country_taxresidence[key]['country_taxresidence'] : '';
                        //country_crs = listcountry.filter(a => a.value.toUpperCase() === country_crs.toUpperCase())[0];
                        //country_crs = (country_crs && country_crs.value) ? country_crs.value : '';

                        crsDetails.push({
                            'tinnumber': customerdata.country_taxresidence[key]['tinnumber'],
                            'country_taxresidence': country_crs,
                            'reason': customerdata.country_taxresidence[key]['reason'],
                            'details': customerdata.country_taxresidence[key]['details'] || '',
                        });
                        let crsReason = crsNoTinReasons.filter(a => a.label === customerdata.country_taxresidence[key]['reason'])[0];
                        slctd[crs] = crsReason && crsReason['key'] ? crsReason['key'] : '';
                        crs++;
                    }
                });
                //console.log(slctd)
                setcrsReasonSelected(slctd);
            }

            let showcrsselection ;
            if(customerdata.taxresident_not_oman && customerdata.taxresident_not_oman.toUpperCase() === "YES"){
                showcrsselection = true ;
            }else{
                showcrsselection = false ;
            }


            let showw8ben ;
            let showw9 ;

            showw8ben = false;
            showw9 = false ;

            let ustax_resident = customerdata.ustax_resident === "" ? 'No' : customerdata.ustax_resident;
            ustax_resident = ustax_resident.charAt(0).toUpperCase() + ustax_resident.slice(1).toLowerCase();
            let usaddress_tel = customerdata.usaddress_tel === "" ? 'No' : customerdata.usaddress_tel;
            usaddress_tel = usaddress_tel.charAt(0).toUpperCase() + usaddress_tel.slice(1).toLowerCase();
            let us_nationality = customerdata.us_nationality === "" ? 'No' : customerdata.us_nationality;
            us_nationality = us_nationality.charAt(0).toUpperCase() + us_nationality.slice(1).toLowerCase();
            let us_payments = customerdata.us_payments === "" ? 'No' : customerdata.us_payments;
            us_payments = us_payments.charAt(0).toUpperCase() + us_payments.slice(1).toLowerCase();

            if(customerdata.addtln_info !== undefined && customerdata.addtln_info !== null) {
                addtnl_info =  JSON.parse(customerdata.addtln_info) ;
                //console.log(addtnl_info) ;
                showw9 = addtnl_info.showW9 ;
                showw8ben = addtnl_info.showW8 ;
            }else{
                if(ustax_resident === "Yes" || us_nationality === "Yes"){
                    showw9 = true ;
                }
                if(usaddress_tel === "Yes" || us_payments === "Yes"){
                    showw8ben = true ;
                }
            }

            if(ustax_resident === "Yes" || usaddress_tel === "Yes" || us_nationality === "Yes" || us_payments === "Yes") {
                updateNonstp();
            }


            //validating data rom T24 exist in dropdowns
            //postalcode
            let pincode = customerdata.picode;
            pincode = listpostalcode.filter(a => a.value === pincode)[0];
            pincode = (pincode && pincode.value) ? pincode.value : '';

            //employer postal code
            let pccode = customerdata.employer_pccode ? customerdata.employer_pccode : '';
            pccode = listpostalcode.filter(a => a.value === pccode)[0];
            pccode = (pccode && pccode.value) ? pccode.value : '';

            //employment sector
            let employement_sector = customerdata.employement_sector ? customerdata.employement_sector : '';
            //employement_sector = employmentSectors.filter(a => a.value === employement_sector)[0];
            //employement_sector = (employement_sector && employement_sector.value) ? employement_sector.value : '';

            //marital status
            let marital_status = customerdata.marital_status ? customerdata.marital_status : '';
            //marital_status = listmaritalstatus.filter(a => a.value === marital_status)[0];
            //marital_status = (marital_status && marital_status.value) ? marital_status.value : '';

            //country of birth
            let country_birth = customerdata.country_birth ? customerdata.country_birth : '';
            //country_birth = listcountry.filter(a => a.value.toUpperCase() === country_birth.toUpperCase())[0];
            //country_birth = (country_birth && country_birth.value) ? country_birth.value : '';

            //monthly income
            let monthly_income = customerdata.monthly_income ? customerdata.monthly_income : '';
            //monthly_income = listincome.filter(a => a.value.toUpperCase() === monthly_income.toUpperCase())[0];
            //monthly_income = (monthly_income && monthly_income.value) ? monthly_income.value : '';

            let landmark = customerdata.landmark || '';
            landmark = landmark.replace(/[^a-zA-Z0-9 ]/g,'');
            let building = customerdata.building || '';
            building = building.replace(/[^a-zA-Z0-9 ]/g,'');

            //console.log(addtnl_info.showselfemployment_proof !== undefined ? addtnl_info.showselfemployment_proof : showselfemployment_proof)
            setInputValues({
            country_birth: country_birth,
            marital_status: marital_status ,
            name: customerdata.name ,
            email: customerdata.email || '',
            mobile : customerdata.mobile || '',
            address: customerdata.address || '',
            house: customerdata.house || '',
            landmark: landmark || '',
            building: building || '',
            po_box: customerdata.po_box || '',
            way: customerdata.way || '',
            city: customerdata.city || '',
            wilayat: customerdata.wilayat || '',
            //picode: (idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT') ? '999' : pincode,
            picode: pincode,
            monthly_income: monthly_income,
            employed: emplyd,
            employed_cur_status: curStatus,
            employment_sector: employement_sector,
            ustax_resident: ustax_resident ,
            usaddress_tel: usaddress_tel,
            us_nationality: us_nationality,
            us_payments: us_payments,
            taxresident_not_oman: crs === 0 ? 'No' : 'Yes',
            taxresident_count: crs,
            showtaxresidence1: crs >= 1 ? true : false,
            showtaxresidence2: crs >= 2 ? true : false,
            showtaxresidence3: crs >= 3 ? true : false,
            country_taxresidence1: crsDetails[0] ? crsDetails[0]['country_taxresidence'] : '',
            tinnumber1: crsDetails[0] ? crsDetails[0]['tinnumber'] : '',
            reason1: crsDetails[0] ? crsDetails[0]['reason'] : '',
            details1: crsDetails[0] ? crsDetails[0]['details'] : '',
            country_taxresidence2: crsDetails[1] ? crsDetails[1]['country_taxresidence'] : '',
            tinnumber2: crsDetails[1] ? crsDetails[1]['tinnumber'] : '',
            reason2: crsDetails[1] ? crsDetails[1]['reason'] : '',
            details2: crsDetails[1] ? crsDetails[1]['details'] : '',
            country_taxresidence3: crsDetails[2] ? crsDetails[2]['country_taxresidence'] : '',
            tinnumber3: crsDetails[2] ? crsDetails[2]['tinnumber'] : '',
            reason3: crsDetails[2] ? crsDetails[2]['reason'] : '',
            details3: crsDetails[2] ? crsDetails[2]['details'] : '',
            current_former_pep: customerdata.current_former_pep ? customerdata.current_former_pep : '',
            pep_category: customerdata.pep_category ? customerdata.pep_category : '',
            pep_relation: customerdata.pep_relation ? customerdata.pep_relation : '',
            pep_name: customerdata.pep_name ? customerdata.pep_name : customerdata.name,
            pep_period_from: customerdata.pep_period_from ? customerdata.pep_period_from : '',
            pep_period_to: customerdata.pep_period_to ? customerdata.pep_period_to : '',
            pep_country: customerdata.pep_country ? customerdata.pep_country : '',
            pep_relative: customerdata.pep_relative,
            pep_busns_associate: customerdata.pep_busns_associate,
            pep_orgname_directv: customerdata.pep_orgname_directv,
            pep_orgposition_relative: customerdata.pep_orgposition_relative,
            ownproperty_oman: customerdata.ownproperty_oman,
            relative_family_oman: customerdata.relative_family_oman,
            own_business_oman: customerdata.own_business_oman,
            benefit_from_emplyr: customerdata.benefit_from_emplyr,
            authorise_bank_credit: customerdata.authorise_bank_credit,
            other_reason: customerdata.other_reason,
            showdocresidenttononresident:customerdata.other_reason && customerdata.other_reason.toUpperCase() === 'YES' ? true : false,
            accholding_reason1: customerdata.accholding_reason1,
            accholding_reason2: customerdata.accholding_reason1,
            showempselection: showempselection,
            showempsector: showempsector,
            showcrsselection: showcrsselection,
            cust_id_type: idtype,
            updation_type: renewType,
            showsalary_bom : showsalary_bom,
            showW8: showw8ben,
            showW9: showw9,
            selectedfilenameaddress_proof : addtnl_info.selectedfilenameaddress_proof !== undefined ? addtnl_info.selectedfilenameaddress_proof : '',
            selectedfilenameauthorisation_letter : addtnl_info.selectedfilenameauthorisation_letter !== undefined ? addtnl_info.selectedfilenameauthorisation_letter : '',
            selectedfilenameemployment_proof : addtnl_info.selectedfilenameemployment_proof !== undefined ? addtnl_info.selectedfilenameemployment_proof : '',
            selectedfilenamew8 : addtnl_info.selectedfilenamew8 !== undefined ? addtnl_info.selectedfilenamew8 : '',
            selectedfilenamew9 : addtnl_info.selectedfilenamew8 !== undefined ? addtnl_info.selectedfilenamew9 : '',
            selectedfilenameselfemployment_proof : addtnl_info.selectedfilenameselfemployment_proof !== undefined ? addtnl_info.selectedfilenameselfemployment_proof : '',
            showaddress_proof: addtnl_info.showaddress_proof !== undefined ? addtnl_info.showaddress_proof : true,
            addressProofCategory: addtnl_info.addressProofCategory !== undefined ? addtnl_info.addressProofCategory : '',
            employmentProofCategory: addtnl_info.employmentProofCategory !== undefined ? addtnl_info.employmentProofCategory : '',
            selfemploymentProofCategory: addtnl_info.selfemploymentProofCategory !== undefined ? addtnl_info.selfemploymentProofCategory : '',
            showauthorisation_letter: addtnl_info.showauthorisation_letter !== undefined ? addtnl_info.showauthorisation_letter : false,
            showselfemployment_proof: addtnl_info.showselfemployment_proof !== undefined ? addtnl_info.showselfemployment_proof : showselfemployment_proof,
            showemployment_proof: addtnl_info.showemployment_proof !== undefined ? addtnl_info.showemployment_proof : showemployment_proof,
            address_proof_own_name: addtnl_info.address_proof_own_name !== undefined ? addtnl_info.address_proof_own_name : 'Yes',
            relatioshipaddrs: addtnl_info.relatioshipaddrs !== undefined ? addtnl_info.relatioshipaddrs : '',
            //employmentStatus: addtnl_info.employmentStatus !== undefined ? addtnl_info.employmentStatus : '',
            preview: addtnl_info.preview !== undefined ? addtnl_info.preview : '',
            filenamedocresidenttononresident: addtnl_info.filenamedocresidenttononresident !== undefined ? addtnl_info.filenamedocresidenttononresident : '',
            filenameadditionaldoc: addtnl_info.filenameadditionaldoc !== undefined ? addtnl_info.filenameadditionaldoc : '',
            nameofemployer: customerdata.employer_name ? customerdata.employer_name : '',
            employersaddress: customerdata.employer_address ? customerdata.employer_address : '',
            pcCode: pccode,
            location: customerdata.employer_location ? customerdata.employer_location : '',
            presentposition: customerdata.employer_position ? customerdata.employer_position : '',

        });
//console.log(inputValues)
        } else {
            if(customerdata && customerdata.message && (customerdata.message === 'Insufficient Data')) {
                setLoader(false)
                //dispatch(createAccessLog('GeneralInstructions', 'Error', 'Insufficient Data', cif));
                this.setState({"errorMessage": data[lang]['unabletofetch']});
            }else if(customerdata !== undefined && customerdata !== null && (customerdata.name === null || customerdata.name === undefined)) {
                //dispatch(createAccessLog('GetCustomerInfo', 'Error', 'Error in fetching details from T24 for this cif: ' + cif, cif));
                setCustomerErrorMessages(data[lang]['unabletofetch']);
                setLoader(false)
            }
        }

    },[customerdata, listpostalcode]);

    useEffect(() => {
        if(boxcount === 1) {
            setBoxtitle(data[lang]['personalInformation']);
        }else if(boxcount ===2){
            setBoxtitle(data[lang]['residenceAddress']);
        }else if(boxcount ===3 ){
            setBoxtitle(data[lang]['employmentDetails']) ;
        }else if(boxcount ===4 ){
            setBoxtitle(data[lang]['usIndecia']) ;
        }else if(boxcount ===5 ){
            setBoxtitle(data[lang]['commonReportingStandard']) ;
        }else if(boxcount ===6 ){
            setBoxtitle(data[lang]['exposedPersonMessage']) ;
            /*if(residentonon === false){
                setJumpcount(2);
            }else{
                setJumpcount(1);
            }*/

        }else if(boxcount ===7 ){
            setBoxtitle(data[lang]['residentToNon']) ;
        }else if(boxcount ===8 ){
            setBoxtitle(data[lang]['addnlDocuments']) ;
        }


    },[boxcount,boxtitle]);

    useEffect(() => {
         //console.log(customerupdatestats) ;


       if(customerupdatestats.status !== undefined && next === true) {
           if (customerupdatestats.status === 'success') {
               //dispatch(createAccessLog('Customer Form Submission' , 'Success', 'Update customer info successfully',cif));
               setLoader(false)
               setLoaderMesssage('')
               setSubmitErrorMessages('')
               //setModalIn(false);
               sendOtp();
           } else if (customerupdatestats.status === 'error' || customerupdatestats.status.toLowerCase() === 'failure') {
               dispatch(createAccessLog('Customer Form Submission ' + customerupdatestats.status , 'Error', customerupdatestats.message,cif));
               setLoader(false)
               setSubmitErrorMessages(data[lang]['unabletocommunicate'])

               //setModalIn(false);
               //sendOtp();
           }
       }/* else {
           if(loader === true) {
               dispatch(createAccessLog('Customer Form Submission' , 'Error', customerupdatestats.message || '',cif));
               setLoader(false)
               setLoaderMesssage('')
               setSubmitErrorMessages(customerupdatestats.message)
           }
       }*/

    },[customerupdatestats]);
const closePopupModal = () => {
    setLoader(false)
    setSubmitErrorMessages('')
    setCustomerErrorMessages('')
}
const closeErrorModal = () => {
    setLoader(false)
    setCustomerErrorMessages('')
}
const sendOtp = () =>{
    history.push('/submit-otp');

}

const updateNonstp = () =>{
    if(customerdata.sector_code !== '1002') {
        setNonstp(true);
        setInputValues({'nonstp': true});
        dispatch(UpdateNonSTP(true));
    } else {
        updateStp();
    }
}
    const updateStp = () =>{
        setNonstp(false) ;
        setInputValues({ 'nonstp' : false});
        dispatch(UpdateNonSTP(false));

    }




    const nextPage = () =>{
        setNext(true)
        setLoader(true)
        setLoaderMesssage(data[lang]['submittingdetails'])
        //setModalIn(true);
        /* history.push('/thank-you'); */

        /* console.log("non stp status before move next"+nonstp) ;
        setNonstp(false) ;
        setInputValues({ 'nonstp' : false});
        dispatch(UpdateNonSTP(false));  */

        //console.log('Non stp value in local storage - ', getToken().nonstp)

        //console.log('Non stp value in inputValues - ', inputValues.nonstp) ;

        if(getToken().nonstp === 'true'){
            inputValues.nonstp = true
            }
        //console.log('Non stp value in inputValues if true in local storage - ', inputValues.nonstp) ;

        const myObjStr = JSON.stringify(inputValues);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');

        //(updateCustomerInfo(cid, enc_myObjStr, nonstp)) ;



        let strjson = '' ;
        let urlarray = []  ;
        let ftypearray = [] ;


        /*if(inputValues.preview !== ""){
            urlarray.push(inputValues.preview) ;
            ftypearray.push("preview");
        }*/
//console.log(urlarray)
        if( urlarray.length > 0){
            strjson += '{' ;
            for(var i=0;i<urlarray.length;i++){
                strjson += ' "'+ftypearray[i]+'" : "'+urlarray[i]+'" ';
                if(urlarray.length -1  !== i && i >=0 ) {
                    strjson += ',' ;
                }
            }
            strjson += '}' ;
            //console.log(strjson) ;

            //console.log(strjson) ;
            let formdata = new FormData();
            formdata.append('userid', journeyid);
            formdata.append('data', strjson);
            //dispatch(createAccessLog('uploadCustomerDocs' , 'Started', 'Update customer documents',cif));
            let postData = {data: strjson}
            dispatch(customerAddnlDocsUpload(postData));



        } else {
            ///console.log(cid)
            //console.log(inputValues)
            let obj = inputValues;
            kycStore.getState().KYCState.inputValues = inputValues;
            if(obj.current_former_pep.toUpperCase() !== 'YES' && obj.pep_relative.toUpperCase() !== 'YES' && obj.pep_busns_associate.toUpperCase() !== 'YES') {
                obj.pep_name = '';
                obj.pep_period_from = '';
                obj.pep_period_to = '';
                obj.pep_country = '';
            }
            if((idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT') && (obj.picode === '' || obj.picode === null || obj.picode === undefined)) {
                obj.picode = '999';
            }

            if(obj.employed.toUpperCase() === "YES"){
                obj.employed_cur_status = '';
            }else{
                obj.employment_sector = '';
            }
            //console.log(obj)
            const myObjStr = JSON.stringify(obj);
            const enc_myObjStr = new Buffer(myObjStr).toString('base64');
            setCountDown(4)
            if( kycStore.getState().KYCState.apiTimestamp['updateCustomerInfo']) {
                kycStore.getState().KYCState.apiTimestamp['updateCustomerInfo']['cnt'] = -1;
            }
            dispatch(updateCustomerInfo(cid, enc_myObjStr, nonstp));
            //dispatch(createAccessLog('updateCustomerInfo' , 'Started', 'Update customer info started ' + inputValues.cust_id_type,cif));

        }
       }



    useEffect(() => {
        //console.log(uploadFormResponsedata);

        if(uploadFormResponsedata.message !== undefined){
            setLoader(false)
            //console.log(uploadFormResponsedata) ;
            //dispatch(createAccessLog('Additional Documents' , 'Response', uploadFormResponsedata.message,cif));
            if(uploadFormResponsedata.message.indexOf("Success") >= 0 ){
                setInputValues({[userFile.name]: userFile.value, [userFile.filenamelabel]: userFile.filename});
                //dispatch(createAccessLog('Additional Documents' , 'Success', 'Uploaded Additional Documents successfully',cif));
                //console.log(uploadFormResponsedata.key)
                /*if(uploadFormResponsedata.key === 'preview') {
                    const myObjStr = JSON.stringify(inputValues);
                    const enc_myObjStr = new Buffer(myObjStr).toString('base64');
                    dispatch(updateCustomerInfo(cid, enc_myObjStr, nonstp));
                    dispatch(createAccessLog('updateCustomerInfo', 'Started', 'Update customer info started ' + inputValues.cust_id_type, cif));
                }*/
            }
            if(uploadFormResponsedata.message.indexOf("Failure") >= 0 ) {
               // dispatch(createAccessLog('Additional Documents' , 'Error', uploadFormResponsedata.message,cif));


                if(uploadFormResponsedata.key !== 'preview') {
                    //setLoader(false)
                    setCustomerErrorMessages(data[lang]['unabletocommunicate']);
                    setInputValues({[userFile.name]: '', [userFile.filenamelabel]: ''});
                }
            }
        }

    },[uploadFormResponsedata]);

       const editPage = () =>{
           //dispatch(createAccessLog('Edit Form 1' , 'Started', 'Customer Details Personal Information',cif));
           setEditform(!editform) ;
           setBoxcount(1);
       }


    useEffect(() => {
        let id  =  curstep  ;
       //console.log(inputValues)
    //console.log(listmaritalstatus)
    //console.log(inputValues.marital_status)
        if(id === 1){
            let countryBirth = listcountry.filter(a => a.value === inputValues.country_birth)[0];
            countryBirth = (countryBirth && countryBirth.option) ? countryBirth.option : '';



            const formfields = [
                {"label": data[lang]['countryOfBirth'],  "fieldname": "country_birth",  "value": inputValues.country_birth, "option": countryBirth, "readonly": false, "inputtype": "searchlist", "selectvalues": listcountry},
                {"label": data[lang]['maritalStatus'], "fieldname": "marital_status", "value": inputValues.marital_status, "readonly": false, "inputtype": "select",  "selectvalues": listmaritalstatus}
                /*{"label": data[lang]['name'], "fieldname": "name", "value": inputValues.name, "inputtype": "text", "readonly": true}*/
            ] ;
            setSection(formfields)

        }else if(id === 2){
            const formfields = [
                {"label": data[lang]['address'], "fieldname": "address", "value": inputValues.address, "inputtype": "text", "readonly": false},
                {"label": data[lang]['po_box'], "fieldname": "po_box", "value": inputValues.po_box, "inputtype": "text", "readonly": false},
                {"label": data[lang]['landmark'], "fieldname": "landmark", "value": inputValues.landmark, "inputtype": "text", "readonly": false},
                {"label": data[lang]['houseFlatNo'], "fieldname": "house", "value": inputValues.house, "inputtype": "text", "readonly": false},
                {"label": data[lang]['buildingNo'], "fieldname": "building", "value": inputValues.building, "inputtype": "text", "readonly": false},
                {"label": data[lang]['wayNo'], "fieldname": "way", "value": inputValues.way, "inputtype": "text", "readonly": false},
                {"label": data[lang]['city'],"fieldname": "city",  "value": inputValues.city, "inputtype": "text", "readonly": false},
                {"label": (idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT') ? data[lang]['statewilayat'] : data[lang]['wilayat'], "fieldname": "wilayat", "value": inputValues.wilayat, "inputtype": "text", "readonly": false},
            ];
            if((idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT')){
                //formfields.push({"label": data[lang]['pinCode'], "fieldname": "picode", "value": inputValues.picode, "inputtype": "text",  "readonly": true})
                formfields.push({"label": data[lang]['pinCode'], "fieldname": "picode", "value": picode, "inputtype": "searchlist",  "selectvalues": listpostalcode})
            }else{
                formfields.push({"label": data[lang]['pinCode'], "fieldname": "picode", "value": picode, "inputtype": "searchlist",  "selectvalues": listpostalcode})
            }

            setSection(formfields)
        }else if(id === 3){
            let monthlyIncome = inputValues.monthly_income;

            const formfields = [
                {"label": data[lang]['monthlyIncom'], "fieldname": "monthly_income", "value": monthlyIncome, "inputtype": "select", "showfield" : true, "selectvalues": listincome, "readonly": false},
                {"label": data[lang]['areYouEmployed'], "fieldname": "employed", "value": inputValues.employed, "inputtype": "switch",  "selectvalues": yesnovalue,  "readonly": inputValues.salary_bom.toUpperCase() === 'YES' ? true : false},
                {"label": data[lang]['mentionCurrentStatus'], "fieldname": "employed_cur_status", "value": inputValues.employed_cur_status, "inputtype": "select",  "showfield" : inputValues.showempselection , "selectvalues": listempstatus, "readonly": false},
                {"label": data[lang]['employmentsector'], "fieldname": "employment_sector", "value": inputValues.employment_sector, "inputtype": "select",  "showfield" : inputValues.showempsector , "selectvalues": employmentSectors, "readonly": false}
            ];
            setSection(formfields)
        }else if(id === 4){
            const formfields = [
                {"label": data[lang]['usTaxResident'], "fieldname": "ustax_resident", "value": inputValues.ustax_resident, "inputtype": "switch",  "selectvalues": yesnovalue,  "readonly": false},
                {"label": data[lang]['addressInUS'],"fieldname": "usaddress_tel", "value": inputValues.usaddress_tel, "inputtype": "switch",  "selectvalues": yesnovalue,  "readonly": false},
                {"label": data[lang]['usNationality'], "fieldname": "us_nationality", "value": inputValues.us_nationality, "inputtype": "switch",  "selectvalues": yesnovalue,  "readonly": false},
                {"label": data[lang]['makingPaymentsToUs'], "fieldname": "us_payments", "value": inputValues.us_payments, "inputtype": "switch",  "selectvalues": yesnovalue,  "readonly": false}

            ];
            setSection(formfields)
        }else if(id === 5) {

            let country_taxresidence1 = listcountry.filter(a => a.value === inputValues.country_taxresidence1)[0];
            let country_taxresidence_option_1 = (country_taxresidence1 && country_taxresidence1.option) ? country_taxresidence1.option : '';
            country_taxresidence1 = (country_taxresidence1 && country_taxresidence1.value) ? country_taxresidence1.value : '';


            let country_taxresidence2 = listcountry.filter(a => a.value === inputValues.country_taxresidence2)[0];
            let country_taxresidence_option_2 = (country_taxresidence2 && country_taxresidence2.option) ? country_taxresidence2.option : '';
            country_taxresidence2 = (country_taxresidence2 && country_taxresidence2.value) ? country_taxresidence2.value : '';

            let country_taxresidence3 = listcountry.filter(a => a.value === inputValues.country_taxresidence3)[0];
            let country_taxresidence_option_3 = (country_taxresidence3 && country_taxresidence3.option) ? country_taxresidence3.option : '';
            country_taxresidence3 = (country_taxresidence3 && country_taxresidence3.value) ? country_taxresidence3.value : '';

            //let country_taxresidence4 = listcountry.filter(a => a.value === inputValues.country_taxresidence4)[0];
            //country_taxresidence4 = (country_taxresidence4 && country_taxresidence4.option) ? country_taxresidence4.option : '';
//alert(country_taxresidence1)
//console.log(listcountry)
            const crsfields = [];
            if(inputValues.showtaxresidence1) {
                crsfields.push({
                    'country': {"label": data[lang]['countryOfTaxResident1'], "fieldname": "country_taxresidence1", "value": country_taxresidence1, "option": country_taxresidence_option_1, "inputtype": "searchlist", "showfield" : inputValues.showtaxresidence1, "readonly": false, "selectvalues": listcountry },
                    'tin': {"label": data[lang]['tinNumber1'], "fieldname": "tinnumber1", "value": inputValues.tinnumber1, "inputtype": "text", "showfield" : inputValues.showtaxresidence1 , "readonly": false},
                    'reason': {"label": data[lang]['crsreason1'], "fieldname": "reason1", "value": crsReason1, "inputtype": "selectlist", "showfield" : inputValues.showtaxresidence1 , "cnt": 1, "readonly": false}
                })
            }
            if(inputValues.showtaxresidence2) {
                crsfields.push({
                    'country': {"label": data[lang]['countryOfTaxResident2'], "fieldname": "country_taxresidence2", "value": country_taxresidence2, "option": country_taxresidence_option_2, "inputtype": "searchlist", "showfield" : inputValues.showtaxresidence2, "readonly": false, "selectvalues": listcountry },
                    'tin': {"label": data[lang]['tinNumber2'], "fieldname": "tinnumber2", "value": inputValues.tinnumber2, "inputtype": "text", "showfield" : inputValues.showtaxresidence2 , "readonly": false},
                    'reason': {"label": data[lang]['crsreason2'], "fieldname": "reason2", "value": crsReason2, "inputtype": "selectlist", "showfield" : inputValues.showtaxresidence2 , "cnt": 2, "readonly": false}
                })
            }
            if(inputValues.showtaxresidence3) {
                crsfields.push({
                    'country': {"label": data[lang]['countryOfTaxResident3'], "fieldname": "country_taxresidence3", "value": country_taxresidence3, "option": country_taxresidence_option_3, "inputtype": "searchlist", "showfield" : inputValues.showtaxresidence3, "readonly": false, "selectvalues": listcountry },
                    'tin': {"label": data[lang]['tinNumber3'], "fieldname": "tinnumber3", "value": inputValues.tinnumber3, "inputtype": "text", "showfield" : inputValues.showtaxresidence3 , "readonly": false},
                    'reason': {"label": data[lang]['crsreason3'], "fieldname": "reason3", "value": crsReason3, "inputtype": "selectlist", "showfield" : inputValues.showtaxresidence3 , "cnt": 3, "readonly": false}
                })
            }
            const formfields = [
                {
                    "label": data[lang]['taxResidentOtherCountry'],
                    "fieldname": "taxresident_not_oman",
                    "value": inputValues.taxresident_not_oman,
                    "inputtype": "switch",
                    "showfield" : true,
                    "selectvalues": yesnovalue,
                    "readonly": false
                },
                {"label": data[lang]['countryOfTaxResident1'], "fieldname": "country_taxresidence1", "value": country_taxresidence1, "option": country_taxresidence_option_1, "inputtype": "searchlist", "showfield" : inputValues.showtaxresidence1, "readonly": false, "selectvalues": listcountry },
                {"label": data[lang]['tinNumber1'], "fieldname": "tinnumber1", "value": inputValues.tinnumber1, "inputtype": "text", "showfield" : inputValues.showtaxresidence1 , "readonly": false},
                {"label": data[lang]['crsreason1'], "fieldname": "reason1", "value": crsReason1, "inputtype": "selectlist", "showfield" : inputValues.showtaxresidence1 , "cnt": 1, "readonly": false},
                {"label": data[lang]['countryOfTaxResident2'], "fieldname": "country_taxresidence2", "value": country_taxresidence2, "option": country_taxresidence_option_2, "inputtype": "searchlist", "showfield" : inputValues.showtaxresidence2, "readonly": false, "selectvalues": listcountry },
                {"label": data[lang]['tinNumber2'], "fieldname": "tinnumber2", "value": inputValues.tinnumber2, "inputtype": "text", "showfield" : inputValues.showtaxresidence2 , "readonly": false},
                {"label": data[lang]['crsreason2'], "fieldname": "reason2", "value": crsReason2, "inputtype": "selectlist", "showfield" : inputValues.showtaxresidence2 , "cnt": 2, "readonly": false},
                {"label": data[lang]['countryOfTaxResident3'], "fieldname": "country_taxresidence3", "value": country_taxresidence3, "option": country_taxresidence_option_3, "inputtype": "searchlist", "showfield" : inputValues.showtaxresidence3, "readonly": false, "selectvalues": listcountry },
                {"label": data[lang]['tinNumber3'], "fieldname": "tinnumber3", "value": inputValues.tinnumber3, "inputtype": "text", "showfield" : inputValues.showtaxresidence3 , "readonly": false},
                {"label": data[lang]['crsreason3'], "fieldname": "reason3", "value": crsReason3, "inputtype": "selectlist", "showfield" : inputValues.showtaxresidence3 , "cnt": 3, "readonly": false},
                {"label": data[lang]['countryOfTaxResident4'], "fieldname": "country_taxresidence4", "value": country_taxresidence4, "option": country_taxresidence4, "inputtype": "searchlist", "showfield" : inputValues.showtaxresidence4, "readonly": false, "selectvalues": listcountry },
                {"label": data[lang]['tinNumber4'], "fieldname": "tinnumber4", "value": inputValues.tinnumber4, "inputtype": "text", "showfield" : inputValues.showtaxresidence4 , "readonly": false},
                {
                    "value": inputValues.taxresident_count,
                    "inputtype": "Add",
                    "showfield" : inputValues.showcrsselection,
                }
            ];
            setcrsfields(crsfields)
            setSection(formfields)
        }else if(id === 6) {
            let pepPeriodFrom = null;
            if(inputValues.pep_period_from !== '') {
                //console.log(inputValues.pep_period_from)
                pepPeriodFrom = inputValues.pep_period_from.split('/');
                //console.log(parseInt(pepPeriodFrom[1]))
                //console.log(parseInt(pepPeriodFrom[0]) - 1)
                pepPeriodFrom = new Date(parseInt(pepPeriodFrom[1]), parseInt(pepPeriodFrom[0]) - 1, 1, 0, 0, 0, 0);
                //console.log(pepPeriodFrom)
            }
            let pepPeriodTo = null;
            if(inputValues.pep_period_to !== '') {
                pepPeriodTo = inputValues.pep_period_to.split('/');
                pepPeriodTo = new Date(parseInt(pepPeriodTo[1]), parseInt(pepPeriodTo[0]) - 1, 1, 0, 0, 0, 0);
                //console.log(pepPeriodTo)
            }

            if(pepPeriodTo !== null && pepPeriodFrom !== null && pepPeriodTo <= pepPeriodFrom) {
                pepPeriodTo = new Date(pepPeriodFrom)
                pepPeriodTo = new Date(pepPeriodTo.setMonth(pepPeriodTo.getMonth()+1))
            }
            //console.log(pepListLabels)
            const formfields = [
                {"fieldname": "current_former_pep","label": data[lang]['pepquestion1'], "value": inputValues.current_former_pep, "inputtype": "switch",  "selectvalues": yesnovalue,  "readonly": false, child:inputValues.current_former_pep.toUpperCase()==='YES'},
                {"fieldname": "pep_category","label": '', "value": inputValues.pep_category, "inputtype": "checklabel",  "selectvalues": pepCategories,  "readonly": false, showfield: inputValues.current_former_pep.toUpperCase()==='YES'},
                {"fieldname": "pep_relative","label": data[lang]['pepquestion2'], "value": inputValues.pep_relative, "inputtype": "switch",  "selectvalues": yesnovalue,  "readonly": false, chaild:false},
                {"fieldname": "pep_busns_associate","label": data[lang]['pepquestion3'], "value": inputValues.pep_busns_associate, "inputtype": "switch", "selectvalues": yesnovalue, "readonly": false, child:inputValues.pep_busns_associate.toUpperCase()==='YES'},
                {"fieldname": "pep_relation", "label": data[lang]['pepquestion3a'], "value": inputValues.pep_relation, "inputtype": "text", "readonly": false, showfield: inputValues.pep_busns_associate.toUpperCase()==='YES'},
                {"fieldname": "pep_name", "label": data[lang]['pepname'], "value": inputValues.pep_name, "inputtype": "text", "readonly": false, showfield: (inputValues.current_former_pep.toUpperCase()==='YES' || inputValues.pep_relative.toUpperCase()==='YES' || inputValues.pep_busns_associate.toUpperCase()==='YES')},
                {"fieldname": "pep_period", "label": data[lang]['pepperiod'] , "value": pepPeriodFrom, "inputtype": "label", "readonly": false, showfield: (inputValues.current_former_pep.toUpperCase()==='YES' || inputValues.pep_relative.toUpperCase()==='YES' || inputValues.pep_busns_associate.toUpperCase()==='YES'), child: true},
                 /*{"fieldname": "pep_orgposition_relative", "label": data[lang]['pepPositionDirectRelative'], "value": inputValues.pep_orgposition_relative, "inputtype": "text", "readonly": false},*/
                /*{"fieldname": "pep_orgposition_relative", "label": data[lang]['pepPositionDirectRelative'], "value": inputValues.pep_orgposition_relative, "inputtype": "checklabel", "readonly": false, "selectvalues":pepRelativePositions}*/

            ];
            if(lang === 'En') {
                formfields.push({
                        "fieldname": "pep_period_from",
                        "label": data[lang]['pepfrom'],
                        "enlabel": data['En']['pepfrom'],
                        "value": pepPeriodFrom,
                        "inputtype": "monthyear",
                        "readonly": false,
                        showfield: (inputValues.current_former_pep.toUpperCase() === 'YES' || inputValues.pep_relative.toUpperCase() === 'YES' || inputValues.pep_busns_associate.toUpperCase() === 'YES')
                    },
                    {
                        "fieldname": "pep_period_to",
                        "label": data[lang]['pepto'],
                        "enlabel": data['En']['pepto'],
                        "value": pepPeriodTo,
                        "inputtype": "monthyear",
                        "minDate": pepPeriodFrom,
                        "readonly": false,
                        showfield: (inputValues.current_former_pep.toUpperCase() === 'YES' || inputValues.pep_relative.toUpperCase() === 'YES' || inputValues.pep_busns_associate.toUpperCase() === 'YES')
                    },
                    {
                        "fieldname": "pep_country",
                        "label": data[lang]['pepcountry'],
                        "value": inputValues.pep_country,
                        "inputtype": "text",
                        "readonly": false,
                        showfield: (inputValues.current_former_pep.toUpperCase() === 'YES' || inputValues.pep_relative.toUpperCase() === 'YES' || inputValues.pep_busns_associate.toUpperCase() === 'YES')
                    },
                )
            }
            if(lang === 'Ar') {
                formfields.push({
                        "fieldname": "pep_period_to",
                        "label": data[lang]['pepto'],
                        "enlabel": data['En']['pepto'],
                        "value": pepPeriodTo,
                        "inputtype": "monthyear",
                        "minDate": pepPeriodFrom,
                        "readonly": false,
                        showfield: (inputValues.current_former_pep.toUpperCase() === 'YES' || inputValues.pep_relative.toUpperCase() === 'YES' || inputValues.pep_busns_associate.toUpperCase() === 'YES')
                    },
                    {
                        "fieldname": "pep_period_from",
                        "label": data[lang]['pepfrom'],
                        "enlabel": data['En']['pepfrom'],
                        "value": pepPeriodFrom,
                        "inputtype": "monthyear",
                        "readonly": false,
                        showfield: (inputValues.current_former_pep.toUpperCase() === 'YES' || inputValues.pep_relative.toUpperCase() === 'YES' || inputValues.pep_busns_associate.toUpperCase() === 'YES')
                    },
                    {
                        "fieldname": "pep_country",
                        "label": data[lang]['pepcountry'],
                        "value": inputValues.pep_country,
                        "inputtype": "text",
                        "readonly": false,
                        showfield: (inputValues.current_former_pep.toUpperCase() === 'YES' || inputValues.pep_relative.toUpperCase() === 'YES' || inputValues.pep_busns_associate.toUpperCase() === 'YES')
                    },
                )
            }
            setSection(formfields) ;

        }else if(id === 7){


            const formfields = [
                {
                    "label": data[lang]['ownPropertyOman'],
                    "fieldname": "ownproperty_oman",
                    "value": inputValues.ownproperty_oman,
                    "inputtype": "switch",
                    "selectvalues": yesnovalue,
                    "readonly": false
                },
                {
                    "label": data[lang]['relativeFamilyOman'],
                    "fieldname": "relative_family_oman",
                    "value": inputValues.relative_family_oman,
                    "inputtype": "switch",
                    "selectvalues": yesnovalue,
                    "readonly": false
                },
                {
                    "label": data[lang]['ownBusinessOman'],
                    "fieldname": "own_business_oman",
                    "value": inputValues.own_business_oman,
                    "inputtype": "switch",
                    "selectvalues": yesnovalue,
                    "readonly": false
                },
                {
                    "label": data[lang]['benefitFromEmplyr'],
                    "fieldname": "benefit_from_emplyr",
                    "value": inputValues.benefit_from_emplyr,
                    "inputtype": "switch",
                    "selectvalues": yesnovalue,
                    "readonly": false
                },
                {
                    "label": data[lang]['authoriseBankCredit'],
                    "fieldname": "authorise_bank_credit",
                    "value": inputValues.authorise_bank_credit,
                    "inputtype": "switch",
                    "selectvalues": yesnovalue,
                    "readonly": false
                },
                {
                    "label": data[lang]['otherReason'],
                    "fieldname": "other_reason",
                    "value": inputValues.other_reason,
                    "inputtype": "switch",
                    "selectvalues": yesnovalue,
                    "readonly": false
                },
                {"label": data[lang]['reason1'], "fieldname": "accholding_reason1", "value": inputValues.accholding_reason1, "inputtype": "text", "readonly": false, show: inputValues.other_reason && inputValues.other_reason.toUpperCase() === 'YES' ? true : false},
                {"label": data[lang]['reason2'], "fieldname": "accholding_reason2", "value": inputValues.accholding_reason2, "inputtype": "text", "readonly": false, show: inputValues.other_reason && inputValues.other_reason.toUpperCase() === 'YES' ? true : false}
            ];
            setSection(formfields)
        }else if(id === 8){
            //console.log(inputValues)
            let empSector = inputValues.employment_sector? inputValues.employment_sector.toUpperCase() : '';
            let customerProof = employmentSectors.filter(a => a.value.toUpperCase() === empSector.toUpperCase())[0]
            customerProof = customerProof ? customerProof['child'] : '';

            let showEmployersDetails = inputValues.salary_bom.toUpperCase() !== 'YES' &&
                inputValues.employed.toUpperCase() === 'YES' &&
                customerProof === 'Employment Proof'
            const addressProofRelatedFields = [{
                    "instruction": "<p>" + data[lang]['addressProofInstruction1'] + "</p>" +
                        "<p>" + data[lang]['addressProofInstruction2'] + "</p> ",
                    "label": '',//data[lang]['addressProofInstruction1'],
                    "fieldname": "addressProofCategory",
                    "value": inputValues.addressProofCategory,
                    "inputtype": "select",
                    "showfield":  true,
                    "selectvalues": addProofs,
                    "readonly": false
                },
                {

                    "label": data[lang]['attachfile'],
                    "fieldname": "address_proof",
                    "value": inputValues.address_proof,
                    "inputtype": "file",
                    "readonly": false,
                    "showfield" : inputValues.showaddress_proof,
                    "selectedfilename" : inputValues.selectedfilenameaddress_proof,
                },
                {
                    "label": data[lang]['addressProofOwnName'],
                    "fieldname": "address_proof_own_name",
                    "value": inputValues.address_proof_own_name,
                    "inputtype": "switch",
                    "selectvalues": yesnovalue,
                    "readonly": false,
                    "showfield": true
                },
                {
                    "label": data[lang]['relatioshipAddrs'],
                    "fieldname": "relatioshipaddrs",
                    "value": inputValues.relatioshipaddrs,
                    "inputtype": "select",
                    "showfield":  inputValues.showlistrelatioshipaddrs,
                    "selectvalues": listrelatioshipaddrs,
                    "readonly": false
                },
                {
                    "instruction": data[lang]['authorisationLetter'],
                    "label": data[lang]['attachfile'],
                    "fieldname": "authorisation_letter",
                    "value": inputValues.authorisation_letter,
                    "inputtype": "file",
                    "readonly": false,
                    "showfield" : inputValues.showauthorisation_letter,
                    "selectedfilename" : inputValues.selectedfilenameauthorisation_letter,
                }];
            let formfields = [
                /*{
                    "label": data[lang]['employmentStatus'],
                    "fieldname": "employmentStatus",
                    "value": inputValues.employmentStatus,
                    "inputtype": "select",
                    "showfield":  false,
                    "selectvalues": empDropdown,
                    "readonly": empDropdownStatus
                },*/
                ,


                {
                    "label": data[lang]['salaryBom'],
                    "fieldname": "salary_bom",
                    "value": inputValues.salary_bom,
                    "inputtype": "switch",
                    "selectvalues": yesnovalue,
                    "readonly": inputValues.salary_bom.toUpperCase() === 'YES' ? true : false,
                    showfield: inputValues.showsalary_bom
                },
                {
                    "label": data[lang]['nameofemployer'],
                    "fieldname": "nameofemployer",
                    "value": inputValues.nameofemployer,
                    "inputtype": "text",
                    "selectvalues": yesnovalue,
                    "readonly": false,
                    showfield: showEmployersDetails
                },
                {
                    "label": data[lang]['employersaddress'],
                    "fieldname": "employersaddress",
                    "value": inputValues.employersaddress,
                    "inputtype": "text",
                    "selectvalues": yesnovalue,
                    "readonly": false,
                    showfield: showEmployersDetails
                },
                {
                    "label": data[lang]['pcCode'],
                    "fieldname": "pcCode",
                    "value": pcCode,
                    "inputtype": "searchlist",
                    "selectvalues": listpostalcode,
                    "readonly": false,
                    showfield: showEmployersDetails
                },
                {
                    "label": data[lang]['location'],
                    "fieldname": "location",
                    "value": inputValues.location,
                    "inputtype": "text",
                    "selectvalues": yesnovalue,
                    "readonly": false,
                    showfield: showEmployersDetails
                },
                {
                    "label": data[lang]['presentposition'],
                    "fieldname": "presentposition",
                    "value": inputValues.presentposition,
                    "inputtype": "text",
                    "selectvalues": yesnovalue,
                    "readonly": false,
                    showfield: showEmployersDetails
                }
                ,
                {
                    "instruction": "<p>" + data[lang]['employmentProofInstruction1'] + "</p>" +
                        "<p>" + data[lang]['employmentProofInstruction1a'] + "</p> ",
                    "label": '',//data[lang]['addressProofInstruction1'],
                    "fieldname": "employmentProofCategory",
                    "value": inputValues.employmentProofCategory,
                    "inputtype": "select",
                    "showfield":  inputValues.showemployment_proof,
                    "selectvalues": empProofs,
                    "readonly": false
                },
                {
                    "instruction": "",
                    "label": data[lang]['attachfile'],
                    "fieldname": "employment_proof",
                    "value": inputValues.employment_proof,
                    "inputtype": "file",
                    "readonly": false,
                    "showfield" : inputValues.showemployment_proof,
                    "selectedfilename" : inputValues.selectedfilenameemployment_proof
                },

                {
                    "instruction": "<p>" + data[lang]['selfEmploymentProofInstruction1'] + "</p>",
                    "label": '',//data[lang]['addressProofInstruction1'],
                    "fieldname": "selfemploymentProofCategory",
                    "value": inputValues.selfemploymentProofCategory,
                    "inputtype": "select",
                    "showfield":  inputValues.showselfemployment_proof,
                    "selectvalues": selfEmpProofs,
                    "readonly": false
                },
                {
                    "instruction": "",
                    "label": data[lang]['attachfile'],
                    "fieldname": "selfemployment_proof",
                    "value": inputValues.selfemployment_proof,
                    "inputtype": "file",
                    "readonly": false,
                    "showfield" : inputValues.showselfemployment_proof,
                    "selectedfilename" : inputValues.selectedfilenameselfemployment_proof
                },
                {
                    "instruction": data[lang]['w8form'],
                    "label": data[lang]['attachfile'],
                    "fieldname": "w8form",
                    "value": inputValues.w8form,
                    "inputtype": "file",
                    "readonly": false,
                    "showfield" : inputValues.showW8,
                    "selectedfilename" : inputValues.selectedfilenamew8,
                },
                {
                    "instruction": data[lang]['w9form'],
                    "label": data[lang]['attachfile'],
                    "fieldname": "w9form",
                    "value": inputValues.w9form,
                    "inputtype": "file",
                    "readonly": false,
                    "showfield" : inputValues.showW9,
                    "selectedfilename" : inputValues.selectedfilenamew9,
                },
                {
                    "instruction": data[lang]['docresidenttononresident'],
                    "label": data[lang]['attachfile'],
                    "fieldname": "docresidenttononresident",
                    "value": inputValues.docresidenttononresident,
                    "inputtype": "file",
                    "readonly": false,
                    "showfield" : inputValues.other_reason.toUpperCase() === 'YES',
                    "selectedfilename" : inputValues.filenamedocresidenttononresident,
                },
                {
                    "instruction": data[lang]['additionaldoc'],
                    "label": data[lang]['attachfile'],
                    "fieldname": "additionaldoc",
                    "value": inputValues.additionaldoc,
                    "inputtype": "file",
                    "readonly": false,
                    "showfield" : isNstp === 1,
                    "selectedfilename" : inputValues.filenameadditionaldoc,
                }

            ];

            if(addressproofstatus === 'ON') {
                formfields = addressProofRelatedFields.concat(formfields);
            }
            setSection(formfields)
        }


    },[curstep,inputValues, listcountry]);

    const editDetails = (id) =>{
        //dispatch(createAccessLog('Edit Form' , 'Started', 'Customer Details - Edit Form ' + id,cif));
        setCurstep(id) ;
      /*   setFormfields(id) ; */
        setModalIn(true);
    }

    const handleFieldChange  = (name,value) =>{
           setInputValues({[name] : value});
           //console.log(name+" "+value) ;
           if(value === false){
               let filenamelabel ;

               if(name === "showW8"){ filenamelabel = "selectedfilenamew8" ;}
               if(name === "showW9"){ filenamelabel = "selectedfilenamew9" ;}
               if(filenamelabel !== ''){
                   setInputValues({[name] : '', [filenamelabel] : ''});
               }

           }

    }

    const handleFieChange  = (name,value,filename) =>{
        //setCustomerErrorMessages('');
        setUserFile({name: '', value: '', filenamelabel: '', filename: ''});
        //if(value.length > 0  && name !== '') {
            let filenamelabel;
            if (name === "w8form") {
                filenamelabel = "selectedfilenamew8";
            }
            if (name === "w9form") {
                filenamelabel = "selectedfilenamew9";
            }
            if (name === "address_proof") {
                filenamelabel = "selectedfilenameaddress_proof";
            }
            if (name === "authorisation_letter") {
                filenamelabel = "selectedfilenameauthorisation_letter";
            }
            if (name === "employment_proof") {
                filenamelabel = "selectedfilenameemployment_proof";
            }
            if (name === "selfemployment_proof") {
                filenamelabel = "selectedfilenameselfemployment_proof";
            }
            if (name === "docresidenttononresident") {
                filenamelabel = "filenamedocresidenttononresident";
            }
            if (name === "additionaldoc") {
                filenamelabel = "filenameadditionaldoc";
            }
            // console.log(name+" : "+filenamelabel );
            //console.log('********************************')
            //console.log(name)
            //console.log(value)
            if (value.length > 0 && name !== '') {
                uploadCustomerDoc(value[0], name);
                setUserFile({name: name, value: value, filenamelabel: filenamelabel, filename: filename});
            } else {

                setInputValues({[name]: value, [filenamelabel]: filename});
            }
        //}
    }
    const selectSearchItem = (name, val, option='') => {
        let slctd = crsReasonSelected;
        slctd[crsSelected] = option;
        setcrsReasonSelected(slctd)
        handleChange(name, val);
        if(name === 'reason1') {
            setInputValues({'tinnumber1': ''})
            if(option !== 'B') {
                setInputValues({'details1': ''})
            }
        }
        if(name === 'reason2') {
            setInputValues({'tinnumber2': ''})
            if(option !== 'B') {
                setInputValues({'details2': ''})
            }
        }
        if(name === 'reason3') {
            setInputValues({'tinnumber3': ''})
            if(option !== 'B') {
                setInputValues({'details3': ''})
            }
        }
        setSearch(option);
        if(option !== 'B') {
            setOuterModal(false)
        }
    }
    const closeCRSReason = (item) => {
        let errors = [];
        if(crsReasonSelected[crsSelected] === 'B' && inputValues['details'+(crsSelected+1)] === '') {
            errors.push('details'+crsSelected);
            setErrors(errors)
        } else {
            setOuterModal(false)
        }

    }
    const handleInput = (e) => {
        handleChange(e.target.name, e.target.value)
    }
    const handleChange = (name,value) =>{

        setErrors([])
        if(name === "salary_bom" && value === "Yes"){
            //fetchAPI('salary') ;
        }
        if(name === "other_reason") {
            setInputValues({showdocresidenttononresident : value.toUpperCase() === 'YES' ? true : false})
        }
        if(name === "employed"){
            setInputValues({[name]: value.toUpperCase() });
            if(value.toUpperCase() === "YES"){
                setInputValues({showempselection: false});
                setInputValues({showempsector: true});
                setInputValues({'employed_cur_status': '' });
            }else{
                setInputValues({showempselection: true});
                setInputValues({showempsector: false});
                setInputValues({'employment_sector': '' });
            }

        } else {
            setInputValues({[name]: value });
        }
        //console.log(name)
        //console.log(value)
        if(name === 'employed_cur_status' && value.toUpperCase() === 'SELF EMPLOYED') {
            setInputValues({showselfemployment_proof: true});
        }
        if(name === 'employed_cur_status' && value.toUpperCase() !== 'SELF EMPLOYED') {
            setInputValues({showselfemployment_proof: false, selectedfilenameselfemployment_proof: ''});
        }
       // console.log(name+ ": "+value);

        if((name === "ustax_resident" ||  name === "usaddress_tel"  || name=== "us_nationality" ||  name=== "us_payments") && value === "Yes"){
            //console.log(nonstp+"Set as NonSTP")
            updateNonstp();
        }

        //if(name === "taxresident_not_oman" && value === "Yes"){

            //console.log(nonstp+"Set as NonSTP")
            //updateNonstp();
        //}

        if((name === "current_former_pep"  || name === "pep_relative"  || name === "pep_busns_associate")  && value === "Yes"){

            //console.log(nonstp+"Set as NonSTP")
            updateNonstp();
        }
        let crsSlct = crsReasonSelected;
        if(name === 'reason1' && value === '') {
            crsSlct[0] = '';
            //setInputValues({details1: ''});
        }
        if(name === 'reason2' && value === '') {
            crsSlct[1] = '';
            //setInputValues({details2: ''});
        }
        if(name === 'reason3' && value === '') {
            crsSlct[2] = '';
            //setInputValues({details3: ''});
        }
        setcrsReasonSelected(crsSlct);
        const params = (new URL(document.location)).searchParams;
        let ref = params.get("ref");
        if(ref !== 'otp') {

            editDetails(curstep);
        }
        //console.log(inputValues.current_former_pep)
        //console.log(value)
        let pepcndn = true;
        if(name === "current_former_pep") {
            pepcndn = (inputValues.pep_relative.toUpperCase() !== 'YES' && inputValues.pep_busns_associate.toUpperCase() !== 'YES');
        }
        if(name === "pep_relative") {

            pepcndn = (inputValues.current_former_pep.toUpperCase() !== 'YES' && inputValues.pep_busns_associate.toUpperCase() !== 'YES');
        }
        if(name === "pep_busns_associate") {
            pepcndn = (inputValues.current_former_pep.toUpperCase() !== 'YES' && inputValues.pep_relative.toUpperCase() !== 'YES');

        }
        if(name === "current_former_pep"  || name === "pep_relative"  || name === "pep_busns_associate") {
            if(value.toUpperCase() !== 'YES' && pepcndn ) {

                 //setInputValues({pep_name: '', pep_period_from: '', pep_period_to: '', pep_country: ''});
            }

        }

        if(name === "current_former_pep" && value.toUpperCase() !== 'YES') {
            setInputValues({pep_category: ''});
        }
        if(name === "current_former_pep" && value.toUpperCase() === 'YES') {
            setInputValues({pep_name: customerdata.name});
        }
        if(name === "pep_busns_associate" && value.toUpperCase() !== 'YES') {
            setInputValues({pep_relation: ''});
        }
        if(name === "pep_relative" && value.toUpperCase() === 'YES') {
            if(inputValues.current_former_pep.toUpperCase() !== 'YES') {
                setInputValues({pep_name: ''});
            }
        }
        if(name === "pep_busns_associate" && value.toUpperCase() === 'YES') {
            if(inputValues.current_former_pep.toUpperCase() !== 'YES') {
                setInputValues({pep_name: ''});
            }
        }

        if(name === 'pep_period_from') {
            let pepPeriodFrom = value.split('/');
            pepPeriodFrom = new Date(parseInt(pepPeriodFrom[1]), parseInt(pepPeriodFrom[0]) - 1, 1, 0, 0, 0, 0);
            let pepPeriodTo = inputValues.pep_period_to.split('/');
            pepPeriodTo = new Date(parseInt(pepPeriodTo[1]), parseInt(pepPeriodTo[0]) - 1, 1, 0, 0, 0, 0);
            if(pepPeriodTo <= pepPeriodFrom || inputValues.pep_period_to === '') {
                let periodTo = new Date(pepPeriodFrom.setMonth(pepPeriodFrom.getMonth() + 1))
                periodTo = (parseInt(periodTo.getMonth()) + 1) + '/' + periodTo.getFullYear();
                setInputValues({pep_period_to: periodTo});
            }
        }
    }

    /*const fetchAPI = (apiservice) =>{
        //console.log(apiservice) ;
        if(apiservice === "salary"){
            dispatch(fetchSalaryInfo(cif, journeyid, client)) ;
        }

    }*/
    /*const updateErrors = (field) => {
        if(errors.indexOf(field) < 0) {
            errors.push(field);
        }
        setErrors(errors);
    }*/
    const setNextBoxcount = (boxCount) => {
        let errors = [];
        //dispatch(createAccessLog('Customer Details' , 'Started', 'Customer Details - Form ' + boxCount,cif));
        let errorCnt = 0;
        setErrors([]);

        if(boxCount === 1) {
            if(inputValues.country_birth === ''){
                errors.push('country_birth');
                errorCnt++;
                editDetails(1)
            }
            if(inputValues.marital_status === ''){
                errors.push('marital_status');
                errorCnt++;
                editDetails(1)
            }
            setErrors(errors)
        }

        if(boxCount === 2) {
            if(inputValues.address === '' || inputValues.address === null){
                //updateErrors('house');
                errors.push('address');
                errorCnt++;
                editDetails(2)
            }
            if(inputValues.landmark === '' || inputValues.landmark === null){
                //updateErrors('landmark');
                errors.push('landmark');
                errorCnt++;
                editDetails(2)
            }
            if(inputValues.house === '' || inputValues.house === null){
                //updateErrors('house');
                errors.push('house');
                errorCnt++;
                editDetails(2)
            }
            /*if(inputValues.po_box === '' || inputValues.po_box === null){
                //updateErrors('po_box');
                errors.push('po_box');
                errorCnt++;
                editDetails(2)
            }*/
            if(inputValues.building === '' || inputValues.building === null){
                //updateErrors('building');
                errors.push('building');
                errorCnt++;
                editDetails(2)
            }
            /*if(inputValues.way === '' || inputValues.way === null){
                updateErrors('way');
                errorCnt++;
                editDetails(2)
            }*/
            if(inputValues.city === '' || inputValues.city === null){
                errors.push('city');
                //updateErrors('city');
                errorCnt++;
                editDetails(2)
            }
            /*if(inputValues.wilayat === '' || inputValues.wilayat === null){
                updateErrors('wilayat');
                errorCnt++;
                editDetails(2)
            }*/
            if((inputValues.picode === '' || inputValues.picode === null) && !(idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT')){
                //updateErrors('picode');
                errors.push('picode');
                errorCnt++;
                editDetails(2)
            }
            setErrors(errors)
        }
        if(boxCount === 3) {
            if(inputValues.monthly_income === ''){
                errors.push('monthly_income');
                //updateErrors('monthly_income');
                errorCnt++;
                editDetails(3);
            }
            if(inputValues.employed === ''){

                errorCnt++;
                editDetails(3);
            }
            if(inputValues.employed.toUpperCase() === 'NO' && inputValues.employed_cur_status === '') {
                errors.push('employed_cur_status');
                //updateErrors('employed_cur_status');
                errorCnt++;
                editDetails(3);
            }
            if(inputValues.employed.toUpperCase() === 'YES' && inputValues.employment_sector === '') {
                errors.push('employment_sector');
                errorCnt++;
                editDetails(3);
            }
            setErrors(errors)
            /*if(errorCnt === 0) {
                if (inputValues.employed.toUpperCase() === "YES" && inputValues.salary_bom.toUpperCase() !== "YES") {
                    /!*  handleChange("showempselection", true);  *!/
                    handleChange("employmentStatus", 'Employed');
                    handleChange("showemploymentStatus", true);
                    let empStatus = [...listemploymentstatus].filter(e => e.value !== 'Unemployed');
                    setEmpDropdown(empStatus);
                    setEmpDropdownStatus(false);
                    handleChange("showemployment_proof", true);
                } else if (inputValues.employed.toUpperCase() === "YES" && inputValues.salary_bom.toUpperCase() === "YES") {
                    handleChange("employmentStatus", 'Employed');
                    handleChange("showemployment_proof", false);
                    handleChange("selectedfilenameemployment_proof", '');
                    handleChange("selfemployment_proof", '');
                    handleChange("showselfemployment_proof", false);
                    handleChange("selectedfilenameselfemployment_proof", '');
                    setEmpDropdownStatus(true);
                } else if (inputValues.employed.toUpperCase() === "NO") {
                    let empStatus = [...listemploymentstatus].filter(e => e.value !== 'Employed');
                    setEmpDropdown(empStatus);
                    handleChange("employmentStatus", 'Unemployed');
                    handleChange("showemployment_proof", false);
                    handleChange("selectedfilenameemployment_proof", '');
                    handleChange("selfemployment_proof", '');
                    handleChange("showselfemployment_proof", false);
                    handleChange("selectedfilenameselfemployment_proof", '');
                    setEmpDropdownStatus(true);
                }
            }*/
        }
        /*if(boxCount === 4) {
            if (inputValues.usaddress_tel.toUpperCase() !== 'YES' && countryCode === '+1') {
                errors.push('usaddress_tel');
                errorCnt++;
                editDetails(4);
            }
            if (inputValues.ustax_resident.toUpperCase() !== 'YES' && (countryResidence === 'USA' || nationality === 'Canada' )) {
                errors.push('ustax_resident');
                errorCnt++;
                editDetails(4);
            }
            if (inputValues.us_nationality.toUpperCase() !== 'YES' && (nationality === 'USA' || nationality === 'Canada' )) {
                errors.push('us_nationality');
                //updateErrors('employed_cur_status');
                errorCnt++;
                editDetails(4);
            }
            setErrors(errors)
        }*/
        if(boxCount === 5) {

            if(inputValues.taxresident_not_oman === 'Yes' && (!inputValues.country_taxresidence1 || inputValues.country_taxresidence1 === '' || inputValues.country_taxresidence1 === null)){
                //updateErrors('country_taxresidence1');
                errors.push('country_taxresidence1');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (
                (!inputValues.tinnumber1 || inputValues.tinnumber1 === '' || inputValues.tinnumber1 === null)) &&
                (!inputValues.reason1 || inputValues.reason1 === '' || inputValues.reason1 === null)
            ){
                //updateErrors('tinnumber1');
                errors.push('tinnumber1');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (inputValues.country_taxresidence2 && inputValues.country_taxresidence2 !== '') &&
                (!inputValues.tinnumber2 || inputValues.tinnumber2 === '' || inputValues.tinnumber2 === null) &&
            (!inputValues.reason2 || inputValues.reason2 === '' || inputValues.reason2 === null)){
                errors.push('tinnumber2');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (!inputValues.country_taxresidence2 || inputValues.country_taxresidence2 === '' || inputValues.country_taxresidence2 === null) &&
                (inputValues.tinnumber2 !== '' || inputValues.reason2 !== '')){
                errors.push('country_taxresidence2');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (inputValues.country_taxresidence3 && inputValues.country_taxresidence3 !== '') &&
                (!inputValues.tinnumber3 || inputValues.tinnumber3 === '' || inputValues.tinnumber3 === null) &&
                (!inputValues.reason3 || inputValues.reason3 === '' || inputValues.reason3 === null)){
                errors.push('tinnumber3');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (!inputValues.country_taxresidence3 || inputValues.country_taxresidence3 === '' || inputValues.country_taxresidence3 === null) &&
                (inputValues.tinnumber3 !== '' || inputValues.reason3 !== '')){
                errors.push('country_taxresidence3');
                errorCnt++;
                editDetails(5)
            }
            setErrors(errors)
        }
        if(boxCount === 6) {
            //let errors = [];
            if(inputValues.current_former_pep.toUpperCase() === 'YES' && inputValues.pep_category === ''){
                errors.push('pep_category');
                errorCnt++;
                editDetails(6)
            }
            if(inputValues.pep_busns_associate.toUpperCase() === 'YES' && inputValues.pep_relation === ''){
                errors.push('pep_relation');
                errorCnt++;
                editDetails(6)
            }
            if(inputValues.current_former_pep.toUpperCase() === 'YES' || inputValues.pep_relative.toUpperCase() === 'YES' || inputValues.pep_busns_associate.toUpperCase() === 'YES'){
                if(inputValues.pep_name === '') {
                    errors.push('pep_name');
                    errorCnt++;
                    editDetails(6)
                }
                if(inputValues.pep_period_from === '') {
                    errors.push('pep_period_from');
                    errorCnt++;
                    editDetails(6)
                }
                if(inputValues.pep_period_to === '') {
                    errors.push('pep_period_to');
                    errorCnt++;
                    editDetails(6)
                }
                if(inputValues.pep_country === '') {
                    errors.push('pep_country');
                    errorCnt++;
                    editDetails(6)
                }
            }

            setErrors(errors)

        }
        if(boxCount === 7) {
            //let errors = [];
            if((inputValues.other_reason.toUpperCase()=== 'YES' ) && (!inputValues.accholding_reason1 || inputValues.accholding_reason1 === '' || inputValues.accholding_reason1 === null)){
                errors.push('accholding_reason1');
                errorCnt++;
                editDetails(7)
            }
            if((inputValues.other_reason.toUpperCase()=== 'YES' ) && (!inputValues.accholding_reason2 || inputValues.accholding_reason2 === '' || inputValues.accholding_reason2 === null)){
                errors.push('accholding_reason2');
                errorCnt++;
                editDetails(7)
            }

            setErrors(errors)

        }
        if(boxCount === 8) {
            if(addressproofstatus === 'ON') {
                if (inputValues.addressProofCategory === '') {
                    errors.push('addressProofCategory');
                    errorCnt++;
                    editDetails(8)
                }
                if (inputValues.showemployment_proof === true && inputValues.employmentProofCategory === '') {
                    errors.push('employmentProofCategory');
                    errorCnt++;
                    editDetails(8)
                }
                if (inputValues.showselfemployment_proof === true && inputValues.selfemploymentProofCategory === '') {
                    errors.push('selfemploymentProofCategory');
                    errorCnt++;
                    editDetails(8)
                }
                if (inputValues.selectedfilenameaddress_proof.trim() === '') {
                    //console.log('---' + inputValues.selectedfilenameaddress_proof + '--')
                    errors.push('address_proof');
                    errorCnt++;
                    editDetails(8)
                }
            }
            let empSector = inputValues.employment_sector? inputValues.employment_sector.toUpperCase() : '';
            let customerProof = employmentSectors.filter(a => a.value.toUpperCase() === empSector.toUpperCase())[0]
            customerProof = customerProof ? customerProof['child'] : '';

            let showEmployersDetails = inputValues.salary_bom.toUpperCase() !== 'YES' &&
                inputValues.employed.toUpperCase() === 'YES' &&
                customerProof === 'Employment Proof'

            if(showEmployersDetails === true && inputValues.nameofemployer === ''){
                errors.push('nameofemployer');
                errorCnt++;
                editDetails(8)
            }
            if(showEmployersDetails === true && inputValues.employersaddress === ''){
                errors.push('employersaddress');
                errorCnt++;
                editDetails(8)
            }
            if(showEmployersDetails === true && inputValues.pcCode === ''){
                errors.push('pcCode');
                errorCnt++;
                editDetails(8)
            }
            if(showEmployersDetails === true && inputValues.location === ''){
                errors.push('location');
                errorCnt++;
                editDetails(8)
            }
            if(showEmployersDetails === true && inputValues.presentposition === ''){
                errors.push('presentposition');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showselfemployment_proof && inputValues.selectedfilenameselfemployment_proof === ''){
                errors.push('selfemployment_proof');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showauthorisation_letter && inputValues.selectedfilenameauthorisation_letter === ''){
                errors.push('authorisation_letter');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showemployment_proof && inputValues.selectedfilenameemployment_proof === ''){
                errors.push('employment_proof');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showW8 && inputValues.selectedfilenamew8 === ''){
                errors.push('w8form');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showW9 && inputValues.selectedfilenamew9 === ''){
                errors.push('w9form');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showlistrelatioshipaddrs && inputValues.relatioshipaddrs === ''){
                errors.push('relatioshipaddrs');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showauthorisation_letter && inputValues.selectedfilenameauthorisation_letter === ''){
                errors.push('authorisation_letter');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.other_reason.toUpperCase() === 'YES' && inputValues.filenamedocresidenttononresident === ''){
                errors.push('docresidenttononresident');
                errorCnt++;
                editDetails(8)
            }
            setErrors(errors);

        }


        if(errorCnt === 0) {
            setModalIn(false);
            /*if (boxCount === 6 && residentonon === false) {
                setBoxcount(cnt + 1);
            } else {
                setBoxcount(cnt);
            }*/
        }
    }
    const validateAll = () => {
        let errors = [];
        //dispatch(createAccessLog('Customer Details' , 'Started', 'Customer Details - Form ' + boxCount,cif));
        let errorCnt = 0;
        setErrors([]);

        if(errorCnt === 0) {
            if(inputValues.country_birth === ''){
                errors.push('country_birth');
                errorCnt++;
                editDetails(1)
            }
            if(inputValues.marital_status === ''){
                errors.push('marital_status');
                errorCnt++;
                editDetails(1)
            }
            setErrors(errors)
        }

        if(errorCnt === 0) {
            if(inputValues.address === '' || inputValues.address === null){
                //updateErrors('address');
                errors.push('address');
                errorCnt++;
                editDetails(2)
            }
            if(inputValues.landmark === '' || inputValues.landmark === null){
                //updateErrors('landmark');
                errors.push('landmark');
                errorCnt++;
                editDetails(2)
            }
            if(inputValues.house === '' || inputValues.house === null){
                //updateErrors('house');
                errors.push('house');
                errorCnt++;
                editDetails(2)
            }
            /*if(inputValues.po_box === '' || inputValues.po_box === null){
                //updateErrors('po_box');
                errors.push('po_box');
                errorCnt++;
                editDetails(2)
            }*/
            if(inputValues.building === '' || inputValues.building === null){
                //updateErrors('building');
                errors.push('building');
                errorCnt++;
                editDetails(2)
            }
            /*if(inputValues.way === '' || inputValues.way === null){
                updateErrors('way');
                errorCnt++;
                editDetails(2)
            }*/
            if(inputValues.city === '' || inputValues.city === null){
                errors.push('city');
                //updateErrors('city');
                errorCnt++;
                editDetails(2)
            }
            /*if(inputValues.wilayat === '' || inputValues.wilayat === null){
                updateErrors('wilayat');
                errorCnt++;
                editDetails(2)
            }*/
            if((inputValues.picode === '' || inputValues.picode === null) && !(idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT')){
                //updateErrors('picode');
                errors.push('picode');
                errorCnt++;
                editDetails(2)
            }
            setErrors(errors)
        }
        if(errorCnt === 0) {
            if(inputValues.monthly_income === ''){
                errors.push('monthly_income');
                //updateErrors('monthly_income');
                errorCnt++;
                editDetails(3);
            }
            if(inputValues.employed === ''){

                errorCnt++;
                editDetails(3);
            }
            if(inputValues.employed.toUpperCase() === 'NO' && inputValues.employed_cur_status === '') {
                errors.push('employed_cur_status');
                //updateErrors('employed_cur_status');
                errorCnt++;
                editDetails(3);
            }
            if(inputValues.employed.toUpperCase() === 'YES' && inputValues.employment_sector === '') {
                errors.push('employment_sector');
                errorCnt++;
                editDetails(3);
            }
            setErrors(errors)

        }

        if(errorCnt === 0) {

            if(inputValues.taxresident_not_oman === 'Yes' && (!inputValues.country_taxresidence1 || inputValues.country_taxresidence1 === '' || inputValues.country_taxresidence1 === null)){
                //updateErrors('country_taxresidence1');
                errors.push('country_taxresidence1');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (
                    (!inputValues.tinnumber1 || inputValues.tinnumber1 === '' || inputValues.tinnumber1 === null)) &&
                (!inputValues.reason1 || inputValues.reason1 === '' || inputValues.reason1 === null)
            ){
                //updateErrors('tinnumber1');
                errors.push('tinnumber1');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (inputValues.country_taxresidence2 && inputValues.country_taxresidence2 !== '') &&
                (!inputValues.tinnumber2 || inputValues.tinnumber2 === '' || inputValues.tinnumber2 === null) &&
                (!inputValues.reason2 || inputValues.reason2 === '' || inputValues.reason2 === null)){
                errors.push('tinnumber2');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (!inputValues.country_taxresidence2 || inputValues.country_taxresidence2 === '' || inputValues.country_taxresidence2 === null) &&
                (inputValues.tinnumber2 !== '' || inputValues.reason2 !== '')){
                errors.push('country_taxresidence2');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (inputValues.country_taxresidence3 && inputValues.country_taxresidence3 !== '') &&
                (!inputValues.tinnumber3 || inputValues.tinnumber3 === '' || inputValues.tinnumber3 === null) &&
                (!inputValues.reason3 || inputValues.reason3 === '' || inputValues.reason3 === null)){
                errors.push('tinnumber3');
                errorCnt++;
                editDetails(5)
            }
            if(inputValues.taxresident_not_oman === 'Yes' && (!inputValues.country_taxresidence3 || inputValues.country_taxresidence3 === '' || inputValues.country_taxresidence3 === null) &&
                (inputValues.tinnumber3 !== '' || inputValues.reason3 !== '')){
                errors.push('country_taxresidence3');
                errorCnt++;
                editDetails(5)
            }
            setErrors(errors)
        }
        if(errorCnt === 0) {
            if(inputValues.current_former_pep.toUpperCase() === 'YES' && inputValues.pep_category === ''){
                errors.push('pep_category');
                errorCnt++;
                editDetails(6)
            }
            if(inputValues.pep_busns_associate.toUpperCase() === 'YES' && inputValues.pep_relation === ''){
                errors.push('pep_relation');
                errorCnt++;
                editDetails(6)
            }
            if(inputValues.current_former_pep.toUpperCase() === 'YES' || inputValues.pep_relative.toUpperCase() === 'YES' || inputValues.pep_busns_associate.toUpperCase() === 'YES'){
                if(inputValues.pep_name === '') {
                    errors.push('pep_name');
                    errorCnt++;
                    editDetails(6)
                }
                if(inputValues.pep_period_from === '') {
                    errors.push('pep_period_from');
                    errorCnt++;
                    editDetails(6)
                }
                if(inputValues.pep_period_to === '') {
                    errors.push('pep_period_to');
                    errorCnt++;
                    editDetails(6)
                }
                if(inputValues.pep_country === '') {
                    errors.push('pep_country');
                    errorCnt++;
                    editDetails(6)
                }
            }

            setErrors(errors)

        }
        if(errorCnt === 0) {
            //let errors = [];
            if((inputValues.other_reason.toUpperCase()=== 'YES' ) && (!inputValues.accholding_reason1 || inputValues.accholding_reason1 === '' || inputValues.accholding_reason1 === null)){
                errors.push('accholding_reason1');
                errorCnt++;
                editDetails(7)
            }
            if((inputValues.other_reason.toUpperCase()=== 'YES' ) && (!inputValues.accholding_reason2 || inputValues.accholding_reason2 === '' || inputValues.accholding_reason2 === null)){
                errors.push('accholding_reason2');
                errorCnt++;
                editDetails(7)
            }

            setErrors(errors)

        }


        if(errorCnt === 0) {
            if(addressproofstatus === 'ON') {
                if (inputValues.addressProofCategory === '') {
                    errors.push('addressProofCategory');
                    errorCnt++;
                    editDetails(8)
                }
                if (inputValues.showemployment_proof === true && inputValues.employmentProofCategory === '') {
                    errors.push('employmentProofCategory');
                    errorCnt++;
                    editDetails(8)
                }
                if (inputValues.showselfemployment_proof === true && inputValues.selfemploymentProofCategory === '') {
                    errors.push('selfemploymentProofCategory');
                    errorCnt++;
                    editDetails(8)
                }
                if (inputValues.selectedfilenameaddress_proof.trim() === '') {
                    //console.log('---' + inputValues.selectedfilenameaddress_proof + '--')
                    errors.push('address_proof');
                    errorCnt++;
                    editDetails(8)
                }
            }
            let empSector = inputValues.employment_sector? inputValues.employment_sector.toUpperCase() : '';
            let customerProof = employmentSectors.filter(a => a.value.toUpperCase() === empSector.toUpperCase())[0]
            customerProof = customerProof ? customerProof['child'] : '';

            let showEmployersDetails = inputValues.salary_bom.toUpperCase() !== 'YES' &&
                inputValues.employed.toUpperCase() === 'YES' &&
                customerProof === 'Employment Proof'

            if(showEmployersDetails === true && inputValues.nameofemployer === ''){
                errors.push('nameofemployer');
                errorCnt++;
                editDetails(8)
            }
            if(showEmployersDetails === true && inputValues.employersaddress === ''){
                errors.push('employersaddress');
                errorCnt++;
                editDetails(8)
            }
            if(showEmployersDetails === true && inputValues.pcCode === ''){
                errors.push('pcCode');
                errorCnt++;
                editDetails(8)
            }
            if(showEmployersDetails === true && inputValues.location === ''){
                errors.push('location');
                errorCnt++;
                editDetails(8)
            }
            if(showEmployersDetails === true && inputValues.presentposition === ''){
                errors.push('presentposition');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showselfemployment_proof && inputValues.selectedfilenameselfemployment_proof === ''){
                errors.push('selfemployment_proof');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showauthorisation_letter && inputValues.selectedfilenameauthorisation_letter === ''){
                errors.push('authorisation_letter');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showemployment_proof && inputValues.selectedfilenameemployment_proof === ''){
                errors.push('employment_proof');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showW8 && inputValues.selectedfilenamew8 === ''){
                errors.push('w8form');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showW9 && inputValues.selectedfilenamew9 === ''){
                errors.push('w9form');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showlistrelatioshipaddrs && inputValues.relatioshipaddrs === ''){
                errors.push('relatioshipaddrs');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.showauthorisation_letter && inputValues.selectedfilenameauthorisation_letter === ''){
                errors.push('authorisation_letter');
                errorCnt++;
                editDetails(8)
            }
            if(inputValues.other_reason.toUpperCase() === 'YES' && inputValues.filenamedocresidenttononresident === ''){
                errors.push('docresidenttononresident');
                errorCnt++;
                editDetails(8)
            }
            setErrors(errors);
        }
        if(errorCnt === 0) {
            setPreview();
        }
    }
    const setPreview = () => {
        let errorCnt = 0;
        /*setErrors([]);
        let erros = [];
        //console.log(inputValues)
        if(inputValues.selectedfilenameaddress_proof.trim() === ''){
            //console.log('---' + inputValues.selectedfilenameaddress_proof + '--')
            erros.push('address_proof');
            errorCnt++;
            editDetails(8)
        }
        if(inputValues.showselfemployment_proof && inputValues.selectedfilenameselfemployment_proof === ''){
            erros.push('selfemployment_proof');
            errorCnt++;
            editDetails(8)
        }
        if(inputValues.showauthorisation_letter && inputValues.selectedfilenameauthorisation_letter === ''){
            erros.push('authorisation_letter');
            errorCnt++;
            editDetails(8)
        }
        if(inputValues.showemployment_proof && inputValues.selectedfilenameemployment_proof === ''){
            erros.push('employment_proof');
            errorCnt++;
            editDetails(8)
        }
        if(inputValues.showW8 && inputValues.selectedfilenamew8 === ''){
            erros.push('w8form');
            errorCnt++;
            editDetails(8)
        }
        if(inputValues.showW9 && inputValues.selectedfilenamew9 === ''){
            erros.push('w9form');
            errorCnt++;
            editDetails(8)
        }
        if(inputValues.showlistrelatioshipaddrs && inputValues.relatioshipaddrs === ''){
            erros.push('relatioshipaddrs');
            errorCnt++;
            editDetails(8)
        }
        if(inputValues.showauthorisation_letter && inputValues.selectedfilenameauthorisation_letter === ''){
            erros.push('authorisation_letter');
            errorCnt++;
            editDetails(8)
        }
        if(inputValues.other_reason.toUpperCase() === 'YES' && inputValues.filenamedocresidenttononresident === ''){
            erros.push('docresidenttononresident');
            errorCnt++;
            editDetails(8)
        }
        setErrors(erros);*/
        //console.log(inputValues.ustax_resident)
        //alert(customerdata.typeFromScore.toUpperCase())

        if(errorCnt === 0) {
            let highrisk = custrisk === null ? '' : custrisk.toString();
            let reason = '';
            if( (inputValues.ustax_resident && inputValues.ustax_resident.toUpperCase() === "YES") ||
                (inputValues.usaddress_tel && inputValues.usaddress_tel.toUpperCase() === "YES")  ||
                (inputValues.us_nationality && inputValues.us_nationality.toUpperCase() === "YES") ||
                (inputValues.us_payments && inputValues.us_payments.toUpperCase() === "YES")
                ){
                reason = 'NSTP - FATCA'
            }
            if((inputValues.country_birth === "US")) {
                reason = 'NSTP - country'
            }
            if((inputValues.current_former_pep && inputValues.current_former_pep.toUpperCase() === "YES") ||
                (inputValues.pep_relative && inputValues.pep_relative.toUpperCase() === "YES") ||
                (inputValues.pep_busns_associate && inputValues.pep_busns_associate.toUpperCase() === "YES")
                ) {
                reason = 'NSTP - PEP'
            }
            if(livenessstatus === 'ON') {
                if (((customerdata.typeFromScore && customerdata.typeFromScore.toUpperCase() === 'NSTP') || kycStore.getState().KYCState.BM_facematchnstp === true)
                ) {
                    reason = 'NSTP - typeFromScore'
                }
            } else {
                if (((customerdata.typeFromScore && customerdata.typeFromScore.toUpperCase() === 'NSTP'))
                ) {
                    reason = 'NSTP - typeFromScore'
                }
            }
            if((idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT')
                ) {
                reason = 'NSTP - ' + idtype.toUpperCase()
            }
            if(highrisk === '1') {
                reason = 'NSTP - highrisk'
            }
            if( (inputValues.ustax_resident && inputValues.ustax_resident.toUpperCase() === "YES") ||
                (inputValues.usaddress_tel && inputValues.usaddress_tel.toUpperCase() === "YES")  ||
                (inputValues.us_nationality && inputValues.us_nationality.toUpperCase() === "YES") ||
                (inputValues.us_payments && inputValues.us_payments.toUpperCase() === "YES") ||
                (inputValues.country_birth === "US") ||
                (inputValues.current_former_pep && inputValues.current_former_pep.toUpperCase() === "YES") ||
                (inputValues.pep_relative && inputValues.pep_relative.toUpperCase() === "YES") ||
                (inputValues.pep_busns_associate && inputValues.pep_busns_associate.toUpperCase() === "YES") ||
                ((customerdata.typeFromScore && customerdata.typeFromScore.toUpperCase() === 'NSTP') || (livenessstatus === 'ON' && kycStore.getState().KYCState.BM_facematchnstp === true)) ||
                (idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT') ||
                (highrisk === '1')){
                //dispatch(createAccessLog('NSTP' , 'Reason ', reason,cif));
                //(inputValues.taxresident_not_oman && inputValues.taxresident_not_oman.toUpperCase() === "YES") ||
                //facematchStatus
                updateNonstp();
            } else {
                //dispatch(createAccessLog('STP' , '', '',cif));
                updateStp();
            }


            setEditform(!editform)

        }
    }


    const setPrevBoxcount = (boxCount, cnt) => {
        if(boxCount === 8 && residentonon === false){
            setBoxcount(cnt-1);
        }else{
            setBoxcount(cnt);
        }
    }



    const closeModal = (boxcount) =>{
        //console.log("close modal") ;
        setNextBoxcount(boxcount)
        //setModalIn(false);
    }
    const openSearchList = (name, list) =>{
        setOuterModal(true)
        setOuterModalSearch(true)
        setOuterModalFull(true)
        setOuterModalItem(name)
        setOuterModalList(list)
        if(name === 'country_birth') {
            setListsearchcountry(listcountry);
            let countryBirth = listcountry.filter(a => a.value === inputValues.country_birth)[0];
            countryBirth = (countryBirth && countryBirth.option) ? countryBirth.option : '';
            setSearch(countryBirth)
        }
        if(name === 'picode') {
            setListsearchPcode(listpostalcode)
            let picode = listpostalcode.filter(a => a.value === inputValues.picode)[0];
            picode = (picode && picode.option) ? picode.option : '';
            setSearch(picode)
        }
        if(name === 'pcCode') {
            setListsearchPcode(listpostalcode)
            let picode = listpostalcode.filter(a => a.value === inputValues.pcCode)[0];
            picode = (picode && picode.option) ? picode.option : '';
            setSearch(picode)
        }
        if(name === 'country_taxresidence1') {
            setListsearchcountry(listcountry);
            let code = listcountry.filter(a => a.value === inputValues.country_taxresidence1)[0];
            code = (code && code.option) ? code.option : '';
            setSearch(code)
        }
        if(name === 'country_taxresidence2') {
            setListsearchcountry(listcountry);
            let code = listcountry.filter(a => a.value === inputValues.country_taxresidence2)[0];
            code = (code && code.option) ? code.option : '';
            setSearch(code)
        }
        if(name === 'country_taxresidence3') {
            setListsearchcountry(listcountry);
            let code = listcountry.filter(a => a.value === inputValues.country_taxresidence3)[0];
            code = (code && code.option) ? code.option : '';
            setSearch(code)
        }
    }
    const openSelectList = (name, cnt) =>{
        setOuterModal(true)
        setOuterModalSearch(false)
        setOuterModalFull(false)
        setOuterModalItem(name)
        setcrsSelected(cnt-1)
        //setOuterModalList(list)

    }
    const setOuterModalIn = (stat) => {
        setOuterModal(stat)
    }
    const handleSearch = (e) => {
        setSearch(e.target.value);
        if(e.target.name === 'country_birth') {
            let listSearchcountry = listcountry.filter(a => a.option.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
            setListsearchcountry(listSearchcountry)
        }
        if(e.target.name === 'picode') {
            let listSearchPcode = listpostalcode.filter(a => a.option.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
            setListsearchPcode(listSearchPcode)
        }
        if(e.target.name === 'pcCode') {
            let listSearchPcode = listpostalcode.filter(a => a.option.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
            setListsearchPcode(listSearchPcode)
        }
        if(e.target.name === 'country_taxresidence1') {
            let listSearchcountry = listcountry.filter(a => a.option.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
            setListsearchcountry(listSearchcountry)
        }
        if(e.target.name === 'country_taxresidence2') {
            let listSearchcountry = listcountry.filter(a => a.option.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
            setListsearchcountry(listSearchcountry)
        }
        if(e.target.name === 'country_taxresidence3') {
            let listSearchcountry = listcountry.filter(a => a.option.toUpperCase().indexOf(e.target.value.toUpperCase()) >= 0)
            setListsearchcountry(listSearchcountry)
        }
    }

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const compressPhoto = (pic) => {
        var options = {
            maxSizeMB: 1,
            // maxWidthOrHeight: 200,
            useWebWorker: true,
            fileType: "image/png"
        };

        imageCompression
            .getFilefromDataUrl(pic)
            .then((file) => imageCompression(file, options))
            .then(toBase64)
            .then((compressedPic) => {
                // console.log(compressedPic);
                setShowCamera(false)
                setCompressedPhoto(compressedPic)

            })
            .catch(function (error) {
                //console.log(error.message);
            });
    };
    const capturePhoto = () =>{
       // console.log("capturePhoto", cameraFieldName) ;
        let imageSrc = webcamRef.current.getScreenshot();
        // console.log(imageSrc)
        compressPhoto(imageSrc);
    }
    const showCameraPanel = (fieldName) =>{
        //console.log("showCameraPanel", fieldName) ;
        setShowCamera(true)
        setCameraFieldName(fieldName)
    }
    const closeCameraPanel = () =>{
        //console.log("closeCameraPanel") ;
        setShowCamera(false)
    }

    const uploadCustomerDoc = (doc, key) => {
        //console.log(kyc)
        //setCustomerErrorMessages('')
        //setModalIn(false)
        setLoader(true)
        setLoaderMesssage(data[lang]['uploading'])
        let strjson = '' ;
        let urlarray = []  ;
        let ftypearray = [] ;
        if(doc !== ""){
            urlarray.push(doc) ;
            if(key === 'selfemployment_proof'){
                ftypearray.push("SelfEmpProof");
            }
            if(key === 'employment_proof'){
                ftypearray.push("EmpProof");
            }
            if(key === 'address_proof'){
                ftypearray.push("AddressProof");
            }
            if(key === 'authorisation_letter'){
                ftypearray.push("AddressAuthLetter");
            }
            if(key === 'w9form'){
                ftypearray.push("W9Form");
            }
            if(key === 'w8form'){
                ftypearray.push("W8Form");
            }
            if(key === 'preview'){
                ftypearray.push("preview");
            }
            if(key === 'docresidenttononresident'){
                ftypearray.push("ResidentToNonResident");
            }
            if(key === 'additionaldoc'){
                ftypearray.push("AdditionalDoc");
            }

        }
        //console.log(urlarray)
        if( urlarray.length > 0){
            strjson += '{' ;
            for(var i=0;i<urlarray.length;i++){
                strjson += ' "'+ftypearray[i]+'" : "'+urlarray[i]+'" ';
                if(urlarray.length -1  !== i && i >=0 ) {
                    strjson += ',' ;
                }
            }
            strjson += '}' ;
            //console.log(strjson) ;
            //console.log(strjson) ;
            let formdata = new FormData();
            formdata.append('userid', journeyid);
            formdata.append('data', strjson);
            //dispatch(createAccessLog('Additional Documents' , 'Started', 'Update Additional Documents',cif));
            let postData = {data: strjson}
            dispatch(customerFormUpload(postData, key));


        }
    }



    //console.log("inputValues.employed: " + inputValues.employed) ;
    //console.log("inputValues.showempselection : "+inputValues.showempselection) ;
    //console.log("maxboxcount : "+maxboxcount) ;
    //console.log(listcountry.filter(a => a.value === inputValues.country_birth)[0]) ;
    let countryBirth = listcountry.filter(a => a.value === inputValues.country_birth)[0];
    countryBirth = (countryBirth && countryBirth.option) ? countryBirth.option : '';
    let maritalStatus = listmaritalstatus.filter(a => a.value === inputValues.marital_status)[0];
    maritalStatus = (maritalStatus && maritalStatus.option) ? maritalStatus.option : '';
//console.log(listincome)
    let incomeRange = listincome.filter(a => a.value === inputValues.monthly_income)[0];
    incomeRange = (incomeRange && incomeRange.option) ? incomeRange.option : '';
    let picode = listpostalcode.filter(a => a.value === inputValues.picode)[0];
    picode = (picode && picode.option) ? picode.option : '';
    let pcCode = listpostalcode.filter(a => a.value === inputValues.pcCode)[0];
    pcCode = (pcCode && pcCode.option) ? pcCode.option : '';
    incomeRange = (incomeRange && incomeRange.option) ? incomeRange.option : '';
    //console.log(incomeRange)
    //let employmentStatus = listemploymentstatus.filter(a => a.value === inputValues.employmentStatus)[0];
    //employmentStatus = (employmentStatus && employmentStatus.option) ? employmentStatus.option : '';
    //console.log(inputValues.employed_cur_status)
    let employedCurStatus = inputValues.employed_cur_status ? inputValues.employed_cur_status : '';
    if(employedCurStatus !== '') {
        employedCurStatus = listempstatus.filter(a => a.value.toUpperCase().indexOf(inputValues.employed_cur_status.toUpperCase()) >= 0)[0];
        employedCurStatus = (employedCurStatus && employedCurStatus.option) ? employedCurStatus.option : '';
    }
    //console.log(employedCurStatus)
    let employmentSector = inputValues.employment_sector ? inputValues.employment_sector : '';
    if(employmentSector !== '') {
        employmentSector = employmentSectors.filter(a => a.value.toUpperCase() === inputValues.employment_sector.toUpperCase())[0];
        employmentSector = (employmentSector && employmentSector.option) ? employmentSector.option : '';
    }
    let country_taxresidence1 = listcountry.filter(a => a.value === inputValues.country_taxresidence1)[0];
    country_taxresidence1 = (country_taxresidence1 && country_taxresidence1.option) ? country_taxresidence1.option : '';

    let country_taxresidence2 = listcountry.filter(a => a.value === inputValues.country_taxresidence2)[0];
    country_taxresidence2 = (country_taxresidence2 && country_taxresidence2.option) ? country_taxresidence2.option : '';

    let country_taxresidence3 = listcountry.filter(a => a.value === inputValues.country_taxresidence3)[0];
    country_taxresidence3 = (country_taxresidence3 && country_taxresidence3.option) ? country_taxresidence3.option : '';

    let country_taxresidence4 = listcountry.filter(a => a.value === inputValues.country_taxresidence4)[0];
    country_taxresidence4 = (country_taxresidence4 && country_taxresidence4.option) ? country_taxresidence4.option : '';

    //let pep_orgposition_relative = pepRelativePositions.filter(a => a.value === inputValues.pep_orgposition_relative)[0];
    //pep_orgposition_relative = (pep_orgposition_relative && pep_orgposition_relative.option) ? pep_orgposition_relative.option : '';

    let relatioshipaddrs = listrelatioshipaddrs.filter(a => a.value === inputValues.relatioshipaddrs)[0];
    relatioshipaddrs = (relatioshipaddrs && relatioshipaddrs.option) ? relatioshipaddrs.option : '';

    let addressProofCategory = addProofs.filter(a => a.value === inputValues.addressProofCategory)[0];
    addressProofCategory = (addressProofCategory && addressProofCategory.option) ? addressProofCategory.option : '';

    let employmentProofCategory = empProofs.filter(a => a.value === inputValues.employmentProofCategory)[0];
    employmentProofCategory = (employmentProofCategory && employmentProofCategory.option) ? employmentProofCategory.option : '';

    let selfemploymentProofCategory = selfEmpProofs.filter(a => a.value === inputValues.selfemploymentProofCategory)[0];
    selfemploymentProofCategory = (selfemploymentProofCategory && selfemploymentProofCategory.option) ? selfemploymentProofCategory.option : '';


    let crsReason1 = crsNoTinReasons.filter(a => a.label === inputValues.reason1)[0];
    if(crsReason1 && crsReason1.label) {
        crsReason1 = lang === 'Ar' ? crsReason1.label_ar : crsReason1.label;
        if(inputValues.details1 !== '') {
            crsReason1 += '<br/><br/>' +  inputValues.details1;
        }
    } else {
        crsReason1 = '';
    }


    let crsReason2 = crsNoTinReasons.filter(a => a.label === inputValues.reason2)[0];
    if(crsReason2 && crsReason2.label) {
        crsReason2 = lang === 'Ar' ? crsReason2.label_ar : crsReason2.label;
        if(inputValues.details2 !== '') {
            crsReason2 += '<br/><br/>' +  inputValues.details2;
        }
    } else {
        crsReason2 = '';
    }

    let crsReason3 = crsNoTinReasons.filter(a => a.label === inputValues.reason3)[0];
    if(crsReason3 && crsReason3.label) {
        crsReason3 = lang === 'Ar' ? crsReason3.label_ar : crsReason3.label;
        if(inputValues.details3 !== '') {
            crsReason3 += '<br/><br/>' +  inputValues.details3;
        }
    } else {
        crsReason3 = '';
    }


    // console.log(inputValues)
 //console.log('-'+customerErrorMessages.trim()+'-')
    let isNstp = 0;
    let highrisk = custrisk === null ? '' : custrisk.toString();
    if( (inputValues.ustax_resident && inputValues.ustax_resident.toUpperCase() === "YES") ||
        (inputValues.usaddress_tel && inputValues.usaddress_tel.toUpperCase() === "YES")  ||
        (inputValues.us_nationality && inputValues.us_nationality.toUpperCase() === "YES") ||
        (inputValues.us_payments && inputValues.us_payments.toUpperCase() === "YES") ||
        (inputValues.country_birth === "US") ||
        (inputValues.pep_relative && inputValues.pep_relative.toUpperCase() === "YES") ||
        (inputValues.pep_busns_associate && inputValues.pep_busns_associate.toUpperCase() === "YES") ||
        (customerdata && customerdata.typeFromScore && customerdata.typeFromScore.toUpperCase() === 'NSTP') ||
        (idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT') ||
        (highrisk === '1')){
        isNstp = 1;
    }
    //console.log(isNstp)
    let pepListLabels = '<ul>';
    pepRelativePositions.map((pepItem) => {
        pepListLabels += '<li>' + pepItem.option + '</li>';
    })
    pepListLabels += '</ul>';
    let empSector = inputValues.employment_sector? inputValues.employment_sector.toUpperCase() : '';
    let customerProof = employmentSectors.filter(a => a.value.toUpperCase() === empSector.toUpperCase())[0]
    customerProof = customerProof ? customerProof['child'] : '';
    let showEmployersDetails = inputValues.salary_bom.toUpperCase() !== 'YES' &&
        inputValues.employed.toUpperCase() === 'YES' &&
        customerProof === 'Employment Proof'
    //console.log(inputValues)
    //console.log(crsSelected)
    //console.log(apiRetry + ' - ' + countDown)
    return (
        <>
            {loader &&
            <Loader message={loaderMessage}/>
            }
            {(apiRetry === true && countDown > 0) &&
            <Popup messagehead={data[lang]['oops']} message={data[lang]['retry'].replace('{countdown}', countDown)} returnToPage={true} closePopupModal={closePopupModal}/>
            }
            {customerErrorMessages !== '' &&
            <Popup messagehead={data[lang]['oops']} message={customerErrorMessages} returnToPage={true} closePopupModal={closePopupModal}/>
            }

            {
                showCamera === true &&

                <>
                <div className="cameraView cameraView--idScan" style={{zIndex: 3, background: "#333"}} >
                    <div className="cameraPosition">

                        <Webcam
                            ref={webcamRef}
                            audio={false}
                            screenshotQuality={1} // set the Quality of camera (0-1)
                            forceScreenshotSourceSize
                            screenshotFormat="image/png"
                            videoConstraints={videoConstraints} //cameraSetting,eg:resolution, use which camera
                            height="200px" // size of displaying screen
                            width="200px" // size of displaying screen
                            // onUserMedia={(e) => console.log(e)} // show info of media stream
                        />

                    </div>
                </div>
                <div className="cameraOverlay cameraOverlay--idScan" style={{zIndex: 3}} >
                    <span className="csModal__out" onClick={closeCameraPanel}/>
                    <div className="cameraOverlay__head"> </div>

                    <div className="cameraOverlay__option">
                        <div className="cameraOverlay__option__ButtonSet">

                            <span className="btn btn--primaryG btn--capsule" onClick={capturePhoto}>
                                {data[lang]['scan']}
                            </span>

                        </div>
                    </div>
                </div>
                </>
            }



            <Header userIn={true} headerTitle={renewType === 'KYC' ? data[lang]['kycDetails'] : data[lang]['lidDetails']}  activestep={boxcount} />
            <div className={`contentBlock  ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                <div  className={`edit-form ${editform ? 'active' : 'hide' }`} >
                <div className="headerBlock">
                    <h3>{data[lang]['customerDetails']}</h3>
                </div>

                <div className="grid2masonry">
                <div   className={`box-holder ${(boxcount === 1 || showAllBox === true) ? 'active' : 'hide' }`} >
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <>
                            <h3 className="editable">
                                {data[lang]['personalInformation']}
                                <span className="editable__icon" onClick={() => editDetails(1)} />
                            </h3>
                            { inputValues !== undefined ? (
                                <>
                            <ListCustInfo  inputvalue={countryBirth}  inputtype="input" fieldlabel={data[lang]['countryOfBirth']}   />
                            <ListCustInfo  inputvalue={maritalStatus} inputtype="input" fieldlabel={data[lang]['maritalStatus']} />
                            {/*<ListCustInfo  inputvalue={inputValues.name}  inputtype="input"  fieldlabel={data[lang]['name']} />*/}
                            </>
                                ) : '' }

                                {/*<div className="flexCC mnButtons desktopBtn">
                                    { boxcount > 1 && (
                                        <button onClick={() => setPrevBoxcount(boxcount, boxcount - jumpcount)}  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                                    ) }

                                    { boxcount < maxboxcount ? (
                                        <button  onClick={() => setNextBoxcount(boxcount, boxcount + jumpcount)}  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                                    ):  (
                                        <button  onClick={() => setPreview(!editform)}   className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                                    ) }
                                </div>*/}
                            </>
                        </div>


                    </div>

                <div   className={`box-holder ${(boxcount === 2 || showAllBox === true) ? 'active' : 'hide' }`} >
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <>
                            <h3 className="editable">
                                {data[lang]['residenceAddress']}
                                <span className="editable__icon" onClick={() => editDetails(2)} />
                            </h3>

                            { custdata !== undefined ? (
                                <>
                            <ListCustInfo   inputvalue={inputValues.address} inputtype="input" fieldlabel={data[lang]['address']} />
                            <ListCustInfo   inputvalue={inputValues.po_box} inputtype="input" fieldlabel={data[lang]['po_box']} />
                            <ListCustInfo   inputvalue={inputValues.landmark} inputtype="input" fieldlabel={data[lang]['landmark']} />
                            <ListCustInfo   inputvalue={inputValues.house} inputtype="input" fieldlabel={data[lang]['houseFlatNo']} />
                            <ListCustInfo  inputvalue={inputValues.building} inputtype="input"  fieldlabel={data[lang]['buildingNo']}  />
                            <ListCustInfo   inputvalue={inputValues.way} inputtype="input" fieldlabel={data[lang]['wayNo']} />
                            <ListCustInfo   inputvalue={inputValues.city} inputtype="input" fieldlabel={data[lang]['city']}/>
                            <ListCustInfo  inputvalue={inputValues.wilayat} inputtype="input" fieldlabel={(idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT') ? data[lang]['statewilayat'] : data[lang]['wilayat']}  />
                            <ListCustInfo  inputvalue={picode} inputtype="input"  fieldlabel={data[lang]['pinCode']}  />

                                </>
                            ) : '' }

                                {/*<div className="flexCC mnButtons desktopBtn">
                                    { boxcount > 1 && (
                                        <button onClick={() => setPrevBoxcount(boxcount, boxcount - jumpcount)}  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                                    )}

                                    { boxcount < maxboxcount ? (
                                        <button  onClick={() => setNextBoxcount(boxcount, boxcount + jumpcount)}  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                                    ):  (
                                        <button  onClick={() => setPreview(!editform)}   className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                                    ) }
                                </div>*/}
                            </>

                        </div>
                    </div>

                <div   className={`box-holder ${(boxcount === 3 || showAllBox === true) ? 'active' : 'hide' }`} >
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <>
                            <h3 className="editable">
                                {data[lang]['employmentDetails']}
                                <span className="editable__icon" onClick={() => editDetails(3)} />
                            </h3>
                            { custdata !== undefined ? (
                                <>
                            <ListCustInfo   inputvalue={incomeRange}  inputtype="input" fieldlabel={data[lang]['monthlyIncom']} />
                            <ListCustInfo  inputvalue={inputValues.employed}  inputtype="switch" values={yesnovalue} fieldlabel={data[lang]['areYouEmployed']}  />
                            { inputValues.employed.toUpperCase() === "NO" ? (
                            <ListCustInfo   inputvalue={employedCurStatus}  inputtype="input"  values={yesnovalue} fieldlabel={data[lang]['mentionCurrentStatus']} />
                            ) :
                                <ListCustInfo   inputvalue={employmentSector}  inputtype="input"  values={yesnovalue} fieldlabel={data[lang]['employmentsector']} />
                            }

                                </>
                            ) : '' }

                                {/*<div className="flexCC mnButtons desktopBtn">
                                    { boxcount > 1 && (
                                        <button onClick={() => setPrevBoxcount(boxcount, boxcount - jumpcount)}  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                                    )}

                                    { boxcount < maxboxcount ? (
                                        <button  onClick={() => setNextBoxcount(boxcount, boxcount + jumpcount)}  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                                    ):  (
                                        <button  onClick={() => setPreview(!editform)}   className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                                    ) }
                                </div>*/}
                            </>
                        </div>
                    </div>

                <div   className={`box-holder ${(boxcount === 4 || showAllBox === true) ? 'active' : 'hide' }`} >
                    <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                        <>
                        <h3 className="editable">
                            {data[lang]['usIndecia']}
                            <span className="editable__icon" onClick={() => editDetails(4)} />
                        </h3>
                        { custdata !== undefined ? (
                            <>
                        <ListCustInfo  inputvalue={inputValues.ustax_resident}  inputtype="switch"  values={yesnovalue}  fieldlabel={data[lang]['usTaxResident']} />
                        <ListCustInfo  inputvalue={inputValues.usaddress_tel}  inputtype="switch"  values={yesnovalue} fieldlabel={data[lang]['addressInUS']}  />
                        <ListCustInfo  inputvalue={inputValues.us_nationality}  inputtype="switch"  values={yesnovalue} fieldlabel={data[lang]['usNationality']}  />
                        <ListCustInfo  inputvalue={inputValues.us_payments}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['makingPaymentsToUs']}  />



                            </>
                        ) : '' }
                            {/*<div className="flexCC mnButtons desktopBtn">
                                { boxcount > 1 && (
                                    <button onClick={() => setPrevBoxcount(boxcount, boxcount - jumpcount)}  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                                )}

                                { boxcount < maxboxcount ? (
                                    <button  onClick={() => setNextBoxcount(boxcount, boxcount + jumpcount)}  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                                ):  (
                                    <button  onClick={() => setPreview(!editform)}   className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                                ) }
                            </div>*/}
                        </>
                    </div>
                </div>



                <div   className={`box-holder ${(boxcount === 5 || showAllBox === true) ? 'active' : 'hide' }`} >
                    <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                        <>
                        <h3 className="editable">
                            {data[lang]['commonReportingStandard']}
                            <span className="editable__icon" onClick={() => editDetails(5)} />
                        </h3>
                        { custdata !== undefined ? (
                            <>
                        <ListCustInfo  inputvalue={inputValues.taxresident_not_oman}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['taxResidentOtherCountry']} />
                                { inputValues.taxresident_not_oman === "Yes" ?(
                                       <>
                                           { inputValues.showtaxresidence1 ? (
                                           <>
                                               { country_taxresidence1 !== '' &&
                                               <div className="crs-outer">
                                               <ListCustInfo  inputvalue={country_taxresidence1}  inputtype="input-full"  fieldlabel={data[lang]['countryOfTaxResident1']}  />
                                               <ListCustInfo   inputvalue={inputValues.tinnumber1}  inputtype="input-full" fieldlabel={data[lang]['tinNumber1']} />
                                               {inputValues.reason1 !== '' &&
                                                   <ListCustInfo inputvalue={crsReason1} inputtype="input-full"
                                                                 fieldlabel={data[lang]['crsreason1']}/>
                                               }
                                                </div>
                                                   }
                                            </>
                                           ): ''}

                                           { inputValues.showtaxresidence2  ? (
                                           <>
                                               { country_taxresidence2 !== '' &&
                                                   <div className="crs-outer">
                                                    <ListCustInfo  inputvalue={country_taxresidence2}  inputtype="input-full"  fieldlabel={data[lang]['countryOfTaxResident2']}  />
                                                    <ListCustInfo   inputvalue={inputValues.tinnumber2}  inputtype="input-full" fieldlabel={data[lang]['tinNumber2']} />
                                                       {inputValues.reason2 !== '' &&
                                                       <ListCustInfo inputvalue={crsReason2} inputtype="input-full"
                                                                     fieldlabel={data[lang]['crsreason2']}/>
                                                       }
                                                   </div>
                                               }
                                                   </>
                                           ): ''}

                                           { inputValues.showtaxresidence3  ? (
                                            <>
                                                { country_taxresidence3 !== '' &&
                                                <div className="crs-outer">
                                                <ListCustInfo  inputvalue={country_taxresidence3}  inputtype="input-full"  fieldlabel={data[lang]['countryOfTaxResident3']}  />
                                                <ListCustInfo   inputvalue={inputValues.tinnumber3}  inputtype="input-full" fieldlabel={data[lang]['tinNumber3']} />
                                                {inputValues.reason3 !== '' &&
                                                    <ListCustInfo inputvalue={crsReason3} inputtype="input-full"
                                                                  fieldlabel={data[lang]['crsreason3']}/>
                                                }
                                                </div>
                                                }
                                                    </>
                                           ): ''}

                                           { inputValues.showtaxresidence4  ? (
                                               <>
                                                   <ListCustInfo  inputvalue={country_taxresidence4}  inputtype="input-full"  fieldlabel={data[lang]['countryOfTaxResident4']}  />
                                                   <ListCustInfo   inputvalue={inputValues.tinnumber4}  inputtype="input-full" fieldlabel={data[lang]['tinNumber4']} />
                                               </>
                                           ): ''}

                                       </>
                                    ) : '' }
                            </>
                        ) : '' }
                            {/*<div className="flexCC mnButtons desktopBtn">
                                { boxcount > 1 && (
                                    <button onClick={() => setPrevBoxcount(boxcount, boxcount - jumpcount)}  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                                )}

                                { boxcount < maxboxcount ? (
                                    <button  onClick={() => setNextBoxcount(boxcount, boxcount + jumpcount)}  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                                ):  (
                                    <button  onClick={() => setPreview(!editform)}   className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                                ) }
                            </div>*/}
                        </>
                    </div>
                </div>



                <div   className={`box-holder ${(boxcount === 6 || showAllBox === true) ? 'active' : 'hide' }`} >
                    <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                        <>
                        <h3 className="editable">
                            {data[lang]['exposedPersonMessage']}<span className="info" onClick={showPepInfo}>?</span>
                            <span className="editable__icon" onClick={() => editDetails(6)} />
                        </h3>

                        { custdata !== undefined ? (
                            <>
                                <ListCustInfo  inputvalue={inputValues.current_former_pep}  inputtype="switch" values={yesnovalue} fieldlabel={data[lang]['pepquestion1']}  child={inputValues.current_former_pep.toUpperCase()==='YES' && inputValues.pep_category !== ''}/>
                                {inputValues.current_former_pep === 'Yes' &&
                                <ListCustInfo inputvalue={inputValues.pep_category} inputtype="input-full"  fieldlabel="" parent={true}/>
                                }
                                {(inputValues.current_former_pep.toUpperCase() === 'YES' && inputValues.pep_relative.toUpperCase() !== 'YES' && inputValues.pep_busns_associate.toUpperCase() !== 'YES') ?
                                    <>
                                        <ListCustInfo inputvalue='' inputtype="input-full"
                                                      fieldlabel={data[lang]['pepquestion2']}/>
                                        <ListCustInfo inputvalue='' inputtype="input-full"
                                                       fieldlabel={data[lang]['pepquestion3']}/>
                                    </>
                                    :
                                    <>
                                        <ListCustInfo inputvalue={inputValues.pep_relative} inputtype="switch"
                                                      values={yesnovalue} fieldlabel={data[lang]['pepquestion2']}/>
                                        <ListCustInfo inputvalue={inputValues.pep_busns_associate} inputtype="switch"
                                                      values={yesnovalue} fieldlabel={data[lang]['pepquestion3']}
                                                      child={inputValues.pep_busns_associate.toUpperCase() === 'YES'}/>

                                    </>
                                }

                                {/*<ListCustInfo  inputtype="label-list" values={yesnovalue} fieldlabel="test" pepListLabels={pepListLabels}/>*/}
                                { inputValues.pep_busns_associate.toUpperCase() === "YES"  ? (
                                    <div className="crs-outer haveParent">
                                    <ListCustInfo  inputvalue={inputValues.pep_relation}  inputtype="input-full"   fieldlabel={data[lang]['pepquestion3a']} />
                                    </div>

                                    ) : ''}
                                { inputValues.current_former_pep.toUpperCase() === "YES" || inputValues.pep_relative.toUpperCase() === "YES" || inputValues.pep_busns_associate.toUpperCase() === "YES"  ? (
                                    <div className="crs-outer">
                                    <ListCustInfo  inputvalue={inputValues.pep_name}  inputtype="input-full"   fieldlabel={data[lang]['pepname']}   />
                                    <ListCustInfo  inputvalue={''}  inputtype="input"   fieldlabel={data[lang]['pepperiod']}   />
                                        {lang === 'En' ?
                                            <>
                                            <ListCustInfo inputvalue={formatDate(inputValues.pep_period_from)}
                                                          inputtype="input-half" fieldlabel={data[lang]['pepfrom']}/>
                                            <ListCustInfo inputvalue={formatDate(inputValues.pep_period_to)}
                                                          inputtype="input-half" fieldlabel={data[lang]['pepto']}/>
                                            </>
                                            :
                                            <>
                                                <ListCustInfo inputvalue={formatDate(inputValues.pep_period_to)}
                                                              inputtype="input-half" fieldlabel={data[lang]['pepto']}/>
                                                <ListCustInfo inputvalue={formatDate(inputValues.pep_period_from)}
                                                              inputtype="input-half" fieldlabel={data[lang]['pepfrom']}/>

                                            </>
                                        }
                                            <ListCustInfo  inputvalue={inputValues.pep_country}  inputtype="input-full"   fieldlabel={data[lang]['pepcountry']}   />
                                    </div>

                                    ) : ''}
                            </>
                        ) : '' }

                            {/*<div className="flexCC mnButtons desktopBtn">
                                { boxcount > 1 && (
                                    <button onClick={() => setPrevBoxcount(boxcount, boxcount - jumpcount)}  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                                )}

                                { boxcount < maxboxcount ? (
                                    <button  onClick={() => setNextBoxcount(boxcount, boxcount + jumpcount)}  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                                ):  (
                                    <button  onClick={() => setPreview(!editform)}   className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                                ) }
                            </div>*/}

                        </>

                    </div>
                </div>




                <div   className={`box-holder ${((boxcount === 7 || showAllBox === true) && idtype === "Non-Resident") ? 'active' : 'hide' }`} >

                    <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                        <>
                        <h3 className="editable">
                            {data[lang]['residentToNon']}
                            <span className="editable__icon" onClick={() => editDetails(7)} />
                        </h3>
                        { custdata !== undefined ? (
                            <>

                                <ListCustInfo   inputvalue={inputValues.ownproperty_oman}  inputtype="switch"  values={yesnovalue} fieldlabel={data[lang]['ownPropertyOman']} />
                                <ListCustInfo   inputvalue={inputValues.relative_family_oman}  inputtype="switch" values={yesnovalue} fieldlabel={data[lang]['relativeFamilyOman']} />
                                <ListCustInfo   inputvalue={inputValues.own_business_oman}  inputtype="switch" values={yesnovalue} fieldlabel={data[lang]['ownBusinessOman']} />
                                <ListCustInfo  inputvalue={inputValues.benefit_from_emplyr}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['benefitFromEmplyr']}  />
                                <ListCustInfo inputvalue={inputValues.authorise_bank_credit}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['authoriseBankCredit']}  />
                                <ListCustInfo  inputvalue={inputValues.other_reason}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['otherReason']} />
                                {(inputValues.other_reason && inputValues.other_reason.toUpperCase() === 'YES') &&
                                    <>
                                    <ListCustInfo inputvalue={inputValues.accholding_reason1} inputtype="input"
                                              fieldlabel={data[lang]['reason1']}/>
                                    <ListCustInfo   inputvalue={inputValues.accholding_reason2}  inputtype="input" fieldlabel={data[lang]['reason2']} />
                                    </>
                                }
                            </>
                        ) : '' }
                            {/*<div className="flexCC mnButtons desktopBtn">
                                { boxcount > 1 && (
                                    <button onClick={() => setPrevBoxcount(boxcount, boxcount - jumpcount)}  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                                )}

                                { boxcount < maxboxcount ? (
                                    <button  onClick={() => setNextBoxcount(boxcount, boxcount + jumpcount)}  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                                ):  (
                                    <button  onClick={() => setPreview(!editform)}   className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                                ) }
                            </div>*/}
                        </>

                    </div>
                </div>
                    {(addressproofstatus === 'ON' || (inputValues.salary_bom.toUpperCase() === 'YES' || showEmployersDetails === true || inputValues.showemployment_proof === true || inputValues.showselfemployment_proof === true || inputValues.showW8 === true || inputValues.showW9 === true || (inputValues.other_reason && inputValues.other_reason.toUpperCase() === 'YES') || isNstp === 1)) &&
                    <div className={`box-holder ${(boxcount === 8 || showAllBox === true) ? 'active' : 'hide'}`}>

                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <>
                                <h3 className="editable">
                                    {data[lang]['addnlDocuments']}
                                    {(addressproofstatus === 'ON' || (showEmployersDetails === true || inputValues.showemployment_proof === true || inputValues.showselfemployment_proof === true || inputValues.showW8 === true || inputValues.showW9 === true || (inputValues.other_reason && inputValues.other_reason.toUpperCase() === 'YES') || isNstp === 1)) &&
                                    <span className="editable__icon" onClick={() => editDetails(8)}/>
                                    }
                                </h3>
                                {custdata !== undefined ? (
                                    <>
                                        {/*<div className="adnl-outer">
                                    <ListCustInfo   inputvalue={employmentStatus}  inputtype="input-full"   fieldlabel={data[lang]['employmentStatus']}   />
                                    </div>*/}
                                        {addressproofstatus === 'ON' &&
                                        <>
                                            <div className="adnl-outer">
                                                <ListCustInfo inputvalue={addressProofCategory} inputtype="input"
                                                              fieldlabel={data[lang]['addressProofInstruction1']}/>
                                                <ListCustInfo inputvalue={inputValues.selectedfilenameaddress_proof}
                                                              inputtype="file-full" fieldlabel=""/>
                                            </div>

                                            <ListCustInfo inputvalue={inputValues.address_proof_own_name}
                                                          inputtype="switch"
                                                          values={yesnovalue}
                                                          fieldlabel={data[lang]['addressProofOwnName']}/>
                                            {inputValues.showlistrelatioshipaddrs ? (
                                                <div className="adnl-outer">
                                                    <ListCustInfo inputvalue={relatioshipaddrs} inputtype="input-full"
                                                                  fieldlabel={data[lang]['relatioshipAddrs']}/>
                                                </div>
                                            ) : ''}
                                            {inputValues.showauthorisation_letter ? (
                                                <div className="adnl-outer">
                                                    <ListCustInfo
                                                        inputvalue={inputValues.selectedfilenameauthorisation_letter}
                                                        inputtype="file-full"
                                                        fieldlabel={data[lang]['authorisationLetter']}/>
                                                </div>
                                            ) : ''}
                                        </>
                                        }
                                        <ListCustInfo inputvalue={inputValues.salary_bom} inputtype="switch"
                                                      fieldlabel={data[lang]['salaryBom']}/>
                                        {/*{(inputValues.salary_bom.toUpperCase() === 'YES' && salarydata.length > 0) &&
                                    <div className="salaryDtls">
                                        <div className="uploaded-doc-label">Account No: {salarydata[0]['SalaryAccNo']}</div>
                                        <table className="salaryTable" style={{width: "90%"}}>
                                            <thead>
                                            <tr>
                                                <td>Date</td>
                                                <td>Salary</td>

                                            </tr>
                                            </thead>
                                            {salarydata.map((monthSalary, indx) =>
                                                <tr>
                                                    <td>{covertDate(monthSalary.PrevSalaryDate)}</td>
                                                    <td style={{textAlign: "right"}}>{monthSalary.PrevSalary}</td>

                                                </tr>

                                            )}
                                        </table>
                                    </div>
                                    }*/}
                                        {showEmployersDetails === true &&
                                        <>
                                            <ListCustInfo inputvalue={inputValues.nameofemployer} inputtype="input"
                                                          fieldlabel={data[lang]['nameofemployer']}/>
                                            <ListCustInfo inputvalue={inputValues.employersaddress} inputtype="input"
                                                          fieldlabel={data[lang]['employersaddress']}/>
                                            <ListCustInfo inputvalue={pcCode} inputtype="input"
                                                          fieldlabel={data[lang]['pcCode']}/>
                                            <ListCustInfo inputvalue={inputValues.location} inputtype="input"
                                                          fieldlabel={data[lang]['location']}/>
                                            <ListCustInfo inputvalue={inputValues.presentposition} inputtype="input"
                                                          fieldlabel={data[lang]['presentposition']}/>
                                        </>
                                        }
                                        {inputValues.showemployment_proof ? (
                                            <div className="adnl-outer">
                                                <ListCustInfo inputvalue={employmentProofCategory} inputtype="input"
                                                              fieldlabel={data[lang]['employmentProofInstruction1']}/>
                                                <ListCustInfo inputvalue={inputValues.selectedfilenameemployment_proof}
                                                              inputtype="file-full" fieldlabel=""/>
                                            </div>
                                        ) : ''}

                                        {inputValues.showselfemployment_proof ? (
                                            <div className="adnl-outer">
                                                <ListCustInfo inputvalue={selfemploymentProofCategory} inputtype="input"
                                                              fieldlabel={data[lang]['selfEmploymentProofInstruction1']}/>
                                                <ListCustInfo
                                                    inputvalue={inputValues.selectedfilenameselfemployment_proof}
                                                    inputtype="file-full" fieldlabel=""/>
                                            </div>
                                        ) : ''}

                                        {inputValues.showW8 ? (
                                            <div className="adnl-outer">
                                                <ListCustInfo inputvalue={inputValues.selectedfilenamew8}
                                                              inputtype="file-full" fieldlabel={data[lang]['w8form']}/>
                                            </div>
                                        ) : ''}
                                        {inputValues.showW9 ? (
                                            <div className="adnl-outer">
                                                <ListCustInfo inputvalue={inputValues.selectedfilenamew9}
                                                              inputtype="file-full" fieldlabel={data[lang]['w9form']}/>
                                            </div>
                                        ) : ''}
                                        {(inputValues.other_reason && inputValues.other_reason.toUpperCase() === 'YES') ? (
                                            <div className="adnl-outer">
                                                <ListCustInfo inputvalue={inputValues.filenamedocresidenttononresident}
                                                              inputtype="file-full"
                                                              fieldlabel={data[lang]['docresidenttononresident']}/>
                                            </div>
                                        ) : ''}
                                        {isNstp === 1 ? (
                                            <div className="adnl-outer">
                                                <ListCustInfo inputvalue={inputValues.filenameadditionaldoc}
                                                              inputtype="file-full"
                                                              fieldlabel={data[lang]['additionaldoc']}/>
                                            </div>
                                        ) : ''}


                                    </>
                                ) : ''}

                                {/*<div className="flexCC mnButtons desktopBtn">
                                    { boxcount > 1 && (
                                        <button onClick={() => setPrevBoxcount(boxcount, boxcount - jumpcount)}  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                                    )}

                                    { boxcount < maxboxcount ? (
                                        <button  onClick={() => setNextBoxcount(boxcount, boxcount + jumpcount)}  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                                    ):  (
                                        <button  onClick={() => setPreview(!editform)}   className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                                    ) }
                                </div>*/}
                            </>
                        </div>
                    </div>
                    }
                </div>

                    {showAllBox === true ?
                        <>
                        <div className="flexCC mnButtons">
                            <button onClick={() => validateAll()}
                                    className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                        </div>
                        <div className="flexCC mnButtons desktopBtn">
                            <button onClick={() => validateAll()}
                                    className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                        </div>
                        </>
                        :


                        <div className="flexCC mnButtons">
                            {boxcount > 1 && (
                                <button onClick={() => setPrevBoxcount(boxcount, boxcount - jumpcount)}
                                        className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                            )/* : (
                        <button onClick={prevPage}  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</button>
                    )*/}

                            {boxcount < maxboxcount ? (
                                <button onClick={() => setNextBoxcount(boxcount, boxcount + jumpcount)}
                                        className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                            ) : (
                                <button onClick={() => setPreview(!editform)}
                                        className="btn btn--capsule btn--primaryG">{data[lang]['previewButton']}</button>
                            )}
                        </div>
                    }
                </div>
                <div   id= "divToPrint"    className={`preview-form  ${!editform ? 'active' : 'hide' }`} ref={previewRef} >
                    <div className="headerBlock">
                       <h3>{data[lang]['cstomerDetailsPreview']}</h3>
                    </div>

                    <div   className="box-holder active">
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <h3 className="editable">

                                {data[lang]['personalInformation']}
                            </h3>
                            { inputValues !== undefined ? (
                                <>
                                    <ListCustInfo  inputvalue={countryBirth}  inputtype="input" fieldlabel={data[lang]['countryOfBirth']}   />
                                    <ListCustInfo  inputvalue={maritalStatus} inputtype="input" fieldlabel={data[lang]['maritalStatus']} />
                                    <ListCustInfo  inputvalue={inputValues.name}  inputtype="input"  fieldlabel={data[lang]['name']} />
                                </>
                            ) : '' }
                        </div>
                    </div>

                    <div   className="box-holder active">
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>

                            <h3 className="editable">
                                {data[lang]['residenceAddress']}

                            </h3>
                            { custdata !== undefined ? (
                                <>
                                    <ListCustInfo   inputvalue={inputValues.address} inputtype="input" fieldlabel={data[lang]['address']} />
                                    <ListCustInfo   inputvalue={inputValues.po_box} inputtype="input" fieldlabel={data[lang]['po_box']} />
                                    <ListCustInfo   inputvalue={inputValues.landmark} inputtype="input" fieldlabel={data[lang]['landmark']} />
                                    <ListCustInfo   inputvalue={inputValues.house} inputtype="input" fieldlabel={data[lang]['houseFlatNo']} />
                                    <ListCustInfo  inputvalue={inputValues.building} inputtype="input"  fieldlabel={data[lang]['buildingNo']}  />
                                    <ListCustInfo   inputvalue={inputValues.way} inputtype="input" fieldlabel={data[lang]['wayNo']} />
                                    <ListCustInfo   inputvalue={inputValues.city} inputtype="input" fieldlabel={data[lang]['city']} />
                                    <ListCustInfo  inputvalue={inputValues.wilayat} inputtype="input" fieldlabel={(idtype.toUpperCase() === "NON-RESIDENT" || idtype.toUpperCase() === 'PASSPORT') ? data[lang]['statewilayat'] : data[lang]['wilayat']}  />
                                    <ListCustInfo  inputvalue={picode} inputtype="input"  fieldlabel={data[lang]['pinCode']}  />

                                </>
                            ) : '' }
                        </div>
                    </div>

                    <div   className="box-holder active" >
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <h3 className="editable">
                                {data[lang]['employmentDetails']}
                            </h3>
                            { custdata !== undefined ? (
                                <>
                                    <ListCustInfo   inputvalue={incomeRange}  inputtype="input" fieldlabel={data[lang]['monthlyIncom']} />
                                    <ListCustInfo  inputvalue={inputValues.employed}  inputtype="switch" values={yesnovalue} fieldlabel={data[lang]['areYouEmployed']}  />
                                    { inputValues.employed.toUpperCase() === "NO" ? (
                                        <ListCustInfo   inputvalue={employedCurStatus}  inputtype="input"  values={yesnovalue} fieldlabel={data[lang]['mentionCurrentStatus']} />
                                    ) :
                                        <ListCustInfo   inputvalue={employmentSector}  inputtype="input"  values={yesnovalue} fieldlabel={data[lang]['employmentsector']} />
                                    }

                                </>
                            ) : '' }
                        </div>
                    </div>

                    <div   className="box-holder active" >
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <h3 className="editable">
                                {data[lang]['usIndecia']}
                            </h3>
                            { custdata !== undefined ? (
                                <>
                                    <ListCustInfo  inputvalue={inputValues.ustax_resident}  inputtype="switch"  values={yesnovalue}  fieldlabel={data[lang]['usTaxResident']} />
                                    <ListCustInfo  inputvalue={inputValues.usaddress_tel}  inputtype="switch"  values={yesnovalue} fieldlabel={data[lang]['addressInUS']}  />
                                    <ListCustInfo  inputvalue={inputValues.us_nationality}  inputtype="switch"  values={yesnovalue} fieldlabel={data[lang]['usNationality']}  />
                                    <ListCustInfo  inputvalue={inputValues.us_payments}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['makingPaymentsToUs']}  />
                                </>
                            ) : '' }
                        </div>
                    </div>



                    <div   className="box-holder active" >
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <h3 className="editable">
                                {data[lang]['commonReportingStandard']}

                            </h3>
                            { custdata !== undefined ? (
                                <>
                                    <ListCustInfo  inputvalue={inputValues.taxresident_not_oman}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['taxResidentOtherCountry']} />
                                    { inputValues.taxresident_not_oman === "Yes" ?(
                                        <>
                                            { inputValues.showtaxresidence1 ? (
                                        <>
                                            {country_taxresidence1 !== '' &&
                                            <div className="crs-outer">
                                            <ListCustInfo inputvalue={country_taxresidence1} inputtype="input"
                                                          fieldlabel={data[lang]['countryOfTaxResident']}/>
                                            <ListCustInfo   inputvalue={inputValues.tinnumber1}  inputtype="input" fieldlabel={data[lang]['tinNumber']} />
                                                {inputValues.reason1 !== '' &&
                                                <ListCustInfo inputvalue={crsReason1} inputtype="input-full"
                                                              fieldlabel={data[lang]['crsreason1']}/>
                                                }
                                            </div>
                                            }
                                        </>
                                                ) :''}
                                        { inputValues.showtaxresidence2  ? (
                                        <>
                                            {country_taxresidence2 !== '' &&
                                            <div className="crs-outer">
                                            <ListCustInfo  inputvalue={country_taxresidence2}  inputtype="input"  fieldlabel={data[lang]['countryOfTaxResident']}  />
                                            <ListCustInfo   inputvalue={inputValues.tinnumber2}  inputtype="input" fieldlabel={data[lang]['tinNumber']} />
                                                {inputValues.reason2 !== '' &&
                                                <ListCustInfo inputvalue={crsReason2} inputtype="input-full"
                                                              fieldlabel={data[lang]['crsreason2']}/>
                                                }
                                            </div>
                                            }
                                            </>):''
                                        }
                                        { inputValues.showtaxresidence3  ? (
                                            <>
                                                {country_taxresidence3 !== '' &&
                                                <div className="crs-outer">
                                                <ListCustInfo  inputvalue={country_taxresidence3}  inputtype="input"  fieldlabel={data[lang]['countryOfTaxResident']}  />
                                                <ListCustInfo   inputvalue={inputValues.tinnumber3}  inputtype="input" fieldlabel={data[lang]['tinNumber']} />
                                                    {inputValues.reason3 !== '' &&
                                                    <ListCustInfo inputvalue={crsReason3} inputtype="input-full"
                                                                  fieldlabel={data[lang]['crsreason3']}/>
                                                    }
                                                </div>
                                                }
                                            </>):''
                                        }
                                        { inputValues.showtaxresidence4  ? (
                                            <>
                                                <ListCustInfo  inputvalue={country_taxresidence4}  inputtype="input"  fieldlabel={data[lang]['countryOfTaxResident']}  />
                                                <ListCustInfo   inputvalue={inputValues.tinnumber4}  inputtype="input" fieldlabel={data[lang]['tinNumber']} />
                                            </>):''
                                        }
                                        </>
                                    ) : '' }
                                </>
                            ) : '' }
                        </div>
                    </div>








                    <div   className="box-holder active" >
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <h3 className="editable">
                                {data[lang]['exposedPersonMessage']}
                            </h3>

                            { custdata !== undefined ? (
                                <>
                                    <ListCustInfo  inputvalue={inputValues.current_former_pep}  inputtype="switch" values={yesnovalue} fieldlabel={data[lang]['pepquestion1']}  child={inputValues.current_former_pep.toUpperCase()==='YES' && inputValues.pep_category !== ''}/>
                                    {inputValues.current_former_pep === 'Yes' &&
                                    <ListCustInfo inputvalue={inputValues.pep_category} inputtype="input-full"  fieldlabel="" parent={true}/>
                                    }
                                    {(inputValues.current_former_pep.toUpperCase() === 'YES' && inputValues.pep_relative.toUpperCase() !== 'YES' && inputValues.pep_busns_associate.toUpperCase() !== 'YES') ?
                                        <>
                                            <ListCustInfo inputvalue='' inputtype="input-full"
                                                          fieldlabel={data[lang]['pepquestion2']}/>
                                            <ListCustInfo inputvalue='' inputtype="input-full"
                                                          fieldlabel={data[lang]['pepquestion3']}/>
                                        </>
                                        :
                                        <>
                                            <ListCustInfo inputvalue={inputValues.pep_relative} inputtype="switch"
                                                          values={yesnovalue} fieldlabel={data[lang]['pepquestion2']}/>
                                            <ListCustInfo inputvalue={inputValues.pep_busns_associate} inputtype="switch"
                                                          values={yesnovalue} fieldlabel={data[lang]['pepquestion3']}
                                                          child={inputValues.pep_busns_associate.toUpperCase() === 'YES'}/>

                                        </>
                                    }
                                    {/*<ListCustInfo  inputtype="label-list" values={yesnovalue} fieldlabel="test" pepListLabels={pepListLabels}/>*/}
                                    { inputValues.pep_busns_associate.toUpperCase() === "YES"  ? (
                                        <div className="crs-outer haveParent">
                                            <ListCustInfo  inputvalue={inputValues.pep_relation}  inputtype="input-full"   fieldlabel={data[lang]['pepquestion3a']} />
                                        </div>

                                    ) : ''}
                                    { inputValues.current_former_pep.toUpperCase() === "YES" || inputValues.pep_relative.toUpperCase() === "YES" || inputValues.pep_busns_associate.toUpperCase() === "YES"  ? (
                                        <div className="crs-outer">
                                            <ListCustInfo  inputvalue={inputValues.pep_name}  inputtype="input-full"   fieldlabel={data[lang]['pepname']}   />
                                            <ListCustInfo  inputvalue={''}  inputtype="input"   fieldlabel={data[lang]['pepperiod']}   />
                                            {lang === 'En' ?
                                                <>
                                                    <ListCustInfo inputvalue={formatDate(inputValues.pep_period_from)}
                                                                  inputtype="input-half" fieldlabel={data[lang]['pepfrom']}/>
                                                    <ListCustInfo inputvalue={formatDate(inputValues.pep_period_to)}
                                                                  inputtype="input-half" fieldlabel={data[lang]['pepto']}/>
                                                </>
                                                :
                                                <>
                                                    <ListCustInfo inputvalue={formatDate(inputValues.pep_period_to)}
                                                                  inputtype="input-half" fieldlabel={data[lang]['pepto']}/>
                                                    <ListCustInfo inputvalue={formatDate(inputValues.pep_period_from)}
                                                                  inputtype="input-half" fieldlabel={data[lang]['pepfrom']}/>

                                                </>
                                            }<ListCustInfo  inputvalue={inputValues.pep_country}  inputtype="input-full"   fieldlabel={data[lang]['pepcountry']}   />
                                        </div>

                                    ) : ''}
                                </>
                            ) : '' }
                        </div>
                    </div>


                    { residentonon ?(
                    <div   className="box-holder active"  >
                    <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                        <h3 className="editable">
                            {data[lang]['residentToNon']}
                        </h3>
                        { custdata !== undefined ? (
                            <>

                                <ListCustInfo   inputvalue={inputValues.ownproperty_oman}  inputtype="switch"  values={yesnovalue} fieldlabel={data[lang]['ownPropertyOman']} />
                                <ListCustInfo   inputvalue={inputValues.relative_family_oman}  inputtype="switch" values={yesnovalue} fieldlabel={data[lang]['relativeFamilyOman']} />
                                <ListCustInfo   inputvalue={inputValues.own_business_oman}  inputtype="switch" values={yesnovalue} fieldlabel={data[lang]['ownBusinessOman']} />
                                <ListCustInfo  inputvalue={inputValues.benefit_from_emplyr}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['benefitFromEmplyr']}  />
                                <ListCustInfo inputvalue={inputValues.authorise_bank_credit}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['authoriseBankCredit']}  />
                                <ListCustInfo  inputvalue={inputValues.other_reason}  inputtype="switch" values={yesnovalue}  fieldlabel={data[lang]['otherReason']} />
                                <ListCustInfo   inputvalue={inputValues.accholding_reason1}  inputtype="input" fieldlabel={data[lang]['reason1']} />
                                <ListCustInfo   inputvalue={inputValues.accholding_reason2}  inputtype="input" fieldlabel={data[lang]['reason2']} />
                            </>
                        ) : '' }
                    </div>
                </div> )  : ''}


                    {(addressproofstatus === 'ON' || (inputValues.salary_bom.toUpperCase() === 'YES' || showEmployersDetails === true || inputValues.showemployment_proof === true || inputValues.showselfemployment_proof === true || inputValues.showW8 === true || inputValues.showW9 === true || (inputValues.other_reason && inputValues.other_reason.toUpperCase() === 'YES') || isNstp === 1)) &&
                    <div className="box-holder active">
                        <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <h3 className="editable">
                                {data[lang]['addnlDocuments']}
                            </h3>
                            {custdata !== undefined ? (
                                <>
                                    {addressproofstatus === 'ON' &&
                                    <>
                                        <ListCustInfo inputvalue={addressProofCategory} inputtype="input"
                                                      fieldlabel={data[lang]['addressProofInstruction1']}/>
                                        <ListCustInfo inputvalue={inputValues.selectedfilenameaddress_proof}
                                                      inputtype="file-full" fieldlabel=""/>

                                        <ListCustInfo inputvalue={inputValues.address_proof_own_name} inputtype="switch"
                                                      values={yesnovalue}
                                                      fieldlabel={data[lang]['addressProofOwnName']}/>
                                        {inputValues.showlistrelatioshipaddrs ? (
                                            <ListCustInfo inputvalue={relatioshipaddrs} inputtype="input"
                                                          fieldlabel={data[lang]['relatioshipAddrs']}/>
                                        ) : ''}
                                        {inputValues.showauthorisation_letter ? (
                                            <ListCustInfo inputvalue={inputValues.selectedfilenameauthorisation_letter}
                                                          inputtype="file"
                                                          fieldlabel={data[lang]['authorisationLetter']}/>
                                        ) : ''}
                                    </>
                                    }
                                    <ListCustInfo inputvalue={inputValues.salary_bom} inputtype="switch"
                                                  fieldlabel={data[lang]['salaryBom']}/>
                                    {/*{(inputValues.salary_bom.toUpperCase() === 'YES' && salarydata.length > 0) &&
                                    <div className="salaryDtls">
                                        <div className="uploaded-doc-label">Account No: {salarydata[0]['SalaryAccNo']}</div>
                                        <table className="salaryTable" style={{width: "90%"}}>
                                            <thead>
                                            <tr>
                                                <td>Date</td>
                                                <td>Salary</td>

                                            </tr>
                                            </thead>
                                            {salarydata.map((monthSalary, indx) =>
                                                <tr>
                                                    <td>{covertDate(monthSalary.PrevSalaryDate)}</td>
                                                    <td style={{textAlign: "right"}}>{monthSalary.PrevSalary}</td>

                                                </tr>

                                            )}
                                        </table>
                                    </div>
                                    }*/}
                                    {showEmployersDetails === true &&
                                    <>
                                        <ListCustInfo inputvalue={inputValues.nameofemployer} inputtype="input"
                                                      fieldlabel={data[lang]['nameofemployer']}/>
                                        <ListCustInfo inputvalue={inputValues.employersaddress} inputtype="input"
                                                      fieldlabel={data[lang]['employersaddress']}/>
                                        <ListCustInfo inputvalue={pcCode} inputtype="input"
                                                      fieldlabel={data[lang]['pcCode']}/>
                                        <ListCustInfo inputvalue={inputValues.location} inputtype="input"
                                                      fieldlabel={data[lang]['location']}/>
                                        <ListCustInfo inputvalue={inputValues.presentposition} inputtype="input"
                                                      fieldlabel={data[lang]['presentposition']}/>
                                    </>
                                    }
                                    {inputValues.showemployment_proof ? (
                                        <>
                                            <ListCustInfo inputvalue={employmentProofCategory} inputtype="input"
                                                          fieldlabel={data[lang]['employmentProofInstruction1']}/>
                                            <ListCustInfo inputvalue={inputValues.selectedfilenameemployment_proof}
                                                          inputtype="file-full" fieldlabel=""/>
                                        </>
                                    ) : ''}

                                    {inputValues.showselfemployment_proof ? (
                                        <>
                                            <ListCustInfo inputvalue={selfemploymentProofCategory} inputtype="input"
                                                          fieldlabel={data[lang]['selfEmploymentProofInstruction1']}/>
                                            <ListCustInfo inputvalue={inputValues.selectedfilenameselfemployment_proof}
                                                          inputtype="file-full" fieldlabel=""/>
                                        </>
                                    ) : ''}

                                    {inputValues.showW8 ? (

                                        <ListCustInfo inputvalue={inputValues.selectedfilenamew8} inputtype="file"
                                                      fieldlabel={data[lang]['w8form']}/>

                                    ) : ''}
                                    {inputValues.showW9 ? (
                                        <ListCustInfo inputvalue={inputValues.selectedfilenamew9} inputtype="file"
                                                      fieldlabel={data[lang]['w9form']}/>
                                    ) : ''}
                                    {(inputValues.other_reason && inputValues.other_reason.toUpperCase() === 'YES') ? (
                                        <ListCustInfo inputvalue={inputValues.filenamedocresidenttononresident}
                                                      inputtype="file"
                                                      fieldlabel={data[lang]['docresidenttononresident']}/>
                                    ) : ''}
                                    {isNstp === 1 ? (
                                        <ListCustInfo inputvalue={inputValues.filenameadditionaldoc} inputtype="file"
                                                      fieldlabel={data[lang]['additionaldoc']}/>
                                    ) : ''}


                                </>
                            ) : ''}
                        </div>

                    </div>
                    }
                    {!loader && submitErrorMessages !== '' &&
                        <Popup messagehead={data[lang]['oops']} message={submitErrorMessages} returnToPage={true} closePopupModal={closePopupModal}/>
                    }

                    <div className="csCheckboxWrap" id="agreeTerms" style={{'display': 'none'}}>

                            <div className="splitBox">
                                <label className="csCheckbox">
                                    <input type="checkbox" checked='checked' className="csCheckbox__input" />
                                    <span className="csCheckbox__placeholder" />
                                    <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:data[lang]['agreeTerms']}}></label>

                                </label>
                            </div>


                    </div>
                    <div className="flexCB mnButtons" data-html2canvas-ignore="true">

                            <button onClick={editPage}   className="btn btn--capsule btn--primaryOuter">{data[lang]['edit']}</button>
                            <button  onClick={nextPage}  className="btn btn--capsule btn--primaryG">{data[lang]['submit']}</button>

                    </div>
                    <div className="flexCC  mnButtons desktopBtn" data-html2canvas-ignore="true">

                        <button onClick={editPage}   className="btn btn--capsule btn--primaryOuter">{data[lang]['edit']}</button>
                        <button  onClick={nextPage}  className="btn btn--capsule btn--primaryG">{data[lang]['submit']}</button>

                    </div>


                </div>





            </div>

            {
                modalIn && (
                    <div className="csModal">
                        {loader !== true &&
                        <span className="csModal__out" onClick={() => setModalIn(!modalIn)}/>
                        }
                        <div className={`csModal__content ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            { (curstep === 1) ? (
                            <Editform1  curstep={curstep}  boxtitle={data[lang]['personalInformation']}  section={section} onChange={handleChange}   inputValues={inputValues}  closeModal={closeModal} errors={errors} openSearchList={openSearchList}/>
                                ) : '' }

                            { curstep === 2 ? (
                                <Editform2  curstep={curstep}  boxtitle={data[lang]['residenceAddress']}  section={section} onChange={handleChange}   inputValues={inputValues} closeModal={closeModal} errors={errors} openSearchList={openSearchList} />
                            ) : '' }

                            { curstep === 3 ? (
                                <Editform3  curstep={curstep}  boxtitle={data[lang]['employmentDetails']}  section={section} onChange={handleChange}  ondepFieldChange={handleFieldChange}  inputValues={inputValues} closeModal={closeModal} errors={errors} />
                            ) : '' }
                            { curstep === 4 ? (
                                <Editform4  curstep={curstep}  boxtitle={data[lang]['usIndecia']}  section={section} onChange={handleChange}   ondepFieldChange={handleFieldChange}  handleFieChange={handleFieChange} inputValues={inputValues}  closeModal={closeModal} errors={errors} />
                            ) : '' }
                            { curstep === 5 ? (
                                <Editform5  curstep={curstep}  boxtitle={data[lang]['commonReportingStandard']}  section={section} onChange={handleChange}    ondepFieldChange={handleFieldChange} inputValues={inputValues}  closeModal={closeModal} errors={errors} openSearchList={openSearchList} openSelectList={openSelectList} crsfields={crsfields}/>
                            ) : '' }
                            { curstep === 6 ? (
                                <Editform6  curstep={curstep}  boxtitle={data[lang]['exposedPersonMessage']}  section={section} onChange={handleChange}   inputValues={inputValues}  closeModal={closeModal} errors={errors} pepRelativePositions={pepRelativePositions} showPepInfo={showPepInfo}/>
                            ) : '' }

                            { curstep === 7 ? (
                                <Editform7  curstep={curstep}  boxtitle={data[lang]['residentToNon']}  section={section} onChange={handleChange}   inputValues={inputValues} closeModal={closeModal} errors={errors} />
                            ) : '' }
                            { (curstep === 8) ? (
                                <Editform8  curstep={curstep}  boxtitle={data[lang]['addnlDocuments']}  section={section} onChange={handleChange}   ondepFieldChange={handleFieldChange}  handleFieChange={handleFieChange}  inputValues={inputValues} closeModal={closeModal} errors={errors} showCameraPanel={showCameraPanel} compressedPhoto ={compressedPhoto} salarydata={salarydata} openSearchList={openSearchList}/>
                            ) : '' }

                            {errorMessages !== '' &&
                                <span className="primaryTextAnchor error-msg" ref={errorRef}>{errorMessages}</span>
                            }
                        </div>
                    </div>
                )
            }
            {
                outerModal && (
                    <div className={`csModal ${outerModalFull === true ? 'csModal--inFull' : ''} test`}>

                        <span className="csModal__out" onClick={() => setOuterModalIn(!outerModal)}/>

                        <div className={`csModal__content ${lang === "Ar" ? 'ct-arabic' : ''}`}>

                                <>
                                    {outerModalSearch === true &&
                                    <div className="countrySearchInput">
                                        <input name={outerModalItem} value={search} className="formBlock__input"
                                               autoComplete="off" onChange={handleSearch}/>
                                        <span className="iconIn iconIn--search"/>
                                    </div>
                                    }
                                    <div className="countrySearchList">
                                    {outerModalItem === 'country_birth' &&
                                    listSearchcountry.map((item, itemkey) => {
                                        return (<div key={`country-${itemkey}`} className='select-item' name={outerModalItem}
                                                     onClick={(e) => selectSearchItem(outerModalItem, item.value, item.option)}>{item.option}</div>)
                                    })
                                    }
                                    {outerModalItem === 'picode' &&

                                        listSearchPcode.map((item, itemkey) => {
                                            return (<div key={`pi-${itemkey}`} className='select-item' name={outerModalItem}
                                                         onClick={(e) => selectSearchItem(outerModalItem, item.value, item.option)}>{item.option}</div>)
                                        })
                                    }
                                    {outerModalItem === 'pcCode' &&

                                        listSearchPcode.map((item, itemkey) => {
                                            return (<div key={`pc-${itemkey}`} className='select-item' name={outerModalItem}
                                                         onClick={(e) => selectSearchItem(outerModalItem, item.value, item.option)}>{item.option}</div>)
                                        })
                                    }
                                    {outerModalItem === 'country_taxresidence1' &&
                                    listSearchcountry.map((item, itemkey) => {
                                        return (<div key={`country-crs1-${itemkey}`} className='select-item' name={outerModalItem}
                                                     onClick={(e) => selectSearchItem(outerModalItem, item.value, item.option)}>{item.option}</div>)
                                    })
                                    }
                                    {outerModalItem === 'country_taxresidence2' &&
                                    listSearchcountry.map((item, itemkey) => {
                                        return (<div key={`country-crs2-${itemkey}`} className='select-item' name={outerModalItem}
                                                     onClick={(e) => selectSearchItem(outerModalItem, item.value, item.option)}>{item.option}</div>)
                                    })
                                    }
                                    {outerModalItem === 'country_taxresidence3' &&
                                    listSearchcountry.map((item, itemkey) => {
                                        return (<div  key={`country-crs3-${itemkey}`}className='select-item' name={outerModalItem}
                                                     onClick={(e) => selectSearchItem(outerModalItem, item.value, item.option)}>{item.option}</div>)
                                    })
                                    }
                                    {(outerModalItem === 'reason1' || outerModalItem === 'reason2' || outerModalItem === 'reason3') &&
                                        <>
                                            <div className={`csCheckboxWrap ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                                                <div className="textLabel textLabel--type2">
                                                    <label className="csCheckbox">
                                                    <input name="reason" type="radio"  className="csCheckbox__input" checked={ crsReasonSelected[crsSelected] === 'A' ? 'checked' : '' }  onChange={()=>selectSearchItem(outerModalItem, data['En']['crsReasonA'], 'A')}/>
                                                    <span className="csCheckbox__placeholder"/>
                                                    <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html: data[lang]['crsReasonA']}}></label>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className={`csCheckboxWrap ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                                                <div className="textLabel textLabel--type2">
                                                    <label className="csCheckbox">
                                                        <input name="reason" type="radio"  className="csCheckbox__input" checked={ crsReasonSelected[crsSelected] === 'B' ? 'checked' : '' }  onChange={()=>selectSearchItem(outerModalItem, data['En']['crsReasonB'], 'B')}/>
                                                        <span className="csCheckbox__placeholder"/>
                                                        <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html: data[lang]['crsReasonB']}}></label>
                                                        <fieldset className={`${errors.indexOf('details'+crsSelected) > -1 ? 'error' : ''}`} >

                                                            <input type="text" name={`details${(crsSelected+1)}`} disabled={ crsReasonSelected[crsSelected] !== 'B' ? 'disabled' : '' }  value={inputValues['details' + (crsSelected+1)]} onChange={(e) => handleInput(e)}    className="formBlock__input" />
                                                        </fieldset>
                                                    </label>
                                                </div>

                                            </div>

                                            <div className={`csCheckboxWrap ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                                                <div className="textLabel textLabel--type2">
                                                    <label className="csCheckbox">
                                                        <input name="reason" type="radio"  className="csCheckbox__input" checked={ crsReasonSelected[crsSelected] === 'C' ? 'checked' : '' }  onChange={()=>selectSearchItem(outerModalItem, data['En']['crsReasonC'], 'C')}/>
                                                        <span className="csCheckbox__placeholder"/>
                                                        <label className="csCheckbox__text" dangerouslySetInnerHTML={{__html: data[lang]['crsReasonC']}}></label>
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="flexCC mT15"><span className="btn btn--primaryG btn--capsule" onClick={() => closeCRSReason(outerModalItem)}>{data[lang]['close']}</span>
                                            </div>

                                        </>
                                    }
                                    </div>
                                </>


                        </div>
                    </div>
                )
            }
            {
                pepModal && (
                    <div className={`csModal`}>

                        <span className="csModal__out" onClick={() => setpepModal(false)}/>

                        <div className={`csModal__content ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <h3 className="editable">
                                {data[lang]['exposedPersonMessage']}
                            </h3>
                            <p>{data[lang]['pepDescription']}</p>
                            <div className="splitBox">
                                <div className="textLabel textLabel--type2" dangerouslySetInnerHTML={{__html: pepListLabels}}/>
                            </div>


                        </div>
                    </div>
                )
            }
        </>
    )
}
export default CustomerDetails
