import React, { useReducer } from 'react';
import PreLoader from "../assets/images/loader_01.gif";
import {getToken} from "../utilities/AuthService";


const Loader = (props) => {
    const lang = getToken().lang;
    return (

            <div className={`csModal ${props.message === '' ? '' : 'flexCol'}`}>

                <div className="imgCntnr">
                    <img width={50} src={PreLoader} className="loader" alt="" />
                </div>
                {props.message !== '' &&
                <div className={`msgCntnr ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                    <p dangerouslySetInnerHTML={{__html:props.message}}></p>
                </div>
                }
            </div>

    )
}

export default Loader
