import {combineReducers} from 'redux';
import HandshakingReducer from './HandshakingReducer'
import CustomerReducer from './CustomerReducer'
import  AppReducer   from './AppReducer'

const app = combineReducers({
    HandshakingReducer,
    CustomerReducer,
    AppReducer


});

export default app;
