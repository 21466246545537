import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';

const IdscanComplete = () => {
    const lang = getToken().lang;
    return (
        <>
            <Header className="hasCrossOver hasCrossOver--lg">
                <div className="navHead">
                    <Link className="navHead__back">
                        <span className="iconIn iconIn--back" />
                    </Link>
                    <p className="navHead__text">{data[lang]['scanningID']}</p>
                </div>
            </Header>

            <div className={`wPanel ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                <div className="iconMessageBox">
                    <span className="iconMessageBox__icon iconIn iconIn--image" />
                    <h2 className="iconMessageBox__title">{data[lang]['done']}</h2>
                    <p className="iconMessageBox__text">{data[lang]['idHasBeenScanned']}</p>
                </div>
                <div className="buttomWrap">
                    <Link to="/resident-id-check"  className="btn btn--capsule btn--primaryG btn--inFull">{data[lang]['submitID']}</Link>
                    <Link to="/id-scan"  className="btn btn--capsule btn--primaryOuter btn--inFull">{data[lang]['rescan']}</Link>
                </div>
            </div>
        </>
    )
}

export default IdscanComplete
