import React, { useState, useEffect } from 'react';
import logo from '../assets/images/logo.png';
import {useDispatch, useSelector} from "react-redux";
import {HeartBeat, SessionOut, createAccessLog} from "../actions/Authorization";
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import { useIdleTimer } from 'react-idle-timer'
import Popup from "../pages/Popup";
import {kycStore} from "../reducers/KYCState";

const Header = (props) => {

    const lang = getToken().lang;//console.log(getToken())
    const [sidebar, setSidebar] = useState(false)
    const [closer, setCloser] = useState(false)
    const {className, children, userIn, headerTitle} = props;
    const params = (new URL(document.location)).searchParams;

    const dispatch = useDispatch();
    const [bway] = useState(getToken().bway);
    let token = getToken().token || '';
    token = params.get("ref") ? params.get("ref") : token
    const [jsessionid] = useState(getToken().jsessionid);
    const [client] = useState(getToken().client);
    const userdata = useSelector(state => state.AppReducer.userSession) ;
    const responseStatus =  kycStore.getState().KYCState.responseStatus ;
    let interval;
    let idleTimeOut = props.idleTimeOut;
    const [isIdle, setIsIdle] = useState(false)
    const cif = getToken().cif;
    const livenessstatus = getToken().livenessstatus;
    const renewType = getToken().renewType;
//console.log(token)
    // let isUserIdle = false

    useEffect(() => {
        //console.log(getToken())
        const ismobile = (client === 'MB');
        if(!ismobile && jsessionid && bway) {

            //console.log('Start check', document.getElementById('idleStatusSetter').innerText)

            if(document.getElementById('idleStatusSetter').innerText === ''){
                //console.log('Starting HeartBeat')
                document.getElementById('idleStatusSetter').innerText = 'T'
                setTimeout(checkConfig, 100)
                // startHeartBeat()
                }


        }

    }, [dispatch, idleTimeOut, jsessionid, bway, client]);



    function checkConfig(){
        if(getToken().BM_PWA_API === ''){
            setTimeout(this.checkConfig, 100)
            }
        else{
            startHeartBeat()
            }
        }

    const startHeartBeat = event => {
       if(document.getElementById('idleStatusSetter') && document.getElementById('idleStatusSetter').innerText === 'T'){
           if(client === 'IB') {
               dispatch(HeartBeat(bway, jsessionid));
               setTimeout(startHeartBeat, 240000)
                }
           }
    }



    useEffect(() => {
        if(idleTimeOut === true) {
            clearInterval(interval);
        }
    }, [idleTimeOut, interval]);

    useEffect(() => {
        if(userdata === 'active'){
            //console.log(userdata)
        } else {
            dispatch(SessionOut())
            setIsIdle(true)
            clearInterval(interval);
        }
    }, [userdata, dispatch, interval])

    const sideBarActive = () => {
        setSidebar(true);
        document.body.classList.add('overlayIn');
    }

    const sideBarClose = () => {
        setSidebar(false);
        document.body.classList.remove('overlayIn');
    }
    const closeFrame = () => {
        const ismobile = (client === 'MB');

        if(!ismobile) {
            dispatch(createAccessLog('Close button' , 'Clicked', 'Calling window.parent.postMessage("gieomRedirectHome", "*");',cif));
            //window.parent.gieomRedirectHome();
            window.parent.postMessage("gieomRedirectHome", "*");
        } else {
            dispatch(createAccessLog('Close button' , 'Clicked', 'Adding bom-pwa-close-btn',cif));
            setCloser(true)
        }
    }

    const handleOnIdle = event => {

        // console.log('idleStatusSetter - ', document.getElementById('idleStatusSetter').innerText)
        //if(document.getElementById('idleStatusSetter').innerText !== 'F' && props.disableIdleTime !==true){
        if(document.getElementById('idleStatusSetter').innerText !== 'F'){
            //console.log('User idle - Stopping HeartBeat')
            document.getElementById('idleStatusSetter').innerText = 'F'
            //dispatch(createAccessLog('SessionTime' , 'Error', 'Session timed out',getToken().cif));
            setIsIdle(true)
            dispatch(SessionOut())
            }

      }

      const handleOnActive = event => {
        // console.log('user is active', event)
        // console.log('time remaining', getRemainingTime())
        // console.log('User active - resuming HeartBeat')
        // document.getElementById('idleStatusSetter').innerText = 'F'
      }

      const handleOnAction = event => {
        // console.log('user did something', event)
        // document.getElementById('idleStatusSetter').innerText = 'F'
      }
        const timer = props.disableIdleTime === true ? 1200000 : (client === 'MB' ? 300000 : 600000)

      const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: timer,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
      })
    const getApiNameFromUrl = (url) => {

        let path = url.split('?')[0].split('/');
        if(path[path.length-1] === ''){
            return path[path.length-2]
        }
        else{
            return path[path.length-1]
        }

    }
    if(getApiNameFromUrl(window.location.href) === 'api-tests') {
        token = 'test';
    }
    if(token === '' || !token) {
        dispatch(createAccessLog('Header' , 'Error', 'No token in url or store',getToken().cif));
    }
    if(responseStatus === true) {
        dispatch(createAccessLog('Header' , 'Error', 'responseStatus',getToken().cif));
    }

    return (
        <>
            <header className={`pageHeader ${token === 'test' ? '' : className} ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                <span className="csModal__out close-frame" onClick={() => closeFrame()}></span>
                {closer === true &&
                <div id="bom-pwa-close-btn"></div>
                }
                {
                    (responseStatus === true) &&

                    <Popup messagehead={data[lang]['oops']} message={data[lang]['somethingwrong']} sessionout={false}/>
                }
                {
                    (token === '' || !token) &&

                    <Popup messagehead={data[lang]['oops']} message={data[lang]['somethingwrong']} sessionout={false}/>
                }
                {
                    children
                }
                {
                    userIn && (
                        <div className="tblock">
                            <span className="iconIn iconIn--hamburger" onClick={() => sideBarActive()}/>
                            <span className="tblock__text">{headerTitle}</span>
                        </div>
                    )
                }

                {
                    isIdle === true &&

                    <Popup messagehead={data[lang]['oops']} message={data[lang]['sessionexpired']} sessionout={true} />
                }


            </header>
            {
                userIn && (
                    <div className={`sidebar ${sidebar && 'sidebar--active'} ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                        <span className="sidebar__out" onClick={() => sideBarClose()}/>
                        <span className="sidebar__logo">
                            <img src={logo} alt="sidebar-logo" className="sidebar__logo__in" id="sidebar__logo__in"/>
                        </span>
                        <ul className={`sidebar__List ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                            <li className="sidebar__List__item sidebar__List__item--completed">
                                {data[lang]['idScan']}
                                </li>
                            {(livenessstatus === 'ON') &&
                            <li className="sidebar__List__item sidebar__List__item--completed">
                                {data[lang]['liveness']}
                            </li>
                            }
                            {renewType == "KYC" &&
                            <li className="sidebar__List__item sidebar__List__item--active">{data[lang]['eKYCForm']} - {props.activestep}
                                <ul className="sidebar__List__innerList">
                                    <li className={`sidebar__List__item ${props.activestep > 2 ? 'sidebar__List__item--completed' : ''}   ${props.activestep === 1 || props.activestep === 2 ? 'sidebar__List__item--active' : ''}`}>{data[lang]['residenceAddress']}</li>
                                    <li className={`sidebar__List__item ${props.activestep > 3 ? 'sidebar__List__item--completed' : ''}  ${props.activestep === 3 ? 'sidebar__List__item--active' : ''}`}>{data[lang]['employmentDetails']}</li>
                                    <li className={`sidebar__List__item ${props.activestep > 5 ? 'sidebar__List__item--completed' : ''}  ${props.activestep === 4 || props.activestep === 5 ? 'sidebar__List__item--active' : ''} `}>{data[lang]['fatcaCrs']}</li>
                                    <li className={`sidebar__List__item ${props.activestep > 6 ? 'sidebar__List__item--completed' : ''}  ${props.activestep === 6 ? 'sidebar__List__item--active' : ''} `}>{data[lang]['exposedPersonMessage']}</li>

                                </ul>
                            </li>
                            }
                        </ul>


                    </div>

                )
            }
        </>
    )
}

export default Header
