import {kycStore} from "./KYCState";

let initialState = {
    customerBankData: [],
    livenessResponsedata: [],
    pepData:[],
    ocrProcessdata:[],
    uploadIDResponsedata: [],
    uploadAddnlDocResponsedata: [],
    uploadFormResponsedata: [],
    uploadDocResponsedata:[],
    processFileOCRResp: [],
    processFileOCRBackResp: [],
    referenceResp: [],
    uploadResponsedata: [],
    livenessupdResp: [],
    idscanupdResp: [],
    cInfoResp:[],
    customerSalaryData: [],
    userSession:'active',
    uploadRespdata: [],
    fmatchScore: [],
    ocrScore: [],
    fmatchupdResp: [],
    clidInfoResp: [],
    otpResp: [],
    otpverifyResp: [],
    countries: [],
    maritalstatus: [],
    lidLimits: [],
    apiTimestamp: {},
    location: '',
    listPep: [],
    listRelationships: [],
    listEmploymentSectors: [],
    listselfempproof: [],
    listempproof: [],
    listaddproof: [],
    listPostalcode: [],
    listIncome: [],
    unemployedstatus: [],
    redoJourneyStatus: '',
    apiRetry: false
}

const AppReducer =  (state=initialState, action )=> {
    switch (action.type) {
        case "CUSTOMER_INFO":

            return {
                ...state,
                customerBankData: action.customerBankData

            };
            break;
        case "SALARY_INFO":
            return {
                ...state,
                customerSalaryData: action.customerSalaryData

            };
            break;
        case "OTP_GENERATED":
            return {
                ...state,
                otpResp: action.otpResp

            };
            break;
        case "OTP_VERIFIED":
            return {
                ...state,
                otpverifyResp: action.otpverifyResp

            };
            break;


        case "LIVENESS_TEST":
            return {
                ...state,
                livenessResponsedata: action.livenessResp
            };
            break;
        case "LIVENESS_STATUS_UPDATE":
            return {
                ...state,
                livenessupdResp: action.livenessupdResp
            };
            break;
        case "IDSCAN_STATUS_UPDATE":
            return {
                ...state,
                idscanupdResp: action.idscanupdResp
            };
            break;
        case "FACEMATCH_UPDATE":
            return {
                ...state,
                fmatchupdResp: action.fmatchupdResp
            };
            break;

        case "UPLOAD_PHOTOID":
            return {
                ...state,
                uploadResponsedata: action.uploadResponsedata
            };
            break;
        case "OCR_PROCESS_DATA":
            return {
                ...state,
                ocrProcessdata: action.ocrProcessdata
            };
            break;
        case "PROCESS_FILE_OCR":
            return {
                ...state,
                processFileOCRResp: action.processFileOCRResp
            };
            break;
        case "PROCESS_FILE_OCR2":
            return {
                ...state,
                processFileOCRBackResp: action.processFileOCRBackResp
            };
            break;

        case "REFERENCE_ID":
            return {
                ...state,
                referenceResp: action.referenceResp
            };
            break;

        case "UPDATE_PEP":
            return {
                ...state,
                pepData: action.pepData

            };
            break;

        case "CUSTOMER_UPDATE":
            return {
                ...state,
                cInfoResp: action.cInfoResp

            };
            break;
        case "CUSTOMER_LID_UPDATE":
            return {
                ...state,
                clidInfoResp: action.clidInfoResp

            };
            break;

        case "UPLOAD_IDCARD":
            return {
                ...state,
                uploadIDResponsedata: action.uploadIDResponsedata
            };
            break;
        case "UPLOAD_DOC":
            return {
                ...state,
                uploadDocResponsedata: action.uploadDocResponsedata
            };
            break;
        case "UPLOAD_ADDNL_DOC":
            return {
                ...state,
                uploadAddnlDocResponsedata: action.uploadAddnlDocResponsedata
            };
            break;
        case "UPLOAD_FORM_DOC":
            return {
                ...state,
                uploadFormResponsedata: action.uploadFormResponsedata
            };
            break;
        case "USER_SESSION":
            return {
                ...state,
                userSession: action.session
            };
            break;
        case "GET_UPLOAD":
            return {
                ...state,
                uploadRespdata: action.uploadRespdata,
            };
            break;
        case "GET_FACEMATCH":
            console.log(action.fmatchScore)
            kycStore.getState().KYCState.facematchdata = action.fmatchScore
            return {
                ...state,
                fmatchScore: action.fmatchScore,
            };
            break;
        case "COUNTRIES":
            return {
                ...state,
                countries: action.countries,
            };
            break;
        case "MARITALSTATUS":
            return {
                ...state,
                maritalstatus: action.maritalstatus,
            };
            break;
        case "UNEMPLOYEDSTATUS":
            return {
                ...state,
                unemployedstatus: action.unemployedstatus,
            };
            break;
        case "EMPLOYMENTSTATUS":
            return {
                ...state,
                employmentstatus: action.employmentstatus,
            };
            break;
        case "LISTINCOME":
            return {
                ...state,
                listIncome: action.listIncome,
            };
            break;
        case "LISTPOSTALCODE":
            return {
                ...state,
                listPostalcode: action.listPostalcode,
            };
            break;
        case "LIVENESS_MAX_RETRIES":
            return {
                ...state,
                livenessMaxRetries: action.maxretries,
            };
            break;
        case "ID_MAX_RETRIES":
            return {
                ...state,
                idMaxRetries: action.maxretries,
            };
        case "OTP_MAX_RETRIES":
            return {
                ...state,
                otpMaxRetries: action.maxretries,
            };
            break;
        case "OTP_SUBMIT_MAX_RETRIES":
            return {
                ...state,
                otpSubmitMaxRetries: action.maxretries,
            };
            break;
        case "STRING_MATCH_SCORE":
            kycStore.getState().KYCState.namematchdata = action.matchScore
            return {
                ...state,
                nmatchScore: action.matchScore,

            };

            break;
        case "SWICHED_TO_MOBILE":
            return {
                ...state,
                swichToMobile: action.swichToMobile,
            };
        case "SWICHED_TO_DESKTOP":
            return {
                ...state,
                swichToDesktop: action.swichToDesktop,
            };
        case "CONTINUE_ON_MOBILE":
            return {
                ...state,
                continueOnMobile: action.continueOnMobile,
            };
        case "SWICHED_BACK_DESKTOP":
            return {
                ...state,
                swichedBackDesktop: action.swichedBackDesktop,
            };
            break;
        case "QR_OTP_VERIFIED":
            return {
                ...state,
                qrOtpverifyResp: action.qrOtpverifyResp

            };
            break;
        case "FACEMATCH_LIMITS":
            return {
                ...state,
                facematchLimits: action.facematchLimits

            };
            break;
        case "OCR_SCORE":
            return {
                ...state,
                ocrScore: action.ocrScore

            };
            break;

        case "FETCH_USER_UPLOADS":
            return {
                ...state,
                fetchUploadsData: action.fetchUploadsData

            };
            break;
        case "LID_LIMITS":
            return {
                ...state,
                lidLimits: action.lidLimits

            };
            break;
        case "LOCATION_REF":
            return {
                ...state,
                location: action.search
            };
            break;
        case "LISTPEP":
            return {
                ...state,
                listPep: action.listpep,
            };
            break;
        case "LISTRELATIONSHIPS":
            return {
                ...state,
                listRelationships: action.listRelationships,
            };
        case "LISTSECTORS":
            return {
                ...state,
                listEmploymentSectors: action.listsectorsdata,
            };
        case "LIST_SELF_EMP_PROOF":
            return {
                ...state,
                listselfempproof: action.listselfempproof,
            };
        case "LIST_EMP_PROOF":
            return {
                ...state,
                listempproof: action.listempproof,
            };
        case "LIST_ADDRESS_PROOF":
            return {
                ...state,
                listaddproof: action.listaddproof,
            };
            break;
        case "MASTERS":
            return {
                ...state,
                countries: action.countries,
                maritalstatus: action.maritalstatus,
                unemployedstatus: action.unemployedstatus,
                listIncome: action.listIncome,
                listPostalcode: action.listPostalcode,
                listPep: action.listPep,
                listRelationships: action.listRelationships,
                listEmploymentSectors: action.listEmploymentSectors,
                listselfempproof: action.listselfempproof,
                listaddproof: action.listaddproof,
                listempproof: action.listempproof,
            };
            break;
        case "REDO_JOURNEY":
            return {
                ...state,
                redoJourneyStatus: action.status,
            };
            break;
        case "API_RETRY":
            return {
                ...state,
                apiRetry: action.status,
            };
            break;
        default:
            return state;
    }
}


export default AppReducer;
