import React, {useReducer} from 'react';
import SwitchInput from '../components/SwitchInput';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';


/*function ListItem(props) {
    return  <option>{props.option}</option> ;
}*/


const Editform3 = (props) => {
    const lang = getToken().lang;
    const [inputFormValues, setInputFormValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            monthly_income: props.inputValues.monthly_income,
            employed_cur_status: props.inputValues.employed_cur_status
        }
    );
    /*const updateDetails = (curstep) =>{
        //console.log("update step1: "+curstep) ;
    }*/

    const handleInput = (e) =>{
        setInputFormValues({[e.target.name]: e.target.value}) ;
        props.onChange(e.target.name,  e.target.value) ;
       // console.log(e.target.name+"  "+e.target.value) ;
    }


    const handleSwitchChange = (name, value) =>{
        //console.log(name+" : "+value) ;
        if(name === "employed") {
            let bomsalshow;
            let showempselection ;
            let showselfemployment ;
            //console.log(" employed value dependency check: "+value);
            if(value.toUpperCase() === "YES"){
                bomsalshow = true ;
                showempselection = false ;
                showselfemployment = false;
            }else{
                bomsalshow = false ;
                showempselection = true ;
                showselfemployment = true;
            }

            props.ondepFieldChange("showsalary_bom",bomsalshow);
            props.ondepFieldChange("showempselection", showempselection);
            props.ondepFieldChange("showselfemployment_proof", showselfemployment);
        }
        props.onChange(name,  value.toUpperCase()) ;
    }

    //console.log(props.section) ;
    return (
        <>
            <div className="formBlock">
                <h3>{props.boxtitle}</h3>

                { props.section && props.section.map(item => {
                    return (
                        <>


                            { item.inputtype === "select"  &&  item.showfield  ? (
                                <>
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>
                                    <select type="text"    name={item.fieldname}  onChange={handleInput}  className="formBlock__input formBlock__select">
                                        {(item.value === '' || item.selectvalues.filter((a) => a.value === item.value).length === 0 ) &&
                                        <option value="">{data[lang]['select']}</option>
                                        }
                                        { item.selectvalues &&  item.selectvalues.map(selectlabel => {
                                            return( <option  value={selectlabel.value} selected={(item.value !== "" && selectlabel.value.toUpperCase() === item.value.toUpperCase()) ? 'selected' : "" } > {selectlabel.option} </option>)

                                        })}
                                    </select>
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }
                            { item.inputtype === "switch" ? (
                                <div className="splitBox splitBox--mBMd">
                                    <div className="splitBox__w80Out">
                                            <label className="textLabel textLabel--type2">{item.label}</label>
                                    </div>
                                    <div className="splitBox__w80">
                                        <SwitchInput className=""  name={item.fieldname}   active={ item.value && item.value.toUpperCase() === "YES" ? true : false }  onChange={handleSwitchChange} value={item.value.charAt(0).toUpperCase() + item.value.slice(1)} readonly={item.readonly}/>
                                    </div>
                                </div>

                            ) : ''}

                        </>
                    )
                }
                )}

                <div className="flexCC mT15">
                    <span className="btn btn--primaryG btn--capsule"   onClick={() => props.closeModal(3)} >{data[lang]['close']}</span>
                </div>
            </div>
        </>
    )
}

export default Editform3
