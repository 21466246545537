import React, {useEffect, useState} from 'react'
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
const Switch = (props) => {
    const lang = getToken().lang;
    const[active, setActive] = useState(false)
   /*  const{activeText, passiveText, className,name, value} = props; */
    const{ className } = props;
    useEffect(() => {
        setActive(props.active)
    },[props.active]);

    return (
        <div className={`switch ${className} ${active && 'switch--active'}`} onClick={() => setActive(!active)} status={active ? 'active' : 'inactive'}>

            <span  className="switch__text switch__text--active"  >{data[lang]['yes']}</span>
            <span  className="switch__text switch__text--passive"  >{data[lang]['no']}</span>

        </div>
    )
}

export default Switch
