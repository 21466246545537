import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import {Provider} from 'react-redux';
import configureStore from './configureStore';
import "./assets/scss/main.scss"

import Onboarding from './pages/Onboarding';
import Instructions from './pages/Instructions';
import Identification from './pages/Identification';
import VerificationComplete from './pages/VerificationComplete';
import InstructionID from './pages/InstructionID';
import Details from './pages/Details' ;
import KycComponents from './pages/KycComponents';
import IdScan from './pages/IdScan';
import IdscanComplete from './pages/IdscanComplete';
import ResidentIdCheck from './pages/ResidentIdCheck';
import Thankyou from './pages/Thankyou';
import Generalinstructions from "./pages/Generalinstructions";
import Willingtononresident from "./pages/Willingtononresident";
import Contactbranch from "./pages/Contactbranch" ;
import CustomerDetails from './pages/CustomerDetails' ;
import Generateqrcode from './pages/Generateqrcode'
import Submitotp from "./pages/Submitotp";
import NotFound from "./pages/NotFound";
//import IdUpload from"./pages/IdUpload" ;
import Onboardingswitchmobile from "./pages/Onboardingswitchmobile" ;
import QRSubmitotp from "./pages/QRSubmitotp";
import ApiTests from "./pages/ApiTests";
import IdleTimer from 'react-idle-timer';
import {authService} from "./services/auth.services";
import {kycStore} from "./reducers/KYCState";
import {getToken} from "./utilities/AuthService";
import {createErrorLog} from "./actions/Authorization";
const store = configureStore();
//const SessionContext = React.createContext(false);


class App extends Component {
    constructor(props){
        super(props)

        this.state = {
            timeout:1000 * 10 * 1,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false,
            polling: {},
            online: 'online'
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.checkConfig = this.checkConfig.bind(this);
        this.polling = this.polling.bind(this);
    }

    _onAction(e) {
        //console.log('user did something', e)
        //this.setState({isTimedOut: false})
    }

    _onActive(e) {
        //console.log('user is active', e)
        //this.setState({isTimedOut: false})
    }

    _onIdle(e) {
        //console.log('user is idle', e)
        //this.idleTimer.reset();
        //this.setState({isTimedOut: true})
        //alert('user is idle')
        /*const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            this.props.history.push('/')
        } else {
            this.setState({showModal: true})
            this.idleTimer.reset();
            this.setState({isTimedOut: true})
        }*/

    }

    polling() {
        //let poll = this.ping(this.state.polling)
        //console.log(kycStore.getState().KYCState.BM_journeyid)
        if(kycStore.getState().KYCState.BM_journeyid !== null && kycStore.getState().KYCState.BM_journeyid !== '') {
            let poll = authService.isonline();
            poll.then(poll => {
                //console.log(poll.status)
                this.setState({online: poll.status === 200 ? 'online' : 'offline'})
            }).catch(poll => {
                //console.log(poll)
                this.setState({online: poll.status === 200 ? 'online' : 'offline'})
            });
            setTimeout(this.polling, 30000)
        }
    }
    checkConfig(){
        //console.log(kycStore.getState().KYCState)
        if(kycStore.getState().KYCState.BM_PWA_API === ''){
            setTimeout(this.checkConfig, 100)
        }
        else{
            if(kycStore.getState().KYCState.BM_PT === 0) {

                this.polling()
            }
        }

    }



    componentDidMount() {
        authService.init();
        setTimeout(this.checkConfig, 100)
        document.addEventListener("contextmenu", (evt) => {
            evt.preventDefault();
        }, false);
        document.addEventListener("copy", (evt) => {
            evt.clipboardData.setData("text/plain", "Copying is not allowed on this webpage");
            evt.preventDefault();
        }, false);

    }

    //static contextType = SessionContext;
    render() {


        return (
            <>

                <IdleTimer
                    ref={ref => {
                        this.idleTimer = ref
                    }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.timeout}/>

                    <Provider store={store}>
                        <Router>
                            {/*<Online polling={polling}>*/}
                            {this.state.online === 'online' ?
                            <Switch>
                                <Route path="/" exact
                                       render={(props) => <Onboarding idleTimeOut={this.state.isTimedOut}/>}/>
                                <Route path="/generalinstructions" exact
                                       render={(props) => <Generalinstructions idleTimeOut={this.state.isTimedOut}/>}/>
                                <Route path="/generate-qrcode" exact component={Generateqrcode}/>
                                <Route path="/willing-nonresident" exact component={Willingtononresident}/>
                                <Route path="/resident-id-check" exact component={ResidentIdCheck}/>
                                <Route path="/instructions" exact component={Instructions}/>
                                <Route path="/identification" exact component={Identification}/>
                                <Route path="/verification-complete" exact component={VerificationComplete}/>
                                <Route path="/scan-ID-instruction" exact component={InstructionID}/>
                                <Route path="/details" exact component={Details}/>
                                <Route path="/customer-details" exact component={CustomerDetails}/>
                                <Route path="/kyc-components" exact component={KycComponents}/>
                                <Route path="/id-scan" exact component={IdScan}/>
                                {/*<Route path="/id-upload" exact component={IdUpload} />*/}
                                <Route path="/id-scan-complete" exact component={IdscanComplete}/>
                                <Route path="/thank-you" exact component={Thankyou}/>
                                <Route path="/submit-otp" exact component={Submitotp}/>
                                <Route path="/contact-branch" exact component={Contactbranch}/>
                                <Route path="/switch-device" exact component={Onboardingswitchmobile}/>

                                <Route path="/qr-submit-otp" exact component={QRSubmitotp}/>
                                <Route path="/api-tests" exact component={ApiTests}/>
                            </Switch>
                                :
                                <NotFound />
                            }
                            {/*</Online>
                            <Offline polling={polling}>
                                <NotFound />
                            </Offline>*/}
                        </Router>
                        <div id="idleStatusSetter" style={{display: 'none'}} ></div>
                    </Provider>

                </>
        )
    }
}

export default App;
