import React, {useReducer, useState} from 'react';

import SwitchInput from '../components/SwitchInput';
import data from "../constants/data.json";
import {getToken} from "../utilities/AuthService";



/*function ListItem(props) {
    return  <option>{props.option}</option> ;
}*/


const Editform5 = (props) => {
    //console.log(props.section)
    //console.log(props.inputValues.taxresident_count)
    //alert(props.inputValues.taxresident_count);
    const lang = getToken().lang;
    const [taxresidentcount, setTaxresidentcount] = useState(props.crsfields.length);



    const [inputFormValues, setInputFormValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            country_taxresidence: props.inputValues.country_taxresidence,
            tinnumber: props.inputValues.tinnumber,
            reason: props.inputValues.reason,
            details: props.inputValues.details
        }
    );
    /*const updateDetails = (curstep) =>{
        //console.log("update step1: "+curstep) ;
    }*/
    const handleDropDown = (name, list) =>{//console.log(list)
        props.openSearchList(name, list)
    }
    const handleSelect = (name, cnt) =>{
        props.openSelectList(name, cnt)
    }
    const handleInput = (e) =>{
        let error = 0;

        let value = e.target.value;
        switch (e.target.name) {
            case 'country_taxresidence1':
                if(value !== '' && (!value.match(/^[A-Za-z0-9-_& ]+$/) )){  error++; return false; }
                break;
            case 'country_taxresidence2':
                if(value !== '' && (!value.match(/^[A-Za-z0-9-_& ]+$/) )){  error++; return false; }
                break;
            case 'country_taxresidence3':
                if(value !== '' && (!value.match(/^[A-Za-z0-9-_& ]+$/) )){  error++; return false; }
                break;
            case 'country_taxresidence4':
                if(value !== '' && (!value.match(/^[A-Za-z0-9-_& ]+$/) )){  error++; return false; }
                break;
            case 'tinnumber1':
                if(value !== '' && (!value.match(/^[A-Za-z0-9]+$/))){  error++; return false; }
                break;
            case 'tinnumber2':
                if(value !== '' && (!value.match(/^[A-Za-z0-9]+$/) )){  error++; return false; }
                break;
            case 'tinnumber3':
                if(value !== '' && (!value.match(/^[A-Za-z0-9]+$/) )){  error++; return false; }
                break;
            case 'tinnumber4':
                if(value !== '' && (!value.match(/^[A-Za-z0-9]+$/) )){  error++; return false; }
                break;
            default:
                break;

        }

        if(error === 0) {
            setInputFormValues({[e.target.name]: e.target.value});
            props.onChange(e.target.name, e.target.value);
            if(e.target.name === 'tinnumber1') {
                props.onChange('reason1', '');
                props.onChange('details1', '');
            }
            if(e.target.name === 'tinnumber2') {
                props.onChange('reason2', '');
                props.onChange('details2', '');
            }
            if(e.target.name === 'tinnumber3') {
                props.onChange('reason3', '');
                props.onChange('details3', '');
            }
            //console.log(e.target.name + "  " + e.target.value);
        }/* else{
            setInputFormValues({[e.target.name]: props.section.filter(obj1 => obj1.fieldname === 'tinnumber1')[0].value ? props.section.filter(obj1 => obj1.fieldname === 'tinnumber1')[0].value : ''});
            props.onChange(e.target.name, props.section.filter(obj1 => obj1.fieldname === 'tinnumber1')[0].value ? props.section.filter(obj1 => obj1.fieldname === 'tinnumber1')[0].value : '');
        }*/
    }


    const handleSwitchChange = (name, value) =>{
        //console.log(name+" : "+value) ;
        props.onChange(name,  value) ;
        if(name === "taxresident_not_oman"){
            let show;
            if(value === "Yes"){ show = "Yes" ;  }else{show = "No" ; }
            props.ondepFieldChange("showcrsselection",show);
            props.ondepFieldChange("showtaxresidence1",show === 'Yes' ? true : false);
            props.onChange("taxresident_count", 1);
            setTaxresidentcount(1) ;
            if(value === "No") {
                props.ondepFieldChange("showtaxresidence2",show === 'Yes' ? true : false);
                props.ondepFieldChange("showtaxresidence3",show === 'Yes' ? true : false);

                props.onChange("taxresident_count", 1);
                setTaxresidentcount(1) ;
                props.onChange('country_taxresidence1', '');
                props.onChange('country_taxresidence2', '');
                props.onChange('country_taxresidence3', '');
                props.onChange('tinnumber1', '');
                props.onChange('tinnumber2', '');
                props.onChange('tinnumber3', '');
                props.onChange('reason1', '');
                props.onChange('reason2', '');
                props.onChange('reason3', '');
                props.onChange('details1', '');
                props.onChange('details2', '');
                props.onChange('details3', '');

            }
        }


    }

    const handleTaxResidentCount = () => {
        let cnt = taxresidentcount+1;
        var taxcountshow =  "showtaxresidence"+cnt ;
        setTaxresidentcount(cnt) ;
        props.onChange("taxresident_count", cnt);
        //console.log(taxcountshow) ;
        props.ondepFieldChange(taxcountshow,true);
    }
    let crs = [];

    //console.log(props.crsfields)
    return (
        <>
            <div className="formBlock">
                <h3>{props.boxtitle}</h3>
                <>
                    <div className="splitBox splitBox--mBMd">
                        <div className="splitBox__w80Out">
                            <label className="textLabel textLabel--type2">{props.section[0].label}</label>
                        </div>
                        <div className="splitBox__w80">
                            <SwitchInput className=""  name={props.section[0].fieldname}   active={ props.section[0].value === "Yes" ? true : false }  onChange={handleSwitchChange} value={props.section[0].value} />
                        </div>
                    </div>
                    {props.crsfields && props.crsfields.map((crsitem, crsindex) => {
                        return(
                        <div className="crsBlock" key={`crs-${crsindex}`}><span className="delete-icon"></span>
                            <>
                            <fieldset  className={`${props.errors.indexOf(crsitem.country.fieldname) > -1 ? 'error' : ''}`}>
                                <legend>{crsitem.country.label}</legend>

                                <div name={crsitem.country.fieldname} list={crsitem.country.fieldname} className="formBlock__input formBlock__select"  onClick={(e) => handleDropDown(crsitem.country.fieldname, crsitem.country.selectvalues)}>{crsitem.country.option}</div>
                            </fieldset>
                            {props.errors.indexOf(crsitem.country.fieldname) > -1 &&
                            <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                            }
                            </>
                            <>
                            <fieldset className={`${props.errors.indexOf(crsitem.tin.fieldname) > -1 ? 'error' : ''}`} key={`country${crsindex}`}>
                                <legend>{crsitem.tin.label} </legend>
                                <input type="text" name={crsitem.tin.fieldname}   value={crsitem.tin.value}    onChange={handleInput} className="formBlock__input"  key={`tin${crsindex}`}/>
                            </fieldset>
                            {props.errors.indexOf(crsitem.tin.fieldname) > -1 &&
                            <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                            }
                            </>
                            <>
                            <fieldset  className={`${props.errors.indexOf(crsitem.reason.fieldname) > -1 ? 'error' : ''}`}>
                                <legend>{crsitem.reason.label}</legend>

                                <div name={crsitem.reason.fieldname} style={{height: "auto", minHeight: '35px'}} className="formBlock__input formBlock__select" onClick={(e) => handleSelect(crsitem.reason.fieldname, crsitem.reason.cnt)} dangerouslySetInnerHTML={{__html: crsitem.reason.value}}/>
                            </fieldset>
                            {props.errors.indexOf(crsitem.reason.fieldname) > -1 &&
                            <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                            }
                            </>
                        </div>
                        )
                    })
                    }
                    { ((props.section[0].value === "Yes")  && props.crsfields.length <= 2) ? (
                        <div><span className="btn btn-link" onClick={handleTaxResidentCount}>{data[lang]['addnew']}</span></div>
                    ) : ''}
                </>

                {/*{ props.section && props.section.map((item,index) => {
                    return (
                        <>

                            { item.inputtype === "text"  &&  item.showfield === true  ? (
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`} key={index}>
                                    <legend>{item.label} </legend>
                                    <input type="text" name={item.fieldname}   value={item.value}    onChange={handleInput} className="formBlock__input"  key={index}/>
                                </fieldset>
                            ) : '' }
                            { item.inputtype === "select"  &&  item.showfield === true  ? (
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>
                                    <select type="text"    name={item.fieldname}  onChange={handleInput}  className="formBlock__input formBlock__select">
                                        <option value="" >{data[lang]['select']}</option>
                                        { item.selectvalues &&  item.selectvalues.map(selectlabel => {
                                            return( <option  value={selectlabel.value ? selectlabel.value : selectlabel.option} selected={selectlabel.value === item.value ? 'selected' : "" } > {selectlabel.option}</option>)

                                        })}
                                    </select>
                                </fieldset>
                            ) : '' }
                            { (item.inputtype === "searchlist" &&  item.showfield === true) ? (
                                <fieldset  className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>

                                    <div name={item.fieldname} list={item.fieldname} className="formBlock__input formBlock__select"  onClick={(e) => handleDropDown(item.fieldname, item.selectvalues)}>{item.option}</div>
                                </fieldset>
                            ) : '' }
                            { (item.inputtype === "selectlist" &&  item.showfield === true) ? (
                                <fieldset  className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>

                                    <div name={item.fieldname} style={{height: "auto", minHeight: '35px'}} className="formBlock__input formBlock__select" onClick={(e) => handleSelect(item.fieldname, item.cnt)} dangerouslySetInnerHTML={{__html: item.value}}/>
                                </fieldset>
                            ) : '' }

                            { item.inputtype === "switch" ? (
                                <div className="splitBox splitBox--mBMd">
                                    <div className="splitBox__w80Out">
                                            <label className="textLabel textLabel--type2">{item.label}</label>
                                    </div>
                                    <div className="splitBox__w80">
                                        <SwitchInput className=""  name={item.fieldname}   active={ item.value === "Yes" ? true : false }  onChange={handleSwitchChange} value={item.value} />
                                    </div>
                                </div>

                            ) : ''}

                            { (item.inputtype === "Add"   &&  (item.showfield === true || item.showfield === 'Yes')  && taxresidentcount <= 2) ? (
                                <div><span className="btn btn-link" onClick={handleTaxResidentCount}>{data[lang]['addnew']}</span></div>
                            ) : ''}


                        </>
                    )
                })}*/}


                <div className="flexCC mT15">
                    <span className="btn btn--primaryG btn--capsule"   onClick={() => props.closeModal(5)} >{data[lang]['close']}</span>
                </div>

            </div>
        </>
    )
}

export default Editform5
