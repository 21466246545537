import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../components/Header';
import * as authorizationAction from '../actions/Authorization'
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getToken} from "../utilities/AuthService";
import * as device from 'react-device-detect';
import data from '../constants/data.json';
import Logo from '../assets/images/BM_logo.png'
import Popup from "./Popup";
import {setReference} from "../utilities/AuthService";
import {PWA_JOURNEY_STEPS} from '../constants/index';
import {createAccessLog, HeartBeat} from "../actions/Authorization";
import {kycStore} from "../reducers/KYCState";


class Generalinstructions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: getToken().token,
            idtype: getToken().idtype,
            custname: getToken().custname,
            cif: getToken().cif,
            mobile: getToken().mobile,
            lang: getToken().lang,
            addtnlInstruction: true,
            showproceed: false,
            journeyId: getToken().journeyid,
            renewType: getToken().renewType,
            sector_code: getToken().sector_code,
            errorMessage: '',
            livenessMaxretries: 0,
            idMaxRetries: 0,
            showVersion: false,
            bankdata: false,
            open: 0,
            terms: false,
            addressproofstatus: getToken().addressproofstatus,

        }
    }

    componentDidMount() {

        if(this.props.handshakeData.customerData !== undefined) {
            //this.props.createAccessLog('GeneralInstructions' , 'Started', 'General Instructions Launched.', this.state.cif)
            this.props.createAccessLog('GeneralInstructions' , 'Device Info', 'osName - ' + getToken().osName + ' osVersion - ' + getToken().osVersion, this.state.cif)
            //console.log(this.props.handshakeData.customerData);
            this.setState({
                bankdata: true,
                "livenessMaxretries": this.props.handshakeData.customerData.livenessmaxretries,
                "idMaxRetries": this.props.handshakeData.customerData.scanidmaxretries,
                "journeyId": this.props.handshakeData.customerData.journeyid,
                "showproceed": !this.state.showproceed
            })
        }
    }
    setOpen = (id) => {
        let open = this.state.open;
        if(open === id) {
            this.setState({open: 0})
        } else {
            this.setState({open: id})
        }
    }

    toggleaddInstruction = () => {
        this.setState({"addtnlInstruction": !this.state.addtnlInstruction});
    }
    viewTerms = () => {
        this.setState({terms: true})

    }
    hideTerms = () => {
        this.setState({terms: false})
    }

    verifyCutomerType = () => {
        //console.log(kycStore.getState().KYCState.doctype)
        let idtype = getToken().idtype;
        let livenessstatus = getToken().livenessstatus;
        let doctype = '';
        if( idtype === "National" ){
            doctype = 'OmanNationalIdFront';
        } else if(idtype === "Resident"){
            doctype = 'OmanResidentIdFront';
        } else {
            doctype = 'PassportFront'
        }
        kycStore.getState().KYCState.doctype = doctype;
        //console.log(kycStore.getState().KYCState.doctype)
        //this.setState({terms: true});return;
        const ismobile = device.isMobile;
        //console.log(this.state.idtype)
        //console.log(this.state.sector_code)
        let step = getToken().step;
        let stepStatus = getToken().stepstatus;
        var nextstep = '';
        if (ismobile) {
            if (this.state.idtype === "National") {
                nextstep = PWA_JOURNEY_STEPS[1];
            } else if (this.state.idtype === "Resident") {
                if(this.state.sector_code === '1002') {
                    nextstep = PWA_JOURNEY_STEPS[1];
                } else {
                    nextstep = 'resident-id-check';
                }
            } else {
                nextstep = PWA_JOURNEY_STEPS[1];
            }
        } else {
            if(livenessstatus === 'ON') {
                if (step === null || step === 0 || step === '0' || step === '1' || (step === '2' && stepStatus !== 'Completed')) {
                    nextstep = '/generate-qrcode';
                }
            }
            if(livenessstatus === 'OFF') {
                if (step === null || step === 0 || step === '0' || (step === '1' && stepStatus !== 'Completed')) {
                    nextstep = '/generate-qrcode';
                }
            }
        }
        //console.log(nextstep);return
        //nextstep = '/scan-ID-instruction';
        //this.props.history.push(PWA_JOURNEY_STEPS[4]);return;
        //this.props.history.push('/resident-id-check');return;

        let otp_locked = getToken().otp_locked;
        let ocr_attempts = JSON.parse(getToken().ocr_attempts);
        let more_info = getToken().more_info;
        //console.log(ocr_attempts);return;
        if(ocr_attempts.livenesstest_attempts !== undefined) {
            if (step === '2' && stepStatus === 'Rejected' && parseInt(ocr_attempts.livenesstest_attempts) < parseInt(this.state.livenessMaxretries)) {
                stepStatus = 'Inprogress';
            }
        }
        if(ocr_attempts.idscan_attempts !== undefined) {
            if (step === '1' && stepStatus === 'Rejected' && parseInt(ocr_attempts.idscan_attempts) < parseInt(this.state.idMaxRetries)) {
                stepStatus = 'Inprogress';
            }
        }
        //alert(step+" "+stepStatus) ;return
        //this.props.createAccessLog('Find current level of customer' , 'Finished', 'Step- '+step+ ', Status- ' + stepStatus + ', OTP Locked- ' + otp_locked, this.state.cif)
        if(otp_locked === "true" || otp_locked === true) {
            //window.location.href = '/contact-branch?ref=15';
            setReference(15)
            this.props.history.push('/contact-branch');
        } else {
            if(this.state.renewType === 'LID' && step === '4' && stepStatus === 'Inprogress') {
                step = '3';
            }
            if(this.state.renewType === 'LID' && step === '3' && stepStatus === 'Completed') {
                stepStatus = 'Inprogress';
            }

            let stepAfterIDScan = PWA_JOURNEY_STEPS[2];
            if(livenessstatus === 'OFF') {
                stepAfterIDScan = PWA_JOURNEY_STEPS[3];
            }

            switch (step) {
                case '1':
                    if (stepStatus === 'Inprogress') {
                        if (ismobile) {
                            //this.props.history.push('/instructions');
                            this.props.history.push(nextstep);
                        }else {
                            this.props.history.push('/generate-qrcode');
                        }
                    } else if (stepStatus === 'Completed') {
                        if (ismobile) {
                            this.props.history.push('/' + stepAfterIDScan);
                        }else {
                            if(livenessstatus === 'ON') {
                                this.props.history.push('/generate-qrcode');
                            } else {
                                this.props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                            }
                        }
                    } else if (stepStatus === 'Rejected') {
                        //this.props.history.push('/contact-branch?ref=1');
                        setReference(2)
                        this.props.history.push('/contact-branch');
                    }
                    break;
                case '2':
                    if (stepStatus === 'Inprogress') {
                        if (ismobile) {
                            this.props.history.push('/' + stepAfterIDScan);
                        }else {
                            if(livenessstatus === 'ON') {
                                this.props.history.push('/generate-qrcode');
                            } else {
                                this.props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                            }
                        }
                    } else if (stepStatus === 'Completed') {
                        this.props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                    } else if (stepStatus === 'Rejected') {
                        setReference(1)
                        this.props.history.push('/contact-branch');

                    }
                    break;
                case '3':
                    if (stepStatus === 'Inprogress') {
                        this.props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                    } else if (stepStatus === 'Completed') {
                        this.props.history.push('/' + PWA_JOURNEY_STEPS[4]);
                    } else if (stepStatus === 'Rejected') {
                        //window.location.href = '/contact-branch?ref=5' ;
                        if(more_info === 'namematch') {
                            setReference(5)
                        } else if(more_info === 'facematch') {
                            setReference(4)
                        }

                        this.props.history.push('/contact-branch');
                    }
                    break;
                case '4':
                    if (stepStatus === 'Inprogress') {
                        this.props.history.push('/' + PWA_JOURNEY_STEPS[4]);
                    } else if (stepStatus === 'Completed') {
                        this.props.history.push('/thank-you');
                    } else if (stepStatus === 'Rejected') {
                        //window.location.href = '/contact-branch?ref=6' ;
                        setReference(6)
                        this.props.history.push('/contact-branch');
                    }
                    break;
                default:
                    this.props.history.push(nextstep)
            }
        }

    }
    showVersion = () => {//console.log(getToken())
        this.setState({showVersion: true})
        //this.props.HeartBeat(getToken().bway, getToken().jsessionid);
    }
    //static contextType = SessionContext;
    render() {
//console.log(getToken().livenessmaxretries)
        let idname = this.state.idtype === "National" ? "National ID"
            : this.state.idtype === "Resident" ? "Civil ID"
                : "Passport";

        return (
            <>
                {this.state.errorMessage !== '' &&
                    <Popup messagehead={data[this.state.lang]['oops']} message={this.state.errorMessage}/>
                }
                <Header className="hasCrossOver hasCrossOver--lg"  idleTimeOut={this.props.idleTimeOut}>
                    {this.state.showVersion === true &&
                    <span className="version">{getToken().version}</span>
                    }
                    <Link className="logoLink" onClick={this.showVersion}>
                        <img src={Logo} className="logoLink__in" alt="bank-of-muscat"/>
                    </Link>
                    <div className="titleBlock">
                        {/*<h1>{data[this.state.lang]['welcomeHeadline']}</h1>*/}
                        {this.state.renewType === 'KYC' &&
                        <p className="welcomeSubHeadline">{data[this.state.lang]['welcomeSubHeadline1']}</p>
                        }
                        {this.state.renewType === 'LID' &&
                            <>
                                {this.state.idtype === "National" ?
                                <p className="welcomeSubHeadline">{data[this.state.lang]['welcomeSubHeadline1National']}</p>
                                :this.state.idtype === "Resident" ?
                                    <p className="welcomeSubHeadline">{data[this.state.lang]['welcomeSubHeadline1Resident']}</p>
                                    :
                                    <p className="welcomeSubHeadline">{data[this.state.lang]['welcomeSubHeadline1Passport']}</p>

                                }
                            </>

                        }
                    </div>
                </Header>

                <div className={`wPanel ${this.state.lang === "Ar" ? 'ct-arabic' : ''}`}>


                        <div className="grid2">
                            <div className="halfBlock leftBlock">
                                <div className="userDetailBox">

                                    <div className="iconText">
                                        <span className="iconText__icon iconIn iconIn--user"/>
                                        <span className="iconText__Text">{this.state.custname}</span>
                                    </div>

                                    <div className="iconText">
                                        <span className="iconText__icon iconIn iconIn--mob"/>
                                        <span className="iconText__Text">{this.state.mobile}</span>
                                    </div>
                                </div>
                                <h2>{data[this.state.lang]['instructionsHeadline']}</h2>
                                {this.state.renewType === 'KYC' &&
                                <p className="arrowText">{data[this.state.lang]['instructionSubsHeadline1'].replace('{idname}', data[this.state.lang][idname])}</p>
                                }
                                {this.state.renewType === 'LID' &&
                                <p className="arrowText">{data[this.state.lang]['instructionSubsHeadline1LID'].replace('{idname}', data[this.state.lang][idname])}</p>
                                }
                                <p className="arrowText">{data[this.state.lang]['instructionSubsHeadlineMore']}</p>
                                {/*<label className="csFile csFile--inFull">

                                <span className="csFile__placeholder">
                                <span className="iconIn iconIn--idRed"/>
                                <span className="csFile__placeholder__text">
                                    {data[this.state.lang][idname]} </span>
                                </span>
                                    </label>*/}
                            </div>

                            <div className="halfBlock">
                                {this.state.renewType === 'KYC' &&
                                    <>
                                    {/*<p className="arrowText">{data[this.state.lang]['instructionSubsHeadline2']}</p>
                                    <p className="arrowText">{data[this.state.lang]['instructionSubsHeadline3']}</p>*/}
                                    <div className={`more-instrction ${this.state.addtnlInstruction === true ? 'show' : 'hide'}`}>
                                        {this.state.addressproofstatus === 'ON' &&
                                        <div className="instruction-item">
                                            <p className={`instruction-head ${this.state.open === 1 ? 'open' : ''}`}
                                               onClick={(e) => this.setOpen(1)}>{data[this.state.lang]['moreInstruction1']}</p>
                                            <ul className={`more-instructions-sub ${this.state.open === 1 ? 'show' : 'hide'}`}>
                                                <li>{data[this.state.lang]['moreInstruction1a']}</li>
                                                <li>{data[this.state.lang]['moreInstruction1b']}</li>
                                                <li>{data[this.state.lang]['moreInstruction1c']}</li>
                                                <li>{data[this.state.lang]['moreInstruction1d']}</li>
                                                <li>{data[this.state.lang]['moreInstruction1e']}</li>
                                            </ul>
                                        </div>
                                        }
                                        <div className="instruction-item">
                                            <p className={`instruction-head ${this.state.open === 2 ? 'open' : ''}`} onClick={(e) => this.setOpen(2)}>{data[this.state.lang]['moreInstruction2']}</p>
                                            <ul className={`more-instructions-sub ${this.state.open === 2 ? 'show' : 'hide'}`}>
                                                <li>{data[this.state.lang]['moreInstruction2a']}</li>
                                                <li>{data[this.state.lang]['moreInstruction2b']}</li>
                                                <li>{data[this.state.lang]['moreInstruction2c']}</li>
                                                <li>{data[this.state.lang]['moreInstruction2d']}</li>
                                            </ul>
                                        </div>
                                       {/* <p>{data[this.state.lang]['moreInstruction3']}</p>*/}
                                        <div className="instruction-item">
                                            <p className={`instruction-head ${this.state.open === 3 ? 'open' : ''}`} onClick={(e) => this.setOpen(3)}>{data[this.state.lang]['moreInstruction4']}</p>
                                            <ul className={`more-instructions-sub ${this.state.open === 3 ? 'show' : 'hide'}`}>
                                                <li>{data[this.state.lang]['moreInstruction4a']}</li>
                                                <li>{data[this.state.lang]['moreInstruction4b']}</li>
                                                <li dangerouslySetInnerHTML={{__html: data[this.state.lang]['moreInstruction4c']}}></li>
                                            </ul>
                                        </div>
                                        {/*{getToken().cif === '1023352' &&
                                        <>
                                            <p>For Testing...</p>
                                            <p>
                                                <a className="primaryLink " target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">W9</a>
                                            </p>
                                            <p>
                                                <a className="primaryLink " target="_blank" href="https://www.bankmuscat.com/en/about/Documents/bmforms/individuals_TC.pdf">TNC</a>
                                            </p>
                                            <br/>
                                            <p>
                                                <a className="primaryLink " target="_blank" href="https://www.irs.gov/pub/irs-pdf/fw9.pdf">W9</a>
                                            </p>

                                        </>
                                        }*/}
                                    </div>

                                    {/*<div className="textCenter mT20">
                                        <Link onClick={() => this.toggleaddInstruction()}
                                        className={`primaryLink ${this.state.addtnlInstruction === false ? 'show' : 'hide'}`}>{this.state.addtnlInstruction === false ? data[this.state.lang]['additionalInstructions'] : data[this.state.lang]['closeAdditionalInstructions']}</Link>
                                    </div>*/}
                                    </>
                                }


                            </div>
                        </div>
                        {(this.state.showproceed && this.state.bankdata) ? (
                            <div className="flexCC"><Link onClick={() => this.verifyCutomerType()}
                                                          className="btn btn--capsule btn--primaryG ">{data[this.state.lang]['nextButton']}</Link>
                            </div>
                        ) : ''}
                </div>

                {this.state.terms &&
                <div className="csModal tncModal">
                    <span className="csModal__out" onClick={this.hideTerms}/>
                    <div className={`csModal__content pdfContainer`}>
                        <iframe src={getToken().BM_BASE_URL + 'individuals_TC.pdf'} height={"100%"} width={"100%"}/>
                    </div>
                </div>
                }
            </>
        );
    }
}


const mapStateToProps = state => ({
    handshakeData: state.HandshakingReducer.handshakeData,
});


export default withRouter(connect(mapStateToProps, {
    createAccessLog: authorizationAction.createAccessLog,
    HeartBeat: authorizationAction.HeartBeat
})(Generalinstructions));
