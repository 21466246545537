import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import * as handshakeAction from '../actions/Authorization'
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import { osVersion, osName } from 'react-device-detect';
import {getToken, parseJwt} from "../utilities/AuthService";
import data from '../constants/data.json';

import Logo from '../assets/images/BM_logo.png'
import CustomerReducer from "../reducers/CustomerReducer";
import {updateCustomerOcrDetails, getConfig} from "../actions/Authorization";
import Popup from "./Popup";


class Onboardingswitchmobile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            webtoken:'',
            userid:'',
            lang : getToken().lang,
            setjid: false,
            custStatMessage: '',
            proceed: !(osName.toUpperCase() === 'IOS' && parseFloat(osVersion) < 15.2)
        }
    this.checkConfig = this.checkConfig.bind(this)
    this.bomHandshake = this.bomHandshake.bind(this)
    }
    checkConfig(){
        if(getToken().BM_PWA_API === ''){
            setTimeout(this.checkConfig, 100)
            }
        else{

            //this.props.GenerateuserToken() ;
            let params = new URLSearchParams(this.props.location.search);
            let webtoken = params.get("ref") ? params.get("ref") : '';
            let userid = params.get("userid") ? params.get("userid") : '';
            let user = parseJwt(webtoken);

            let lang = user['lang'] || 'en';
            lang = lang.toUpperCase() === 'EN' ? 'En' : 'Ar';
            if(this.state.proceed) {
                this.props.initiateJourney(webtoken)
            } else {
                this.setState({ loading: false });
                this.setState({"custStatMessage": data[lang]['needVersionUpdate'].replace('{version}', osVersion)});
            }

             this.setState({"webtoken": webtoken, "userid": userid  })

            }
        }
    bomHandshake() {
        let params = new URLSearchParams(this.props.location.search);
        let webtoken = params.get("ref") ? params.get("ref") : '';
        if(getToken().apitoken !== '' && getToken().apitoken !== null) {
            this.props.handshake(webtoken);
        } else {
            setTimeout(this.bomHandshake, 100)
        }
    }
    componentDidMount() {
        this.props.getConfig()
        setTimeout(this.checkConfig, 100)

        {/*
        this.props.GenerateuserToken() ;
        let params = new URLSearchParams(this.props.location.search);
        console.log(params);
        let webtoken = params.get("ref") ? params.get("ref") : '';
        console.log(webtoken);
        let userid = params.get("userid") ? params.get("userid") : '';
        console.log(userid);
        
        this.props.handshake(webtoken) ;
        this.setState({"webtoken": webtoken, "userid": userid  })
        */}
        
    }


    componentDidUpdate(prevProps, prevState, snapshot) {

    //console.log(getToken())
        if(this.props.handshakeData.customerData !== undefined) {
            //console.log(this.props.handshakeData.customerData) ;
            if (this.props.handshakeData.customerData !== prevProps.handshakeData.customerData) {
                //console.log("customername: " +this.props.handshakeData.customerData.custinfo.custname ) ;
                /* window.location = "/generalinstructions"; */
                let cStat = this.props.handshakeData.customerData.customer_status ? this.props.handshakeData.customerData.customer_status : ''
                cStat = cStat === null ? '' : cStat;
                let stopJourney = (cStat === 'Pending' || cStat === 'Forward') ? true : false;// || cStat === 'On Hold'
                if(stopJourney) {
                    this.props.createAccessLog('OnBoarding' , 'Error', 'Application is under processing. Status: ' + cStat, this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"custStatMessage": data[getToken().lang]['applicationunderprocessing']});
                    return
                }
                if(getToken().renewType !== 'LID') {
                    // Code to stop journey for PEP or FATCA is on
                    let customerdata = this.props.handshakeData.customerData;
                    let pep = customerdata.current_former_pep || '';
                    pep = pep.toUpperCase() === 'YES' ? 1 : 0;

                    let usfatca = parseInt(customerdata.usfatca) || 0;
                    let ustax_resident = customerdata.ustax_resident || 'No';
                    ustax_resident = ustax_resident === "" ? 'No' : ustax_resident;
                    let usaddress_tel = customerdata.usaddress_tel || 'No';
                    usaddress_tel = usaddress_tel === "" ? 'No' : usaddress_tel;
                    let us_nationality = customerdata.us_nationality || 'No';
                    us_nationality = us_nationality === "" ? 'No' : us_nationality;
                    let us_payments = customerdata.us_payments || 'No';
                    us_payments = us_payments === "" ? 'No' : us_payments;


                    let fatca = (ustax_resident.toUpperCase() === 'YES' ||
                        usaddress_tel.toUpperCase() === 'YES' ||
                        us_nationality.toUpperCase() === 'YES' ||
                        us_payments.toUpperCase() === 'YES') ? 1 : 0;
                    let stopFatcaPepUser = (pep === 1 || fatca === 1) ? true : false;
                    if (stopFatcaPepUser) {
                        this.props.createAccessLog('OnBoarding', 'Error', 'Pep : ' + pep + ' Fatca : ' + fatca, this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                        this.setState({"custStatMessage": data[getToken().lang]['stopFatcaPepUser']});
                        return
                    }
                    // End of Code to stop journey for PEP or FATCA is on
                }

                if(this.props.handshakeData.jstatus === 'Failure') {
                    this.setState({ loading: false })
                    this.props.createAccessLog('OnBoarding' , 'Failure', 'Customer handshake verification.', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"custStatMessage": data[getToken().lang]['unabletocommunicate']});
                } else if(this.props.handshakeData.jstatus === 'Token Expired') {
                    this.setState({ loading: false })
                    this.props.createAccessLog('OnBoarding' , 'Error', 'Token Expired', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"custStatMessage": data[getToken().lang]['sessionexpired']});
                }  else if(this.props.handshakeData.jstatus === 'Insufficient Data') {
                    this.setState({ loading: false })
                    this.props.createAccessLog('OnBoarding' , 'Error', 'Insufficient Data', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"custStatMessage": data[getToken().lang]['unabletofetch']});
                }else if(getToken().journeyid === null || getToken().journeyid === '') {
                    this.setState({ loading: false })
                    this.props.createAccessLog('OnBoarding' , 'Error', 'Journey Id null', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"errorMessage": data[getToken().lang]['unabletocommunicate']});
                } else if(this.props.handshakeData.jstatus === 'success') {
                    //this.props.createAccessLog('OnBoarding' , 'Success', 'Got init journey api success', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({setjid: true});
                    this.props.getAllMasters();
                    this.props.setjourneyDevice(this.state.userid);
                }

            }
        }

        if(this.props.customerTypeData.custjourneyDevice !== undefined) {
                //console.log("journeydata: " +this.props.customerTypeData.custjourneyDevice) ;
                if (this.props.customerTypeData.custjourneyDevice !== prevProps.customerTypeData.custjourneyDevice) {
                    //console.log('test')
                    this.props.updateSwitchedToMobile();
                    this.props.history.push('qr-submit-otp');
                }
            }
    }


    moveNext = () =>{
        const idtype = getToken().idtype ;
        var nextstep = '';
        if(idtype === "National"){
            nextstep = '/instructions';
        }else if(idtype === "Resident"){
            nextstep = '/resident-id-check';
        }else{
            nextstep = '/instructions';
        }
        this.props.history.push(nextstep) ;
    }



    render() {
    return(
            <>
                {this.state.custStatMessage !== '' &&
                <Popup messagehead={data[getToken().lang]['oops']} message={this.state.custStatMessage}/>

                }
            <Header className="hasCrossOver hasCrossOver--lg">
                <Link className="logoLink">
                <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
                </Link>
                {/*<div className="titleBlock">
                <h1>{data[this.state.lang]['welcomeHeadline']}</h1>
                <p>{data[this.state.lang]['welcomeSubHeadline1']}</p>
                </div>*/}
            </Header>

            {/*<div className={`wPanel ${this.state.lang === "Ar" ? 'ct-arabic' : ''}`}>
                <p>{data[this.state.lang]['loading']}</p>
            </div>*/}
            </>
    );
    }
}


const mapStateToProps = state => ({
    handshakeData: state.HandshakingReducer.handshakeData,
    customerTypeData: state.CustomerReducer.customerTypeData
});


export default withRouter(connect(mapStateToProps, {
    createAccessLog : handshakeAction.createAccessLog,
    setjourneyDevice : handshakeAction.setjourneyDevice,
    getConfig: handshakeAction.getConfig,
    updateSwitchedToMobile : handshakeAction.updateSwitchedToMobile,
    initiateJourney: handshakeAction.initiateJourney,
    getAllMasters: handshakeAction.getAllMasters
})(Onboardingswitchmobile));
