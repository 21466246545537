import React from 'react';

class IcoStopHand extends React.Component{
	render() {
		return (
			<svg width="71px" height="71px" viewBox="0 0 71 71" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
					<g id="01-Step-1d-High-Risk-Nationality" transform="translate(-146.000000, -220.000000)">
						<g id="cursor-hand-2" transform="translate(168.000000, 239.000000)" stroke="#DD263D" strokeWidth="1.5">
							<path d="M18.9267865,6.21428568 C18.9267865,5.07026727 19.8386059,4.14285712 20.9633922,4.14285712 L20.9633922,4.14285712 C22.0881784,4.14285712 23,5.07026727 23,6.21428568 L23,21.9239999 C23.0014595,23.8010727 22.2687215,25.6016372 20.9633922,26.9285713 L20.9633922,26.9285713 C19.6597628,28.2552036 17.8911321,29.0003865 16.0470261,29 L12.0851493,29 C9.36123669,29.0005681 6.81728541,27.616415 5.30596793,25.311476 L0.350227519,17.7507618 C-0.116742506,17.0367716 -0.116742506,16.1074663 0.350227519,15.3934761 L0.350227519,15.3934761 C0.675318499,14.8976208 1.19017425,14.563385 1.76925437,14.4722674 C2.3483345,14.3811499 2.93842184,14.5415243 3.39563183,14.9142856 L6.70715262,17.6085237 L6.70715262,4.14285712 C6.70715262,2.99883871 7.61897203,2.07142856 8.74375827,2.07142856 L8.74375827,2.07142856 C9.86854451,2.07142856 10.7803639,2.99883871 10.7803639,4.14285712 L10.7803639,2.07142856 C10.7803639,0.927410155 11.6921833,0 12.8169696,0 L12.8169696,0 C13.9417558,0 14.8535752,0.927410155 14.8535752,2.07142856 L14.8535752,4.14285712 C14.8535752,2.99883871 15.7653946,2.07142856 16.8901809,2.07142856 L16.8901809,2.07142856 C18.0149671,2.07142856 18.9267865,2.99883871 18.9267865,4.14285712 L18.9267865,6.21428568 Z" id="Path"></path>
							<line x1="10.5" y1="4" x2="10.5" y2="12" id="Path"></line>
							<line x1="15.5" y1="4" x2="15.5" y2="12" id="Path"></line>
							<line x1="19.5" y1="12" x2="19.5" y2="6" id="Path"></line>
						</g>
						<circle id="Oval" stroke="#ED3E42" strokeWidth="2" cx="181.5" cy="255.5" r="34.5"></circle>
					</g>
				</g>
			</svg>
		)
	}
}

class IcoPepInfo extends React.Component{
	render() {
		return (
			<svg width="25px" height="25px" viewBox="0 0 25 25" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
					<g id="04-Step-4a-FATCA" transform="translate(-41.000000, -720.000000)" stroke="#C92438" strokeWidth="1.5">
						<g id="book-close" transform="translate(42.000000, 721.000000)">
							<path d="M12.2500008,22.156 C14.7627136,20.0286124 17.8394354,18.6771616 21.1060008,18.266 C21.9022585,18.16109 22.4973567,17.4821389 22.4970076,16.679 L22.4970076,5.46 C22.4983492,4.99284931 22.2968513,4.54811669 21.9447488,4.2411063 C21.5926462,3.9340959 21.1246154,3.79504325 20.6620008,3.86 C17.55889,4.33921701 14.6481207,5.66518802 12.2500008,7.692 C11.6597726,8.15003572 10.8342291,8.15003572 10.2440008,7.692 C7.84638107,5.66676866 4.93674509,4.34187912 1.83500085,3.863 C1.37290577,3.79811792 0.905379808,3.93678508 0.553387913,4.24312352 C0.201396019,4.54946197 -0.00047680623,4.99337241 0,5.46 L0,16.679 C-0.000355056497,17.4821389 0.594743236,18.16109 1.39100085,18.266 C4.65861981,18.6766004 7.73645787,20.0280836 10.2500008,22.156 C10.8386929,22.6119959 11.6613088,22.6119959 12.2500008,22.156 Z" id="Path"></path>
							<path d="M19.2700008,0 C16.6917734,0.645541465 14.2922754,1.86408996 12.2500008,3.565 C11.6597726,4.02303572 10.8342291,4.02303572 10.2440008,3.565 C8.20475138,1.86546014 5.80874066,0.647288648 3.23400085,0.001" id="Path"></path>
							<line x1="11.2500008" y1="8.034" x2="11.2500008" y2="22.497" id="Path"></line>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}

class IcoLock extends React.Component{
	render() {
		return (
			<svg width="71px" height="71px" viewBox="0 0 71 71" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
					<g id="02-Step-2a---OTP" transform="translate(-152.000000, -145.000000)">
						<circle id="Oval" stroke="#ED3E42" strokeWidth="2" cx="187.5" cy="180.5" r="34.5"></circle>
						<g id="lock-2" transform="translate(173.000000, 161.000000)" stroke="#EF2A42" strokeWidth="1.5">
							<rect id="Rectangle" x="0" y="16" width="29" height="24" rx="1.5"></rect>
							<path d="M5,16 L5,9.33333333 C5,4.17867567 9.25329488,0 14.5,0 C19.7467051,0 24,4.17867567 24,9.33333333 L24,16" id="Path"></path>
							<path d="M14,27.2500013 C13.5857851,27.2500013 13.2499987,27.5857878 13.2499987,28 C13.2499987,28.4142149 13.5857851,28.7500013 14,28.7500013 C14.4142122,28.7500013 14.7499987,28.4142149 14.7499987,28 C14.7505317,27.8009261 14.6716852,27.6098508 14.5309172,27.4690828 C14.3901492,27.3283148 14.1990739,27.2494683 14,27.2500013 L14,27.2500013" id="Path"></path>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}

class IcoScanInfoId extends React.Component{
	render() {
		return (

			<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="05-Step-5a-ID-Verification-nationnal" transform="translate(-40.000000, -306.000000)">
						<g id="content" transform="translate(20.000000, 84.000000)" fill="#DD273D">
							<g id="docs" transform="translate(20.000000, 43.000000)">
								<rect id="Rectangle" x="0" y="179" width="35" height="35" rx="8"></rect>
							</g>
						</g>
						<g id="face-id-4" transform="translate(53.000000, 319.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
							<circle id="Oval" cx="5" cy="3" r="3"></circle>
							<path d="M10,11 C9.25092457,9.20205598 7.24749793,8 5,8 C2.75250207,8 0.749075426,9.20205598 0,11" id="Path"></path>
						</g>
						<rect id="Rectangle" stroke="#FFFFFF" strokeWidth="1.5" x="49" y="315" width="18" height="18" rx="1"></rect>
					</g>
				</g>
			</svg>
		)
	}
}
class IcoScanInfoCamera extends React.Component{
	render() {
		return (

			<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="05-Step-5a-ID-Verification-nationnal" transform="translate(-40.000000, -356.000000)">
						<g id="content" transform="translate(20.000000, 84.000000)" fill="#DD273D">
							<g id="docs" transform="translate(20.000000, 43.000000)">
								<rect id="Rectangle-Copy-2" x="0" y="229" width="35" height="35" rx="8"></rect>
							</g>
						</g>
						<g id="taking-pictures-circle" transform="translate(49.250000, 368.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
							<path d="M12,2 L11,0 L5,0 L4,2 L2,2 C0.8954305,2 0,2.8954305 0,4 L0,10 C0,11.1045695 0.8954305,12 2,12 L14,12 C15.1045695,12 16,11.1045695 16,10 L16,4 C16,2.8954305 15.1045695,2 14,2 L12,2 Z" id="Path"></path>
							<circle id="Oval" cx="8" cy="6.5" r="2.5"></circle>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}
class IcoScanInfoLight extends React.Component{
	render() {
		return (

			<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="05-Step-5a-ID-Verification-nationnal" transform="translate(-40.000000, -405.000000)">
						<g id="content" transform="translate(20.000000, 84.000000)" fill="#DD273D">
							<g id="docs" transform="translate(20.000000, 43.000000)">
								<rect id="Rectangle-Copy-3" x="0" y="278" width="35" height="35" rx="8"></rect>
							</g>
						</g>
						<g id="light-mode-sunny" transform="translate(48.000000, 413.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
							<circle id="Oval" cx="9.5" cy="9.5" r="3.5"></circle>
							<line x1="9.5" y1="0" x2="9.5" y2="3" id="Path"></line>
							<line x1="9.5" y1="15" x2="9.5" y2="18" id="Path"></line>
							<line x1="18" y1="9.5" x2="15" y2="9.5" id="Path"></line>
							<line x1="3" y1="9.5" x2="0" y2="9.5" id="Path"></line>
							<line x1="15" y1="3" x2="13" y2="5" id="Path"></line>
							<line x1="5" y1="13" x2="3" y2="15" id="Path"></line>
							<line x1="15" y1="15" x2="13" y2="13" id="Path"></line>
							<line x1="5" y1="5" x2="3" y2="3" id="Path"></line>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}
class IcoScanInfoIns extends React.Component{
	render() {
		return (

			<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="05-Step-5a-ID-Verification-nationnal" transform="translate(-40.000000, -455.000000)">
						<g id="content" transform="translate(20.000000, 84.000000)" fill="#DD273D">
							<g id="docs" transform="translate(20.000000, 43.000000)">
								<rect id="Rectangle-Copy-4" x="0" y="328" width="35" height="35" rx="8"></rect>
							</g>
						</g>
						<g id="book-close" transform="translate(50.000000, 465.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
							<path d="M8.16739435,14.7719818 C9.84268531,13.3535971 11.8940158,12.4525497 14.07192,12.1784175 C14.6028057,12.1084713 14.9995732,11.655797 14.9993404,11.1203233 L14.9993404,3.64032409 C15.0002349,3.32886256 14.865891,3.03234775 14.6311351,2.82765593 C14.3963791,2.62296411 14.0843308,2.53025409 13.7758937,2.57356245 C11.7069689,2.8930689 9.76628325,3.77712828 8.16739435,5.12845657 C7.77387384,5.4338409 7.22346245,5.4338409 6.82994194,5.12845657 C5.23138645,3.77818213 3.29145642,2.8948438 1.22344282,2.57556263 C0.915352007,2.53230406 0.603640278,2.62475706 0.368958123,2.82900087 C0.134275967,3.03324469 -0.000317899122,3.32921133 0,3.64032409 L0,11.1203233 C-0.000236725406,11.655797 0.396530793,12.1084713 0.927416462,12.1784175 C3.10602306,12.4521755 5.15809779,13.3532445 6.8339423,14.7719818 C7.22643863,15.0760061 7.77489802,15.0760061 8.16739435,14.7719818 Z" id="Path"></path>
							<path d="M12.847811,0 C11.1288397,0.430399294 9.52903195,1.24283728 8.16739435,2.37687827 C7.77387384,2.68226261 7.22346245,2.68226261 6.82994194,2.37687827 C5.47032125,1.24375082 3.87283856,0.431564186 2.15619252,0.000666726023" id="Path"></path>
							<line x1="7.50066832" y1="5.35647687" x2="7.50066832" y2="14.9993353" id="Path"></line>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}

class IcoScan extends React.Component{
	render() {
		return (

			<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="05-Step-5c-Card-Front" transform="translate(-239.000000, -680.000000)">
						<g id="button" transform="translate(40.000000, 611.000000)">
							<circle id="Oval" stroke="#FFFFFF" strokeWidth="1.5" cx="208.5" cy="77.5" r="3.5"></circle>
							<path d="M200,79.4347826 C200.512836,79.4347826 200.935507,79.8208228 200.993272,80.3181615 L201,80.4347826 L201,85 L205.565217,85 C206.078053,85 206.500725,85.3860402 206.55849,85.8833789 L206.565217,86 C206.565217,86.5128358 206.179177,86.9355072 205.681839,86.9932723 L205.565217,87 L200.695652,87 C199.808458,87 199.080404,86.3186403 199.006224,85.4506552 L199,85.3043478 L199,80.4347826 C199,79.8824979 199.447715,79.4347826 200,79.4347826 Z M216,79.4347826 C216.512836,79.4347826 216.935507,79.8208228 216.993272,80.3181615 L217,80.4347826 L217,85.3043478 C217,86.1915421 216.31864,86.9195963 215.450655,86.9937759 L215.304348,87 L210.434783,87 C209.882498,87 209.434783,86.5522847 209.434783,86 C209.434783,85.4871642 209.820823,85.0644928 210.318161,85.0067277 L210.434783,85 L215,85 L215,80.4347826 C215,79.9219468 215.38604,79.4992754 215.883379,79.4415103 L216,79.4347826 Z M205.565217,69 C206.117502,69 206.565217,69.4477153 206.565217,70 C206.565217,70.5128358 206.179177,70.9355072 205.681839,70.9932723 L205.565217,71 L201,71 L201,75.5652174 C201,76.0780532 200.61396,76.5007246 200.116621,76.5584897 L200,76.5652174 C199.487164,76.5652174 199.064493,76.1791772 199.006728,75.6818385 L199,75.5652174 L199,70.6956522 C199,69.8084579 199.68136,69.0804037 200.549345,69.0062241 L200.695652,69 L205.565217,69 Z M215.304348,69 C216.191542,69 216.919596,69.6813597 216.993776,70.5493448 L217,70.6956522 L217,75.5652174 C217,76.1175021 216.552285,76.5652174 216,76.5652174 C215.487164,76.5652174 215.064493,76.1791772 215.006728,75.6818385 L215,75.5652174 L215,71 L210.434783,71 C209.921947,71 209.499275,70.6139598 209.44151,70.1166211 L209.434783,70 C209.434783,69.4871642 209.820823,69.0644928 210.318161,69.0067277 L210.434783,69 L215.304348,69 Z" id="scan-id" fill="#FFFFFF" fillRule="nonzero"></path>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}
class IcoScanBlack extends React.Component{
	render() {
		return (

			<svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="05-Step-5c-Card-Front" transform="translate(-239.000000, -680.000000)">
						<g id="button" transform="translate(40.000000, 611.000000)">
							<circle id="Oval" stroke="#000000" strokeWidth="1.5" cx="208.5" cy="77.5" r="3.5"></circle>
							<path d="M200,79.4347826 C200.512836,79.4347826 200.935507,79.8208228 200.993272,80.3181615 L201,80.4347826 L201,85 L205.565217,85 C206.078053,85 206.500725,85.3860402 206.55849,85.8833789 L206.565217,86 C206.565217,86.5128358 206.179177,86.9355072 205.681839,86.9932723 L205.565217,87 L200.695652,87 C199.808458,87 199.080404,86.3186403 199.006224,85.4506552 L199,85.3043478 L199,80.4347826 C199,79.8824979 199.447715,79.4347826 200,79.4347826 Z M216,79.4347826 C216.512836,79.4347826 216.935507,79.8208228 216.993272,80.3181615 L217,80.4347826 L217,85.3043478 C217,86.1915421 216.31864,86.9195963 215.450655,86.9937759 L215.304348,87 L210.434783,87 C209.882498,87 209.434783,86.5522847 209.434783,86 C209.434783,85.4871642 209.820823,85.0644928 210.318161,85.0067277 L210.434783,85 L215,85 L215,80.4347826 C215,79.9219468 215.38604,79.4992754 215.883379,79.4415103 L216,79.4347826 Z M205.565217,69 C206.117502,69 206.565217,69.4477153 206.565217,70 C206.565217,70.5128358 206.179177,70.9355072 205.681839,70.9932723 L205.565217,71 L201,71 L201,75.5652174 C201,76.0780532 200.61396,76.5007246 200.116621,76.5584897 L200,76.5652174 C199.487164,76.5652174 199.064493,76.1791772 199.006728,75.6818385 L199,75.5652174 L199,70.6956522 C199,69.8084579 199.68136,69.0804037 200.549345,69.0062241 L200.695652,69 L205.565217,69 Z M215.304348,69 C216.191542,69 216.919596,69.6813597 216.993776,70.5493448 L217,70.6956522 L217,75.5652174 C217,76.1175021 216.552285,76.5652174 216,76.5652174 C215.487164,76.5652174 215.064493,76.1791772 215.006728,75.6818385 L215,75.5652174 L215,71 L210.434783,71 C209.921947,71 209.499275,70.6139598 209.44151,70.1166211 L209.434783,70 C209.434783,69.4871642 209.820823,69.0644928 210.318161,69.0067277 L210.434783,69 L215.304348,69 Z" id="scan-id" fill="#000000" fillRule="nonzero"></path>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}

class IcoIDError extends React.Component{
	render() {
		return (

			<svg width="71px" height="71px" viewBox="0 0 71 71" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
					<g id="06-Step-6c-ID-Error-in-Extraction" transform="translate(-152.000000, -141.000000)" stroke="#ED3E42">
						<circle id="Oval" strokeWidth="2" cx="187.5" cy="176.5" r="34.5"></circle>
						<g id="single-neutral-id-card-3" transform="translate(170.000000, 164.000000)" strokeWidth="1.5">
							<path d="M2.33333333,0 C1.04466892,0 0,1.05823605 0,2.36363636 L0,23.6363636 C0,24.941764 1.04466892,26 2.33333333,26 L32.6666667,26 C33.9553311,26 35,24.941764 35,23.6363636 L35,2.36363636 C35,1.05823605 33.9553311,0 32.6666667,0 C29.7037037,0 27.4814815,0 26,0 C24.8888889,0 23.2222222,0 21,0 C17.1111111,0 10.8888889,0 2.33333333,0 Z" id="Path"></path>
							<circle id="Oval" cx="11" cy="14" r="4"></circle>
							<path d="M4,26 C4,22.1340067 7.13400679,19 11,19 C14.8659932,19 18,22.1340067 18,26 L4,26 Z" id="Path"></path>
							<line x1="21" y1="12" x2="28" y2="12" id="Path"></line>
							<line x1="21" y1="17" x2="30" y2="17" id="Path"></line>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}

class IcoNoHat extends React.Component{
	render() {
		return (

			<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="09-Step-9-Liveness-Test" transform="translate(-40.000000, -405.000000)">
						<g id="content" transform="translate(20.000000, 84.000000)">
							<g id="docs" transform="translate(20.000000, 43.000000)">
								<rect id="Rectangle-Copy-3" fill="#DD273D" x="0" y="278" width="35" height="35" rx="8"></rect>
								<g id="Group" transform="translate(7.000000, 287.000000)" stroke="#FFFFFF" strokeWidth="1.5">
									<g id="cap-3" transform="translate(9.500000, 7.500000) scale(-1, 1) translate(-9.500000, -7.500000) translate(0.000000, 2.000000)" strokeLinecap="round" strokeLinejoin="round">
										<path d="M14.1866667,6.10172278 L2.20166409,9.97848631 C1.5398557,10.1921088 0.818837041,9.86186639 0.590931124,9.24073512 L0.382195006,8.67829899 C-0.87213605,5.26098703 1.06221752,1.53620526 4.70269463,0.358766042 C8.34317174,-0.818673174 12.3111978,0.997102447 13.5655288,4.41441441 L14.1866667,6.10172278 Z" id="Path"></path>
										<path d="M14.1113342,6.2173913 L18.1826082,7.56375886 C18.6330997,7.7232468 18.9467019,8.09927176 18.9938614,8.53649267 C19.0410209,8.97371359 18.813732,9.39790895 18.4058909,9.63383785 C15.2875417,11.382827 11.3705961,11.4575219 8.17423798,9.82895232 L6.08,8.72978157" id="Path"></path>
										<path d="M4.81333333,0.239130435 C4.81333333,0.239130435 8.34620047,2.0801473 9.37333333,7.65217391" id="Path"></path>
									</g>
									<line x1="4.125" y1="14.875" x2="18.875" y2="0.125" id="Line" strokeLinecap="square" transform="translate(11.500000, 7.500000) scale(-1, 1) translate(-11.500000, -7.500000) "></line>
								</g>
							</g>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}

class IcoGlasses extends React.Component{
	render() {
		return (

			<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="09-Step-9-Liveness-Test" transform="translate(-40.000000, -455.000000)">
						<g id="content" transform="translate(20.000000, 84.000000)" fill="#DD273D">
							<g id="docs" transform="translate(20.000000, 43.000000)">
								<rect id="Rectangle-Copy-4" x="0" y="328" width="35" height="35" rx="8"></rect>
							</g>
						</g>
						<g id="read-glasses" transform="translate(45.000000, 469.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
							<circle id="Oval" cx="7" cy="4" r="4"></circle>
							<circle id="Oval" cx="18" cy="4" r="4"></circle>
							<path d="M11,4 C11,2.8954305 11.6715729,2 12.5,2 C13.3284271,2 14,2.8954305 14,4" id="Path"></path>
							<line x1="25" y1="4.5" x2="22" y2="4.5" id="Path"></line>
							<line x1="3" y1="4.5" x2="0" y2="4.5" id="Path"></line>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}

class IcoGestureHead extends React.Component{
	render() {
		return (


			<svg width="107px" height="121px" viewBox="0 0 107 121" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="10--Step-10-Liveness-Gestures" transform="translate(-134.000000, -321.000000)" strokeWidth="2">
						<g id="head" transform="translate(135.000000, 322.000000)">
							<path d="M11.9403099,50.9748177 C11.9403099,50.9748177 8.23573698,48.1275911 4.54290117,49.2706725 C-3.01800885,51.6108738 0.782372024,62.6664502 2.37316566,67.6173214 C3.96423225,72.5684683 6.81745219,82.321329 12.4411861,81.9918591 C15.2398146,81.8278134 16,78.4975481 16,78.4975481" id="Stroke-3" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M92.0596901,50.9748177 C92.0596901,50.9748177 95.764263,48.1275911 99.4570988,49.2706725 C107.018009,51.6108738 103.217628,62.6664502 101.626834,67.6173214 C100.035768,72.5684683 97.1825478,82.321329 91.5588139,81.9918591 C88.7601854,81.8278134 88,78.4975481 88,78.4975481" id="Stroke-5" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M38,60.8937463 C38,60.8937463 34.8988828,58 30.1034966,58 C25.3083626,58 22,60.4998866 22,60.4998866 C22,60.4998866 25.7253746,63 30.1730827,63 C34.6207907,63 38,60.8937463 38,60.8937463" id="Fill-11" stroke="#E80000"></path>
							<path d="M44,50.3952749 C44,50.3952749 40.2121721,49 34.2985602,49 C23.7517887,49 17,54 17,54" id="Stroke-13" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M65,60.8937463 C65,60.8937463 68.1011172,58 72.8965034,58 C77.6916374,58 81,60.4998866 81,60.4998866 C81,60.4998866 77.2746254,63 72.8269173,63 C68.3792093,63 65,60.8937463 65,60.8937463" id="Fill-15" stroke="#E80000"></path>
							<path d="M59,50.3952749 C59,50.3952749 62.7878279,49 68.7014398,49 C79.2482113,49 86,54 86,54" id="Stroke-17" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M93.7244702,51 C93.7244702,51 94.0994969,47.8685363 93.974488,42.0525307 C93.4663874,18.4111018 75.7234599,0 52,0" id="Stroke-19" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M15,81 C15,81 17.90983,94.3630069 19.6896945,98.2963327 C21.469289,102.229392 34.8077424,113.867916 37.689222,115.897959 C40.5709716,117.928003 47.5265372,119 51.000135,119 C54.4734628,119 61.4292984,117.928003 64.310778,115.897959 C67.1922576,113.867916 80.530711,102.229392 82.3105755,98.2963327 C84.09017,94.3630069 87,81 87,81" id="Stroke-21" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M52,0 C28.2765401,0 10.5336126,18.4111018 10.025512,42.0525307 C9.90050313,47.8682626 10.2755298,51 10.2755298,51" id="Stroke-23" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M86.0816931,86 C82.4880394,102.395272 89,119 89,119" id="Stroke-25" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M16.9183069,86 C20.5119606,102.395272 14,119 14,119" id="Stroke-27" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M52,0 C28.2765401,0 10.5336126,18.4111018 10.025512,42.0525307 C9.90050313,47.8682626 10.2755298,51 10.2755298,51" id="Stroke-29" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M16.9183069,86 C20.5119606,102.395272 14,119 14,119" id="Stroke-31" stroke="#E80000" strokeLinecap="round"></path>
							<line x1="44.1304348" y1="97" x2="61.8695652" y2="97" id="Line" stroke="#C60000" strokeLinecap="square"></line>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}

class SVGFaceStraight extends React.Component{
	render() {
		return (

			<svg width="145px" height="192px" viewBox="0 0 145 192" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="10-Step-10b-Look-Straight" transform="translate(-115.000000, -523.000000)">
						<ellipse id="Oval-Copy" fillOpacity="0.454996176" fill="#3C3C3C" cx="187.5" cy="619" rx="72.2242991" ry="96"></ellipse>
						<g id="head" transform="translate(135.000000, 553.000000)" stroke="#FFFFFF" strokeWidth="3">
							<path d="M11.9403099,50.9748177 C11.9403099,50.9748177 8.23573698,48.1275911 4.54290117,49.2706725 C-3.01800885,51.6108738 0.782372024,62.6664502 2.37316566,67.6173214 C3.96423225,72.5684683 6.81745219,82.321329 12.4411861,81.9918591 C15.2398146,81.8278134 16,78.4975481 16,78.4975481" id="Stroke-3" strokeLinecap="round"></path>
							<path d="M92.0596901,50.9748177 C92.0596901,50.9748177 95.764263,48.1275911 99.4570988,49.2706725 C107.018009,51.6108738 103.217628,62.6664502 101.626834,67.6173214 C100.035768,72.5684683 97.1825478,82.321329 91.5588139,81.9918591 C88.7601854,81.8278134 88,78.4975481 88,78.4975481" id="Stroke-5" strokeLinecap="round"></path>
							<path d="M38,60.8937463 C38,60.8937463 34.8988828,58 30.1034966,58 C25.3083626,58 22,60.4998866 22,60.4998866 C22,60.4998866 25.7253746,63 30.1730827,63 C34.6207907,63 38,60.8937463 38,60.8937463" id="Fill-11"></path>
							<path d="M44,50.3952749 C44,50.3952749 40.2121721,49 34.2985602,49 C23.7517887,49 17,54 17,54" id="Stroke-13" strokeLinecap="round"></path>
							<path d="M65,60.8937463 C65,60.8937463 68.1011172,58 72.8965034,58 C77.6916374,58 81,60.4998866 81,60.4998866 C81,60.4998866 77.2746254,63 72.8269173,63 C68.3792093,63 65,60.8937463 65,60.8937463" id="Fill-15"></path>
							<path d="M59,50.3952749 C59,50.3952749 62.7878279,49 68.7014398,49 C79.2482113,49 86,54 86,54" id="Stroke-17" strokeLinecap="round"></path>
							<path d="M93.7244702,51 C93.7244702,51 94.0994969,47.8685363 93.974488,42.0525307 C93.4663874,18.4111018 75.7234599,0 52,0" id="Stroke-19" strokeLinecap="round"></path>
							<path d="M15,81 C15,81 17.90983,94.3630069 19.6896945,98.2963327 C21.469289,102.229392 34.8077424,113.867916 37.689222,115.897959 C40.5709716,117.928003 47.5265372,119 51.000135,119 C54.4734628,119 61.4292984,117.928003 64.310778,115.897959 C67.1922576,113.867916 80.530711,102.229392 82.3105755,98.2963327 C84.09017,94.3630069 87,81 87,81" id="Stroke-21" strokeLinecap="round"></path>
							<path d="M52,0 C28.2765401,0 10.5336126,18.4111018 10.025512,42.0525307 C9.90050313,47.8682626 10.2755298,51 10.2755298,51" id="Stroke-23" strokeLinecap="round"></path>
							<path d="M86.0816931,86 C82.4880394,102.395272 89,119 89,119" id="Stroke-25" strokeLinecap="round"></path>
							<path d="M16.9183069,86 C20.5119606,102.395272 14,119 14,119" id="Stroke-27" strokeLinecap="round"></path>
							<path d="M52,0 C28.2765401,0 10.5336126,18.4111018 10.025512,42.0525307 C9.90050313,47.8682626 10.2755298,51 10.2755298,51" id="Stroke-29" strokeLinecap="round"></path>
							<path d="M16.9183069,86 C20.5119606,102.395272 14,119 14,119" id="Stroke-31" strokeLinecap="round"></path>
							<line x1="44.1304348" y1="97" x2="61.8695652" y2="97" id="Line" strokeLinecap="square"></line>
						</g>
					</g>
				</g>
			</svg>

		)
	}
}
class SVGFaceLeft extends React.Component{
	render() {
		return (

			<svg width="145px" height="192px" viewBox="0 0 145 192" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="10-Step-10c-Look-left" transform="translate(-115.000000, -523.000000)">
						<ellipse id="Oval" fillOpacity="0.454996176" fill="#3C3C3C" cx="187.5" cy="619" rx="72.2242991" ry="96"></ellipse>
						<g id="Group-25" transform="translate(188.000000, 613.000000) scale(-1, 1) translate(-188.000000, -613.000000) translate(139.000000, 550.000000)">
							<path d="M83.5893887,125 C84.9554379,124.658846 85.6673348,120.569594 86.1422201,119.144152 C86.326371,118.591534 86.9667335,109.125311 87.2442526,105.870723 C87.4407569,103.568439 87.046312,93.0804762 87.2341976,90.8120487 C87.2649373,90.4396203 88.6707905,89.3722907 88.7006683,89.0187994 C88.7859925,88.0105456 90.7408215,87.8116761 91.1183165,87.6303396 C91.4590388,87.4665055 91.7744798,86.9927927 92,86.0241345 C90.7704121,79.7304389 86.5062126,62.7614191 86.3961817,61.2232152 C86.2594331,59.3134437 87.2784398,58.5091935 87.7610818,55.233373 C87.9035761,54.2652887 87.9799944,53.1758352 87.8877753,52.2157848 C87.3646258,46.7151494 83.3796692,30.2568556 80.2858766,23.6859907 C77.2690768,17.2789598 72.369686,10.4659303 65.9091782,6.04499291 C60.39959,2.27451405 52.9930464,-0.182996901 48,0.010677342" id="Stroke-1" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M23.1216389,55.0346606 C23.1216389,55.0346606 18.3737489,52.1011545 13.4248975,53.2788747 C3.29157497,55.6899912 7.72083334,67.080585 9.5574369,72.1814827 C11.39432,77.2826643 14.6407285,87.3310662 22.1006203,86.9916124 C25.8132371,86.8225956 27,83.3914132 27,83.3914132" id="Stroke-3" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<polygon id="Fill-5" points="77 91 92 88 90.0904396 90.7821721"></polygon>
							<polygon id="Stroke-7" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" points="77 91 91 88 87.037342 90.9518237"></polygon>
							<path d="M78,63.4724956 C78,63.4724956 76.4967145,60 71.1019051,60 C65.7073793,60 60,62.999864 60,62.999864 C60,62.999864 66.1765179,66 71.1801894,66 C76.183861,66 78,63.4724956 78,63.4724956" id="Fill-9" stroke="#FFFFFF" strokeWidth="3"></path>
							<path d="M81,54.6747611 C81,54.6747611 78.5784831,53.0701471 71.1842291,53.0005982 C61.4547341,52.9307558 51,59 51,59" id="Stroke-11" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M91,56 C91,56 92.7487534,53.8880013 95.8315106,53.1250343 C96.5953069,52.9360976 97.3288917,52.9766127 98,53.1442889" id="Stroke-13" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M24,86.2093816 C24,86.2093816 28.3319873,98.966737 31.2044427,103.181254 C34.0771859,107.39577 57.0499243,120.228043 61.7006897,122.403213 C65.770577,124.306736 76.3126955,125.224614 81.034812,124.953254 C82.6989028,124.857749 84.1460633,124.647009 85.0693114,124.346483 C87.294141,123.622474 88.6066551,122.63597 89.5534952,120.447361 C90.3268485,118.659072 93.2007425,103.345671 95.1188775,91.5619522 C95.591866,88.6544818 96.7326198,88.1008958 97.7493723,86.7114979 C98.2071124,82.493264 97.979537,77.8183788 97.5341683,74.6584206 C96.6983828,68.7276729 95.2057647,62.3608619 95.2057647,62.3608619 C95.2057647,62.3608619 96.7429772,57.5919014 96.9271089,54.8925977 C96.9751558,54.1883196 96.974005,53.4605942 96.8853916,52.7980638 C96.1701548,47.3442694 91.7601992,30.5479665 87.2924147,22.8972706 C81.649064,13.3153143 72.0716233,-1.02931166 43.8931348,0.0584162308" id="Stroke-15" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M89,63.803829 C89,63.803829 92.505609,60 95.2394231,60 C95.5080929,60 95.7582532,60.0354926 95.9910256,60.0991345 C95.6483173,61.4704957 95.4189103,63.5960748 95.4189103,63.5960748 C95.4189103,63.5960748 95.7627404,65.6384299 96,66.9360521 C95.7961138,66.9776641 95.5776442,67 95.3443109,67 C92.505609,67 89,63.803829 89,63.803829" id="Fill-17" stroke="#FFFFFF" strokeWidth="3"></path>
							<path d="M45,0 C17.8711388,1.05223215 -0.0319769358,20.9164275 0,46.0945104 C0.00408141558,49.23997 0.21898919,51.8065866 0.344183786,53" id="Stroke-19" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M1,53 C2.41738171,65.3658654 10.4916641,85.2501724 11.7557702,96.150511 C13.3247554,109.678957 6.8208517,126 6.8208517,126" id="Stroke-21" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M86,124 C86.6074246,125.290538 87,126 87,126" id="Stroke-23" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
						</g>
					</g>
				</g>
			</svg>

		)
	}
}
class SVGHFaceRight extends React.Component{
	render() {
		return (

			<svg width="145px" height="192px" viewBox="0 0 145 192" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="10---Step-10d-Look-right" transform="translate(-115.000000, -523.000000)">
						<ellipse id="Oval" fillOpacity="0.454996176" fill="#3C3C3C" cx="187.5" cy="619" rx="72.2242991" ry="96"></ellipse>
						<g id="Group-25" transform="translate(139.000000, 550.000000)">
							<path d="M83.5893887,125 C84.9554379,124.658846 85.6673348,120.569594 86.1422201,119.144152 C86.326371,118.591534 86.9667335,109.125311 87.2442526,105.870723 C87.4407569,103.568439 87.046312,93.0804762 87.2341976,90.8120487 C87.2649373,90.4396203 88.6707905,89.3722907 88.7006683,89.0187994 C88.7859925,88.0105456 90.7408215,87.8116761 91.1183165,87.6303396 C91.4590388,87.4665055 91.7744798,86.9927927 92,86.0241345 C90.7704121,79.7304389 86.5062126,62.7614191 86.3961817,61.2232152 C86.2594331,59.3134437 87.2784398,58.5091935 87.7610818,55.233373 C87.9035761,54.2652887 87.9799944,53.1758352 87.8877753,52.2157848 C87.3646258,46.7151494 83.3796692,30.2568556 80.2858766,23.6859907 C77.2690768,17.2789598 72.369686,10.4659303 65.9091782,6.04499291 C60.39959,2.27451405 52.9930464,-0.182996901 48,0.010677342" id="Stroke-1" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M23.1216389,55.0346606 C23.1216389,55.0346606 18.3737489,52.1011545 13.4248975,53.2788747 C3.29157497,55.6899912 7.72083334,67.080585 9.5574369,72.1814827 C11.39432,77.2826643 14.6407285,87.3310662 22.1006203,86.9916124 C25.8132371,86.8225956 27,83.3914132 27,83.3914132" id="Stroke-3" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<polygon id="Fill-5" points="77 91 92 88 90.0904396 90.7821721"></polygon>
							<polygon id="Stroke-7" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" points="77 91 91 88 87.037342 90.9518237"></polygon>
							<path d="M78,63.4724956 C78,63.4724956 76.4967145,60 71.1019051,60 C65.7073793,60 60,62.999864 60,62.999864 C60,62.999864 66.1765179,66 71.1801894,66 C76.183861,66 78,63.4724956 78,63.4724956" id="Fill-9" stroke="#FFFFFF" strokeWidth="3"></path>
							<path d="M81,54.6747611 C81,54.6747611 78.5784831,53.0701471 71.1842291,53.0005982 C61.4547341,52.9307558 51,59 51,59" id="Stroke-11" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M91,56 C91,56 92.7487534,53.8880013 95.8315106,53.1250343 C96.5953069,52.9360976 97.3288917,52.9766127 98,53.1442889" id="Stroke-13" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M24,86.2093816 C24,86.2093816 28.3319873,98.966737 31.2044427,103.181254 C34.0771859,107.39577 57.0499243,120.228043 61.7006897,122.403213 C65.770577,124.306736 76.3126955,125.224614 81.034812,124.953254 C82.6989028,124.857749 84.1460633,124.647009 85.0693114,124.346483 C87.294141,123.622474 88.6066551,122.63597 89.5534952,120.447361 C90.3268485,118.659072 93.2007425,103.345671 95.1188775,91.5619522 C95.591866,88.6544818 96.7326198,88.1008958 97.7493723,86.7114979 C98.2071124,82.493264 97.979537,77.8183788 97.5341683,74.6584206 C96.6983828,68.7276729 95.2057647,62.3608619 95.2057647,62.3608619 C95.2057647,62.3608619 96.7429772,57.5919014 96.9271089,54.8925977 C96.9751558,54.1883196 96.974005,53.4605942 96.8853916,52.7980638 C96.1701548,47.3442694 91.7601992,30.5479665 87.2924147,22.8972706 C81.649064,13.3153143 72.0716233,-1.02931166 43.8931348,0.0584162308" id="Stroke-15" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M89,63.803829 C89,63.803829 92.505609,60 95.2394231,60 C95.5080929,60 95.7582532,60.0354926 95.9910256,60.0991345 C95.6483173,61.4704957 95.4189103,63.5960748 95.4189103,63.5960748 C95.4189103,63.5960748 95.7627404,65.6384299 96,66.9360521 C95.7961138,66.9776641 95.5776442,67 95.3443109,67 C92.505609,67 89,63.803829 89,63.803829" id="Fill-17" stroke="#FFFFFF" strokeWidth="3"></path>
							<path d="M45,0 C17.8711388,1.05223215 -0.0319769358,20.9164275 0,46.0945104 C0.00408141558,49.23997 0.21898919,51.8065866 0.344183786,53" id="Stroke-19" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M1,53 C2.41738171,65.3658654 10.4916641,85.2501724 11.7557702,96.150511 C13.3247554,109.678957 6.8208517,126 6.8208517,126" id="Stroke-21" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M86,124 C86.6074246,125.290538 87,126 87,126" id="Stroke-23" stroke="#FFFFFF" strokeWidth="3" strokeLinecap="round"></path>
						</g>
					</g>
				</g>
			</svg>

		)
	}
}
class SVGFaceBlink extends React.Component{
	render() {
		return (

			<svg width="145px" height="192px" viewBox="0 0 145 192" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="10-Step-10e-Blink" transform="translate(-115.000000, -523.000000)">
						<ellipse id="Oval-Copy" fillOpacity="0.454996176" fill="#3C3C3C" cx="187.5" cy="619" rx="72.2242991" ry="96"></ellipse>
						<g id="head-copy" transform="translate(135.000000, 553.000000)" stroke="#FFFFFF">
							<path d="M11.9403099,51.9748177 C11.9403099,51.9748177 8.23573698,49.1275911 4.54290117,50.2706725 C-3.01800885,52.6108738 0.782372024,63.6664502 2.37316566,68.6173214 C3.96423225,73.5684683 6.81745219,83.321329 12.4411861,82.9918591 C15.2398146,82.8278134 16,79.4975481 16,79.4975481" id="Stroke-3" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M92.0596901,51.9748177 C92.0596901,51.9748177 95.764263,49.1275911 99.4570988,50.2706725 C107.018009,52.6108738 103.217628,63.6664502 101.626834,68.6173214 C100.035768,73.5684683 97.1825478,83.321329 91.5588139,82.9918591 C88.7601854,82.8278134 88,79.4975481 88,79.4975481" id="Stroke-5" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M45,51.6743299 C45,51.6743299 41.0718821,50 34.9392476,50 C24.001855,50 17,56 17,56" id="Stroke-13" strokeWidth="3" strokeLinecap="round"></path>
							<line x1="25.137931" y1="61" x2="40.862069" y2="61" id="Line-2" strokeWidth="3" strokeLinecap="square"></line>
							<line x1="63.137931" y1="61" x2="78.862069" y2="61" id="Line-2-Copy" strokeWidth="3" strokeLinecap="square"></line>
							<path d="M59,51.6743299 C59,51.6743299 62.9281179,50 69.0607524,50 C79.998145,50 87,56 87,56" id="Stroke-17" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M94.71791,51 C94.71791,51 95.1018658,47.8685363 94.9738806,42.0525307 C94.4536823,18.4111018 76.2883042,0 52,0" id="Stroke-19" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M15,81 C15,81 17.9502443,94.7146649 19.7548291,98.7514994 C21.5591402,102.78806 35.0828499,114.732861 38.0043501,116.816327 C40.926124,118.899792 47.9782947,120 51.5001369,120 C55.0217053,120 62.0741498,118.899792 64.9956499,116.816327 C67.9171501,114.732861 81.4408598,102.78806 83.2454446,98.7514994 C85.0497557,94.7146649 88,81 88,81" id="Stroke-21" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M52,0 C27.7116958,0 9.5463177,18.4111018 9.02611945,42.0525307 C8.89813416,47.8682626 9.28209002,51 9.28209002,51" id="Stroke-23" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M87.0816931,87 C83.4880394,103.395272 90,120 90,120" id="Stroke-25" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M16.9183069,87 C20.5119606,103.395272 14,120 14,120" id="Stroke-27" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M52,0 C27.7116958,0 9.5463177,18.4111018 9.02611945,42.0525307 C8.89813416,47.8682626 9.28209002,51 9.28209002,51" id="Stroke-29" strokeWidth="3" strokeLinecap="round"></path>
							<path d="M16.9183069,87 C20.5119606,103.395272 14,120 14,120" id="Stroke-31" strokeWidth="0.5" strokeLinecap="round"></path>
							<line x1="43.1376812" y1="97" x2="61.8623188" y2="97" id="Line" strokeWidth="3" strokeLinecap="square"></line>
						</g>
					</g>
				</g>
			</svg>

		)
	}
}

class IcoGestureArrowLeft extends React.Component{
	render() {
		return (

			<svg width="36px" height="17px" viewBox="0 0 36 17" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="10---Step-10d-Look-right" transform="translate(-170.000000, -47.000000)" fill="#FFFFFF" fillRule="nonzero">
						<g id="video">
							<path d="M203.750074,53.2931833 L180.541334,53.2931833 C180.334234,53.2931833 180.166347,53.1285225 180.166347,52.9254031 L180.166347,48.4708496 C180.166217,47.8759344 179.800767,47.3396547 179.240359,47.1120077 C178.67995,46.8843607 178.034908,47.0101621 177.60593,47.4307672 L170.439165,54.4597821 C169.853612,55.0342545 169.853612,55.9654743 170.439165,56.5399468 L177.60593,63.5689616 C177.88716,63.8448694 178.268617,64 178.666396,64 C179.494796,64 180.166347,63.3413568 180.166347,62.5288793 L180.166347,58.0743257 C180.166347,57.8712063 180.334234,57.7065455 180.541334,57.7065455 L203.750074,57.7065455 C204.992674,57.7065455 206,56.7185807 206,55.4998644 C206,54.2811481 204.992674,53.2931833 203.750074,53.2931833 Z" id="arrow-right" transform="translate(188.000000, 55.500000) scale(-1, 1) translate(-188.000000, -55.500000) "></path>
						</g>
					</g>
				</g>
			</svg>


		)
	}
}
class IcoGestureArrowRight extends React.Component{
	render() {
		return (

			<svg width="36px" height="17px" viewBox="0 0 36 17" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="10-Step-10c-Look-left" transform="translate(-170.000000, -47.000000)" fill="#FFFFFF" fillRule="nonzero">
						<g id="video">
							<path d="M203.750074,53.2931833 L180.541334,53.2931833 C180.334234,53.2931833 180.166347,53.1285225 180.166347,52.9254031 L180.166347,48.4708496 C180.166217,47.8759344 179.800767,47.3396547 179.240359,47.1120077 C178.67995,46.8843607 178.034908,47.0101621 177.60593,47.4307672 L170.439165,54.4597821 C169.853612,55.0342545 169.853612,55.9654743 170.439165,56.5399468 L177.60593,63.5689616 C177.88716,63.8448694 178.268617,64 178.666396,64 C179.494796,64 180.166347,63.3413568 180.166347,62.5288793 L180.166347,58.0743257 C180.166347,57.8712063 180.334234,57.7065455 180.541334,57.7065455 L203.750074,57.7065455 C204.992674,57.7065455 206,56.7185807 206,55.4998644 C206,54.2811481 204.992674,53.2931833 203.750074,53.2931833 Z" id="arrow"></path>
						</g>
					</g>
				</g>
			</svg>


		)
	}
}

class IcoClose extends React.Component{
	render() {
		return (

			<svg stroke="#000000" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="25" width="25" >
				<path d="M331.3 308.7L278.6 256l52.7-52.7c6.2-6.2 6.2-16.4 0-22.6-6.2-6.2-16.4-6.2-22.6 0L256 233.4l-52.7-52.7c-6.2-6.2-15.6-7.1-22.6 0-7.1 7.1-6 16.6 0 22.6l52.7 52.7-52.7 52.7c-6.7 6.7-6.4 16.3 0 22.6 6.4 6.4 16.4 6.2 22.6 0l52.7-52.7 52.7 52.7c6.2 6.2 16.4 6.2 22.6 0 6.3-6.2 6.3-16.4 0-22.6z"></path>
				<path  d="M256 76c48.1 0 93.3 18.7 127.3 52.7S436 207.9 436 256s-18.7 93.3-52.7 127.3S304.1 436 256 436c-48.1 0-93.3-18.7-127.3-52.7S76 304.1 76 256s18.7-93.3 52.7-127.3S207.9 76 256 76m0-28C141.1 48 48 141.1 48 256s93.1 208 208 208 208-93.1 208-208S370.9 48 256 48z"></path>
			</svg>


		)
	}
}

class IcoUpload  extends React.Component{
	render() {
		return (

			<svg width="25px" height="19px" viewBox="0 0 25 19" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
					<g id="13-Step-13a-Employment" transform="translate(-58.000000, -677.000000)" stroke="#000000" strokeWidth="1.5">
						<g id="upload-bottom-copy" transform="translate(59.000000, 678.000000)">
							<line x1="11.25" y1="12" x2="11.25" y2="0" id="Path"></line>
							<polyline id="Path" points="15.75 4.5 11.25 0 6.75 4.5"></polyline>
							<path d="M22.5,12 L22.5,13.5 C22.5,15.1568542 21.1568542,16.5 19.5,16.5 L3,16.5 C1.34314575,16.5 0,15.1568542 0,13.5 L0,12" id="Path"></path>
						</g>
					</g>
				</g>
			</svg>

		)
	}
}

class IcoTick  extends React.Component{
	render() {
		return (

			<svg width="71px" height="71px" viewBox="0 0 71 71" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
					<g id="15--Step-15c-Thank-You" transform="translate(-152.000000, -212.000000)" stroke="#ED3E42" strokeWidth="2">
						<polyline id="Path" points="205.5 232.5 184.5 261 169.5 249"></polyline>
						<circle id="Oval" cx="187.5" cy="247.5" r="34.5"></circle>
					</g>
				</g>
			</svg>


		)
	}
}

class IcoRemove extends React.Component{
	render() {
		return (

			<svg stroke="currentColor" fill="none" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" >
				<path d="M8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13H16C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H8Z" fill="currentColor"></path>
				<path fillRule="evenodd" clipRule="evenodd" d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" fill="currentColor"></path>
			</svg>


		)
	}
}

class IcoSmallArrow extends React.Component{
	render() {
		return (

			<svg width="13px" height="10px" viewBox="0 0 13 10" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
					<g id="15--Step-15a-Preview" transform="translate(-20.000000, -35.000000)" stroke="#FFFFFF" strokeWidth="1.5">
						<g id="move-left" transform="translate(21.000000, 36.500000)">
							<line x1="0" y1="3.75" x2="10.5" y2="3.75" id="Path"></line>
							<polyline id="Path" points="3.75 0 0 3.75 3.75 7.5"></polyline>
						</g>
					</g>
				</g>
			</svg>
		)
	}
}

class IcoEmail extends React.Component{
	render() {
		return (


			<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="16--Step-16-Post-thank-You" transform="translate(-40.000000, -565.000000)">
						<rect id="Rectangle-Copy-3" fill="#DD273D" x="40" y="565" width="35" height="35" rx="8"></rect>
						<g id="email-action-unread" transform="translate(50.000000, 576.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
							<rect id="Rectangle" x="0" y="0" width="17" height="12" rx="1.5"></rect>
							<path d="M16,0 L9.58803267,5.40772394 C8.65182818,6.19742535 7.34817182,6.19742535 6.41196733,5.40772394 L0,0" id="Path"></path>
						</g>
					</g>
				</g>
			</svg>

		)
	}
}
class IcoLocation extends React.Component{
	render() {
		return (


			<svg width="35px" height="35px" viewBox="0 0 35 35" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="16--Step-16-Post-thank-You" transform="translate(-40.000000, -635.000000)">
						<rect id="Rectangle-Copy-4" fill="#DD273D" x="40" y="635" width="35" height="35" rx="8"></rect>
						<g id="pin" transform="translate(51.000000, 644.000000)" stroke="#FFFFFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5">
							<circle id="Oval" cx="6" cy="6" r="3"></circle>
							<path d="M5.5,0 C8.53756612,0 11,2.53700551 11,5.66656041 C11,8.24068992 7.27906667,14.69528 5.95833333,16.7465749 C5.85643234,16.9048486 5.68434583,17 5.5,17 C5.31565417,17 5.14356766,16.9048486 5.04166667,16.7465749 C3.72093333,14.69528 0,8.24068992 0,5.66656041 C0,2.53700551 2.46243388,0 5.5,0 Z" id="Path"></path>
						</g>
					</g>
				</g>
			</svg>

		)
	}
}

class IcoLivenessError extends React.Component{
	render() {
		return (


			<svg width="71px" height="71px" viewBox="0 0 71 71" version="1.1" >
				<g id="New-Version-2.10" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
					<g id="11-Step-11a-Error-in-liveness" transform="translate(-152.000000, -141.000000)">
						<g id="content" transform="translate(20.000000, 89.000000)" stroke="#ED3E42" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
							<g id="check-circle" transform="translate(133.000000, 53.000000)">
								<circle id="Oval" cx="34.5" cy="34.5" r="34.5"></circle>
							</g>
						</g>
						<g id="head" transform="translate(171.000000, 157.000000)">
							<path d="M3.73134685,15.6582726 C3.73134685,15.6582726 2.57366781,14.709197 1.41965662,15.0902242 C-0.943127767,15.8702913 0.244491258,19.5554834 0.741614268,21.2057738 C1.23882258,22.8561561 2.13045381,26.1071097 3.88787066,25.9972864 C4.76244208,25.9426045 5,24.832516 5,24.832516" id="Stroke-3" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M29.2686531,15.6582726 C29.2686531,15.6582726 30.4263322,14.709197 31.5803434,15.0902242 C33.9431278,15.8702913 32.7555087,19.5554834 32.2583857,21.2057738 C31.7611774,22.8561561 30.8695462,26.1071097 29.1121293,25.9972864 C28.2375579,25.9426045 28,24.832516 28,24.832516" id="Stroke-5" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M12,19.1574985 C12,19.1574985 11.0309009,18 9.5323427,18 C8.03386332,18 7,18.9999547 7,18.9999547 C7,18.9999547 8.16417957,20 9.55408834,20 C10.9439971,20 12,19.1574985 12,19.1574985" id="Fill-11" stroke="#E80000"></path>
							<path d="M14,15.55811 C14,15.55811 12.7373907,15 10.7661867,15 C7.25059624,15 5,17 5,17" id="Stroke-13" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M21,19.1574985 C21,19.1574985 21.9690991,18 23.4676573,18 C24.9661367,18 26,18.9999547 26,18.9999547 C26,18.9999547 24.8358204,20 23.4459117,20 C22.0560029,20 21,19.1574985 21,19.1574985" id="Fill-15" stroke="#E80000"></path>
							<path d="M19,15.55811 C19,15.55811 20.2626093,15 22.2338133,15 C25.7494038,15 28,17 28,17" id="Stroke-17" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M29.914717,16 C29.914717,16 30.0307966,15.01758 29.9921034,13.1929508 C29.8348342,5.77603194 24.3429757,0 17,0" id="Stroke-19" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M5,26 C5,26 5.92952903,30.2198969 6.49809685,31.4619998 C7.06657842,32.7040184 11.3274733,36.3793418 12.2479459,37.0204082 C13.1685048,37.6614745 15.3904216,38 16.5000431,38 C17.6095784,38 19.8315814,37.6614745 20.7520541,37.0204082 C21.6725267,36.3793418 25.9334216,32.7040184 26.5019894,31.4619998 C27.070471,30.2198969 28,26 28,26" id="Stroke-21" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M16,0 C8.65702431,0 3.16516582,5.77603194 3.00789658,13.1929508 C2.96920335,15.0174942 3.08528303,16 3.08528303,16" id="Stroke-23" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M27.2704233,27 C26.3720099,32.4650907 28,38 28,38" id="Stroke-25" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M5.72957671,27 C6.62799015,32.4650907 5,38 5,38" id="Stroke-27" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M16,0 C8.65702431,0 3.16516582,5.77603194 3.00789658,13.1929508 C2.96920335,15.0174942 3.08528303,16 3.08528303,16" id="Stroke-29" stroke="#E80000" strokeLinecap="round"></path>
							<path d="M5.72957671,27 C6.62799015,32.4650907 5,38 5,38" id="Stroke-31" stroke="#E80000" strokeLinecap="round"></path>
							<line x1="14.0434783" y1="31" x2="19.9565217" y2="31" id="Line" stroke="#C60000" strokeLinecap="square"></line>
						</g>
					</g>
				</g>
			</svg>

		)
	}
}

export {
	IcoStopHand, IcoLock, IcoPepInfo, IcoScanInfoId, IcoScanInfoCamera, IcoScanInfoLight, IcoScanInfoIns, IcoScan, IcoScanBlack, IcoIDError, IcoNoHat, IcoGlasses, IcoGestureHead, SVGFaceStraight, SVGHFaceRight, SVGFaceLeft, SVGFaceBlink, IcoGestureArrowLeft, IcoGestureArrowRight, IcoClose, IcoUpload, IcoTick, IcoRemove, IcoSmallArrow, IcoEmail, IcoLocation, IcoLivenessError
}