import React, {useEffect, useState} from 'react'
import data from "../constants/data.json";
import {getToken} from "../utilities/AuthService";

const SwitchInput = (props) => {
    const lang = getToken().lang;
    const[active, setActive] = useState(false)
  /*  const{activeText, passiveText, className,name, value} = props; */
    const{ className } = props;

    useEffect(() => {
        setActive(props.active);
        //console.log(props.name, props.active) ;
    },[]);



    const handleChange = () =>{
        if(props.readonly !== true) {
            let newvalue;
            if (props.value.toUpperCase() === "YES") {
                newvalue = "No";
            } else {
                newvalue = "Yes";
            }
            props.onChange(props.name, newvalue);
            setActive(!active)
        }
    }

//console.log(props.value)

    return (
        <div className={`switch ${className} ${props.active && 'switch--active'}  ${props.readonly === true && 'switch--disabled'}`} onClick={handleChange} status={active ? 'active' : 'inactive'}>

            <span  className="switch__text switch__text--active"  >{data[lang]['yes']}</span>
            <span  className="switch__text switch__text--passive"  >{data[lang]['no']}</span>

        </div>
    )
}

export default SwitchInput
