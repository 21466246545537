import {authService} from "../services/auth.services";
import langdata from '../constants/data.json';
import {
    handshake,
    setClient,
    setLang,
    updateIdtype,
    updateJourneyIdService,
    updateNonstp,
    getToken, clearToken
} from '../utilities/AuthService'

import {parseJwt} from "../utilities/AuthService"
import {kycStore} from "../reducers/KYCState";
import * as device from 'react-device-detect';

export const getConfig = () => dispatch => {

    //let siteConfig = authService.getConfig();
    /*siteConfig.then(config => {

        localStorage.setItem('BM_PWA_API', config.data.PWA_API)
        localStorage.setItem('BM_GM_API', config.data.GM_API)
        localStorage.setItem('BM_BASE_URL', config.data.BASE_URL)

    }).catch(config => {
        console.log( config.error)
    })*/

};

export const UpdateCustomerType = (idtype,token) => dispatch => {
    updateIdtype(idtype)  ;
    updateNonstp(true)  ;
    authService.updateAsNonSTP(token);

    dispatch({
        type: 'IDTYPE_UPDATED',
        newidtype: idtype

    })
};




export const UpdateNonSTP = (stat = true) => dispatch => {

    updateNonstp(stat)  ;
    dispatch({
        type: 'NONSTP_UPDATED',
        nonstp: stat
    })
};



export const updateJourneyLevel = (level, status, reason = '') => dispatch => {

    authService.updateJourneyLevel(level, status, reason)  ;
};


export const setjourneyDevice = (journey_id) => dispatch => {
    updateJourneyIdService(journey_id)  ;

    dispatch({
        type: 'UPDATE_JOURNEY_MOBILE',
        custjourneyDevice: journey_id

    })


};


export const LivenessTestCheck = (jid) => dispatch => {
    let startTime = new Date().getTime();

    let livenessInstruct = authService.getLivenessCheck(jid) ;

    livenessInstruct.then(livenessInstruct => {
        let time = (new Date().getTime() - startTime);
        dispatch({
            type: 'LIVENESS_CHECK',
            livenessInst: livenessInstruct.data

        })
        let request = {"userid":jid};
        let response = livenessInstruct.data;
        let status = livenessInstruct.message;
        let journeyId = getToken().journeyid;
        let service = 'livenesscheck';
        let source = 'GIEOM';
        dispatch(serviceLog(request, response, status, journeyId, time, service, source));
    }).catch(livenessInstruct => {

        let data = 'Failure'
        dispatch({
            type: 'LIVENESS_CHECK',
            livenessInst: data

        })
        dispatch(createErrorLog(
            'LivenessCheck',
            livenessInstruct,
            jid
        ));
    })
};

export const LivenessTestStatus = (jid,idtype) => dispatch => {

    let livenessStatus = authService.LivenessTestStatus(jid,idtype) ;

    livenessStatus.then(livenessStatus => {
        dispatch({
            type: 'LIVENESS_STATUS',
            livenessStatus: livenessStatus.data

        })
    })
};



export const livenessTestResult = (postData, isBlob) => dispatch => {
    //let startTime = new Date().getTime();
    let livenessResp = authService.getLivenessTest(postData, isBlob) ;
    //let saveLiveness = authService.saveLivenessTest(frmobj) ;
    livenessResp.then(livenessResp => {
        //console.log(livenessResp)
        let encr_data = authService.decryptDataFromOcr(livenessResp.data, 'livenesscheck')
        //console.log(JSON.parse(encr_data))
        dispatch(createAccessLog(
            'livenesscheck',
            'Response from OCR',
            encr_data,
            getToken().cif
        ));
        let livenessRsponse = JSON.parse(encr_data);
        //let time = (new Date().getTime() - startTime);
        dispatch({
            type: 'LIVENESS_TEST',
            livenessResp: livenessRsponse

        })
        /*let request = postData;
        let response = livenessRsponse;
        let status = livenessResp.message;
        let journeyId = getToken().journeyid;
        let service = 'livenesstest';
        let source = 'GIEOM';*/
        //dispatch(serviceLog(request, response, status, journeyId, time, service, source));
    }).catch(livenessResp => {
        let lang = getToken().lang;
        let data = {message: langdata[lang]['unabletocommunicate']}
        dispatch({
            type: 'LIVENESS_TEST',
            livenessResp: data

        })

        dispatch(createErrorLog(
            'LivenessTest',
            livenessResp,
            getToken().journeyid
        ));
    })
};



export const updatelivenessTestResult = (jid,frmobj) => dispatch => {

    let livenessupdResp = authService.updatelivenessTestResult(jid,frmobj) ;
    livenessupdResp.then(livenessupdResp => {
        dispatch({
            type: 'LIVENESS_STATUS_UPDATE',
            livenessupdResp: livenessupdResp.data

        })
    })
};





export const updateIdscanResult = (jid,frmobj) => dispatch => {

    let idscanupdResp = authService.updateIdscanResult(jid,frmobj) ;
    idscanupdResp.then(idscanupdResp => {

        dispatch({
            type: 'IDSCAN_STATUS_UPDATE',
            idscanupdResp: idscanupdResp.data

        })
    })
};


export const updateReason = (reason) => dispatch => {

    let updRejectedReason = authService.updateRejectedReason(reason) ;
    updRejectedReason.then(updRejectedReason => {

        dispatch({
            type: 'UPDATE_REJECTED_REASON',
            idscanupdResp: updRejectedReason.data

        })
    })
};





export const updateCustomerInfo = (cid,frmobj,nonstp) => dispatch => {
    dispatch({
        type: 'API_RETRY',
        status: ''

    })
    let cInfoResp = authService.updateCustomerInfo(cid,frmobj) ;

    updateNonstp(nonstp)  ;


    cInfoResp.then(cInfoResp => {
        dispatch({
            type: 'CUSTOMER_UPDATE',
            cInfoResp: cInfoResp.data

        })

    }).catch(cInfoResp => {
        //console.log(kycStore.getState().KYCState.apiTimestamp['updateCustomerInfo']['cnt'])
        if(kycStore.getState().KYCState.apiTimestamp['updateCustomerInfo']['cnt'] === 2) {
            //console.log(' --- if');
            let lang = getToken().lang;

            let data = {status: 'Failure',message: langdata[lang]['unabletocommunicate']}
            dispatch({
                type: 'API_RETRY',
                status: false

            })
            dispatch({
                type: 'CUSTOMER_UPDATE',
                cInfoResp: data

            })
        } else {
            //console.log(' --- else');
            //console.log(kycStore.getState().KYCState.apiTimestamp['processfileid1']['cnt'] )
            dispatch({
                type: 'API_RETRY',
                status: true

            })
            setTimeout(function() {

                dispatch(updateCustomerInfo(cid,frmobj,nonstp));
                }, 4000);
        }



        dispatch(createErrorLog(
            'updateCustomerInfo',
            cInfoResp,
            cid
        ));

    })
};




export const updateCustomerLIDInfo = (cid,frmobj) => dispatch => {
    let clidInfoResps = authService.updateCustomerLIDInfo(cid,frmobj) ;

    clidInfoResps.then(clidInfoResp => {

        dispatch({
            type: 'CUSTOMER_LID_UPDATE',
            clidInfoResp: clidInfoResp.data

        })
    }).catch(cInfoResp => {
        let lang = getToken().lang;
        let data = {status: 'Failure',message: langdata[lang]['unabletocommunicate']}
        dispatch({
            type: 'CUSTOMER_LID_UPDATE',
            clidInfoResp: data

        })

        dispatch(createErrorLog(
            'updateCustomerLIDInfo',
            cInfoResp,
            cid
        ));
    })
};

export const generateOTP = (jid,cif) => dispatch => {
    let otpResps = authService.generateOTP(jid,cif) ;

    otpResps.then(otpResp => {
        dispatch({
            type: 'OTP_GENERATED',
            otpResp: otpResp.data

        })
    }).catch(otpResps => {

        let data = {message: 'Failure'}
        dispatch({
            type: 'OTP_GENERATED',
            otpResp: data

        })

        dispatch(createErrorLog(
            'generateOTP',
            otpResps,
            cif
        ));
    })
};


export const verifyOTP = (otp,cif,journeyid) => dispatch => {
    let otpverifyResps = authService.verifyOTP(otp,cif,journeyid) ;

    otpverifyResps.then(otpverifyResp => {
        dispatch({
            type: 'OTP_VERIFIED',
            otpverifyResp: otpverifyResp.data
        })

    }).catch(otpverifyResps => {
        //alert(otpverifyResps.message)
        dispatch({
            type: 'OTP_VERIFIED',
            otpverifyResp: {status: 'failure', message: otpverifyResps.message}

        })

        dispatch(createErrorLog(
            'verifyOTP',
            otpverifyResps,
            cif
        ));
    })
};

export const verifyOTPQRcode = (otp,cif,journeyid) => dispatch => {
    let otpverifyResps = authService.verifyOTPQRcode(otp,cif,journeyid) ;
    otpverifyResps.then(otpverifyResp => {
        dispatch({
            type: 'QR_OTP_VERIFIED',
            qrOtpverifyResp: otpverifyResp.data

        })
    }).catch(otpverifyResps => {
        //alert(custJourney.message)
        let lang=getToken().lang;
        //let data = {message: 'Failure'}
        dispatch({
            type: 'QR_OTP_VERIFIED',
            qrOtpverifyResp: {status: 'failure', message: langdata[lang]['unabletocommunicate']}

        })

        dispatch(createErrorLog(
            'verifyOTP',
            otpverifyResps,
            cif
        ));
    })
};



export const customerAddnlDocsUpload = (postData) => dispatch => {

    let uploadResp = authService.customerIdScan(postData) ;

    uploadResp.then(uploadResp => {
        //console.log(uploadResp.data)
        if(getToken().pt === 0) {
            let encr_data = authService.decryptDataFromOcr(uploadResp.data, 'uploadmultiple')
            //console.log(JSON.parse(encr_data))
            /*dispatch(createAccessLog(
                'uploadmultiple',
                'Response from OCR',
                encr_data,
                getToken().cif
            ));*/
            let data = JSON.parse(encr_data);
            dispatch({
                type: 'UPLOAD_ADDNL_DOC',
                uploadAddnlDocResponsedata: data

            })
        } else {
            //console.log(uploadResp.data)
            dispatch({
                type: 'UPLOAD_ADDNL_DOC',
                uploadAddnlDocResponsedata: uploadResp.data

            })
        }
    }).catch(uploadResp => {
        //alert(uploadResp)
        //console.log('uploadmultiple - catch')
        let data = {message: 'Failure'}
        dispatch({
            type: 'UPLOAD_ADDNL_DOC',
            uploadAddnlDocResponsedata: data

        })

        dispatch(createErrorLog(
            'uploadMultiples',
            uploadResp,
            getToken().journeyid
        ));
    })
};
export const customerFormUpload = (postData, key) => dispatch => {
    let uploadResp = authService.customerIdScan(postData) ;
    uploadResp.then(uploadResp => {
        //console.log(uploadResp.data)
        //console.log('uploadmultiple - then')
        if(getToken().pt === 0) {
            let encr_data = authService.decryptDataFromOcr(uploadResp.data, 'uploadmultiple')
            //console.log(JSON.parse(encr_data))
            /*dispatch(createAccessLog(
                'uploadmultiple',
                'Response from OCR',
                encr_data,
                getToken().cif
            ));*/
            let data = JSON.parse(encr_data);

            data.key = key;
            dispatch({
                type: 'UPLOAD_FORM_DOC',
                uploadFormResponsedata: data

            })
        } else {
            //console.log(uploadResp.data)
            dispatch({
                type: 'UPLOAD_FORM_DOC',
                uploadFormResponsedata: uploadResp.data

            })
        }

    }).catch(uploadResp => {
        //alert(custJourney.message)
        //console.log('uploadmultiple - catch')
        let data = {message: 'Failure'}
        dispatch({
            type: 'UPLOAD_FORM_DOC',
            uploadFormResponsedata: data

        })

        dispatch(createErrorLog(
            'uploadMultiples',
            uploadResp,
            getToken().journeyid
        ));
    })
};


export const customerIdScanUpload = (frmobj) => dispatch => {
    dispatch({
        type: 'UPLOAD_IDCARD',
        uploadIDResponsedata: frmobj

    })

};

export const processFile = (frmobj) => dispatch => {
    let startTime = new Date().getTime();
    let processFileResp = authService.processFile(frmobj) ;
    processFileResp.then(processFileResp => {
        let time = (new Date().getTime() - startTime);
        dispatch({
            type: 'PROCESS_FILE',
            processFileResp: processFileResp.data

        })
        let request = frmobj;
        let response = processFileResp.data;
        let status = processFileResp.status;
        let journeyId = getToken().journeyid;
        let service = 'processfile';
        let source = 'GIEOM';
        dispatch(serviceLog(request, response, status, journeyId, time, service, source));
    }).catch(processFileResp => {

        let data = {message: 'Failure'}
        dispatch({
            type: 'PROCESS_FILE',
            processFileResp: data

        })

        dispatch(createErrorLog(
            'processFile',
            processFileResp,
            getToken().journeyid
        ));
    })
};


export const processFileOCR = (postData, auth = '', cnt = 1) => dispatch => { //console.log('processFileOCR')
    let id = 'id' + cnt;
    let processFileResp = authService.processFile(postData, id, auth) ;

    processFileResp.then(processFileResp => {

        if(processFileResp.data) {
            let resData = processFileResp.data;

            if (getToken().pt === 0) {

                let encr_data;
                let errordata = 0;
                try {
                    encr_data = authService.decryptDataFromOcr(processFileResp.data, 'processfile' + id)

                    if(encr_data === '') {
                        encr_data = resData
                        errordata = 1;
                    }

                } catch (e) {

                    encr_data = resData
                    errordata = 1;
                }


                dispatch(createAccessLog(
                    'processfile 1',
                    'Response from OCR',
                    encr_data,
                    getToken().cif
                ));
                dispatch({
                    type: 'PROCESS_FILE_OCR',
                    processFileOCRResp: errordata === 0 ? JSON.parse(encr_data) : {message: 'Failure 1', apiResponse: encr_data}

                })
            } else {
                dispatch({
                    type: 'PROCESS_FILE_OCR',
                    processFileOCRResp: processFileResp.data

                })
            }
        } else {
            if (kycStore.getState().KYCState.apiTimestamp['processfile' + id]['cnt'] === 2) {
                //alert(custJourney.message)

                let data = {message: 'Failure 2', apiResponse: processFileResp}
                dispatch({
                    type: 'PROCESS_FILE_OCR',
                    processFileOCRResp: data

                })
            } else {
                //console.log(kycStore.getState().KYCState.apiTimestamp['processfileid1']['cnt'] )
                setTimeout(function() {dispatch(processFileOCR(postData));}, 5000);

            }
            dispatch(createAccessLog(
                'processfile 1',
                'Response from OCR Error',
                processFileResp.data ? processFileResp.message : processFileResp,
                getToken().cif
            ));

            /*dispatch(createErrorLog(
                'processFileOCR',
                processFileResp,
                getToken().journeyid
            ));*/

        }

    }).catch(processFileResp => {
        //console.log(kycStore.getState().KYCState.apiTimestamp['processfile' + id])
        if(kycStore.getState().KYCState.apiTimestamp['processfile' + id]['cnt'] === 2) {
            //alert(custJourney.message)

            let data = {message: 'Failure 3', apiResponse: processFileResp}
            dispatch({
                type: 'PROCESS_FILE_OCR',
                processFileOCRResp: data

            })
        } else {
            //console.log(kycStore.getState().KYCState.apiTimestamp['processfileid1']['cnt'] )
            setTimeout(function() {dispatch(processFileOCR(postData));}, 5000);
        }
        dispatch(createAccessLog(
            'processfile 1',
            'Response from OCR Error',
            processFileResp.data ? processFileResp.message : processFileResp,
            getToken().cif
        ));

        /*dispatch(createErrorLog(
            'processFileOCR',
            processFileResp,
            getToken().journeyid
        ));*/
    })
};



export const processFileOCR2 = (postData) => dispatch => {
    let processFileResp = authService.processFile(postData, 'id2') ;
    processFileResp.then(processFileResp => {
        if(processFileResp.data) {
            if (getToken().pt === 0) {
                let encr_data = authService.decryptDataFromOcr(processFileResp.data, 'processfileid2')
                //console.log(JSON.parse(encr_data))
                dispatch(createAccessLog(
                    'processfile 2',
                    'Response from OCR',
                    encr_data,
                    getToken().cif
                ));
                dispatch({
                    type: 'PROCESS_FILE_OCR2',
                    processFileOCRBackResp: JSON.parse(encr_data)

                })
            } else {
                dispatch({
                    type: 'PROCESS_FILE_OCR2',
                    processFileOCRBackResp: processFileResp.data

                })
            }
        } else {
            if(kycStore.getState().KYCState.apiTimestamp['processfileid2']['cnt'] === 2) {
                let data = {message: 'Failure'}
                dispatch({
                    type: 'PROCESS_FILE_OCR2',
                    processFileOCRBackResp: data
                })
            } else {
                //dispatch(processFileOCR2(postData));
                setTimeout(function() {dispatch(processFileOCR2(postData));}, 5000);
            }

            dispatch(createAccessLog(
                'processfile 2',
                'Response from OCR Error',
                processFileResp,
                getToken().cif
            ));
            /*dispatch(createErrorLog(
                'processFileOCRBack',
                processFileResp,
                getToken().journeyid
            ));*/
        }

    }).catch(processFileResp => {
        if(kycStore.getState().KYCState.apiTimestamp['processfileid2']['cnt'] === 2) {
            let data = {message: 'Failure'}
            dispatch({
                type: 'PROCESS_FILE_OCR2',
                processFileOCRBackResp: data
            })
        } else {
            //dispatch(processFileOCR2(postData));
            setTimeout(function() {dispatch(processFileOCR2(postData));}, 5000);
        }

        dispatch(createAccessLog(
            'processfile 2',
            'Response from OCR Error',
            processFileResp.data ? processFileResp.message : processFileResp,
            getToken().cif
        ));
        /*dispatch(createErrorLog(
            'processFileOCRBack',
            processFileResp,
            getToken().journeyid
        ));*/
    })
};






export const customerInfo = (jid,cif) => dispatch => {
    let customerBankData = authService.customerInfo(jid,cif) ;
    //console.log(customerBankData);
    customerBankData.then(customerBankData => {
        //console.log('then')
        //console.log(customerBankData)
        dispatch({
            type: 'CUSTOMER_INFO',
            customerBankData: customerBankData.data

        })
    }).catch(customerBankData => {
        //alert(custJourney.message)
        //console.log('catch')
        //console.log(customerBankData)
        let data = {status: 'Failure'}
        dispatch({
            type: 'CUSTOMER_INFO',
            customerBankData: data

        })

        dispatch(createErrorLog(
            'customerInfo',
            customerBankData,
            cif
        ));
    })
};

/*export const fetchAndUpdateCustomerDetails = (jid,cif) => dispatch => {
    let customerBankData = authService.fetchAndUpdateCustomerDetails(jid,cif) ;
    console.log(customerBankData)
    customerBankData.then(customerBankData => {
        console.log('then');
        console.log(customerBankData)
        dispatch({
            type: 'CUSTOMER_INFO',
            customerBankData: customerBankData.data

        })
    }).catch(customerBankData => {
        //alert(custJourney.message)
        console.log('catch');
        console.log(customerBankData)
        let data = {message: 'Failure'}
        dispatch({
            type: 'CUSTOMER_INFO',
            customerBankData: data

        })

        dispatch(createErrorLog(
            'customerInfo',
            customerBankData,
            cif
        ));
    })
};*/

export const getcustomerInfo = (jid,cif) => dispatch => {
    let customerBankData = authService.getcustomerInfo(jid,cif) ;
    customerBankData.then(customerBankData => {
        dispatch({
            type: 'CUSTOMER_INFO',
            customerBankData: customerBankData.data

        })
    }).catch(customerBankData => {
        //alert(custJourney.message)

        let data = {message: 'Failure'}
        dispatch({
            type: 'CUSTOMER_INFO',
            customerBankData: data

        })
        dispatch(createErrorLog(
            'customerInfo',
            customerBankData,
            cif
        ));
    })
};


export const fetchSalaryInfo = (cif, journeyid, client) => dispatch => {
    let customerSalaryDatas = authService.salaryInfo(cif, journeyid, client) ;
    customerSalaryDatas.then(customerSalaryData => {
        dispatch({
            type: 'SALARY_INFO',
            customerSalaryData: customerSalaryData.data

        })
    }).catch(customerSalaryData => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'fetchSalaryInfo',
            customerSalaryData,
            getToken().journeyid
        ));

    })
};



export const updatePEPDetails = (jid,cif,formdata) => dispatch => {
    let pepData = authService.savePEPDetails(jid,cif,formdata) ;
    pepData.then(pepData => {
        dispatch({
            type: 'UPDATE_PEP',
            pepData: pepData.data

        })
    })
};

export const HeartBeat = (bway,jid) => dispatch => {
    /*if(kycStore.getState().KYCState.BM_BASE_URL === 'http://localhost:30000/' || kycStore.getState().KYCState.BM_BASE_URL === 'http://pwabom.labsls.com/') {
        dispatch({
            type: 'USER_SESSION',
            session: 'active'

        })
    } else {
        let hbeatdata1 = authService.heartBeatSession1(bway, jid);
        console.log(hbeatdata1)
        hbeatdata1.then(hbeatdata1 => {
            console.log(hbeatdata1)
            /!*if (hbeatdata1 && hbeatdata1.status === 'success') {
                dispatch({
                    type: 'USER_SESSION',
                    session: hbeatdata.session

                })
            }*!/
        }).catch(hbeatdata1 => {
            console.log(hbeatdata1)
            /!*let hbeatdata2 = authService.heartBeatSession2(bway, jid);
            console.log(hbeatdata2)
            hbeatdata2.then(hbeatdata2 => {
                console.log(hbeatdata2)
            }).catch(hbeatdata2 => {
                console.log(hbeatdata2)
            })*!/
        })
    }*/

    let hbeatdata = authService.heartBeatSession(bway, jid);

    hbeatdata.then(hbeatdata => {
        dispatch({
            type: 'USER_SESSION',
            session: hbeatdata.data.session

        })

    }).catch(hbeatdata => {
        //alert(custJourney.message)
        dispatch({
            type: 'USER_SESSION',
            session: 'inactive'

        })
        dispatch(createErrorLog(
            'HeartBeat',
            hbeatdata,
            getToken().journeyid
        ));
    })
};
export const updateNextLevel = (token,level) => dispatch => {
    let levelData = authService.updateNextLevel(token,level);
    levelData.then(levelData => {
        dispatch({
            type: 'NEXT_LEVEL',
            status: levelData.status

        })

    }).catch(levelData => {
        //alert(custJourney.message)


        dispatch(createErrorLog(
            'updateNextLevel',
            levelData,
            getToken().journeyid
        ));

    })
};
export const customerJourneyLevel = (token) => dispatch => {
    let levelData = authService.customerJourneyLevel(token);

    levelData.then(levelData => {
        dispatch({
            type: 'JOURNEY_LEVEL',
            level: levelData.data[0]['cust_upd_level'],
            status: levelData.data[0]['cust_upd_status']

        })

    }).catch(levelData => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'customerJourneyLevel',
            levelData,
            getToken().journeyid
        ));

    })
};


export const getUploads = (jid) => dispatch => {
    let startTime = new Date().getTime();
    let uploadResps = authService.getUploads(jid) ;

    uploadResps.then(uploadResp => {
        let time = (new Date().getTime() - startTime);
        if(uploadResp.data.message !== undefined){
            dispatch({
                type: 'GET_UPLOAD',
                uploadRespdata: uploadResp.data

            })
            let request = {"referenceid": jid};
            let response = uploadResp.data;
            let status = uploadResp.status;
            let journeyId = getToken().journeyid;
            let service = 'getuploads';
            let source = 'GIEOM';
            dispatch(serviceLog(request, response, status, journeyId, time, service, source));
        }
    }).catch(uploadResp => {
        //alert(custJourney.message)

        let data = {message: 'Failure'}
        dispatch({
            type: 'GET_UPLOAD',
            uploadRespdata: data

        })

        dispatch(createErrorLog(
            'getUploads',
            uploadResp,
            jid
        ));
    })
};


export const getFacematchscore = (doctype) => dispatch => {
    let startTime = new Date().getTime();
    console.log('fmatchScores');
    let fmatchScores = authService.getFacematchscore(doctype) ;
    console.log(fmatchScores);
    fmatchScores.then(fmatchScore => {
        console.log('facematch - then');
        console.log(fmatchScore)
        let time = (new Date().getTime() - startTime);
        if(fmatchScore.data.message !== undefined){
            dispatch({
                type: 'GET_FACEMATCH',
                fmatchScore: fmatchScore.data

            })
        }
        let request = doctype;
        let response = fmatchScore.data;
        let status = fmatchScore.status;
        let journeyId = getToken().journeyid;
        let service = 'getfacematchscore';
        let source = 'GIEOM';
        dispatch(serviceLog(request, response, status, journeyId, time, service, source));
    }).catch(fmatchScore => {
        console.log('facematch - catch')
        console.log(fmatchScore)

        let data = {message: 'Failure'}
        dispatch({
            type: 'GET_FACEMATCH',
            fmatchScore: data

        })
        dispatch(createErrorLog(
            'getFacematchscore',
            fmatchScore,
            getToken().journeyid
        ));
    })
}

export const updateFacematchResult = (jid,frmobj) => dispatch => {
    let fmatchupdResps = authService.updateFacematchResult(jid,frmobj) ;
    fmatchupdResps.then(fmatchupdResp => {
        dispatch({
            type: 'FACEMATCH_UPDATE',
            fmatchupdResp: fmatchupdResp.data

        })
    }).catch(fmatchupdResps => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'updateFacematchResult',
            fmatchupdResps,
            jid
        ));
    })
};

export const SessionOut = (t) => dispatch => {
    //localStorage.clear();
    clearToken()

};

export const serviceLog=(request, response, status, journeyId, time, service, source)=> dispatch => {

    request = JSON.stringify(request);
    request = new Buffer(request).toString('base64');
    response = JSON.stringify(response);
    response = new Buffer(request).toString('base64');

    let formObj = {
        service_name: service,
        source: source,
        request: request,
        response: response,
        status: status,
        journeyId: journeyId,
        cif: getToken().cif,
        time: time,
    }
    formObj = JSON.stringify(formObj);
    formObj = new Buffer(formObj).toString('base64')
    authService.serviceLog(getToken().cif, formObj);
    return;
};

export const countryList = () => dispatch => {
    let countrydata = authService.getListCountry();

    countrydata.then(countrydata => {
        dispatch({
            type: 'COUNTRIES',
            countries: countrydata.data

        })

    }).catch(countrydata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'countryList',
            countrydata,
            getToken().cif
        ));
    })
};
export const maritalstatusList = () => dispatch => {
    let maritalstatusdata = authService.getListMaritalStatus();

    maritalstatusdata.then(maritalstatusdata => {
        dispatch({
            type: 'MARITALSTATUS',
            maritalstatus: maritalstatusdata.data

        })

    }).catch(maritalstatusdata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'maritalstatusList',
            maritalstatusdata,
            getToken().cif
        ));
    })
};
export const listUnemployedStatus = () => dispatch => {
    let unemployedstatusdata = authService.ListUnemployedStatus();
    unemployedstatusdata.then(unemployedstatusdata => {
        dispatch({
            type: 'UNEMPLOYEDSTATUS',
            unemployedstatus: unemployedstatusdata.data
        })
    }).catch(unemployedstatusdata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'unemployedstatusList',
            unemployedstatusdata,
            getToken().cif
        ));
    })
};

export const listEmploymentStatus = () => dispatch => {
    let employmentstatusdata = authService.ListEmploymentStatus();
    employmentstatusdata.then(employmentstatusdata => {
        dispatch({
            type: 'EMPLOYMENTSTATUS',
            employmentstatus: employmentstatusdata.data
        })
    }).catch(employmentstatusdata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'employmentstatusList',
            employmentstatusdata.response.status,
            employmentstatusdata.response.data.error.message,
            getToken().cif
        );

    })
};
export const listIncome = () => dispatch => {
    let listincomedata = authService.listIncome();
    listincomedata.then(listincomedata => {
        dispatch({
            type: 'LISTINCOME',
            listIncome: listincomedata.data
        })
    }).catch(listincomedata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'incomeList',
            listincomedata.response.status,
            listincomedata.response.data.error.message,
            getToken().cif
        );

    })
};
export const listPostalCode = () => dispatch => {
    let listpostaldata = authService.listPostalCode();
    listpostaldata.then(listpostaldata => {
        dispatch({
            type: 'LISTPOSTALCODE',
            listPostalcode: listpostaldata.data
        })
    }).catch(listpostaldata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'postalcodeList',
            listpostaldata.response.status,
            listpostaldata.response.data.error.message,
            getToken().cif
        );

    })
};
export const getLivenessMaxRetries = () => dispatch => {
    let retrydata = authService.getLivenessMaxRetries();

    retrydata.then(retrydata => {
        dispatch({
            type: 'LIVENESS_MAX_RETRIES',
            maxretries: retrydata.data.max_attempts

        })

    }).catch(retrydata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'getLivenessMaxRetries',
            retrydata,
            getToken().cif
        ));
    })
};
export const getFaceMatchLimits = () => dispatch => {
    let retrydata = authService.getFaceMatchLimits();

    retrydata.then(retrydata => {
        dispatch({
            type: 'FACEMATCH_LIMITS',
            facematchLimits: retrydata.data

        })

    }).catch(retrydata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'getFaceMatchLimits',
            retrydata,
            getToken().cif
        ));
    })
};
export const OCRThresholdAcceptedScore = () => dispatch => {
    let retrydata = authService.OCRThresholdAcceptedScore();

    retrydata.then(retrydata => {
        dispatch({
            type: 'OCR_SCORE',
            ocrScore: retrydata.data

        })

    }).catch(retrydata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'OCRThresholdAcceptedScore',
            retrydata,
            getToken().cif
        ));
    })
};
export const getOTPLimitMaxRetries = () => dispatch => {
    let retrydata = authService.getOTPLimitMaxRetries();

    retrydata.then(retrydata => {
        dispatch({
            type: 'OTP_SUBMIT_MAX_RETRIES',
            maxretries: retrydata.data.max_attempts

        })

    }).catch(retrydata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'getOTPLimitMaxRetries',
            retrydata,
            getToken().cif
        ));
    })

};
export const updateMaxOTPRetries = () => dispatch => {
    let retrydata = authService.updateMaxOTPRetries();

    retrydata.then(retrydata => {
        dispatch({
            type: 'UPDATE_SUBMIT_MAX_RETRIES',
            maxretries: retrydata.data.max_attempts

        })

    }).catch(retrydata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'updateMaxOTPRetries',
            retrydata,
            getToken().cif
        ));
    })
};
export const getIDMaxRetries = () => dispatch => {
    let retrydata = authService.getIDMaxRetries();

    retrydata.then(retrydata => {
        dispatch({
            type: 'ID_MAX_RETRIES',
            maxretries: retrydata.data.max_attempts

        })

    }).catch(retrydata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'getIDMaxRetries',
            retrydata,
            getToken().cif
        ));
    })
};
export const getOTPMaxRetries = () => dispatch => {
    let retrydata = authService.getOTPMaxRetries();

    retrydata.then(retrydata => {
        dispatch({
            type: 'OTP_MAX_RETRIES',
            maxretries: retrydata.data.max_attempts

        })

    }).catch(retrydata => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'getOTPMaxRetries',
            retrydata,
            getToken().cif
        ));
    })
};
export const updateCustomerOcrDetails = (custid, frmobj) => dispatch => {
    let uploadResp = authService.updateCustomerOcrDetails(custid, frmobj) ;
    uploadResp.then(uploadResp => {


    }).catch(uploadResp => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'updateCustomerOcrDetails',
            uploadResp,
            getToken().cif
        ));
    })
};
export const updateSwitchedToMobile = () => dispatch => {
    let uploadResp = authService.updateSwitchedToMobile() ;
    uploadResp.then(uploadResp => {


    }).catch(uploadResp => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'updateSwitchedToMobile',
            uploadResp,
            getToken().cif
        ));
    })

};
export const fetchSwitchedToMobile = () => dispatch => {
    let fetchSwitchResp = authService.fetchSwitchedToMobile() ;
    fetchSwitchResp.then(fetchSwitchResp => {
        dispatch({
            type: 'SWICHED_TO_MOBILE',
            swichToMobile: fetchSwitchResp.data.status === 'success' ? fetchSwitchResp.data.result : 0

        })
    }).catch(fetchSwitchResp => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'fetchSwitchedToMobile',
            fetchSwitchResp,
            getToken().cif
        ));
    })
};
export const switchedBackToDesktopRequest = () => dispatch => {
    let fetchSwitchResp = authService.switchedBackToDesktopRequest() ;console.log(fetchSwitchResp)
    fetchSwitchResp.then(fetchSwitchResp => {console.log(fetchSwitchResp)
        dispatch({
            type: 'SWICHED_TO_DESKTOP',
            swichToDesktop: fetchSwitchResp.data.status === 'success' ? 1 : 0

        })
    }).catch(fetchSwitchResp => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'switchedBackToDesktopRequest',
            fetchSwitchResp,
            getToken().cif
        ));
    })
};
export const continueOnMobileRequest = () => dispatch => {
    dispatch({
        type: 'CONTINUE_ON_MOBILE',
        continueOnMobile: 0

    })
    let continueOnMobile = authService.continueOnMobileRequest() ;
    continueOnMobile.then(continueOnMobile => {
        dispatch({
            type: 'CONTINUE_ON_MOBILE',
            continueOnMobile: continueOnMobile.data.status === 'success' ? 1 : 0

        })
    }).catch(continueOnMobile => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'continueOnMobileRequest',
            continueOnMobile,
            getToken().cif
        ));
    })
};
export const continueOnDesktop = () => dispatch => {
    dispatch({
        type: 'CONTINUE_ON_DESKTOP',
        continueOnDesktop: 0

    })
    let continueOnDesktop = authService.continueOnDesktop() ;
    continueOnDesktop.then(continueOnDesktop => {
        dispatch({
            type: 'CONTINUE_ON_DESKTOP',
            continueOnDesktop: continueOnDesktop.data.status === 'success' ? 1 : 0

        })
    }).catch(continueOnDesktop => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'continueOnDesktop',
            continueOnDesktop,
            getToken().cif
        ));
    })
};
export const switchedBacktoDesktop = (dir='', history = null) => dispatch => {
    //console.log('2')
    //console.log(dir)
    let switchedBacktoDesktop = authService.switchedBacktoDesktop() ;
    switchedBacktoDesktop.then(switchedBacktoDesktop => {
        //console.log(switchedBacktoDesktop)
        ///console.log('3')
        dispatch({
            type: 'SWICHED_BACK_DESKTOP',
            swichedBackDesktop: switchedBacktoDesktop.data

        })
        if(dir === 'qr') {
            ///console.log('4')
            setTimeout(function() {
                //console.log('5')
                history.push('/generate-qrcode')
            }, 5000);
        }
    }).catch(switchedBacktoDesktop => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'switchedBacktoDesktop',
            switchedBacktoDesktop,
            getToken().cif
        ));
    })
};
export const stringMatchScore = (ocr, bank, doctype, cif) => dispatch => {
    let matchScore = authService.stringMatchScore(ocr, bank, doctype, cif) ;
    matchScore.then(matchScore => {
        dispatch({
            type: 'STRING_MATCH_SCORE',
            matchScore: matchScore.data.score

        })

    }).catch(matchScore => {
        //alert(custJourney.message)

        dispatch(createErrorLog(
            'stringMatchScore',
            matchScore,
            getToken().cif
        ));
    })
};
export const fetchUploads = (jid) => dispatch => {
    dispatch({
        type: 'FETCH_USER_UPLOADS',
        fetchUploadsData: {}

    })
    let fetchUploads = authService.fetchUploads(jid) ;
    fetchUploads.then(fetchUploads => {
        dispatch({
            type: 'FETCH_USER_UPLOADS',
            fetchUploadsData: fetchUploads.data

        })

    }).catch(fetchUploads => {
        //alert(custJourney.message)

        let data = {message: 'Failure'}
        dispatch({
            type: 'FETCH_USER_UPLOADS',
            fetchUploadsData: data

        })

        dispatch(createErrorLog(
            'fetchUploads',
            fetchUploads,
            getToken().cif
        ));
    })
};
export const createAccessLog = (action, status, message, cif, apitoken=getToken().apitoken) => dispatch => {
    ///console.log(action)
    //console.log(cif)
    //console.log(apitoken)
    //console.log(message)
    if(cif && cif !== '' && cif !== null && apitoken !== '') {
        authService.createAccessLog(
            action,
            status,
            message === '' ? '-' : message,
            cif
        );
    }
};
export const createErrorLog = (action, error, cif = '') => dispatch => {
    let status = 'No Status Returned';
    let message = (error && error.message) ? error.message : 'No Error Message';
    if(error) {
        if (error.response && error.response.status) {
            status = error.response.status;
        }
        if(error.response === undefined) {
            message = error.message ? error.message : error;
        } else if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
            message = error.response.data.error.message;
        }
    }
    if(cif && cif !== '' && cif !== null) {
        authService.createErrorLog(
            action,
            status,
            message === '' ? '-' : message,
            cif
        );
    }
};

export const getLidSystemSettings = () => dispatch => {
    let retrydata = authService.getLidSystemSettings();

    retrydata.then(retrydata => {
        dispatch({
            type: 'LID_LIMITS',
            lidLimits: retrydata.data

        })

    }).catch(retrydata => {
        //alert(custJourney.message)
        let data = {message: 'Failure'}
        dispatch({
            type: 'LID_LIMITS',
            lidLimits: data

        })
        dispatch(createErrorLog(
            'getLidSystemSettings',
            retrydata,
            getToken().cif
        ));
    })
};
export const faceCapture = (arr) => dispatch => {
    let retrydata = authService.getLidSystemSettings();

    retrydata.then(retrydata => {
        dispatch({
            type: 'LID_LIMITS',
            lidLimits: retrydata.data

        })

    }).catch(retrydata => {
        //alert(custJourney.message)
        let data = {message: 'Failure'}
        dispatch({
            type: 'LID_LIMITS',
            lidLimits: data

        })
        dispatch(createErrorLog(
            'getLidSystemSettings',
            retrydata,
            getToken().cif
        ));
    })
};

export const initiateJourney = (token) => dispatch => {
    //authService.testApi();
    let journey = authService.initiatejourney(token);

    journey.then(journey => {//console.log(journey)
        //authService.dobposttestapi2();
        //authService.accessposttestapi2();
        let custInfo = {};
        //console.log(journey)
        if(journey.data.token) {
            let user = parseJwt(journey.data.token);
            custInfo = journey.data.custinfo;
            Object.keys(user).forEach(function (k) {
                custInfo[k] = user[k];
            });
        }
        //console.log(custInfo['cif'])
        /*dispatch(createAccessLog(
            'initiateJourney',
            'Response',
            JSON.stringify( journey.data.custinfo),
            custInfo['cif'],
            journey.data.token
        ));*/
        let data = {token: journey.data.token, custinfo: custInfo, message: journey.data.message, htoken: token, customer_id: journey.data.customer_id};
        //console.log(data)
        if(journey.data.token) {
            handshake(data);
        }
        dispatch({
            type: 'USER_JOURNEY',
            journey: data

        })

    }).catch(journey => {
        //console.log(journey)
        let client = ''
        let lang = ''
        if(token !== '') {
            let t = parseJwt(token);
            client = t.client
            lang = t.lang
            lang = lang.charAt(0).toUpperCase() + lang.slice(1);
            setClient(t.client)
            setLang(lang)
        }
        let data = {custinfo: {client: client, lang: lang}, message: 'Failure'}

        dispatch({
            type: 'USER_JOURNEY',
            journey: data

        })
        dispatch(createErrorLog(
            'initiateJourney',
            journey,
            getToken().cif
        ));
    })
};

export const clearFormUploads = () => dispatch => {

    dispatch({
        type: 'UPLOAD_ADDNL_DOC',
        uploadAddnlDocResponsedata: {}

    })

}
export const listPEP = () => dispatch => {
    let listpepdata = authService.listPEP();
    listpepdata.then(listpepdata => {
        dispatch({
            type: 'LISTPEP',
            listpep: listpepdata.data
        })
    }).catch(listpepdata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'pepList',
            listpepdata.response.status,
            listpepdata.response.data.error.message,
            getToken().cif
        );

    })
};
export const listRelations = () => dispatch => {
    let listrelationsdata = authService.listRelations();
    listrelationsdata.then(listrelationsdata => {
        dispatch({
            type: 'LISTRELATIONSHIPS',
            listRelationships: listrelationsdata.data
        })
    }).catch(listrelationsdata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'listRelations',
            listrelationsdata.response.status,
            listrelationsdata.response.data.error.message,
            getToken().cif
        );

    })
};
export const listEmploymentSectors = () => dispatch => {
    let listdata = authService.listEmploymentSectors();
    listdata.then(listdata => {
        dispatch({
            type: 'LISTSECTORS',
            listsectorsdata: listdata.data
        })
    }).catch(listdata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'listSectors',
            listdata.response.status,
            listdata.response.data.error.message,
            getToken().cif
        );

    })
};

export const listSelfEmploymentProofCategory = () => dispatch => {
    let listdata = authService.listSelfEmploymentProofCategory();
    listdata.then(listdata => {
        dispatch({
            type: 'LIST_SELF_EMP_PROOF',
            listselfempproof: listdata.data
        })
    }).catch(listdata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'listSelfEmploymentProofCategory',
            listdata.response.status,
            listdata.response.data.error.message,
            getToken().cif
        );

    })
};
export const listEmploymentProofCategory = () => dispatch => {
    let listdata = authService.listEmploymentProofCategory();
    listdata.then(listdata => {
        dispatch({
            type: 'LIST_EMP_PROOF',
            listempproof: listdata.data
        })
    }).catch(listdata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'listEmploymentProofCategory',
            listdata.response.status,
            listdata.response.data.error.message,
            getToken().cif
        );

    })
};
export const listAddressProofCategory = () => dispatch => {
    let listdata = authService.listAddressProofCategory();
    listdata.then(listdata => {
        dispatch({
            type: 'LIST_ADDRESS_PROOF',
            listaddproof: listdata.data
        })
    }).catch(listdata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'listAddressProofCategory',
            listdata.response.status,
            listdata.response.data.error.message,
            getToken().cif
        );

    })
};
export const getAllMasters = () => dispatch => {
    let listdata = authService.getAllMasters();

    listdata.then(listdata => {
        let masters = listdata.data;
        //console.log(masters)
        dispatch({
            type: 'MASTERS',
            countries: masters.country,
            maritalstatus: masters.maritalstatus,
            unemployedstatus: masters.unemployedStatuses,
            listIncome: masters.monthlyIncome,
            listPostalcode: masters.postalCodes,
            listPep: masters.pep,
            listRelationships: masters.relationships,
            listEmploymentSectors: masters.employmentSector,
            listselfempproof: masters.selfEmploymentProofCategory,
            listaddproof: masters.addressProofCategory,
            listempproof: masters.employmentProofCategory,
        });

    }).catch(listdata => {
        //alert(custJourney.message)
        authService.createErrorLog(
            'getAllMasters',
            listdata.response.status,
            listdata.response.data.error.message,
            getToken().cif
        );

    })
};
export const redoJourney = (history) => dispatch => {
    let redoJourney = authService.redoJourney();
    const ismobile = device.isMobile;
    redoJourney.then(journey => {
        //console.log(journey)
        //console.log(ismobile)
        if(journey.data && journey.data.message === 'success') {
            kycStore.getState().KYCState.BM_ocr_attempts = '{}';
            if(journey.data.token) {
                kycStore.getState().KYCState.BM_apitoken = journey.data.token;
            }
            kycStore.getState().KYCState.redo_journey = 1;
            kycStore.getState().KYCState.BM_step = 1;
            kycStore.getState().KYCState.BM_step_status = 'Inprogress';
            kycStore.getState().KYCState.facematchdata = {};
            kycStore.getState().KYCState.frontimage = '';
            kycStore.getState().KYCState.fetchresult = '';
            dispatch({
                type: 'GET_FACEMATCH',
                fmatchScore: []

            })
            dispatch({
                type: 'FETCH_USER_UPLOADS',
                fetchUploadsData: {}

            })
            dispatch(updateNextLevel(getToken().token, 1));
            if (ismobile) {
                history.push('/scan-ID-instruction')
            } else {
                //console.log('1')
                dispatch(switchedBacktoDesktop('qr', history));



            }
        } else {
            dispatch({
                type: 'REDO_JOURNEY',
                status: 'failure'
            });
        }

    }).catch(journey => {
        //console.log(journey)
        dispatch({
            type: 'REDO_JOURNEY',
            status: 'failure'
        });
        /*authService.createErrorLog(
            'redoJourney',
            journey.response.status,
            (journey.response.data && journey.response.data.error) ? journey.response.data.error.message : '',
            getToken().cif
        );*/

    })
};

export const resetLIDUpdate = () => dispatch => {
    console.log('resetLIDUpdate')
    dispatch({
        type: 'CUSTOMER_LID_UPDATE',
        clidInfoResp: {}

    })
};
