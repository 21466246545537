import axios from 'axios'
import { osVersion, osName, browserName, browserVersion } from 'react-device-detect';
import {GM_INSTANCE_AUTHTOKEN} from '../constants/index'
import {getToken, getRandomAESKey} from "../utilities/AuthService";
import {kycStore} from '../reducers/KYCState';
import Popup from "../pages/Popup";
import React from "react";
import langdata from "../constants/data.json";




let apiTimestamp = kycStore.getState().KYCState.apiTimestamp ;
kycStore.getState().KYCState.BM_PWA_API = ''
kycStore.getState().KYCState.BM_GM_API = ''
//localStorage.setItem('BM_PWA_API', '')
//localStorage.setItem('BM_GM_API', '')
//let apiTimestamp = {};
let PWA_API = '';
let PWA_PROXY_API = '';
let GM_API = '';
const sc_k = 'crmEp5ww3wV/5I1lL8MACGR8w8WyT33ghVJxRsvS5Maij+wIPJKhXvxQQ3bkUjR26n9DVWxlEN8MeJvmwsO61Q==';
const cs_rsa_k = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDmY8LtmhQ6MNO6WF3prQLQcte2
j2kO3WjJbIeNxuwUt8ywYq8F/cFjLoKozqKeI8RTcP4lHcLK4k2H3cI/XU2CBV2q
d5LurcumVKDdBaCyeDfN9/hMC00k/0Wehl6oIU7W1VAjA9C2V45sak9P7WdV/Qz4
IHMia3Oscvmc/wMsBwIDAQAB
-----END PUBLIC KEY-----`







export const authService = {
    init,
    getLivenessCheck,
    getLivenessTest,
    customerIdScan,
    customerInfo,
    //fetchAndUpdateCustomerDetails,
    LivenessTestStatus,
    updateJourneyId,
    processFile,
    updateAsNonSTP,
    updatelivenessTestResult,
    updateIdscanResult,
    updateCustomerInfo,
    updateCustomerLIDInfo,
    salaryInfo,
    heartBeatSession,
    heartBeatSession1,
    heartBeatSession2,
    updateNextLevel,
    customerJourneyLevel,
    getUploads,
    getFacematchscore,
    updateFacematchResult,
    getcustomerInfo,
    generateOTP,
    verifyOTP,
    verifyOTPQRcode,
    serviceLog,
    getListCountry,
    getListMaritalStatus,
    ListUnemployedStatus,
    ListEmploymentStatus,
    listIncome,
    listPostalCode,
    getLivenessMaxRetries,
    getIDMaxRetries,
    getOTPMaxRetries,
    updateCustomerOcrDetails,
    updateMaxOTPRetries,
    getOTPLimitMaxRetries,
    stringMatchScore,
    updateSwitchedToMobile,
    fetchSwitchedToMobile,
    switchedBackToDesktopRequest,
    switchedBacktoDesktop,
    getFaceMatchLimits,
    OCRThresholdAcceptedScore,
    updateJourneyLevel,
    fetchUploads,
    continueOnMobileRequest,
    createErrorLog,
    createAccessLog,
    getConfig,
    testgetConfig,
    getLidSystemSettings,
    testApi,
    initiatejourney,
    decryptDataFromOcr,
    submitFaceCapture,
    listPEP,
    listRelations,
    listEmploymentSectors,
    isonline,
    listSelfEmploymentProofCategory,
    listEmploymentProofCategory,
    listAddressProofCategory,
    getAllMasters,
    redoJourney,
    continueOnDesktop,
    uploadMultipleTest1,
    updateRejectedReason
};

const apiParams = {
    'config.json': {
        fName: 'config.json',
        maxRetries: 3,
        iteration: 0
    },
    'testapi': {
        fName: 'testapi',
        maxRetries: 3,
        iteration: 0
    },
    'getoptions': {
        fName: 'getLivenessCheck',
        maxRetries: 3,
        iteration: 0
    },
    'livenesscheck': {
        fName: 'getLivenessTest',
        maxRetries: 3,
        iteration: 0
    },
    'uploadmultiple': {
        fName: 'customerIdScan',
        maxRetries: 3,
        iteration: 0
    },
    'processfile': {
        fName: 'processFile',
        maxRetries: 3,
        iteration: 0
    },
    'getfrontdoc': {
        fName: 'getUploads',
        maxRetries: 3,
        iteration: 0
    },
    'facematch': {
        fName: 'getFacematchscore',
        maxRetries: 3,
        iteration: 0
    },
    'servicelog': {
        fName: 'serviceLog',
        maxRetries: 3,
        iteration: 0
    },
    'UpdateJourneyId': {
        fName: 'updateJourneyId',
        maxRetries: 3,
        iteration: 0
    },
    'customerhandshake': {
        fName: 'customerHandshake',
        maxRetries: 3,
        iteration: 0
    },
    'updatenextlevel': {
        fName: 'updateNextLevel',
        maxRetries: 3,
        iteration: 0
    },
    'listcountry': {
        fName: 'getListCountry',
        maxRetries: 3,
        iteration: 0
    },
    'fetchSalaryDetails': {
        fName: 'salaryInfo',
        maxRetries: 3,
        iteration: 0
    },
    'FetchCustomerDetailsTbl': {
        fName: 'getcustomerInfo',
        maxRetries: 3,
        iteration: 0
    },
    'FetchAndUpdateCustomerDetails': {
        fName: 'customerInfo',
        maxRetries: 3,
        iteration: 0
    },
    'SendOTPSMS': {
        fName: 'generateOTP',
        maxRetries: 3,
        iteration: 0
    },
    'VerifyOTP': {
        fName: 'verifyOTP',
        maxRetries: 3,
        iteration: 0
    },
    'heartbeatsesssion': {
        fName: 'heartBeatSession',
        maxRetries: 3,
        iteration: 0
    },
    'livenessandidscanstatus': {
        fName: 'LivenessTestStatus',
        maxRetries: 3,
        iteration: 0
    },
    'UserCamRes': {
        fName: 'updatelivenessTestResult',
        maxRetries: 3,
        iteration: 0
    },
    'UserIdRes': {
        fName: 'updateIdscanResult',
        maxRetries: 3,
        iteration: 0
    },
    'updateFacematchResult': {
        fName: 'updateFacematchResult',
        maxRetries: 3,
        iteration: 0
    },
    'updateCustomerInfo': {
        fName: 'updateCustomerInfo',
        maxRetries: 1,
        iteration: 0
    },
    'updateCustomerLIDInfo': {
        fName: 'updateCustomerLIDInfo',
        maxRetries: 3,
        iteration: 0
    },
    'VerifyOTPQRcode': {
        fName: 'verifyOTPQRcode',
        maxRetries: 3,
        iteration: 0
    },
    'customerjourneylevel': {
        fName: 'customerJourneyLevel',
        maxRetries: 3,
        iteration: 0
    },
    'livenessmaxretries': {
        fName: 'getLivenessMaxRetries',
        maxRetries: 3,
        iteration: 0
    },
    'FaceMatchScore': {
        fName: 'getFaceMatchLimits',
        maxRetries: 3,
        iteration: 0
    },
    'lidsystemsettings': {
        fName: 'getLidSystemSettings',
        maxRetries: 3,
        iteration: 0
    },
    'OCRThresholdScore': {
        fName: 'OCRThresholdAcceptedScore',
        maxRetries: 3,
        iteration: 0
    },
    'scanidmaxretries': {
        fName: 'getIDMaxRetries',
        maxRetries: 3,
        iteration: 0
    },
    'generateotpmaxretries': {
        fName: 'getOTPMaxRetries',
        maxRetries: 3,
        iteration: 0
    },
    'updateCustomerOcrDetails': {
        fName: 'updateCustomerOcrDetails',
        maxRetries: 3,
        iteration: 0
    },
    'updateMaxOTPRetries': {
        fName: 'updateMaxOTPRetries',
        maxRetries: 3,
        iteration: 0
    },
    'updateSwitchedToMobile': {
        fName: 'updateSwitchedToMobile',
        maxRetries: 3,
        iteration: 0
    },
    'fetchSwitchedToMobile': {
        fName: 'fetchSwitchedToMobile',
        maxRetries: 3,
        iteration: 0
    },
    'updateSwitchedBackToDesktopRequest': {
        fName: 'switchedBackToDesktopRequest',
        maxRetries: 3,
        iteration: 0
    },
    'continueOnMobileRequest': {
        fName: 'continueOnMobileRequest',
        maxRetries: 3,
        iteration: 0
    },
    'fetchswitchedbacktodesktop': {
        fName: 'switchedBacktoDesktop',
        maxRetries: 3,
        iteration: 0
    },
    'ValidateOTPLimitMaxRetries': {
        fName: 'getOTPLimitMaxRetries',
        maxRetries: 3,
        iteration: 0
    },
    'stringMatchScore': {
        fName: 'stringMatchScore',
        maxRetries: 3,
        iteration: 0
    },
    'fetch': {
        fName: 'fetchUploads',
        maxRetries: 3,
        iteration: 0
    },
    'initiatejourney': {
        fName: 'initiatejourney',
        maxRetries: 3,
        iteration: 0
    },
    'getAllMasters': {
        fName: 'getAllMasters',
        maxRetries: 3,
        iteration: 0
    },
    'redoJourney': {
        fName: 'redoJourney',
        maxRetries: 3,
        iteration: 0
    },
    'SessionValidate': {
        fName: 'heartBeatSession',
        maxRetries: 1,
        iteration: 0
    }
}

const getApiNameFromUrl = (url) => {
    let action = '';
    let path = url.split('?')[0].split('/');
    if(path[path.length-1] === ''){
        return path[path.length-2]
    }
    else{
        return path[path.length-1]
    }

}

axios.interceptors.request.use(
    (req) => {
        let action = getApiNameFromUrl(req.url);
        if(action === 'heartbeatsesssion') {
            req.timeout = 120000;
        } else {
            req.timeout = 240000;
        }
        let ts_ = new Date().getTime()
        req.uid = ts_;


        //console.log(action)
        if(apiParams[action]) {
            apiParams[action]['iteration'] = apiParams[action]['iteration'] + 1;
            if(apiParams[action]['fName'] !== 'config.json' &&
                getToken().cif &&
                getToken().apitoken !== '' &&
                action !== 'fetchswitchedbacktodesktop' &&
                action !== 'fetchSwitchedToMobile' &&
                action !== 'UserIdRes' &&
                action !== 'UserCamRes' &&
                action !== 'updateCustomerInfo' &&
                action !== 'generateotpmaxretries' &&
                action !== 'ValidateOTPLimitMaxRetries' &&
                action !== 'updateSwitchedToMobile' &&
                action !== 'updateFacematchResult' &&
                action !== 'fetchSalaryDetails' &&
                action !== 'uploadmultiple' &&
                action !== 'createErrorLog' &&
                action !== 'livenessandidscanstatus' &&
                action !== 'fmfacepics' &&
                action !== 'getAllMasters' &&
                action !== 'stringMatchScore' &&
                action !== 'facematch' &&
                action !== 'VerifyOTP' &&
                action !== 'updatenextlevel') {
                createAccessLog('API', 'Request', action, getToken().cif, getToken().journeyid, getToken().client)
            }
        }

        //----encrypt request params----
        if(getToken().pt === 0) {
            if (
                action !== 'config.json' &&
                action !== 'isonline' &&
                action !== 'processfile' &&
                action !== 'uploadmultiple' &&
                action !== 'livenesscheck' &&
                action !== 'fmfacepics') {
                // if(req.headers['X-Encoding'] === 'BOM-ENCRYPTION'){
                if(!req.data) { req.data = {}}
                req.data.ts_ = ts_
                // console.log(req.data)

                //----RSA----
                let cs_aes_k = getRandomAESKey()
                let jsEncrypt = new window.JSEncrypt();
                jsEncrypt.setPublicKey(cs_rsa_k);
                let rsa_enc_cs_aes_k = jsEncrypt.encrypt(cs_aes_k);
                //----RSA----
                let cnt = 0;
                if(kycStore.getState().KYCState.apiTimestamp[action]) {
                    cnt = 1;
                    if(kycStore.getState().KYCState.apiTimestamp[action]['cnt']) {
                        cnt = parseInt(kycStore.getState().KYCState.apiTimestamp[action]['cnt']) + 1;
                    }
                }
                kycStore.getState().KYCState.apiTimestamp[action] = {cnt: cnt}
                kycStore.getState().KYCState.apiTimestamp[action + ts_] = {ts_: ts_, k: cs_aes_k, d: req.data}

                //----AES---
                let encrypted_param = JSON.parse(window.CryptoJSAesJson.encrypt(req.data, cs_aes_k))
                let encrypted_str = encrypted_param.ct + '.' + encrypted_param.s + '.' + encrypted_param.iv + '.' + rsa_enc_cs_aes_k
                req.data = encrypted_str
                //----AES----
                // }
            }
        }
        //----encrypt request params----
        return req;
    },
    (err) => {
        console.log(err) ;
        return Promise.reject(err);
    }
);




// For POST requests
axios.interceptors.response.use(
    (res) => {//console.log(res)
        let action = getApiNameFromUrl(res.config.url);
        if(action.indexOf('SessionValidate') >= 0) {
            action = 'SessionValidate';
        }
        //----decrypt response----
        // if(res.config.headers['X-Encoding'] !== undefined){
        /*if(res.config.headers['X-Encoding'] === 'BOM-ENCRYPTION_'){
            console.log(action)
        }*/
        //console.log(action)
        if(getToken().pt === 0) {
            if (
                action !== 'config.json' &&
                action.toLowerCase() !== 'createaccesslog' &&
                action.toLowerCase() !== 'createerrorlog' &&
                action.toLowerCase() !== 'updatenextlevel' &&
                action.toLowerCase() !== 'UserIdRes' &&
                action !== 'isonline' &&
                action !== 'processfile' &&
                action !== 'uploadmultiple' &&
                action !== 'livenesscheck' &&
                action !== 'fmfacepics'
            ) {
                //console.log(action + ' BOM-ENCRYPTION')
                //console.log(res.data)

                //console.log(kycStore.getState().KYCState.apiTimestamp)

                try {
                    let req_ts = kycStore.getState().KYCState.apiTimestamp[action + res.config.uid].ts_
                    //console.log(action)
                    //console.log(req_ts)

                    let resarr = res.data.split('.')
                    let ct = resarr[0]
                    let s = resarr[1]
                    let iv = resarr[2]
                    let enObj = {ct: ct, s: s, iv: iv}
                    let aes_k = kycStore.getState().KYCState.apiTimestamp[action + res.config.uid].k
                    let decrypted = window.CryptoJSAesJson.decrypt(JSON.stringify(enObj), aes_k)
                    res.data = JSON.parse(decrypted)
                    //console.log(res.data)
                    if (getToken().apitoken !== '' && action !== 'getAllMasters' && action !== 'UserIdRes' && action !== 'UserCamRes' && action !== 'updateFacematchResult' && action !== 'generateotpmaxretries' && action !== 'ValidateOTPLimitMaxRetries' && action !== 'updateSwitchedToMobile' && action !== 'fetchswitchedbacktodesktop' && action !== 'fetchSwitchedToMobile' && action !== 'createErrorLog' && action !== 'fetchSalaryDetails' && action !== 'uploadmultiple' && action !== 'getfrontdoc' && action !== 'livenessandidscanstatus' && action !== 'facematch' && action !== 'uploadmultiple') {
                        createAccessLog('API', 'Response ' + action, decrypted, getToken().cif, getToken().journeyid, getToken().client)
                    }

                    if (req_ts !== res.data.ts_) {
                        res.data = 'encrTSerr'
                        if (getToken().apitoken !== '')
                            createAccessLog('API', 'Response ts_ not matching ' + action, getToken().cif, getToken().journeyid, getToken().client)
                        kycStore.dispatch({
                            type: 'RESPONSE_ERROR',
                            status: true
                        })
                    }
                    //console.log(res.data)
                    /*kycStore.dispatch({
                        type: 'RESPONSE_ERROR',
                        status: false
                    })*/
                } catch (e) {
                    //console.log(e)
                    //console.log(action.toLowerCase())
                    if (getToken().apitoken !== '') {
                        createAccessLog('API', 'Response Decryption failed ' + action + ' ' + e.message, getToken().cif, getToken().journeyid, getToken().client)
                    }
                    res.data = 'encrTSerr'
                    //if(action.toLowerCase() === 'initiatejourney') {
                        kycStore.dispatch({
                            type: 'RESPONSE_ERROR',
                            status: true
                        })
                    //}
                }


            } else {
                //console.log(action + ' NO-ENCRYPTION**')
                if (action !== 'createaccesslog' && action !== 'isonline' && action !== 'updatenextlevel' && action !== 'uploadmultiple' && action !== 'fmfacepics' && action !== 'uploadmultiple') {
                    createAccessLog('API', 'Response ' + action, res.data, getToken().cif, getToken().journeyid, getToken().client)
                }
            }
        }
        // }
        //----decrypt response----

        if (res.status === 201) {
            //console.log('Posted Successfully');
        }
        return res;
    },
    (err) => {


        let action = err.config ? getApiNameFromUrl(err.config.url) : '';
        if(action.indexOf('SessionValidate') >= 0) {
            action = 'SessionValidate';
        }
        //console.log(action)
        //console.log(err)
        if(getToken().pt === 0) {
            if (
                action !== 'createaccesslog' &&
                action !== 'isonline' &&
                action !== 'processfile' &&
                action !== 'uploadmultiple' &&
                action !== 'livenesscheck' &&
                action !== 'fmfacepics'
            ) {
                if (apiParams[action] && err.config && apiParams[action]['iteration'] < apiParams[action]['maxRetries']) {
                    let c = err.config
                    if (kycStore.getState().KYCState.apiTimestamp[action + err.config.uid] !== undefined) {
                        c.data = kycStore.getState().KYCState.apiTimestamp[action + err.config.uid].d
                        return axios.request(c);
                    }
                } else {
                    if (action.toLowerCase() !== 'createaccesslog') {
                        if (err.response) {
                            //createAccessLog('API', 'Response', action + ' status: ' + err.response.status + ', statusText: ' + err.response.statusText + ' URL: ' + err.config.url, getToken().cif, getToken().journeyid, getToken().client)
                            if (getToken().apitoken && getToken().cif && (err.response.status === 408 || err.code === 'ECONNABORTED') && action !== 'createaccesslog' && action !== 'createErrorLog') {
                                createAccessLog('API', 'Response', action + 'timeout on url ' + err.config.url, getToken().cif, getToken().journeyid, getToken().client)
                            }
                            if (getToken().apitoken && getToken().cif && (err.code === 'ERR_NETWORK') && action !== 'createaccesslog' && action !== 'createErrorLog') {
                                     createAccessLog('API', 'Response', action + ' - ' + err.message || 'Network Error', getToken().cif, getToken().journeyid, getToken().client)
                            }
                        } else {
                             if (getToken().apitoken && getToken().cif) {
                                createAccessLog('API', 'Response', action + ' status: ' + err.message + ' URL: ' + (err.config ? err.config.url : ''), getToken().cif, getToken().journeyid, getToken().client)
                            }
                        }
                    }

                    return Promise.reject(err);
                }
            } else if(action === 'processfile') {
                /*console.log("============= processfile error ========")
                console.log(err)
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
                console.log('Error', err.message);*/
                kycStore.getState().KYCState.processfileError = err;
                if (err.response) {
                    //createAccessLog('API', 'Response', action + ' status: ' + err.response.status + ', statusText: ' + err.response.statusText + ' URL: ' + err.config.url, getToken().cif, getToken().journeyid, getToken().client)
                    if (getToken().apitoken && getToken().cif && (err.response.status === 408 || err.code === 'ECONNABORTED') && action !== 'createaccesslog' && action !== 'createErrorLog') {
                        createAccessLog('API', 'Response', action + 'timeout on url ' + err.config.url, getToken().cif, getToken().journeyid, getToken().client)
                    }
                } else {
                    if (getToken().apitoken && getToken().cif) {
                        createAccessLog('API', 'Response', action + ' status: ' + err + ' URL: ' + (err.config ? err.config.url : ''), getToken().cif, getToken().journeyid, getToken().client)
                    }
                }
            }
        }
    }
);
function encrScript4Loaded(){
    //console.log('encrScript4Loaded')

    // let sample = {cif: "123123", journeyid: "asdadsa-asdaqwdsdf-3dskbkasf-kfgeakafa",msg: "This is a message",position: "Test",section: "Office"}
    // let key = utils.getRandomAESKey(10)
    // let iv = utils.getRandomAESKey(10)
    // let key_ = window.CryptoJS.enc.Utf8.parse(key);
    // var iv_ = window.CryptoJS.enc.Utf8.parse(iv)
    // var encrypted = window.CryptoJS.AES.encrypt( JSON.stringify( sample), key_, { iv: iv_, mode: window.CryptoJS.mode.CBC});
    // console.log(encrypted );
    // encrypted =encrypted.toString();
    // console.log('key', key)
    // console.log('iv', iv)
    // console.log(encrypted );


    // var encrypted_s ='eOlf/IsPQRvDRq5iKG42zz+8Q5cXt/N1YjlK8ZfRh2WeytJvgBks/BmPj2yom8b0'; //python is base64 ECB
    // var key_s ='alp6ZjhkTE1IMQ=='
    // let key_s_ = window.CryptoJS.enc.Utf8.parse(key_s);
    // let iv_s = 'TDhqZ3pNc3hpTA=='
    // var iv_s_ = window.CryptoJS.enc.Utf8.parse(iv_s)
    // var decrypted =  window.CryptoJS.AES.decrypt(encrypted_s, key_s_, { iv: iv_s_, mode: window.CryptoJS.mode.CBC});
    // console.log(decrypted);
    // decrypted = decrypted.toString(window.CryptoJS.enc.Utf8);
    // console.log(decrypted);
    if(getApiNameFromUrl(window.location.href) === 'api-tests') {
        testgetConfig();
    } else {
        getConfig()
    }
}
function encrScript3Loaded(){
    //console.log('encrScript3Loaded')

    const script = document.createElement("script");
    script.async = true;

    if(getApiNameFromUrl(window.location.href) === 'api-tests') {
        script.src = "../assets/js/crypto-js.min.js";
    } else {
        script.src = "assets/js/crypto-js.min.js";
    }
    script.onload = () => encrScript4Loaded();
    document.body.appendChild(script);
    //console.log('encrScript3Loaded')

    /*let sample = {name: "Bill", place: "Singapore", occ: "Engineer", ts_: new Date().getTime()}
    let jsEncrypt = new window.JSEncrypt();
    jsEncrypt.setPublicKey(cs_k);
    let enc = jsEncrypt.encrypt(JSON.stringify( sample));
    console.log(enc)

    let encrypted_sample = window.CryptoJSAesJson.encrypt(sample, sc_k)
    console.log(JSON.parse(encrypted_sample))
    let decrypted_sample = window.CryptoJSAesJson.decrypt(encrypted_sample, sc_k)
    console.log((decrypted_sample))*/


    /*let j = {
    "PWA_API": "https://bom.labsls.com/",
    "GM_API": "https://fusion.gieom.com/",
    "BASE_URL": "http://localhost:3000/",
	"HEARTBEAT_URL_1":"https://mt.bankmuscat.com:30443/corp/SessionValidate;",
	"HEARTBEAT_URL_2":"https://192.168.10.217:30443/corp/SessionValidate;"
    }*/
    /*let j = {
	"PWA_API":"https://mt.bankmuscat.com:6441/cc/",
	"GM_API":"https://mt.bankmuscat.com:6441/ocr/",
	"BASE_URL":"https://mt.bankmuscat.com:6441/",
	"HEARTBEAT_URL_1":"https://mt.bankmuscat.com:2443/corp/SessionValidate;",
	"HEARTBEAT_URL_2":"https://mt.bankmuscat.com:2443/corp/SessionValidate;"
}
*/
/*let j = {
    "PWA_API": "https://bom.labsls.com/",
    "GM_API": "https://fusion.gieom.com/",
    "BASE_URL": "http://pwabom.labsls.com/"
    }
console.log(j)

    let encrypted = window.CryptoJSAesJson.encrypt(j, sc_k)
    let encr_b64 = btoa(JSON.stringify(encrypted))
    console.log(encr_b64)*/
    /*let k = 'IntcImN0XCI6XCJjemxiOFErYUVobEZWQUoyejhKV25ObmM2YWlWeENsYzNNNUVjSXhVRzNWQ0JHUXFabitxTkRhQkpsOHBiVG1ONkh1MERsbkdBc1hYREJSd1FFNE5NbW8xb05rTGpjS3RoTzdjbyt5T2lnVFpSWmxPU2YzSFJrSGNnNlN3dG52dUZjTnF1RThTekF4S0J3bGIvMksyeFE9PVwiLFwiaXZcIjpcImU1MzMyMDQ2OWJlZjc0ZTQ0ZjI4YzBmZTlmY2NhZGM2XCIsXCJzXCI6XCIwZjk0NWQyNTYzZTNjZDZmXCJ9Ig==';
    let jsonToDcr = JSON.parse(atob(k))
    let dcrData = window.CryptoJSAesJson.decrypt(jsonToDcr, sc_k)
    console.log(dcrData)*/

    //getConfig()
}
function encrScript2Loaded(){
    //console.log('encrScript2Loaded')
    const script = document.createElement("script");
    script.async = true;

    if(getApiNameFromUrl(window.location.href) === 'api-tests') {
        script.src = "../assets/js/jsencrypt.min.js";
    } else {
        script.src = "assets/js/jsencrypt.min.js";
    }
    script.onload = () => encrScript3Loaded();
    document.body.appendChild(script);
}
function encScript1loaded(){
    //console.log('encScript1loaded')
    const script = document.createElement("script");
    script.async = true;

    if(getApiNameFromUrl(window.location.href) === 'api-tests') {
        script.src = "../assets/js/cryptojs-aes-format.js";
    } else {
        script.src = "assets/js/cryptojs-aes-format.js";
    }
    script.onload = () => encrScript2Loaded();
    document.body.appendChild(script);
}
function init(){
    const script = document.createElement("script");
    script.async = true;
    //script.src = "assets/js/cryptojs-aes.min.js";
    if(getApiNameFromUrl(window.location.href) === 'api-tests') {
        script.src = "../assets/js/cryptojs-aes.min.js";
    } else {
        script.src = "assets/js/cryptojs-aes.min.js";
    }
    script.onload = () => encScript1loaded();
    document.body.appendChild(script);
    //console.log('init')
}
function getPWAapi(){
    //return localStorage.getItem("BM_PWA_API")
    return kycStore.getState().KYCState.BM_PWA_API
}
function getGMapi(){
    //return localStorage.getItem("BM_GM_API")
    return kycStore.getState().KYCState.BM_GM_API
}


function updateAsNonSTP(token){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateAsNonSTP', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_ }})
}

function LivenessTestStatus(journey_id,doctype){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/livenessandidscanstatus', {
        "doctype": doctype
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})
}


function updateJourneyLevel(level, status, reason = ''){//"reason": reason
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateJourneyLevel', {
        "updlevel": level,
        "updstatus": status,
        "reason": reason
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})
}


function getConfig(){
    kycStore.getState().KYCState.osName = osName;
    kycStore.getState().KYCState.osVersion = osVersion;
    axios.get('config.json').then(config => {
        if(config.data !== undefined && config.data !== null){
            let jsonToDcr = JSON.parse(atob(config.data))
            let dcrData = window.CryptoJSAesJson.decrypt(jsonToDcr, sc_k)
            //console.log(dcrData)
            kycStore.getState().KYCState.BM_PWA_API = dcrData.PWA_API
            kycStore.getState().KYCState.BM_GM_API = dcrData.GM_API
            kycStore.getState().KYCState.BM_BASE_URL = dcrData.BASE_URL
            kycStore.getState().KYCState.BM_PT = dcrData.PT || 0
            kycStore.getState().KYCState.BM_HEARTBEAT_URL_1 = dcrData.HEARTBEAT_URL_1 || ''
            kycStore.getState().KYCState.BM_HEARTBEAT_URL_2 = dcrData.HEARTBEAT_URL_2 || ''
        }
    }).catch(err => {
        console.log(err)
    })
}

function testgetConfig(){
    kycStore.getState().KYCState.osName = osName;
    kycStore.getState().KYCState.osVersion = osVersion;
    axios.get('../config.json').then(config => {
        //console.log(config.data)
        if(config.data !== undefined && config.data !== null){
            let jsonToDcr = JSON.parse(atob(config.data));
            //console.log(jsonToDcr);
            let dcrData = window.CryptoJSAesJson.decrypt(jsonToDcr, sc_k)
            //console.log(dcrData)
            kycStore.getState().KYCState.BM_PWA_API = dcrData.PWA_API
            kycStore.getState().KYCState.BM_GM_API = dcrData.GM_API
            kycStore.getState().KYCState.BM_BASE_URL = dcrData.BASE_URL
            kycStore.getState().KYCState.BM_PT = dcrData.PT || 0
        }
    }).catch(err => {
        console.log(err)
    })
}

function getLivenessCheck(jid){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/getoptions', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}


function getLivenessTest(postData, isBlob){
    let ts_ = new Date().getTime()
    //let encr_data = encryptDataForOcr(postData, 'livenesscheck')
    let formdata = new FormData();
    formdata.append('isios', postData.isios);
    formdata.append('livevideo', postData.livevideo);
    let encr_data = livenessDataForOcr(formdata, 'livenesscheck')
    //console.log(encr_data)
    return axios.post( getGMapi()+'livenesscheck/', encr_data, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}

function customerIdScan(postData){
    //console.log(postData)
    let ts_ = new Date().getTime()
    if(getToken().pt === 0) {
        let encr_data = encryptDataForOcr(postData, 'uploadmultiple')
        return axios.post(getGMapi() + 'uploadmultiple/', encr_data, {headers: {"authorization": getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})
    } else {
        let formdata = new FormData();
        formdata.append('userid', getToken().journeyid);
        formdata.append('data', postData.data);
        return axios.post( getGMapi()+'uploadmultiple/', formdata)
    }
}

function processFile(postData, id, auth = ''){
    if(getToken().pt === 0) {

        let encr_data = encryptDataForOcr(postData, 'processfile' + id);
        let submitRes;
        let ts_ = new Date().getTime()
        if(auth === '') {
            submitRes = axios.post(getGMapi() + 'processfile/', encr_data, {headers: {"authorization": getToken().apitoken, uid: getToken().journeyid}})
        } else {
            submitRes = axios.post(getGMapi() + 'processfile/', encr_data, {headers: {"authorization": auth}})
        }
        //console.log(submitRes);
        return submitRes;
    } else {
        let formdata = new FormData();
        formdata.append('userid', getToken().journeyid);
        formdata.append('image', postData['image']);
        formdata.append('doctype', postData['doctype']);
        //console.log(formdata)
        let submitRes =  axios.post(getGMapi()+'processfile/', formdata)
        //console.log(submitRes);
        return submitRes;
    }
}


function getUploads(jid){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/getfrontdoc', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}


function getFacematchscore(doctype){
    let os = osName + ' ' + osVersion;
    let browser = browserName + ' ' + browserVersion;
    let ts_ = new Date().getTime()
    //console.log({scrutinydoctype: doctype, os: os, browser: browser})
    return axios.post( getPWAapi()+'pwa/facematch', {scrutinydoctype: doctype, os: os, browser: browser}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}} ) ;
    //return axios.post( getPWAapi()+'pwa/facematch', {scrutinydoctype: doctype}, { headers:  { "authorization":  getToken().apitoken}} ) ;
}


function updateJourneyId(token,journey_id){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/UpdateJourneyId', {
        "token": token
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})

}

function updatelivenessTestResult(journey_id,formObj){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/UserCamRes', {
        "formobj": formObj
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_ }})

}

function updateIdscanResult(journey_id,formObj){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/UserIdRes', {
        "formobj": formObj
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})

}

function updateFacematchResult(journey_id,formObj){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateFacematchResult', {
        "formobj": formObj
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})

}


function updateCustomerInfo(cid,formObj){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateCustomerInfo', {
            "formobj": formObj,
            "cid": cid
        }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}}
    )
}


function updateCustomerLIDInfo(cid,formObj){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateCustomerLIDInfo', {
        "formobj": formObj,
        "cid": cid
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})
}

function customerInfo(jid,cif){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/FetchAndUpdateCustomerDetails', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}} );
}
/* function fetchAndUpdateCustomerDetails(jid,cif){
     return axios.post(getPWAapi() + 'pwa/FetchAndUpdateCustomerDetails', {}, { headers:  { "authorization":  getToken().apitoken}} );
 }*/

function getcustomerInfo(jid,cif){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/FetchCustomerDetailsTbl', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}} );

}

function salaryInfo(cif, journeyid, client){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/fetchSalaryDetails',{}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}} );
}



function generateOTP(jid,cif){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/SendOTPSMS', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})

}

function verifyOTP(otp,cif,journeyid){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/VerifyOTP', {
        "otp": otp,
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})


}
function verifyOTPQRcode(otp,cif,journeyid){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/VerifyOTPQRcode', {
        "otp": otp
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})

}

function heartBeatSession(bway,jid){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/heartbeatsesssion', {
        "token": bway,
        "jsession_id": jid
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})
}
function updateNextLevel(token,level){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updatenextlevel', {
        "updlevel": level
    }, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})
}

function customerJourneyLevel(token){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/customerjourneylevel', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})
}
function serviceLog(cif, formObj){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/servicelog', {
        "formobj": formObj
    }, { headers:  { "authorization":  getToken().apitoken}});

}
function getListCountry(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listcountry', {},{ headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});

}
function getListMaritalStatus(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listmaritalstatus', {},{ headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function ListUnemployedStatus(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listunemployedstatuses', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function ListEmploymentStatus(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listemploymentstatus', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function listIncome(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listmonthlyincome', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function listPostalCode(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/postalcodelist', {},{ headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function getLivenessMaxRetries(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/livenessmaxretries', {},{ headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function getFaceMatchLimits(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/FaceMatchScore', {},{ headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function OCRThresholdAcceptedScore(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/OCRThresholdScore', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function getIDMaxRetries(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/scanidmaxretries', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function getOTPMaxRetries(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/generateotpmaxretries', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function updateCustomerOcrDetails(custid, formObj){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateCustomerOcrDetails',
        {
            "custid": custid,
            "formobj": formObj
        },
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function updateMaxOTPRetries(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateMaxOTPRetries',
        {},
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function updateSwitchedToMobile(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateSwitchedToMobile',
        {},
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function fetchSwitchedToMobile(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/fetchSwitchedToMobile',
        {},
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function switchedBackToDesktopRequest(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateSwitchedBackToDesktopRequest',
        {},
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function continueOnMobileRequest(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/continueOnMobileRequest',
        {},
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function switchedBacktoDesktop(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/fetchswitchedbacktodesktop',
        {},
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function continueOnDesktop(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/updateSwitchedBackToDesktop',
        {},
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function getOTPLimitMaxRetries(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/ValidateOTPLimitMaxRetries', {},
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}

function stringMatchScore(ocr, bank, doctype, cif, journeyid = '', client = ''){
    let os = osName + ' ' + osVersion;
    let browser = browserName + ' ' + browserVersion;
    let ts_ = new Date().getTime()

    return axios.post(getPWAapi() + 'pwa/stringMatchScore',
        {
            "array1": ocr,
            "array2": bank,
            "doctype": doctype,
            "os": os,
            "browser": browser
        },
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function fetchUploads(jid){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/fetchrekyc', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}

function createErrorLog(action, status, msg, cif = '', journeyid, client){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/createErrorLog',
        {
            "errorcode": status,
            "section": "PWA:" + action,
            "msg": msg
        },
        { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function createAccessLog(action, status, msg, cif = '', journeyid = '', client = ''){
    let ts_ = new Date().getTime()
    let postdata = {service : action, section : 'PWA', position : status, msg : msg}
    //console.log(getToken().apitoken)
    if(typeof postdata.msg === "object") {
        postdata.msg = JSON.stringify(postdata.msg)
    }
    if(getToken().apitoken)
        return axios.post(getPWAapi() + 'pwa/createaccesslog', postdata,{ headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}

function getLidSystemSettings(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/lidsystemsettings', {},{ headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}

function initiatejourney(token){
    let ts_ = new Date().getTime()
    let postdata = {token: token};
    return axios.post(getPWAapi() + 'pwa/initiatejourney',
        postdata,
        {headers: {"authorization": token, uid: getToken().journeyid, ts_: ts_}}
        //{headers: {"authorization": token, "Access-Control-Allow-Origin": "*", 'Content-Type': 'application/json'}}
        //{headers: {"Authorization": 'Bearer ' + token, "Accept": "application/json"}}
    );
}
function testApi(){
    let ts_ = new Date().getTime()
    // console.log('call testApi')
    let sample = {"name": "Bill", "place": "Singapore", "occ": "Engineer"}
    // let sample = {cif: getToken().apitoken, service : "action", section : "PWA", position : "status", msg : "msg", journeyid : getToken().journeyid, client : "IB"}
//console.log(sample)

    //sample = JSON.parse(sample)

    let otpRes =  axios.post(getPWAapi() + 'dob/testapi', sample, { headers:  { "authorization":  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VydHlwZSI6InVzZXIiLCJ1c2VybmFtZSI6Imd1ZXN0IiwiZXhwaXJ5IjoxNjUxNjMwMjQ5LCJjbGllbnQiOiJCT00iLCJyb2xlcyI6WyJ1c2VyIiwiYWRtaW4iXX0.62MJZJmW-FH5DssmAgwppa0JITf-YG4pEVhi4R1MKO2y1XbGKBgPZY3bVwQSeXNIyIpcTO8rnSUDaL8EN9DW5FmznINdSWHFpCfZ82UY-7cMb96SWQWnH1wl7eSWiAi4nbpRaJ4rRJEurgDkgI5deggw4EJhJQr71XSo1t5eTXMuVSbIn4arxjsnjJHaxxPsWUXNYFqv6HPAyAYPKEHHsm7iE7SrSWV_zfSg6FA21lbK5-PoHlNkYTS47Of5iSCGVCKFSYrNcyo1ZyERKX8eGTeJQmn7JQhdsOpcswdTzGzwmkv1YBTvx7eidG86foNvCqI6COkUW-rdHzjGliEGLw' }})
    //let otpRes =  axios.post(getPWAapi() + 'pwa/customerhandshake', sample, { headers:  { "authorization":  'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ1c2VydHlwZSI6InVzZXIiLCJ1c2VybmFtZSI6Imd1ZXN0IiwiZXhwaXJ5IjoxNjUxNjMwMjQ5LCJjbGllbnQiOiJCT00iLCJyb2xlcyI6WyJ1c2VyIiwiYWRtaW4iXX0.62MJZJmW-FH5DssmAgwppa0JITf-YG4pEVhi4R1MKO2y1XbGKBgPZY3bVwQSeXNIyIpcTO8rnSUDaL8EN9DW5FmznINdSWHFpCfZ82UY-7cMb96SWQWnH1wl7eSWiAi4nbpRaJ4rRJEurgDkgI5deggw4EJhJQr71XSo1t5eTXMuVSbIn4arxjsnjJHaxxPsWUXNYFqv6HPAyAYPKEHHsm7iE7SrSWV_zfSg6FA21lbK5-PoHlNkYTS47Of5iSCGVCKFSYrNcyo1ZyERKX8eGTeJQmn7JQhdsOpcswdTzGzwmkv1YBTvx7eidG86foNvCqI6COkUW-rdHzjGliEGLw'}})

    otpRes.then(otpRes => {
        //console.log(otpRes.data)

        // let decrypted = CryptoJSAesJson.decrypt(JSON.stringify(otpRes.data), KEY)
        // console.log(decrypted)

        // var decrypted = CryptoJS.AES.decrypt(otpRes.data, 'j32hg4p9sd8vfksbdjr293rksdbfsejhdfbsdkjfh');
        // // console.log(decrypted)
        // let asd = ''

        // try{
        //     asd = decrypted.toString(CryptoJS.enc.Utf8)

        // }
        // catch(err){
        //     // console.log(err)
        // }



    }).catch(otpRes => {
        // console.log(otpRes)

    })
}
function encryptDataForOcr(post, action){
    let ts_ = new Date().getTime()
    post.ts_ = ts_

    let key = getRandomAESKey(10)
    let iv = getRandomAESKey(10)
    let key_ = window.CryptoJS.enc.Utf8.parse(key);
    var iv_ = window.CryptoJS.enc.Utf8.parse(iv)
    var encrypted = window.CryptoJS.AES.encrypt( JSON.stringify( post), key_, { iv: iv_, mode: window.CryptoJS.mode.CBC});
    // console.log(encrypted );
    encrypted =encrypted.toString();
    //console.log('key', key)
    //console.log('iv', iv)
    // console.log(encrypted );

    let jsEncrypt = new window.JSEncrypt();
    jsEncrypt.setPublicKey(cs_rsa_k);
    let rsa_enc_cs_aes_k = jsEncrypt.encrypt(key);
    let cnt = 0;
    if(kycStore.getState().KYCState.apiTimestamp[action]) {
        cnt = 1;
        if(kycStore.getState().KYCState.apiTimestamp[action]['cnt']) {
            cnt = parseInt(kycStore.getState().KYCState.apiTimestamp[action]['cnt']) + 1;
        }
    }
    kycStore.getState().KYCState.apiTimestamp[action] = {ts_: ts_, k: key+'.'+iv, cnt: cnt}

    let str_to_server = encrypted + '.' + iv + '.' + rsa_enc_cs_aes_k
    // console.log(str_to_server);

    return str_to_server ;
}
function decryptDataFromOcr(res, action){
    let req_ts =  kycStore.getState().KYCState.apiTimestamp[action].ts_
    let aes_k_iv = kycStore.getState().KYCState.apiTimestamp[action].k

    var encrypted_s = res;

    var key_s = aes_k_iv.split('.')[0]
    let key_s_ = window.CryptoJS.enc.Utf8.parse(key_s);

    let iv_s = aes_k_iv.split('.')[1]
    var iv_s_ = window.CryptoJS.enc.Utf8.parse(iv_s)

    //console.log('key.iv', aes_k_iv)
    try {
        var decrypted =  window.CryptoJS.AES.decrypt(encrypted_s, key_s_, { iv: iv_s_, mode: window.CryptoJS.mode.CBC});
        decrypted = decrypted.toString(window.CryptoJS.enc.Utf8);
        //console.log(decrypted)
        return decrypted;
    } catch (err) {
        //console.log(err)
        //console.log(JSON.stringify(res))
        createAccessLog('Api response ocr', 'OCR response decrypt err - '+action, err.message + JSON.stringify(res), getToken().cif, getToken().journeyid, getToken().client)

        return err.message
    }

}
function livenessDataForOcr(formdata, action){
    let ts_ = new Date().getTime()
    // post.ts_ = ts_

    let key = getRandomAESKey(10)
    let iv = getRandomAESKey(10)
    let key_ = window.CryptoJS.enc.Utf8.parse(key);
    var iv_ = window.CryptoJS.enc.Utf8.parse(iv)
    //var encrypted = window.CryptoJS.AES.encrypt( JSON.stringify( post), key_, { iv: iv_, mode: window.CryptoJS.mode.CBC});
    // console.log(encrypted );
    //encrypted =encrypted.toString();
    //console.log('key', key)
    //console.log('iv', iv)
    // console.log(encrypted );

    let jsEncrypt = new window.JSEncrypt();
    jsEncrypt.setPublicKey(cs_rsa_k);
    let rsa_enc_cs_aes_k = jsEncrypt.encrypt(key);

    kycStore.getState().KYCState.apiTimestamp[action] = {ts_: ts_, k: key+'.'+iv}

    formdata.append('ts_', ts_);
    formdata.append('e', iv + '.' + rsa_enc_cs_aes_k);

    // if(isBlob === true) {
    //     post.iv = iv;
    //     post.rsa = rsa_enc_cs_aes_k;
    // } else {
    //     post.livevideo = post.livevideo + '.' + iv + '.' + rsa_enc_cs_aes_k
    // }
    // console.log(str_to_server);

    // return post ;
    return formdata ;
}
function facecaptureDataForOcr(formdata, action){
    let ts_ = new Date().getTime()
    // post.ts_ = ts_

    let key = getRandomAESKey(10)
    let iv = getRandomAESKey(10)
    let key_ = window.CryptoJS.enc.Utf8.parse(key);
    var iv_ = window.CryptoJS.enc.Utf8.parse(iv)

    let jsEncrypt = new window.JSEncrypt();
    jsEncrypt.setPublicKey(cs_rsa_k);
    let rsa_enc_cs_aes_k = jsEncrypt.encrypt(key);

    kycStore.getState().KYCState.apiTimestamp[action] = {ts_: ts_, k: key+'.'+iv}

    formdata.append('ts_', ts_);
    formdata.append('e', iv + '.' + rsa_enc_cs_aes_k);

    return formdata ;
}
function submitFaceCapture(imgarr){
    //interactions.appendDebug('Submit liveness facecapture images...')
    //interactions.appendDebug('Filesize - '+(Math.round(JSON.stringify(imgarr).length/1024))+' KB')
    //submit first image to uploadmultiple
    let strjson = '{"liveimage" : "' + imgarr[0]+'" ' + '}' ;
    let postData = {data: strjson}
    if(getToken().pt === 0) {
        let ts_ = new Date().getTime()
        let encr_data = encryptDataForOcr(postData, 'uploadmultiple')
        axios.post(getGMapi() + 'uploadmultiple/', encr_data, {headers: {"authorization": getToken().apitoken, uid: getToken().journeyid, ts_: ts_}})

    } else {
        let formdata = new FormData();
        formdata.append('userid', getToken().journeyid);
        formdata.append('data', postData.data);
        axios.post(getGMapi() + 'uploadmultiple/', formdata)
    }


    let b = new Blob(imgarr)
    //console.log(b)
    let formdata = new FormData();
    formdata.append('images', b);

    // let postData = {images: JSON.stringify(imgarr)}

    let postData_ = facecaptureDataForOcr(formdata, 'fmfacepics')

    // console.log(postData_)

    let st = new Date().getTime()
    let ts_ = new Date().getTime()
    kycStore.getState().KYCState.livenessFaceCaptureSendTime = st
    //interactions.appendDebug('Submit liveness facecapture at '+st)
    //console.log('Submit liveness facecapture at', st)
    if(getToken().pt === 0) {
        createAccessLog('Api request', 'fmfacepics API request', imgarr.length, getToken().cif, getToken().journeyid, getToken().client)

        let vRes = axios.post(getGMapi() + 'fmfacepics/', postData_, {
            headers: {
                "authorization": getToken().apitoken, uid: getToken().journeyid, ts_: ts_
            }
        });

        vRes.then(vidRes => {
            //console.log(vidRes.data)
            let encr_data = decryptDataFromOcr(vidRes.data, 'fmfacepics')
            encr_data = JSON.parse(encr_data);
            //console.log(encr_data)

            createAccessLog('Api response', 'Face capture', JSON.stringify(encr_data), getToken().cif, getToken().journeyid, getToken().client)

            let rt = new Date().getTime()
            kycStore.getState().KYCState.livenessFaceCaptureReceiveTime = rt
            let idtype = getToken().idtype;
            let doctype = '';
            if( idtype === "National" ){
                doctype = 'OmanNationalIdFront';
            } else if(idtype === "Resident"){
                doctype = 'OmanResidentIdFront';
            } else {
                doctype = 'PassportFront'
            }
            createAccessLog('Face capture', 'Face capture response ' + encr_data.message, ' Facematch doctype: ' + doctype, getToken().cif, getToken().journeyid, getToken().client)

            if (encr_data.message === 'Success') {
                kycStore.getState().KYCState.livenessFaceCaptureResponse = true
                let startTime = new Date().getTime();
                createAccessLog('Api facematch', 'Calling facematch', doctype, getToken().cif, getToken().journeyid, getToken().client)
                let fmatchScores = getFacematchscore(doctype);
                fmatchScores.then(fmatchScore => {

                    let time = (new Date().getTime() - startTime);
                    if(fmatchScore.data.message !== undefined){
                        //console.log(fmatchScore.data)
                        kycStore.dispatch({
                            type: 'FM_SCORE',
                            data: fmatchScore.data
                        })

                    }
                }).catch(fmatchScore => {
                    kycStore.dispatch({
                        type: 'RESPONSE_ERROR',
                        status: true
                    })

                });

            } else {
                kycStore.getState().KYCState.livenessFaceCaptureResponse = encr_data.message
            }


        }).catch(vidRes => {
            //console.log(vidRes)
            kycStore.getState().KYCState.livenessFaceCaptureResponse = false

        })
    } else {
        let vRes =   axios.post( getGMapi()+'fmfacepics/');
        kycStore.getState().KYCState.livenessFaceCaptureResponse = true
        let fmatchScores = getFacematchscore(kycStore.getState().KYCState.doctype);
        fmatchScores.then(fmatchScore => {

            if(fmatchScore.data.message !== undefined){
                kycStore.getState().KYCState.facematchdata = fmatchScore.data
            }

        });
    }
}

function listPEP(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listPEP', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function listRelations(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listRelationships', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function listEmploymentSectors(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listEmploymentSector', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function isonline(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/isonline', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function listSelfEmploymentProofCategory(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listSelfEmploymentProofCategory', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function listAddressProofCategory(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listAddressProofCategory', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function listEmploymentProofCategory(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/listEmploymentProofCategory', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function getAllMasters(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/getAllMasters', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function redoJourney(){
    let ts_ = new Date().getTime()
    return axios.post(getPWAapi() + 'pwa/redoJourney', {}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function uploadMultipleTest1(){
    let ts_ = new Date().getTime()
    //console.log(getToken().apitoken)
    return axios.get(getPWAapi() + 'pwa/uploadMultipleTest1', { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}
function updateRejectedReason(reason){
    let ts_ = new Date().getTime()
    //console.log(getToken().apitoken)
    return axios.post(getPWAapi() + 'pwa/capturefailurereason', {reason: reason}, { headers:  { "authorization":  getToken().apitoken, uid: getToken().journeyid, ts_: ts_}});
}

/*function heartBeatSession(bway,jid){
    if(kycStore.getState().KYCState.BM_BASE_URL === 'http://localhost:30000/' || kycStore.getState().KYCState.BM_BASE_URL === 'http://pwabom.labsls.com/') {
        return {status: 'success', session: 'active'}
    } else {
        let hb1 = heartBeatSession1(bway, jid);
        hb1.then(heartbeat1 => {
            console.log(heartbeat1);
            if(heartbeat1 === 'SUCCESS') {
                console.log('*1')
                return {status: 'success', session: 'active'}
            } else {
                let hb2 = heartBeatSession2(bway, jid);
                console.log(hb2)
                hb2.then(heartbeat2 => {
                    if(heartbeat2 === 'SUCCESS') {
                        console.log('*2')
                        return {status: 'success', session: 'active'}
                    } else {
                        console.log('*3')
                        return {status: 'success', session: 'inactive'}
                    }
                })
            }
        }).catch(heartbeat1 => {
            console.log(heartbeat1);
            let hb2 = heartBeatSession2(bway, jid);
            console.log(hb2)
            hb2.then(heartbeat2 => {
                if(heartbeat2 === 'SUCCESS') {
                    console.log('*4')
                    return {status: 'success', session: 'active'}
                } else {
                    console.log('*5')
                    return {status: 'success', session: 'inactive'}
                }
            }).catch(heartbeat2 => {
                console.log('*6')
                return {status: 'success', session: 'inactive'}
            })
        });




    }

}*/

function heartBeatSession1(bway,jid){
    let ts_ = new Date().getTime()
    let hburl1 = kycStore.getState().KYCState.BM_HEARTBEAT_URL_1;
    return axios.get(hburl1 + 'jsessionid=' + jid + '?bwayparam=' + bway, {});
    /*hb1.then(heartbeat1 => {
        console.log(heartbeat1);
        if(heartbeat1 === 'SUCCESS') {
            return true;
        } else {
            return false
        }
    }).catch(heartbeat1 => {
        console.log(heartbeat1);
        return false
    });*/
}
function heartBeatSession2(bway,jid){
    let hburl2 = kycStore.getState().KYCState.BM_HEARTBEAT_URL_2;
    return  axios.get(hburl2 + 'jsessionid=' + jid + '?bwayparam=' + bway, {});
    /*hb2.then(heartbeat2 => {
        console.log(heartbeat2);
        if(heartbeat2 === 'SUCCESS') {
            return true;
        } else {
            return false
        }
    }).catch(heartbeat2 => {
        console.log(heartbeat2);
        return false
    });*/
}