import React from 'react';

class RightGesture extends React.Component{

	render() {
		return (
			<div className="gesture-ins-anim-bg"  key={new Date().getTime()}>
				<img key={new Date().getTime()} src={`./assets/images/Head-TR-5x-${this.props.gestureCnt}.gif`} alt=""/>
			</div>
			)
		}
	}



export default RightGesture;