import React, { useReducer} from 'react';
import Switch from '../components/Switch';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';



/*function ListItem(props) {
    return  <option>{props.option}</option> ;
}*/


const Editform2 = (props) => {

    const lang = getToken().lang;
    const [inputFormValues, setInputFormValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            address: props.inputValues.address,
            po_box: props.inputValues.po_box,
            landmark: props.inputValues.landmark,
            house: props.inputValues.house,
            building: props.inputValues.building,
            way: props.inputValues.way ,
            city: props.inputValues.city,
            wilayat : props.inputValues.wilayat,
            picode : props.inputValues.picode,
        }
    );
    /*const updateDetails = (curstep) =>{
        //console.log("update step1: "+curstep) ;
    }*/
    const handleDropDown = (name, list) =>{
        props.openSearchList(name, list)
    }
    const handleInput = (e) =>{
        let error = 0;

        let value = e.target.value;
        switch (e.target.name) {
            case 'address':
                if(value !== '' && (value.length > 35  || !value.match(/^[A-Za-z0-9/?:().,'+ ]+$/))){  error++; return false; }
                break;
            case 'landmark':
                if(value !== '' && (value.length > 65  || !value.match(/^[A-Za-z0-9 ]+$/))){  error++; return false; }
                break;
            case 'house':
                if(value !== '' && (!/^[0-9]+$/.test(value) || value.length > 9 )){  error++; return false; }
                break;
            case 'po_box':
                //value = value.replace(/\D/g,'')
                if(value !== '' && (!/^[0-9]+$/.test(value) || value.length > 5 )){  error++; return false; }
                break;
            case 'building':
                //value = value.replace(/\D/g,'')
                if(value !== '' && (value.length > 35  || !value.match(/^[A-Za-z0-9 ]+$/))){  error++; return false; }
                break;
            case 'way':
                if(value !== '' && (!/^[0-9]+$/.test(value) || value.length > 9 )){  error++; return false; }
                break;
            case 'city':
                if(value !== '' && (value.length > 35  || !value.match(/^[A-Za-z0-9/?:().,'+ ]+$/))){  error++; return false; }
                break;
            case 'wilayat':
                if(value !== '' && (value.length > 35  || !value.match(/^[A-Za-z0-9 ]+$/))){  error++; return false; }
                break;
            case 'picode':
                if(value !== '' && (!/^[0-9]+$/.test(value) || value.length > 15 )){  error++; return false; }
                break;
            default:
                break;

        }
        if(error === 0) {
            setInputFormValues({[e.target.name]: e.target.value}) ;
            props.onChange(e.target.name,  e.target.value) ;
            //console.log(e.target.name+"  "+e.target.value) ;
        }
    }
//console.log(getToken())
    return (
        <>
            <div className="formBlock">
                <h3>{props.boxtitle}</h3>
                {(getToken().idtype === 'Passport' || getToken().idtype === 'Non-Resident') &&
                <h4 className="infoText">{data[lang]['noneresidentnote']}</h4>
                }
                { props.section && props.section.map(item => {
                    return (
                        <>

                            { item.inputtype === "text" ? (
                                <>
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>
                                    { item.fieldname === "address" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.address}    onChange={handleInput} className="formBlock__input" autocomplete="off"/>
                                    ) :  '' }
                                    { item.fieldname === "po_box" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.po_box}    onChange={handleInput} className="formBlock__input" autocomplete="off"/>
                                    ) :  '' }
                                    { item.fieldname === "landmark" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.landmark}    onChange={handleInput} className="formBlock__input" autocomplete="off"/>
                                    ) :  '' }
                                    { item.fieldname === "house" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.house}    onChange={handleInput} className="formBlock__input" autocomplete="off"/>
                                    ) :  '' }
                                    { item.fieldname === "building" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.building}   onChange={handleInput} className="formBlock__input" autocomplete="off"/>
                                    ) :  '' }
                                    { item.fieldname === "way" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.way}   onChange={handleInput}  className="formBlock__input" autocomplete="off"/>
                                    ) :  '' }
                                    { item.fieldname === "city" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.city}   onChange={handleInput}  className="formBlock__input" autocomplete="off"/>
                                    ) :  '' }
                                    { item.fieldname === "wilayat" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.wilayat}   onChange={handleInput}  className="formBlock__input" autocomplete="off"/>
                                    ) :  '' }
                                    { item.fieldname === "picode" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.picode}   onChange={handleInput}  className="formBlock__input" autocomplete="off" disabled={item.readonly}/>
                                    ) :  '' }

                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }

                            { item.inputtype === "select" ? (
                                <>
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>
                                    <select type="text"    name={item.fieldname}  onChange={handleInput}  className="formBlock__input formBlock__select">
                                        <option value="" >{data[lang]['select']}</option>
                                        { item.selectvalues &&  item.selectvalues.map(selectlabel => {
                                            return( <option  value={selectlabel.value} selected={selectlabel.value === item.value ? 'selected' : "" } > {selectlabel.option}</option>)

                                        })}
                                    </select>
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }
                            { item.inputtype === "searchlist" ? (
                                <>
                                <fieldset  className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>

                                    <div name={item.fieldname} list={item.fieldname} className="formBlock__input formBlock__select" onClick={(e) => handleDropDown(item.fieldname, item.selectvalues)}>{item.value}</div>
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }
                            { item.inputtype === "switch" ? (
                                <div className="splitBox splitBox--mBMd">
                                    <div className="splitBox__w80Out">
                                            <label className="textLabel textLabel--type2">{item.label}</label>
                                    </div>
                                    <div className="splitBox__w80">
                                        <Switch className="active"  values={item.selectvalues} selectedvalue={item.value} />
                                    </div>
                                </div>

                            ) : ''}

                        </>
                    )
                })}
                <div className="flexCC mT15">
                    <span className="btn btn--primaryG btn--capsule"   onClick={() => props.closeModal(2)} >{data[lang]['close']}</span>
                </div>

            </div>
        </>
    )
}

export default Editform2
