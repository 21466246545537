import React, {useEffect, useState} from 'react'

import Webcam from 'react-webcam'
import { useHistory } from 'react-router-dom';
import imageCompression from "browser-image-compression";
import Header from "../components/Header";
import {getToken} from "../utilities/AuthService";
//import Lottie from 'react-lottie';
import closeImage from "../assets/images/cancel-new.svg";
import thankyouImage from '../assets/images/Success_Icon.svg'
import {updateReason} from "../actions/Authorization"

import {

    processFileOCR,
    processFileOCR2,
    updateIdscanResult,
    switchedBackToDesktopRequest,
    createAccessLog,
    continueOnMobileRequest,
    fetchUploads, customerAddnlDocsUpload, stringMatchScore,
    fetchSwitchedToMobile
} from "../actions/Authorization";
import {useDispatch, useSelector} from "react-redux";
import {isAndroid} from 'react-device-detect';
import data from '../constants/data.json';
import Loader from "./Loader";
import Popup from "./Popup";
import {PWA_JOURNEY_STEPS} from "../constants";
import Logo from "../assets/images/BM_logo.png";

import {Buffer} from "buffer";
import {kycStore} from "../reducers/KYCState";


const version = 2;
const isPassportBack = true;
/*let videoConstraints = {
    height: 720, //set pic resolution
    width: 1280, //set pic resolution
    facingMode: "environment" //use back camera
    //   facingMode: "user" //use front camera
};*/

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const IdScan = (props) => {

    const lang = getToken().lang;
    const cif = getToken().cif;
    const livenessstatus = getToken().livenessstatus;
    const ocr_attempts = JSON.parse(getToken().ocr_attempts);
    const [url, setUrl] = useState(null);
    const [newZipPic, setNewZipPic] = useState(null);
    const [URLArray, setURLArray] = useState([]);
    const [URLSelectedArray, setURLSelectedArray] = useState([]);
    const [scanResult, setScanResult] = useState({});
    const [frontPage, setFrontPage] = useState(false);
    const [backPage, setBackPage] = useState(false);
    const [finishcapture, setFinishcapture] = useState(false);
    const [reload, setReload] = useState(false);
    const [journeyid, setJourneyid] = useState(getToken().journeyid);
    const [idname, setIdname] = useState('');
    const [idScanStsMsg, setIdScanStsMsg] = useState('');
    const [idScanData, setIdScanData] = useState('');
    const [idScanSts, setIdScanSts] = useState('');
    const [idScanErrorType, setidScanErrorType] = useState(0);
    const [idtype, setIdtype] = useState(getToken().idtype);
    const [maxPage, setMaxPage] = useState(2);
    const [pageScanned, setPageScanned] = useState(0);
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [docLabels, setDocLabels] = useState("");
    const [cardSubmitted, setCardSubmitted] = useState(false);
    const [cardSubmittedMsg, setCardSubmittedMsg] = useState("");
    const [attempts, setAttempts] = React.useState(ocr_attempts.idscan_attempts ? parseInt(ocr_attempts.idscan_attempts) : 0);
    const [uploaddata, setUploaddata] = useState([]);
    const [file, setFile] = useState('');
    const [maxretries, setMaxretries] = React.useState(getToken().scanidmaxretries);
    const [loader, setLoader] = React.useState(false);
    const [cameraLoaded, setcameraLoaded] = React.useState(false);

    const [closer, setCloser] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [client] = useState(getToken().client);
    const [extractFront, setExtractFront] = useState({});
    const [extractBack, setExtractBack] = useState({});
    const [maxretriesMsg, setMaxretriesMsg] = React.useState('');
    const [showInstructions, setShowInstructions] = React.useState(true);
    const [showInstructionsHead, setShowInstructionsHead] = React.useState(false);
    const [cameraReady, setCameraReady] = useState(false);
    const [picStatus, setPicStatus] = useState('scan');
    const [picScanned, setPicScanned] = useState(0);
    const [fetch, setFetch] = useState(false);
    const [optionToMobile, setOptionToMobile] = React.useState(0);
    const [optionToDesktop, setOptionToDesktop] = React.useState(0);
    const webcamRef2 = React.createRef();

    const [redirectDesktop, setRedirectDesktop] = React.useState(false);
    const [winWid, setWinWid] =  React.useState(document.documentElement.clientWidth);
    const [winHgt, setWinHgt] =  React.useState(document.documentElement.clientHeight);
    const [showPhoneRotateAnim, setshowPhoneRotateAnim] = useState(false);
    const [showCardSample, setshowCardSample] = useState(true);
    const [backCameras, setbackCameras] = useState([]);
    const [detectCameras, setdetectCameras] = useState(false);
    const [selectedCameraIdx, setselectedCameraIdx] = useState(0);
    const [showCamera, setshowCamera] = useState(0);
    const [imgLoaded, setimgLoaded] = useState(false);
    const [brightness, setbrightness] = useState(255);
    const [doCheckBrightness, setdoCheckBrightness] = useState(false);
    const [showCardIco, setshowCardIco] = useState(false);
    const [showCardPreview, setshowCardPreview] = useState(false);

    let history = useHistory();
    const dispatch = useDispatch();
    const uploadResponsedata = useSelector(state => state.AppReducer.uploadResponsedata ) ;
    const processFileOCRResp = useSelector(state => state.AppReducer.processFileOCRResp  ) ;
    const processFileOCRBackResp = useSelector(state => state.AppReducer.processFileOCRBackResp  ) ;
    let fetchUploadsData = useSelector(state => state.AppReducer.fetchUploadsData  ) ;

    const swichToMobile = useSelector(state => state.AppReducer.swichToMobile  ) ;
    const swichToDesktop = useSelector(state => state.AppReducer.swichToDesktop  ) ;
    const continueOnMobile = useSelector(state => state.AppReducer.continueOnMobile  ) ;
    const [btnClick, setBtnClick] = React.useState('');

    const toggleInstructions =()=> {
        setShowInstructions(!showInstructions);
    }
    const hidePhoneRotAnim = () => {
        setshowPhoneRotateAnim(false)
        setshowCardSample(true)
    }
    const checkConfig = () => {
        if(getToken().BM_PWA_API === ''){
            setTimeout(checkConfig, 100)
        }
    }
    const updateDimensions = () => {
        setWinWid(document.documentElement.clientWidth);
        setWinHgt(document.documentElement.clientHeight);
    }
    const updateRejectedReason = (reason) =>{
        //console.log(reason) ;
        dispatch(updateReason(reason)) ;
    }
    const changeCamera = () => {
        //const tracks = webcamRef2.current.stream.getTracks();
        //tracks.forEach(track => track.stop());
        // console.log(this.state.selectedCameraIdx, this.state.backCameras.length)
        //console.log(backCameras.length)
        if(backCameras.length > 1){
            let selidx
            if(selectedCameraIdx === 0){
                selidx = backCameras.length-1
            }
            else{
                selidx = selectedCameraIdx-1
            }
            //console.log(selidx)
            //dispatch(createAccessLog('IdScan', 'changeCamera', 'Selecting camera - ' + selidx, cif));
            setselectedCameraIdx(selidx)
        }
    }
    function getLegalIdNumber() {
        let legalId = '';
        let customerdata = getToken().custinfo;
        let legalIdType = getToken().legalIdType;
        if(customerdata.LglDocDtl !== undefined){
            let lglDoc  = customerdata.LglDocDtl  ;



            if(Array.isArray(lglDoc)) {
                lglDoc.map((docs, indx) => {
                    if (docs.BMLglDocName === legalIdType) {
                        legalId = docs.LegalDocId;
                    }
                });
            } else {
                if(lglDoc.BMLglDocName === legalIdType ){
                    legalId = lglDoc.LegalDocId ;
                }
            }
        }
        return legalId;
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        if(livenessstatus === 'OFF') {
            dispatch(fetchSwitchedToMobile());
        }
        //dispatch(createAccessLog('IdScan' , 'Launched', 'ID Scan Page Launched',cif));
        //back camera check
        let cameraArr = []
        //if(isAndroid){

            navigator.mediaDevices.enumerateDevices().then(function(devices) {
                //console.log(devices)
                for(var i=0;i<devices.length;i++){
                    //dispatch(createAccessLog('Camera device ' , devices[i].kind, devices[i].label.indexOf('back'),cif));
                    if(devices[i].kind === "videoinput"){// && devices[i].label.indexOf('back') > -1){
                        cameraArr.push(devices[i])
                    }

                }
                //console.log(cameraArr)
                //console.log(cameraArr.length-1)
                setbackCameras(cameraArr);
                setdetectCameras(true)
                setselectedCameraIdx(cameraArr.length-1)
                setTimeout(setshowCamera(1), 1000);
                //this.props.appendLog('Back cameras available - ' + JSON.stringify(cameraArr))
                //dispatch(createAccessLog('Back cameras available' , cameraArr.length, JSON.stringify(cameraArr),cif));

            }.bind(this))

        /*}
        else{
            setdetectCameras(true)
            setshowCamera(1)
        }*/
    }, []);

    useEffect(() => {
        const isIbChannel = (client === 'IB');
        if(swichToMobile !== undefined && swichToMobile === '1'   && isIbChannel && livenessstatus === 'OFF'){
            setOptionToMobile(swichToMobile) ;
        }
    },[swichToMobile]);

    useEffect(() => {
        const isIbChannel = (client === 'IB');
        if(swichToDesktop !== undefined && parseInt(swichToDesktop) === 1  && isIbChannel && btnClick === 'nextToDesk' && livenessstatus === 'OFF'){
            setOptionToDesktop(swichToDesktop) ;

            setTimeout(redirectToHome, 5000)
        }
    },[swichToDesktop]);
    useEffect(() => {
        if(continueOnMobile !== undefined && parseInt(continueOnMobile) === 1  && btnClick === 'nextToMob'  && livenessstatus === 'OFF'){
            dispatch(createAccessLog('ProceedOnMobile' , 'Finished', 'Journey continued on mobile',cif));
            history.push(PWA_JOURNEY_STEPS[3]);
        }
    },[continueOnMobile]);

    const onBrightnessCheckImgLoad = (eve) => {
        let img = eve.target

        // create canvas
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;

        var ctx = canvas.getContext("2d");
        ctx.drawImage(img,0,0);

        var imageData = ctx.getImageData(0,0,canvas.width,canvas.height);
        var data = imageData.data;
        var r,g,b,avg;
        var colorSum = 0;

        for(var x = 0, len = data.length; x < len; x+=4) {
            r = data[x];
            g = data[x+1];
            b = data[x+2];

            avg = Math.floor((r+g+b)/3);
            colorSum += avg;
        }

        var brightness = Math.floor(colorSum / (img.width*img.height));
         //console.log('brightness - ',brightness)
        setbrightness(brightness)

        document.body.removeChild(img);


        setTimeout(checkBrightness, 1000)
    }
    const showCardIcon = () => {
        if(document.getElementById('card_preview') !== null){
            document.getElementById('card_preview').style.opacity = 0
        }

        setTimeout(function(){
            setshowCardPreview(false)
            setshowCardIco(true)
        }, 700)
    }
    const showCardPreviews = () => {
        setshowCardPreview(true);
        setshowCardIco(false);


        setTimeout(function(){
            if(document.getElementById('card_preview') !== null){
                document.getElementById('card_preview').style.opacity = 1
            }
        }, 300)


        setTimeout(showCardIcon, 2300)
    }
    const checkBrightness = () => {
        //console.log('checkBrightness' + doCheckBrightness)
        if(doCheckBrightness === false){
           // return
        }
        //console.log(webcamRef2.current)
        if(webcamRef2.current === null){
            return
        }

        let imgdata_b = webcamRef2.current.getScreenshot(300, 530)

        var img = document.createElement("img");
        img.src = imgdata_b;
        // img.style.position = 'absolute'
        // img.style.top = '0px'
        // img.style.zIndex = 20
        // img.style.width = '100%'
        //img.style.display = "none";
        document.body.appendChild(img);

        img.onload = onBrightnessCheckImgLoad

    }

    const initCheckBrightness = () => {
       // console.log('initCheckBrightness')
        setdoCheckBrightness(true)
        setTimeout(showCardPreviews, 500)
        setTimeout(checkBrightness, 500)
    }

    useEffect(() => {
        checkBrightness();
    }, [webcamRef2]);

    useEffect(() => {

        if( processFileOCRResp !== undefined && processFileOCRResp.message !== undefined){
            let errortype = 0;
            let error = 0;
            let errorMsg = '';
            let errorMsgEn = '';
            const result = processFileOCRResp.result;
            // console.log(result);
            // console.log(result.message);
            //console.log(processFileOCRResp.message);
            if (processFileOCRResp.message.toLowerCase() === "success") {
                //dispatch(createAccessLog('Verifying ID Card front' , 'Completed', 'Processing first file completed',cif));
                //setCardSubmittedMsg('50% Completed')
                /*if (result.error && result.error === 1) {
                    setShowInstructionsHead(true)
                    error = 1;
                    errorMsg = data[lang]['extractingErrorMessage1'].replace(new RegExp('{idname}', 'g'), data[lang][idname]);
                    dispatch(createAccessLog('Verifying ID Card front' , 'Error', 'Extracting fields missing',cif));
                }*/
                if (result.hasOwnProperty('DOES NOT MATCH GIVEN TYPE - Detected Document Type')) {
                    setShowInstructionsHead(false)
                    error = 1;
                    errortype = 1;
                    errorMsg = data[lang]['cardSubmitFailureMessage'].replace(new RegExp('{idname}', 'g'), data[lang][idname]);
                    errorMsgEn = data['En']['cardSubmitFailureMessage'].replace(new RegExp('{idname}', 'g'), data['En'][idname]);
                    dispatch(createAccessLog('Verifying ID Card front' , 'Error', 'DOES NOT MATCH GIVEN TYPE - Detected Document Type',cif));
                }

            }
            else if (processFileOCRResp.message === "Detected as Black and White Image") {
                setShowInstructionsHead(false)
                error = 1;
                errorMsg = data[lang]['extractingErrorMessage3'].replace(new RegExp('{idname}', 'g'), data[lang][idname]);
                errorMsgEn = data['En']['extractingErrorMessage3'].replace(new RegExp('{idname}', 'g'), data['En'][idname]);
                dispatch(createAccessLog('Verifying ID Card front' , 'Error', 'Detected as Black and White Image',cif));
            }
            else if (processFileOCRResp.message === "Failure") {
                setShowInstructionsHead(false)
                dispatch(createAccessLog('Verifying ID Card front' , 'Error', 'Processing front failed',cif));
                error = 2;
                errorMsg = data[lang]['unabletocommunicate'];
                errorMsgEn = data['En']['unabletocommunicate'];
            } else  if (processFileOCRResp.message !== "" && processFileOCRResp.result && Object.keys(processFileOCRResp.result).length === 0){
                setShowInstructionsHead(true)
                error = 1;
                errorMsg = data[lang]['extractingErrorMessage1'].replace(new RegExp('{idname}', 'g'), data[lang][idname]);//processFileOCRResp.message;
                errorMsgEn = data['En']['extractingErrorMessage1'].replace(new RegExp('{idname}', 'g'), data['En'][idname]);//processFileOCRResp.message;
                dispatch(createAccessLog('Verifying ID Card front' , 'Error', processFileOCRResp.message,cif));
            }
            //console.log('###')
            setExtractFront({
                status: 1,
                errortype: errortype,
                error: error,
                result: result,
                message: errorMsg,
                messageEn: errorMsgEn
            });

            //console.log(extractFront)

        }

    },[processFileOCRResp]);

    useEffect(() => {//console.log(processFileOCRBackResp);
        if( processFileOCRBackResp !== undefined && processFileOCRBackResp.message !== undefined){
            let error = 0;
            let errortype = 0;
            let errorMsg = '';
            let errorMsgEn = '';
            const result = processFileOCRBackResp.result;
            // console.log(result);
            //  console.log(result.message);
            //console.log(processFileOCRResp.message);
            if (processFileOCRBackResp.message.toLowerCase() === "success") {
                //dispatch(createAccessLog('Verifying ID Card back' , 'Completed', 'Processing second file completed',cif));
                /*if (result.error && result.error === 1) {
                    setShowInstructionsHead(true)
                    error = 1;
                    errorMsg = data[lang]['extractingErrorMessage1'].replace(new RegExp('{idname}', 'g'), data[lang][idname]);
                    dispatch(createAccessLog('Verifying ID Card back: ' , 'Error', 'Extracting fields missing',cif));
                }*/
                if (result.hasOwnProperty('DOES NOT MATCH GIVEN TYPE - Detected Document Type')) {
                    setShowInstructionsHead(false)
                    error = 1;
                    errortype = 1;
                    errorMsg = data[lang]['cardSubmitFailureMessage'].replace(new RegExp('{idname}', 'g'), data[lang][idname]);
                    errorMsgEn = data['En']['cardSubmitFailureMessage'].replace(new RegExp('{idname}', 'g'), data['En'][idname]);
                    dispatch(createAccessLog('Verifying ID Card back: ' , 'Error', 'DOES NOT MATCH GIVEN TYPE - Detected Document Type',cif));
                }

            }
            else if (processFileOCRResp.message === "Detected as Black and White Image") {
                setShowInstructionsHead(false)
                error = 1;
                errorMsg = data[lang]['extractingErrorMessage3'].replace(new RegExp('{idname}', 'g'), data[lang][idname]);
                errorMsgEn = data['En']['extractingErrorMessage3'].replace(new RegExp('{idname}', 'g'), data['En'][idname]);
                dispatch(createAccessLog('Verifying ID Card back: ' , 'Error', 'Detected as Black and White Image',cif));
            }
            else if (processFileOCRBackResp.message.toLowerCase() === "failure") {
                setShowInstructionsHead(false)
                error = 2;
                errorMsg = data[lang]['unabletocommunicate']
                errorMsgEn = data['En']['unabletocommunicate']
                dispatch(createAccessLog('Verifying ID Card back: ' , 'Error', 'Processing second file failed',cif));
            } else  if (processFileOCRBackResp.message !== "" && processFileOCRBackResp.result && Object.keys(processFileOCRBackResp.result).length === 0){
                setShowInstructionsHead(true)
                error = 1;
                errorMsg = data[lang]['extractingErrorMessage1'].replace(new RegExp('{idname}', 'g'), data[lang][idname]);//processFileOCRBackResp.message;
                errorMsgEn = data['En']['extractingErrorMessage1'].replace(new RegExp('{idname}', 'g'), data['En'][idname]);//processFileOCRBackResp.message;
                dispatch(createAccessLog('Verifying ID Card back: ' , 'Error', processFileOCRBackResp.message,cif));
            }
            setExtractBack({
                status: 1,
                error: error,
                errortype: errortype,
                result: result,
                message: errorMsg,
                messageEn: errorMsgEn
            });


        }


    }, [processFileOCRBackResp]);
    useEffect(() => {
        //console.log(extractFront)
        //console.log(extractBack)
        if( extractFront !== undefined && extractFront.status === 1 && extractBack !== undefined && extractBack.status === 1){
            let error = 0;
            let errortype = 0;
            let result = {};
            let errorMsg = '';
            let errorMsgEn = '';
            if(extractFront.error !== 0) {
                error = extractFront.error;
                errortype = extractFront.errortype;
                result = extractFront.result;
                errorMsg = extractFront.message;
                errorMsgEn = extractFront.messageEn;
            }
            if(extractFront.error === 0 && extractBack.error !== 0) {
                error = extractBack.error;
                errortype = extractBack.errortype;
                result = extractBack.result;
                errorMsg = extractBack.message;
                errorMsgEn = extractBack.messageEn;
            }
            if (error === 0) {

                setScanResult(result)
                //console.log('Fetch the cards uploaded...')
                //dispatch(createAccessLog('Fetch API is calling' , 'Started', 'Fetch the details of the cards uploaded',cif));
                setCardSubmittedMsg(data[lang]['processingfiles'])
                dispatch(fetchUploads(journeyid)) ;

            }
            if (error === 1) {
                setLoader(false)
                setIdScanSts("Failure");
                setidScanErrorType(errortype);
                setIdScanStsMsg(errorMsg);
                console.log('1')
                updateRejectedReason(errorMsgEn)

                var resp = {
                    "data": result,
                    "status": "Failure",
                    "count": attempts
                }

               // dispatch(createAccessLog('ID Scan Check' , 'Error', 'Update ID scan result to backend - UpdateIdscanResult',cif));
                updateIdscanProgress(resp);

                //console.log(maxretries +'>'+ attempts)
                if (maxretries > attempts) {
                    //dispatch(createAccessLog('ID Scan Check Max Retries' , 'Success', 'Maxtries not reached',cif));

                    setLoader(false)
                    setIdScanSts("Failure");
                    setIdScanStsMsg(errorMsg);
                    let rem = parseInt(maxretries) - parseInt(attempts);
                    setMaxretriesMsg(data[lang]['IDMaxRetriesMsg'].replace('{cnt}', attempts).replace('{maxcnt}', rem))
                } else {
                    dispatch(createAccessLog('ID Scan Check Max Retries: ' , 'Error', 'Maxtries reached',cif));
                    contactBranch();
                }
            }
            if (error === 2) {
                var resp = {
                    "data": result,
                    "status": "Failure",
                    "count": attempts
                }
                updateIdscanProgress(resp);
                setErrorMessage(errorMsg);
                setLoader(false)
            }

        }


    }, [extractFront, extractBack]);


    useEffect(() => {
        if(fetchUploadsData !== undefined && journeyid !==undefined && cif !== undefined && fetchUploadsData.message){
            kycStore.getState().KYCState.fetchresult = fetchUploadsData;
            setFetch(true)
            setLoader(false)
            //console.log(fetchUploadsData)
            let errorMsg = '';
            let error = 0;
            let fetchdata = {};
            if(getToken().pt === 0) {
                if (fetchUploadsData.message === "Failure") {
                    setLoader(false)
                    dispatch(createAccessLog('Fetch ID Card', 'Failure', 'Fetch API failed', cif));

                    error = 1;
                    errorMsg = data[lang]['unabletocommunicate']
                }
                if (fetchUploadsData.message === "error") {
                    setLoader(false)
                    dispatch(createAccessLog('Fetch ID Card', 'Error', fetchUploadsData.flag, cif));

                    error = 1;
                    errorMsg = fetchUploadsData.flag
                    fetchdata = fetchUploadsData.data || {}
                    //dispatch(updateJourneyLevel(2, "Rejected"));


                }
                if (error === 1) {
                    var resp = {
                        "data": fetchUploadsData,
                        "status": "Failure",
                        "count": attempts
                    }

                    updateIdscanProgress(resp);
                    console.log('2')
                    updateRejectedReason(data['En'][errorMsg].replace('{idname}', data['En'][idname]))
                    if (maxretries > attempts) {
                        //dispatch(createAccessLog('ID Scan Check Max Retries', 'Success', 'Maxtries not reached', cif));

                        setLoader(false)
                        setIdScanSts("Failure");
                        setIdScanStsMsg(data[lang][errorMsg] ? data[lang][errorMsg].replace('{idname}', data[lang][idname]) : errorMsg);

                        try {
                            setIdScanData(JSON.parse(fetchdata));
                        } catch (e) {

                        }

                        let rem = parseInt(maxretries) - parseInt(attempts);
                        setMaxretriesMsg(data[lang]['IDMaxRetriesMsg'].replace('{cnt}', attempts).replace('{maxcnt}', rem))
                    } else {
                        dispatch(createAccessLog('ID Scan Check Max Retries', 'Error', 'Maxtries reached', cif));
                        contactBranch();
                    }
                }
            }
            if(fetchUploadsData.message.toLowerCase()  === "success" || getToken().pt === 1){
                //console.log(fetchUploadsData)
                let name = fetchUploadsData.result['name'] || ''
                let surname = fetchUploadsData.result['surname'] || ''
                let idNumber = fetchUploadsData.result['idnumber'] || ''
                let custname = name + ' ' + surname;
                let ocrData = {
                    id: idNumber,
                    name: custname.trim()
                }

                let bankData = {
                    id: getLegalIdNumber(),
                    name: getToken().custinfo.name
                } ;
                let doctype = selectedDocs[0]
                const myObjStr1 = JSON.stringify(ocrData);
                const enc_myObjStr1 = new Buffer(myObjStr1).toString('base64');

                const myObjStr2 = JSON.stringify(bankData);
                const enc_myObjStr2 = new Buffer(myObjStr2).toString('base64');
                dispatch(stringMatchScore(enc_myObjStr1, enc_myObjStr2, doctype, cif));
                //console.log('fetchUploadsData received - Success')
                dispatch(createAccessLog('Fetch ID Card' , 'Success', 'Got the card data successfully',cif));
                setShowInstructionsHead(false)
                var resp = {
                    "data": scanResult,
                    "status": "Success",
                    "count": attempts
                }

                //dispatch(createAccessLog('ID Scan Check' , 'Success', 'Update ID scan result to backend - UpdateIdscanResult',cif));
                updateIdscanProgress(resp);

                //  console.log("processd file success");
                setLoader(false)
                //finishCapture()
                setIdScanSts("Success");
                setMaxretriesMsg('')
                setIdScanStsMsg(data[lang]['cardSubmitSuccessMessage']);

            }


            /*  generateReference() ; */
        }


    }, [fetchUploadsData, journeyid, cif]);

    const finishCapture = () => {
        setFinishcapture(true)
        setPicStatus('finished');

    }


    const updateIdscanProgress = (resp) =>{

        const myObjStr = JSON.stringify(resp);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        //console.log(myObjStr) ;
        //console.log(enc_myObjStr);

        dispatch(updateIdscanResult(journeyid,enc_myObjStr)) ;
    }



    useEffect(() => {

        if(uploadResponsedata.message !== undefined){
            //console.log(uploadResponsedata.message.indexOf("Success")) ;
            if(uploadResponsedata.message.toLowerCase().indexOf("success") >= 0 ){
                dispatch(createAccessLog('submitPhoto' , 'Success', uploadResponsedata.message,cif));
                /*  setIdScanStsMsg(uploadResponsedata.message) ; */
                processFiles() ;
            }
            if(uploadResponsedata.message.indexOf("Failure") >= 0 ) {
                dispatch(createAccessLog('submitPhoto' , 'Error', uploadResponsedata.message,cif));
                setLoader(false)
                setErrorMessage(data[lang]['unabletocommunicate']);
            }
        }




    },[uploadResponsedata,setIdScanSts,setIdScanStsMsg]);

    useEffect(() => {
        let idname = '';

        if( idtype === "National" ){
            idname = "National ID";
            setMaxPage(2);
            /*  setSelectedDocs(["IndiaAadharShortFront","IndiaAadharShortBack"]);  */
            setSelectedDocs(["OmanNationalIdFront","OmanNationalIdBack"]);
            setDocLabels("OMANNATIONALID - ");
            /*setDocLabels([
                {
                    name: "OMANRESIDENTID - Name",
                    gender: "OMANRESIDENTID - Sex",
                    nationality: "OMANRESIDENTID - Nationality",
                    civilno: "OMANRESIDENTID - Civil Number",
                    doe: "OMANRESIDENTID - Expiry Date",
                    dob: "OMANRESIDENTID - Date of Birth",
                    mrz: "OMANRESIDENTID - MRZ_Name",
                    civilno_back: "OMANRESIDENTID - IDNO_Back",
                    dob_back: "OMANRESIDENTID - DOB_Back",
                    doe_back: "OMANRESIDENTID - DOE_Back",
                },
                    "OmanNationalIdFront","OmanNationalIdBack"]);*/
        }else if(idtype === "Resident"){
            setDocLabels("OMANRESIDENTID - ");
            idname = "Civil ID";
            /*  setMaxPage(1);
             setSelectedDocs(["IndiaPanFront"]);  */

            setMaxPage(2);
            setSelectedDocs(["OmanResidentIdFront","OmanResidentIdBack"]);
        }else{
            setDocLabels("PASSPORT - ");
            idname = "Passport";
            if(isPassportBack === true) {
                setMaxPage(2);
                setExtractBack({
                    status: 1,
                    error: 0,
                    result: '',
                    message: '',
                    messageEn: ''
                });
                setSelectedDocs(["PassportFront", "PassportBack"]);
            } else {
                setMaxPage(1);
                setExtractBack({
                    status: 1,
                    error: 0,
                    result: '',
                    message: '',
                    errorMsgEn: ''
                });
                setSelectedDocs(["PassportFront"]);
            }
        }
        setIdname(idname);

    },[idtype,setMaxPage,setSelectedDocs,setIdname]);



    const nextStep = () =>{
        setBtnClick('next');
        if(livenessstatus === 'ON') {
            history.push(PWA_JOURNEY_STEPS[2]);
        } else {
            history.push(PWA_JOURNEY_STEPS[3]);
        }
    }

    const nextStepToMobile = () =>{
        setBtnClick('nextToMob');
        dispatch(createAccessLog('ProceedOnMobile' , 'Started', 'Selected to continue journey on mobile',cif));
        dispatch(continueOnMobileRequest())
    }
    const nextStepToDesktop = () =>{
        setBtnClick('nextToDesk');
        dispatch(createAccessLog('ProceedToDesktop' , 'Started', 'Selected to continue journey on desktop',cif));
        dispatch(switchedBackToDesktopRequest())
        setRedirectDesktop(true)
        setTimeout(redirectToHome, 5000)
    }
    const redirectToHome = () =>{
        window.location = 'https://www.bankmuscat.com/'+lang;
    }



    const processFiles = () =>{
        //dispatch(createAccessLog('processFiles' , 'Started', 'Extracting the details started',cif));
        let formdata = new FormData();
        formdata.append('userid', journeyid);
        formdata.append('image', URLArray[0]);
        formdata.append('doctype', selectedDocs[0]);
        //dispatch(createAccessLog('processFiles' , 'Started', 'Extracting the details started - processFileOCR',cif));
        let postData = {image: URLArray[0], doctype: selectedDocs[0]}
        setCardSubmittedMsg(data[lang]['uploading'])

        dispatch(processFileOCR(postData)) ;
        if(URLArray[1]) {
            if(selectedDocs[1] !== 'PassportBack') {
                let formdata2 = new FormData();
                formdata2.append('userid', journeyid);
                formdata2.append('image', URLArray[1]);
                formdata2.append('doctype', selectedDocs[1]);
                //dispatch(createAccessLog('processFiles', 'Started', 'Extracting the details started - processFileOCR2', cif));
                let postData2 = {image: URLArray[1], doctype: selectedDocs[1]}
                setCardSubmittedMsg(data[lang]['uploading'])
                //dispatch(processFileOCR2(postData2));
                setTimeout(function() {dispatch(processFileOCR2(postData2));}, 3000);
            } else {
                let strjson = '{' + ' "'+selectedDocs[1] + '" : "' + URLArray[1]+'" ' + '}' ;
                dispatch(createAccessLog('uploadPassportBack' , 'Started', 'Update Passport Back',cif));
                let postData = {data: strjson}
                setCardSubmittedMsg(data[lang]['uploading'])
                dispatch(customerAddnlDocsUpload(postData));
            }
        }
    }
    const processFileFirst = () =>{
        //dispatch(createAccessLog('processFiles' , 'Started', 'Extracting the details started',cif));
        let formdata = new FormData();
        formdata.append('userid', journeyid);
        formdata.append('image', URLArray[0]);
        formdata.append('doctype', selectedDocs[0]);
        //dispatch(createAccessLog('processFiles' , 'Started', 'Extracting the details started - processFileOCR',cif));
        let postData = {image: URLArray[0], doctype: selectedDocs[0]}
        //setCardSubmittedMsg('Uploading files')
        kycStore.getState().KYCState.doctype = selectedDocs[0];
        kycStore.getState().KYCState.frontimage = URLArray[0];

        dispatch(processFileOCR(postData)) ;

    }
    const callSecondProcessFile = (postData2) => {
        //console.log(extractFront)
        if(extractFront !== undefined && extractFront.status === 1) {
            dispatch(processFileOCR2(postData2));
        } else {
            setTimeout(function() {
                callSecondProcessFile(postData2);
            }, 1000);
        }
    }
    const processFileSecond = () =>{

        if(URLArray[1]) {
            if(selectedDocs[1] !== 'PassportBack') {
                let formdata2 = new FormData();
                formdata2.append('userid', journeyid);
                formdata2.append('image', URLArray[1]);
                formdata2.append('doctype', selectedDocs[1]);

                let postData2 = {image: URLArray[1], doctype: selectedDocs[1]}
                setCardSubmittedMsg(data[lang]['uploading'])
                kycStore.getState().KYCState.backimage = URLArray[1];
                //dispatch(processFileOCR2(postData2));
                setTimeout(function() {
                    //dispatch(createAccessLog('processFiles', 'Started', 'Extracting the details started - processFileOCR2', cif));
                    dispatch(processFileOCR2(postData2));
                    }, 5000);
                /*setTimeout(function() {
                callSecondProcessFile(postData2)
                }, 10000);*/
            } else {
                let strjson = '{' + ' "'+selectedDocs[1] + '" : "' + URLArray[1]+'" ' + '}' ;
                dispatch(createAccessLog('uploadPassportBack' , 'Started', 'Update Passport Back',cif));
                let postData = {data: strjson}
                setCardSubmittedMsg('Uploading files')
                dispatch(customerAddnlDocsUpload(postData));
            }
        }
    }
    const bytesToSize = (bytes) => {
        var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        if (bytes === 0) return "0 Byte";
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    };
    const skipScan = () => {
        setAttempts(attempts+1) ;
        setPicStatus('finished');
        setFinishcapture(true);
        //dispatch(createAccessLog('submitPhoto' , 'Started', 'Image submit',cif));
        if(fetch === false) {
            setLoader(true)
        }





        setCardSubmitted(true);
        setCardSubmittedMsg(data[lang]['cardSubmitWaitMessage'])
    }
    const getScanImageDimension = (eve) =>{
        //console.log('Scan image dimension - ' + eve.target.width+'x'+eve.target.height);
        //dispatch(createAccessLog('IdScan' , 'Scanned', 'Scan image dimension - ' + eve.target.width+'x'+eve.target.height, cif));
    }
    const setImageData = (imgdata) => {
        // console.log(imgdata)



        //----get size----
        let size = Math.round(new Buffer(imgdata, 'base64').length/1024)
        //console.log('Scan image size - ', size)
        dispatch(createAccessLog('IdScan' , 'Scanned', 'Scan image size - ' + size, cif))

        //----get dimension----
        var i = new Image();
        i.onload = getScanImageDimension
        i.src = imgdata;
        if(iOS()) {
            compressPhoto(imgdata);
        } else {
            setPhoto(imgdata, imgdata);
        }
    }

    const rotateBase64Image = (base64data) => {
        // var canvas = document.getElementById("canvas-rotater");
        let canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");

        var image = new Image();
        image.src = base64data;
        image.onload = function() {
            canvas.width = image.height;
            canvas.height = image.width;
            ctx.rotate(-90 * Math.PI / 180);
            ctx.translate(-image.width, 0);
            ctx.drawImage(image, 0, 0);
            setImageData(canvas.toDataURL())

            // window.eval(""+callback+"('"+canvas.toDataURL()+"')");
        }.bind(this);

    }

    const capturePhoto = () => {
        setimgLoaded(false)
        //dispatch(createAccessLog('IdScan' , 'Started', 'Image attached',cif));
        //const imageSrc = webcamRef2.current.getScreenshot();
        let imageSrc =  webcamRef2.current.getScreenshot(1280, 720)
        if(winWid > winHgt){
            if(iOS()) {
                compressPhoto(imageSrc);
            } else {
                setPhoto(imageSrc, imageSrc);
            }
        }
        else{
            imageSrc = rotateBase64Image(imageSrc)
        }
        //----get size----
        /* let size = Math.round(new Buffer(imageSrc, 'base64').length/1024)
         console.log('Scan image size - ', size)
         console.log(imageSrc)
         dispatch(createAccessLog('IdScan' , 'Scanned', 'Scan image size - ' + size,cif));
         //----get dimension----
         var i = new Image();
         i.onload = getScanImageDimension
         i.src = imageSrc;*/

        if(!finishcapture){
            if(!frontPage){
                setPicStatus('capture1')
                setPicScanned(1)

            }else{
                setPicStatus('capture2')
                setPicScanned(2)

            }
        }

        /*if(iOS()) {
            compressPhoto(imageSrc);
        } else {
            setPhoto(imageSrc, imageSrc);
        }*/

    };
    function iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    const compressPhoto = (pic) => {
        var options = {
            maxSizeMB: 2,
            // maxWidthOrHeight: 200,
            useWebWorker: true,
            fileType: "image/jpeg"
        };

        imageCompression
            .getFilefromDataUrl(pic)
            .then((file) => imageCompression(file, options))
            .then(toBase64)
            .then((newZipPic) => {
                // console.log(pic);
                /* this.setState({url: pic, NewZipPic: newZipPic}, () =>
                    this.savePhoto()
                ); */
                setPhoto(pic,newZipPic) ;

            })
            .catch(function (error) {
                //console.log(error.message);
            });
        // setLoader(false)
    };
    const cropImage= (imgUri, width, height, xstart, ystart) => {
        try {

            //console.log("Values in crop", width, height);
            let resize_canvas = document.createElement('canvas');
            let orig_src = new Image();
            orig_src.src = imgUri;
            orig_src.onload = function () {
                resize_canvas.width = width;
                resize_canvas.height = height;
                let cnv = resize_canvas.getContext('2d');
                cnv.drawImage(orig_src, xstart, ystart, width, height, 0, 0, width, height);
                let newimgUri = resize_canvas.toDataURL("image/png").toString();
                compressPhoto(newimgUri);
            }
        }
        catch (e) {
            //console.log("Couldn't crop image due to", e);
            window.alert("Couldn't crop image due to", e);
            compressPhoto(imgUri);
        }
    }

    const setPhoto = (pic,newZipPic) =>{
        //console.log(pic);
        //console.log(newZipPic);
        setUrl(pic);
        setNewZipPic(newZipPic);
        savePhoto(pic,newZipPic);

    }


    const contactBranch = () =>{
        history.push("/contact-branch?ref=2") ;
    }


    const savePhoto = (url,newZipPic) => {

        // console.log("save Photo");
        var imageSrc = url;
        var newURL = newZipPic;

        //console.log(
        //     "Pic size before compress: " + bytesToSize(imageSrc.length)
        // ); //size before compress
        //console.log("Pic size after compress: " + bytesToSize(newURL.length)); //size after compress
        /* this.setState((prevState) => ({
            URLArray: [...prevState.URLArray, newURL]
        })); */
        //console.log(...URLArray) ;
        //console.log(newURL) ;
        setURLArray([...URLArray, newURL]);
    };

    const selectPic = (pic) => {
        //click pic to select that pic, and remove others
        const picIndex = URLArray.indexOf(pic);
        //console.log(picIndex);
        const newselURLArray = URLArray[picIndex];
        /* this.setState((prevState) => ({
            URLSelectedArray: [...prevState.URLSelectedArray, newselURLArray]
        })); */
        setURLSelectedArray([...URLSelectedArray, newselURLArray])

        /*  if (picIndex > -1) {
             let newURLArray = this.state.URLArray.splice(picIndex, 1);
             this.setState({ URLArray: newURLArray });
         } */
    };

    const submitPhoto = () => {
        setPicStatus('finished');
        setFinishcapture(true);
        //dispatch(createAccessLog('submitPhoto' , 'Started', 'Image submit',cif));
        setLoader(true)
        /*  console.log(URLArray[0]);
         console.log(URLArray[1]); */

        let strjson = '' ;
        if(maxPage === 2){
            strjson += '{  "'+selectedDocs[0]+'" : "'+URLArray[0]+'" ,' ;
            strjson += ' "'+selectedDocs[1]+'" : "'+URLArray[1]+'" }' ;
        }else if(maxPage === 1){
            strjson += '{  "'+selectedDocs[0]+'" : "'+URLArray[0]+'"}' ;
        }


        //console.log(strjson) ;


        /* const filejson = JSON.parse(strjson) ;
        console.log(filejson) ; */
        const ocrProcessdata = {
            userid: journeyid ,
            image:  URLArray[0],
            doctype: selectedDocs[0]
        }

        dispatch({
            type: 'OCR_PROCESS_DATA',
            ocrProcessdata: ocrProcessdata

        })


        let formdata = new FormData();
        formdata.append('userid', journeyid);
        formdata.append('data', strjson);
        //dispatch(customerIdScan(formdata));
        processFiles();
        setAttempts(attempts+1) ;

        setCardSubmitted(true);
        setCardSubmittedMsg(data[lang]['cardSubmitWaitMessage'])
        /*   this.nextStep() ;  */

    }
    const submitPhoto1 = () => {
        setdoCheckBrightness(false)
        setbrightness(255)
        setidScanErrorType(0);
        setIdScanStsMsg("");
        const ocrProcessdata = {
            userid: journeyid ,
            image:  URLArray[0],
            doctype: selectedDocs[0]
        }
        //console.log(ocrProcessdata)
        dispatch({
            type: 'OCR_PROCESS_DATA',
            ocrProcessdata: ocrProcessdata

        })
        processFileFirst();
        setcameraLoaded(false)
    }
    const previewLoaded = () => {
        setimgLoaded(true)
    }
    const submitPhoto2 = () => {
        setdoCheckBrightness(false)
        setbrightness(255)
        setAttempts(attempts+1) ;
        setPicStatus('finished');
        setFinishcapture(true);
        //dispatch(createAccessLog('submitPhoto' , 'Started', 'Image submit',cif));
        if(fetch === false) {
            setLoader(true)
        }

        const ocrProcessdata = {
            userid: journeyid ,
            image:  URLArray[0],
            doctype: selectedDocs[0]
        }

        dispatch({
            type: 'OCR_PROCESS_DATA',
            ocrProcessdata: ocrProcessdata

        })



        processFileSecond();



        setCardSubmitted(true);
        setCardSubmittedMsg(data[lang]['cardSubmitWaitMessage'])


    }
    const rescanPic = () => {
        setdoCheckBrightness(false)
        setbrightness(255)
        setcameraLoaded(false)
        setPicStatus('scan');

        if(picScanned === 1) {
            setFrontPage(false);
            setURLArray([]);
        } else {
            setBackPage(false) ;
            setURLArray([URLArray[0]]);
        }

        setFile('');
    }
    const completedScan = () => {
        if(!frontPage){
            setPicStatus('scan');
            if(maxPage  >  pageScanned+1) {
                setFrontPage(true) ;
                setPageScanned(pageScanned + 1) ;
                setCameraReady(true);
                submitPhoto1();
            }else{
                setFrontPage(true) ;
                setPageScanned(pageScanned + 1) ;
                setFinishcapture(true) ;
                setPicStatus('finished');
            }

            setLoader(false)
        }else{
            setPicStatus('finished');
            if(maxPage > pageScanned) {
                if (!backPage) {
                    setBackPage(true) ;
                    setPageScanned(pageScanned + 1) ;
                    setFinishcapture(true) ;

                }
            }else{
                setFinishcapture(true) ;
            }
            setLoader(false)
        }


    }
    const reScanId = () => {
        setdoCheckBrightness(false)
        setbrightness(255)
        setcameraLoaded(false)
        dispatch(createAccessLog('reScan' , 'Started', 'Clicked on re upload',cif));
        /*  console.log("check rescan") ; */
        //console.log(maxretries + " : " + attempts);
        if (maxretries > attempts) {
            setFetch(false)
            setUrl(null);
            setNewZipPic(null);
            setURLArray([]);
            setURLSelectedArray([]);
            setFrontPage(false);
            setBackPage(false);
            //setCameraReady(false);
            setFinishcapture(false);
            setReload(false);
            setMaxretriesMsg('')
            setIdScanSts('');
            setUploaddata([]);
            setFile('');
            setPageScanned(0);
            setCardSubmitted(false) ;
            setCardSubmittedMsg("") ;
            setidScanErrorType(0) ;
            setIdScanStsMsg("") ;
            setExtractFront({});
            setExtractBack({});
            setShowInstructions(false)
            if(idname === 'Passport') {

                setExtractBack({
                    status: 1,
                    error: 0,
                    result: '',
                    message: '',
                    errorMsgEn: ''
                });
            }
        }
    }

    const backToInstructions = () => {
        history.push('/scan-ID-instruction');
    }



    const closeFrame = () => {
        const ismobile = (client === 'MB');

        if(!ismobile) {
            dispatch(createAccessLog('Close button' , 'Clicked', 'Calling window.parent.postMessage("gieomRedirectHome", "*");',cif));
            //window.parent.closeGieomOverlay();
            window.parent.postMessage("gieomRedirectHome", "*");
        } else {
            dispatch(createAccessLog('Close button' , 'Clicked', 'Adding bom-pwa-close-btn',cif));
            setCloser(true)
        }
    }

    function onScanCameraReady(e){
        //console.log("onScanCameraReady: ")
        //console.log(e)
        setcameraLoaded(true)
        setTimeout(initCheckBrightness, 600)
    }
    function onScanCameraError(err){
        //console.log("onScanCameraError: "+err)
    }

    const params = (new URL(document.location)).searchParams;
    let token = getToken().token || '';
    token = params.get("ref") ? params.get("ref") : token
    let islandscape = (version === 1 || (winWid > winHgt)) ? true : false
    //console.log(idScanData)
    //console.log(finishcapture)
    //console.log(loader)


    let videoConstraints = {
        height: 720,
        width: 1280,
        facingMode: "environment" //use back camera
    };

    if(backCameras.length>1){
        videoConstraints = {
            height: 720, //set pic resolution
            width: 1280, //set pic resolution
            deviceId: backCameras[selectedCameraIdx].deviceId
        };
        //console.log("selectedCameraIdx")
        //console.log(selectedCameraIdx)
        //console.log(backCameras[selectedCameraIdx].deviceId)

    }

    let pic = picStatus === 'capture1' ? URLArray[0] : URLArray[1];
    let scanFinishLabel = picStatus === 'capture1' ? data[lang]['frontscancomplete'] : data[lang]['backscancomplete'];
    //console.log(kycStore.getState().KYCState)
    //console.log(showCardPreview)
    return (
        <>
            {closer &&
            <div id="bom-pwa-close-btn"></div>
            }

            {errorMessage !== '' &&
            <Popup messagehead={data[lang]['oops']} message={errorMessage}/>
            }
            {
                (token === '' || !token) &&

                <Popup messagehead={data[lang]['oops']} message={data[lang]['sessionexpired']}/>
            }
            {/*{
                redirectDesktop === true &&

                <Popup messagehead="" message={data[lang]['thankYou']} showIco={'F'} showButton={'F'} />
            }*/}
            <span className="csModal__out close-frame" onClick={() => closeFrame()}></span>
            {closer === true &&
            <div id="bom-pwa-close-btn"></div>
            }
            {(picStatus === 'capture1' || picStatus === 'capture2') &&
            <>


                <div className="cameraOverlay cameraOverlay--idScan id-scan-preview" style={{background: "#333"}}>
                    <div className="id-scan-preview-img"  style={{width: "100%", height: "100%"}}>
                        {imgLoaded === false &&
                        <Loader/>
                        }
                        {pic !== '' &&
                            <>
                            <img
                                className="previewImage"
                                style={{width: "100%", display: imgLoaded === true ? 'block' : 'none'}}
                                src={pic} //show pic in state
                                alt="Screenshot"
                                onLoad={previewLoaded}
                                // click pic to select that pic, and remove others
                            />
                            <div className="scanner-done-info" >{scanFinishLabel}</ div>
                        </>
                        }
                    </div>
                    <div className="flexCC desktopBtn idscan-btn-set">
                        <span className="btn btn--primaryG btn--capsule btn--primaryOuter" onClick={rescanPic}  style={{margin: "0px 10px"}}>
                                            {/*<span className="iconIn iconIn--scan"/>*/}
                            {data[lang]['rescan'].replace(' - {idname}', data[lang][idname])}
                        </span>
                        {(picStatus === 'capture2' || maxPage === 1) ?
                            <span className="btn btn--primaryG btn--capsule"  onClick={submitPhoto2}
                                  style={{margin: "0px 10px"}}>{data[lang]['done']}
                            </span>
                            :
                            <span className="btn btn--primaryG btn--capsule" onClick={completedScan}  style={{margin: "0px 10px"}}>
                                {data[lang]['done']}
                            </span>
                        }

                    </div>


                </div>

            </>
            }
            {(picStatus === 'scan' || picStatus === 'finished') &&
            <>
                {
                    !finishcapture ? (
                        <>
                            <div className="cameraView cameraView--idScan" style={{background: "#333"}}>
                                <div className="cameraPosition">
                                    {cameraLoaded === false &&
                                    <Loader/>
                                    }
                                    {showCamera === 1 &&
                                    <Webcam
                                        key={window.orientation}
                                        ref={webcamRef2}
                                        audio={false}
                                        screenshotQuality={1} // set the Quality of camera (0-1)
                                        forceScreenshotSourceSize={true}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints} //cameraSetting,eg:resolution, use which camera
                                        onUserMedia={(e) => onScanCameraReady(e)}
                                        onUserMediaError={(e) => onScanCameraError(e)}
                                        // onUserMedia={(e) => console.log(e)} // show info of media stream
                                    />
                                    }
                                </div>
                            </div>

                            <div className="scanner-masked-overlay" >

                            </ div>
                            <div className="cameraOverlay cameraOverlay--idScan" style={{background: 'none', backgroundSize: 'cover'}}>
                                <div className={`cameraOverlay__head  ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                                    {/*<Link className="cameraOverlay__head__back"  onClick={() => backToInstructions()}>
                                    <span className="iconIn iconIn--back"/>
                                </Link>*/}
                                    <p className="cameraOverlay__head__text">{data[lang]['scanningID'].replace('{idname}', data[lang][idname])}</p>
                                </div>

                                <div className="cameraOverlay__option">
                                    <div className="cameraOverlay__option__image">
                                        {!frontPage &&

                                        <img src={'../assets/images/card-outline.png'} style={{transform: winHgt > winWid ? 'rotate(90deg)' : ''}}/>

                                        }
                                        {frontPage &&

                                        <img src={'../assets/images/id-card-back.png'} style={{transform: winHgt > winWid ? 'rotate(90deg)' : ''}}/>

                                        }
                                    </div>
                                    {/*{
                                        backCameras.length > 1 &&

                                        <label className="sub-txt" onClick={changeCamera} >{data[lang]['clicktofocus']}</ label>
                                    }*/}

                                    <div className="cameraOverlay__option__ButtonSet">
                                        <div className="flexCC desktopBtn btnMultiWrap">
                                            <span className="btn btn--primaryG btn--capsule btn--primaryOuter" onClick={backToInstructions}>
                                                {data[lang]['cancel']}
                                            </span>
                                            <span className="btn btn--primaryG btn--capsule" onClick={capturePhoto}>
                                                {/*<span className="iconIn iconIn--scan"/>*/}
                                                {data[lang]['scanID'].replace(' - {idname}', data[lang][idname])}
                                            </span>

                                            {(idname === 'Passport' &&  pageScanned === 1) &&

                                            <span className="btn btn--primaryG btn--capsule btn--primaryOuter" onClick={skipScan}>

                                            {data[lang]['skip'].replace(' - {idname}', data[lang][idname])}
                                        </span>

                                            }
                                        </div>
                                    </div>
                                    <div className="cameraOverlay__option__instruction">
                                        {
                                            frontPage ? (
                                                <>
                                                    {
                                                        backPage ? (
                                                            ''
                                                        ) : (
                                                            <>
                                                                { maxPage  > 1 ? (
                                                                    <>
                                                                        {/*<p className="primaryTextAnchor error-msg">{data[lang]['positionMessageFrontCompleted']} </p>*/}
                                                                        <p>{data[lang]['positionMessageBack'].replace('{idname}', data[lang][idname])}</p>
                                                                    </>
                                                                ) :  '' }
                                                            </>
                                                        )
                                                    }
                                                </>

                                            ) : (

                                                <p>{data[lang]['positionMessageFront'].replace('{idname}', data[lang][idname])}</p>


                                            )
                                        }

                                    </div>






                                </div>
                            </div>


                            {
                                showCardSample === true &&
                                <>
                                    {!frontPage &&
                                    <div className="scanner-card-sample-cont">
                                        {showCardIco === true &&
                                            <>
                                            <img src={'../assets/images/card-outline.png'}
                                             style={{transform: winHgt > winWid ? 'rotate(90deg)' : ''}}/>


                                            {
                                                brightness < 160 &&
                                                <div className="scanner-low-brightness" >{data[lang]['notEnoughLight']}</ div>
                                            }
                                            </>
                                        }
                                        {
                                            showCardPreview === true &&

                                            <img id="card_preview" className="card-preview" src={'./assets/images/card-front.svg'} style={{transform: winHgt > winWid ? '' : 'rotate(270deg)'}} />
                                        }
                                    </ div>
                                    }
                                    {frontPage &&
                                    <div className="scanner-card-sample-cont">
                                        {showCardIco === true &&
                                        <>
                                        <img src={'../assets/images/id-card-back.png'} style={{transform: winHgt > winWid ? 'rotate(90deg)' : ''}}/>
                                        {
                                            brightness < 160 &&
                                            <div className="scanner-low-brightness" >{data[lang]['notEnoughLight']}</ div>
                                        }
                                        </>
                                        }
                                        {
                                            showCardPreview === true &&

                                            <img id="card_preview" className="card-preview" src={'./assets/images/card-back.svg'} style={{transform: winHgt > winWid ? '' : 'rotate(270deg)'}} />
                                        }
                                    </ div>
                                    }
                                </>
                            }
                        </>
                    ) : '' }

                {
                    finishcapture ? (
                        <>
                            {loader &&
                            <Loader message={cardSubmittedMsg.replace('{idname}', data[lang][idname])}/>
                            }
                            <Header className="hasCrossOver hasCrossOver--lg">
                                <div className="navHead">
                                    {/*<Link className="navHead__back">fsubmit
                                    <span className="iconIn iconIn--back" />
                                </Link>*/}
                                    <p className="navHead__text">{data[lang]['scanningID'].replace('{idname}', data[lang][idname])}</p>
                                </div>
                            </Header>
                            { idScanStsMsg  === "" ?  (
                                <div className={`wPanel  ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                                    <div className="iconMessageBox">
                                        <span className="iconMessageBox__icon iconIn iconIn--image" />
                                        {/* <h2 className="iconMessageBox__title">Done!</h2> */}
                                        {cardSubmittedMsg === '' &&
                                        <p className="iconMessageBox__text alignCenter">{data[lang]['scannedMessage'].replace('{idname}', data[lang][idname])}</p>
                                        }
                                        {/*{loader &&
                                    <div className="wPanel--loader"><img width={50} src={PreLoader} className="loader" alt="" /></div>
                                    }*/}
                                        {
                                            ((pageScanned)  ===  maxPage && cardSubmittedMsg === '') ? (
                                                <div className="idpreview">
                                                    {URLArray.map((x) => (
                                                        <div id={x} style={{width: "150px", float: "left"}}>
                                                            <img
                                                                className="previewImage"
                                                                style={{width: "150px", transform: islandscape === false ? "rotate(-90deg)" : "none"}}
                                                                src={x} //show pic in state
                                                                alt="Screenshot"
                                                                onClick={() => selectPic(x)} // click pic to select that pic, and remove others
                                                            />
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : ('')
                                        }

                                    </div>
                                    { !cardSubmitted ?(
                                        <>
                                            { URLArray.length > 0 ?(
                                                <div className="buttomWrap">
                                                    <div className="flexCC">
                                                        <button onClick={submitPhoto}  className="btn btn--capsule btn--primaryG ">{data[lang]['submitID']}</button>
                                                    </div>
                                                    <div className="flexCC">
                                                        <button onClick={reScanId}  className="btn btn--capsule btn--primaryOuter">{data[lang]['rescan']}</button>
                                                    </div>
                                                </div>
                                            ) : (  <p className="iconMessageBox__text alignCenter">{data[lang]['loading']}...</p> ) }
                                        </>
                                    ) : (
                                        <p className="iconMessageBox__text alignCenter">{cardSubmittedMsg.replace('{idname}', data[lang][idname])}</p>
                                    ) }
                                </div>

                            ) : (
                                <div className={`wPanel paddingBonly offBox wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                                    <div className="contentWrap">
                                        <div className="shadowBlock">
                                            <div className="shadowBlock__head">
                                                <img src={Logo} className="shadowBlock__head__img" alt="bank-of-muscat"/>
                                            </div>
                                            <div className="flexInBlock">
                                                <div className="flexInBlock__center">
                                                    {idScanSts.toLowerCase() === 'success' ?
                                                        <img src={thankyouImage} className="tqImage"
                                                             alt={data[lang]["Success"]}/>
                                                        :
                                                        <img src={closeImage} className="tqImage smImage"
                                                             alt={data[lang]['oops']}/>
                                                    }
                                                </div>
                                                <div className="flexInBlock__center">
                                                    <h5 className="flexInBlock__title alignCenter">{idScanSts.toLowerCase() === 'success' ? data[lang]["Success"] : data[lang]['extractionFailed']}</h5>
                                                    {idScanSts.toLowerCase() === 'success' &&
                                                        <>
                                                            <p className="iconMessageBox__text alignCenter" dangerouslySetInnerHTML={{__html:data[lang]['uploadedMessageSuccess'].replace('{idname}', data[lang][idname])}}/>
                                                            {livenessstatus === 'OFF' &&
                                                            <>
                                                                {optionToMobile === 0 ? (
                                                                    <></>
                                                                ) : (
                                                                    <>
                                                                        {optionToDesktop === 0 ? (
                                                                            <p className="iconMessageBox__text alignCenter">{data[lang]['liketocontinueondesktop']}</p>
                                                                        ) : (
                                                                            <p className="iconMessageBox__text alignCenter">
                                                                                {data[lang]['redirectedtodesktop']}</p>
                                                                        )}
                                                                    </>
                                                                )
                                                                }
                                                            </>
                                                            }
                                                        </>
                                                    }
                                                    {idScanSts === 'Failure' &&
                                                    <>

                                                        <p className={`iconMessageBox__text ${idScanErrorType === 0 ?  'alignCenter' : ''}`} style={{"textAlign":(lang === 'En' && idScanErrorType === 1) ? 'left' : ''}} dangerouslySetInnerHTML={{__html:idScanStsMsg}}/>
                                                        {/*{(idScanData && idScanData.result) &&

                                                        <div className="ocr-details"><br/>
                                                            <p>{data[lang]['name']}: {idScanData.result[selectedDocs[0]] ? idScanData.result[selectedDocs[0]]["Name"] : ''}</p>
                                                            <p>{data[lang][idname]}: {idScanData.result[selectedDocs[0]] ? idScanData.result[selectedDocs[0]]["Civil Number"] : ''}</p>
                                                            <p>{data[lang]['gender']}: {idScanData.result[selectedDocs[0]] ? idScanData.result[selectedDocs[0]]["Sex"] : ''}</p>
                                                            <p>{data[lang]['nationality']}: {idScanData.result[selectedDocs[0]] ? idScanData.result[selectedDocs[0]]["Nationality"] : ''}</p>
                                                            <p>{data[lang]['expiryDate']}: {idScanData.result[selectedDocs[0]] ? idScanData.result[selectedDocs[0]]["Expiry Date"] : ''}</p>
                                                            <p>{data[lang]['dob']}: {idScanData.result[selectedDocs[0]] ? idScanData.result[selectedDocs[0]]["Date of Birth"] : ''}</p>
                                                            {idname !== 'Passport' &&
                                                            <>
                                                                <br/>
                                                                <p>{data[lang]['name']}: {idScanData.result[selectedDocs[1]] ? idScanData.result[selectedDocs[1]]["Name"] : ''}</p>
                                                                <p>{data[lang][idname]}: {idScanData.result[selectedDocs[1]] ? idScanData.result[selectedDocs[1]]["IDNO_Back"] : ''}</p>
                                                                <p>{data[lang]['expiryDate']}: {idScanData.result[selectedDocs[1]] ? idScanData.result[selectedDocs[1]]["DOE_Back"] : ''}</p>
                                                                <p>{data[lang]['dob']}: {idScanData.result[selectedDocs[1]] ? idScanData.result[selectedDocs[1]]["DOB_Back"] : ''}</p>

                                                            </>
                                                            }
                                                            <p>{data[lang]['recognition']}: {idScanData.result[selectedDocs[0]] ? idScanData.result[selectedDocs[0]]["Recognition Confidence"] : ''}</p>
                                                        </div>
                                                        }*/}
                                                    </>
                                                    }
                                                    {maxretriesMsg !== '' &&
                                                    <>

                                                        <br/>
                                                        <p className="iconMessageBox__text alignCenter" dangerouslySetInnerHTML={{__html:maxretriesMsg}}/>
                                                    </>
                                                    }
                                                    {showInstructionsHead === true &&
                                                    <>
                                                        <br/>
                                                        <div className="iconMessageBox">
                                                            <span className="primaryLink show" onClick={() => toggleInstructions()}>{data[lang]['scanIDInstructionsHead']}</span>
                                                        </div>

                                                        <div className="" style={{'display': showInstructions === true? 'block' : 'none'}}>
                                                            <ol className="numberList">
                                                                <li>{data[lang]['scanIDInstructions1']}</li>
                                                                <li>{data[lang]['scanIDInstructions2']}</li>
                                                                <li>{data[lang]['scanIDInstructions3']}</li>
                                                                <li>{data[lang]['scanIDInstructions4']}
                                                                    <div className="listInBox">
                                                                        <p className="arrowText">{data[lang]['scanIDInstructions4a']}</p>
                                                                        <p className="arrowText">{data[lang]['scanIDInstructions4b']}</p>
                                                                    </div>
                                                                </li>
                                                            </ol>
                                                        </div>
                                                    </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="buttomWrap">
                                            { idScanSts.toLowerCase() === "success"  ? (
                                                <>
                                                    {/*<button onClick={nextStep}
                                                        className="btn btn--capsule  btn--sm">{data[lang]['cancel']}
                                                </button>*/}
                                                    {livenessstatus === 'ON' &&
                                                    <div className="flexCC">
                                                    <span onClick={nextStep}
                                                          className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['proceedButton']}
                                                    </span>
                                                    </div>
                                                    }
                                                    {livenessstatus === 'OFF' &&
                                                    <>
                                                        {optionToMobile === 0 ? (
                                                            <div className="flexCC">
                                                            <span onClick={nextStep}
                                                                  className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['proceedButton']}
                                                            </span>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {optionToDesktop === 0 ? (
                                                                    <>
                                                                        <div className="flexCC mb30">
                                                                        <span onClick={nextStepToDesktop}
                                                                              className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['proceedtodesktop']}
                                                                        </span>
                                                                        </div>
                                                                        <div className="flexCC">
                                                                        <span onClick={nextStepToMobile}
                                                                              className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['proceedtomobile']}
                                                                        </span>
                                                                        </div>
                                                                    </>
                                                                ) : ('')}
                                                            </>
                                                        )
                                                        }
                                                    </>
                                                    }
                                                </>
                                            ) : (
                                                <>
                                                    <div className="flexCC">
                                                    <span onClick={reScanId}
                                                          className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['rescan']}
                                                    </span>
                                                    </div>
                                                    {/*<div className="flexCC">
                                                    <button onClick={nextStep}
                                                            className="btn btn--capsule btn--primaryOuter">{data[lang]['skipProceed']}
                                                    </button>
                                        </div>*/}

                                                </>
                                            ) }
                                        </div>

                                    </div>






                                </div>

                            ) }

                        </>
                    ) : '' }
            </>
            }
            {/*
        <button style={{position: 'fixed', zIndex: 20}} onClick={nextStepToDesktop}
            className="btn btn--capsule btn--primaryG">{data[lang]['proceedButton']} ON DESKTOP
        </button>
        */}


        </>
    )
}

export default IdScan  ;


