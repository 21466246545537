import React, {useEffect, useReducer, useState} from 'react';
import SwitchInput from '../components/SwitchInput';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';

/*function ListItem(props) {
    return  <option>{props.option}</option> ;
}*/
const Editform4 = (props) => {
    const lang = getToken().lang;
    const [ustaxresident, setUstaxresident] = useState(props.inputValues.ustax_resident);
    const [uspayments, setUspayments] = useState(props.inputValues.us_payments);
    const [usaddresstel, setUsaddresstel] = useState(props.inputValues.usaddress_tel);
    const [usnationality, setUsnationality] = useState(props.inputValues.us_nationality);

    /*const updateDetails = (curstep) =>{
        //console.log("update step1: "+curstep) ;
    }*/

    const handleInput = (e) =>{
        props.onChange(e.target.name,  e.target.value) ;
    }


    useEffect(() => {
        if(ustaxresident === "Yes" || usnationality === "Yes"  ){
            props.ondepFieldChange("showW9",true);
        }else{
            props.ondepFieldChange("showW9",false);

        }

        if(uspayments === "Yes" || usaddresstel === "Yes"  ){
            props.ondepFieldChange("showW8",true);
        }else{
            props.ondepFieldChange("showW8",false);
        }
    },[ustaxresident,usnationality,uspayments,usaddresstel]);



    const handleSwitchChange = (name, value) =>{
        if(name === "ustax_resident"){
           setUstaxresident(value) ;
        }else  if(name === "us_nationality"){
            setUsnationality(value) ;
        }else  if(name === "usaddress_tel"){
            setUsaddresstel(value) ;
        }else  if(name === "us_payments"){
            setUspayments(value) ;
        }
        props.onChange(name,  value) ;
    }

    const  handleFieChange = (e) => {
        props.handleFieChange(e.target.name,  e.target.files[0], e.target.files[0].name ) ;
    }

    return (
        <>
            <div className="formBlock">
                <h3>{props.boxtitle}</h3>

                { props.section && props.section.map(item => {
                    return (
                        <>
                            { item.inputtype === "select" ? (
                                <>
                                <fieldset  className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>
                                    <select type="text"    name={item.fieldname}  onChange={handleInput}  className="formBlock__input formBlock__select">
                                        <option value="" >{data[lang]['select']}</option>
                                        { item.selectvalues &&  item.selectvalues.map(selectlabel => {
                                            return( <option  value={selectlabel.value} selected={selectlabel.value === item.value ? 'selected' : "" } > {selectlabel.option}</option>)

                                        })}
                                    </select>
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }
                            { item.inputtype === "switch" ? (
                                <div id={item.fieldname} className="splitBox splitBox--mBMd">
                                    <div className="splitBox__w80Out">
                                            <label className="textLabel textLabel--type2">{item.label}</label>
                                    </div>
                                    <div className="splitBox__w80">
                                        <SwitchInput className=""  name={item.fieldname}   active={ item.value === "Yes" ? true : false }  onChange={handleSwitchChange} value={item.value} />
                                    </div>
                                </div>

                            ) : ''}

                            { item.inputtype === "file" ? (
                                <>

                                {item.selectedfilename !== "" ? (
                                    <label className="uploaded-doc">{item.fieldname}: {item.selectedfilename}</label>
                                ) : "" }
                                { item.showfield ? (
                                    <label className="csBtnFile">
                                    <input type="file" onChange={handleFieChange} name={item.fieldname} className="csBtnFile___inputIn"/>
                                    <span className="btn btn--capsule btn--primaryG btn--inFull">
                                    <span className="iconIn iconIn--tick"/>
                                        {item.label}
                                    </span>
                                    </label>


                                    ) : '' }
                                </>
                            ) : '' }


                        </>
                    )
                } )}
                {
                    (
                        props.errors.indexOf('ustax_resident') > -1 ||
                        props.errors.indexOf('usaddress_tel') > -1 ||
                        props.errors.indexOf('us_nationality') > -1
                    ) ?
                        <span className="primaryTextAnchor error-msg">Your account information and FATCA declaration response is not matching, Kindly update and retry</span>
                        : ''
                }
                <div className="flexCC mT15">
                    <span className="btn btn--primaryG btn--capsule"   onClick={() => props.closeModal(4)} >{data[lang]['close']}</span>
                </div>

            </div>
        </>
    )
}

export default Editform4
