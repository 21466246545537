import {createStore, combineReducers} from 'redux';

var kycState = {
	version: 'v 142.4',
	winWid: document.documentElement.clientWidth,
	winHgt: document.documentElement.clientHeight,
	apiTimestamp: {},
	BM_PWA_API: '',
	BM_GM_API: '',
	BM_BASE_URL: '',
	BM_token: '',

	BM_apitoken: '',
	BM_custname: '',
	BM_mobile: '',
	BM_idtype: '',
	BM_journeyid: '',
	BM_cif: '',
	BM_nonstp: '',
	BM_renewType: '',
	BM_lang: 'En',
	BM_jsessionid: '',
	BM_bway: '',
	BM_step: '',
	BM_step_status: '',
	BM_client: '',
	BM_otp_locked: '',
	BM_ocr_attempts: '',
	BM_livenessmaxretries: '',
	BM_scanidmaxretries: '',
	BM_faceMatchAcceptedScore: '',
	BM_faceMatchLowScore: '',
	BM_nameMatchAcceptedScore: '',
	responseStatus: false,
	location: '',
	livenessFaceCaptureResponse: false,
	livenessVideoResponse: false,
	facematchdata: {},
	namematchdata: '',
	processfileError: '',
	frontimage: '',
	backimage: '',
	doctype: '',
	fetchresult: '',
	redo_journey: 0,
	inputValues: {}
	}

const kycStateData = (state = kycState, action) => {

	switch (action.type){
		case 'CHANGE_SCREENDIM':
			return {
				...state,
				winWid: action.winWid,
				winHgt: action.winHgt
			}
		case 'RESPONSE_ERROR':
			return {
				...state,
				responseStatus: action.status
			}
		case 'FM_SCORE':
			//console.log(action.data)
			return {
				...state,
				facematchdata: action.data
			}

		default:
			return state
		}
	}

export const KYCReducer = combineReducers({
    KYCState: kycStateData
});

export const kycStore = createStore(KYCReducer)

export default KYCReducer;
