import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Switch from '../components/Switch';
import data from '../constants/data.json';
import {getToken} from "../utilities/AuthService";

const KycComponents = () => {
    const lang = getToken().lang;
    return (
        <>
            <Header userIn={true} headerTitle={data[lang]['kycDetails']} />
            <div className="contentBlock">
                <div className="headerBlock">
                    <h3>{data[lang]['exposedPersonMessage']}</h3>
                </div>
                <div className={`wPanel wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                    <div className="splitBox splitBox--mBMd">
                        <div className="splitBox__w80Out">
                            <label className="textLabel textLabel--type2">{data[lang]['pepRelative']}</label>
                        </div>
                        <div className="splitBox__w80">
                            <Switch />
                        </div>
                    </div>

                    <div className="splitBox splitBox--mBMd">
                        <div className="splitBox__w80Out">
                            <label className="textLabel textLabel--type2">{data[lang]['pepRelative']}</label>
                        </div>
                        <div className="splitBox__w80">
                            <Switch />
                        </div>
                    </div>

                    <div className="splitBox splitBox--mBMd">
                        <div className="splitBox__w80Out">
                            <label className="textLabel textLabel--type2">{data[lang]['pepRelative']}</label>
                        </div>
                        <div className="splitBox__w80">
                            <Switch />
                        </div>
                    </div>

                    <div className="splitBox splitBox--mBMd">
                        <div className="splitBox__full">
                            <label className="textLabel">{data[lang]['ifUnemployed']}</label>
                            <select className="csSelect csSelect--inFull">
                                <option selected>{data[lang]['ifUnemployedOption1']}</option>
                                <option>{data[lang]['ifUnemployedOption2']}</option>
                                <option>{data[lang]['ifUnemployedOption3']}</option>
                                <option>{data[lang]['ifUnemployedOption4']}</option>
                                <option>{data[lang]['ifUnemployedOption5']}</option>
                                <option>{data[lang]['ifUnemployedOption6']}</option>
                                <option>{data[lang]['ifUnemployedOption7']}</option>
                            </select>
                        </div>
                    </div>

                    <div className="w20Out lineTopBox">
                        <div className="splitBox">
                            <div className="splitBox__half">
                                <label className="textLabel">{data[lang]['nationalID']}</label>
                            </div>
                            <div className="splitBox__half">
                                <label className="textContent editable">
                                    OMN 12344567
                                    <span className="editable__icon"/>
                                </label>
                            </div>
                        </div>
                    </div>

                    <h3 className="editable">
                        {data[lang]['address']}
                        <span className="editable__icon"/>
                    </h3>

                </div>

                <div className="flexCB mnButtons">
                    <Link to="/details"  className="btn btn--capsule btn--primaryOuter">{data[lang]['previousButton']}</Link>
                    <Link to="/thank-you"  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</Link>
                </div>
            </div>
        </>
    )
}

export default KycComponents
