import {combineReducers} from 'redux';


const handshakeData =  (state = {customerData:{}, apiToken:{}, Validdata:[]}, action) => {
    switch (action.type) {
        case "VALIDATE_CUSTOMER":
            return {
                ...state,
                customerData: action.customerData
            };
            break;
        case "USER_TOKEN":
            return {
                ...state,
                apiToken: action.apiToken
            };
            break;
        case "USER_JOURNEY":
            return {
                ...state,
                jstatus: action.journey.message,
                apiToken: action.journey.token,
                customerData: action.journey.custinfo
            };
            break;
        case "HSHAKE_FAILED":
            return {
                ...state,
                Validdata: action.validdataerrors,
            };
            break;
        default:
            return state;
    }
};



export const HandshakingReducer = combineReducers({
    handshakeData
});

export default HandshakingReducer;
