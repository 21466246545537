import React, {useEffect, useReducer, useRef, useState} from 'react';
import SwitchInput from '../components/SwitchInput';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";
import en from 'date-fns/locale/en-US';
import ar from 'date-fns/locale/ar-SA';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('en', en)
registerLocale('ar', ar)



/*function ListItem(props) {
    return  <option>{props.option}</option> ;
}*/


const Editform6 = (props) => {
    const lang = getToken().lang;
    const [showinput, setShowinput] = useState(false);
    const [pep_relative, setPep_relative] = useState(props.inputValues.pep_relative);
    const [pep_busns_associate, setPep_busns_associate] = useState(props.inputValues.pep_busns_associate);

    const myRef = useRef(null)
    const executeScroll = () => {
        if(myRef.current !== null) {myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
    }


    useEffect(() => {
        if(props.errors.length > 0) {
            executeScroll()
        }
    }, [props.errors]);
    const [inputFormValues, setInputFormValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            pep_name_org_relative: props.inputValues.pep_name_org_relative
        }
    );

    useEffect(() => {
        if(pep_busns_associate === "Yes" || pep_relative === "Yes"  ){
            setShowinput(true)
        }else{
            setShowinput(false)
        }


    },[pep_busns_associate,pep_relative]);

    /*const updateDetails = (curstep) =>{
        //console.log("update step1: "+curstep) ;
    }*/
    const setPeriod = (name, date) => {
        let m = parseInt(date.getMonth()) + 1;
        if(m < 10){
            m = '0' + m
        }
        let y = date.getFullYear();


        props.onChange(name, m + '/' + y);
    }
    const handleInput = (e) =>{
        let error = 0;

        let value = e.target.value;
        switch (e.target.name) {
            case 'pep_relation':
                if(value !== '' && (value.length > 100  || !value.match(/^[A-Za-z0-9 ]+$/) )){  error++; return false; }
                break;
            case 'pep_name':
                if(value !== '' && (value.length > 100  || !value.match(/^[A-Za-z0-9 ]+$/) )){  error++; return false; }
                break;
            case 'pep_country':
                if(value !== '' && (value.length > 100  || !value.match(/^[A-Za-z0-9 ]+$/) )){  error++; return false; }
                break;
            default:
                break;

        }
        if(error === 0) {
            let val = props.section.filter((a => a.fieldname=== 'pep_relative'))[0]['value'];
            val = val === e.target.value ? '' : e.target.value;
            setInputFormValues({[e.target.name]: val});
            props.onChange(e.target.name, val);
            //console.log(e.target.name + "  " + val);
        }
    }


    const handleSwitchChange = (name, value) =>{
        //console.log(name+" : "+value) ;



        props.onChange(name,  value) ;
    }
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthNames_Ar = ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'];

    const locale = {
        localize: {
            month: n => lang === 'En' ? monthNames[n] : monthNames_Ar[n]
        },
        formatLong: {
            date: () => 'mm/dd/yyyy'
        }
    }
    return (

        <>
            <div className="formBlock">
                <h3>{props.boxtitle}<span className="info" onClick={props.showPepInfo}>?</span></h3>

                { props.section && props.section.map(item => {
                    return (

                        <>
                            { (item.showfield && item.inputtype === "text") ? (
                                <>
                                   <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}  ref={props.errors.indexOf(item.fieldname) === 0 ? myRef : null}>
                                           {item.label !== '' && <legend>{item.label}</legend>}
                                        <input type="text" name={item.fieldname}   value={item.value}    onChange={handleInput} className="formBlock__input" />
                                   </fieldset>
                                    {props.errors.indexOf(item.fieldname) > -1 &&
                                    <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                    }
                                    </>

                            ) : '' }


                            <div className={`splitBox-${props.parent ? ' mb20' : ''}`}>
                                {props.fieldlabel !== '' &&
                                <div className="splitBox__half-">
                                    <label className="textLabel">{props.fieldlabel}</label>
                                </div>
                                }
                                <div className="splitBox__half-">
                                    <label className="textContent" dangerouslySetInnerHTML={{__html: props.inputvalue}}/>

                                </div>

                            </div>

                            { (item.showfield && item.inputtype === "monthyear") ? (

                                    <div className={`splitBox__half monthpicker  monthpicker_${item.enlabel.toLowerCase()} monthpicker_${lang}`}>
                                   <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}  ref={props.errors.indexOf(item.fieldname) === 0 ? myRef : null}>
                                           {item.label !== '' && <legend>{item.label}</legend>}
                                        {/*<input type="text" name={item.fieldname}   value={item.value}    onChange={handleInput} className="formBlock__input" />*/}
                                       <DatePicker
                                           name={item.fieldname}
                                           selected={item.value}
                                           onChange={(date) => setPeriod(item.fieldname, date)}
                                           dateFormat="MMM yyyy"
                                           className="formBlock__input"
                                           minDate={item.minDate || ''}
                                           locale={locale}
                                           showMonthYearPicker
                                           placeholderText=""
                                           onChangeRaw={(e) => e.preventDefault()}
                                       />
                                   </fieldset>
                                    {props.errors.indexOf(item.fieldname) > -1 &&
                                    <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                    }
                                    </div>


                            ) : '' }
                            { (item.inputtype === "select" && showinput) ? (
                                <>
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}  ref={props.errors.indexOf(item.fieldname) === 0 ? myRef : null}>
                                    <legend>{item.label} {props.errors.indexOf(item.fieldname)}</legend>
                                    <select type="text"    name={item.fieldname}  onChange={handleInput}  className="formBlock__input formBlock__select">
                                        <option value="" >{data[lang]['select']}</option>
                                        { item.selectvalues &&  item.selectvalues.map(selectlabel => {
                                            return( <option  value={selectlabel.value} selected={selectlabel.value === item.value ? 'selected' : "" } > {selectlabel.option}</option>)

                                        })}
                                    </select>
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }
                            { item.inputtype === "switch" ? (
                                <div className={`splitBox splitBox--mBMd${item.child === true ? ' haveChild' : ''}`}>
                                    <div className="splitBox__w80Out">
                                        <label className="textLabel textLabel--type2">{item.label}</label>
                                    </div>
                                    <div className="splitBox__w80">
                                        <SwitchInput className=""  name={item.fieldname}   active={ item.value.toUpperCase() === "YES" ? true : false }  onChange={handleSwitchChange} value={item.value} />
                                    </div>
                                </div>

                            ) : ''}


                            {item.showfield && item.inputtype === "checklabel" ? (
                                <div className="">
                                    <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}  ref={props.errors.indexOf(item.fieldname) === 0 ? myRef : null}>

                                    <div className={`csCheckboxWrap ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                                        <div className="textLabel textLabel--type2">
                                            {item.selectvalues && item.selectvalues.map((selectlabel) => {
                                                return (<label className="csCheckbox crs_checkbox">
                                                    <input name="pep_category" type="radio"
                                                           checked={selectlabel.value === item.value ? 'checked' : ""}
                                                           className="csCheckbox__input" onClick={handleInput}
                                                           value={selectlabel.value}/>
                                                    <span className="csCheckbox__placeholder"/>
                                                    <label className="csCheckbox__text"
                                                           dangerouslySetInnerHTML={{__html: selectlabel.option}}></label>
                                                </label>)
                                            })
                                            }
                                        </div>
                                    </div>
                                    </fieldset>
                                    {props.errors.indexOf(item.fieldname) > -1 &&
                                    <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                    }
                                </div>
                            ) : ''}

                            {item.showfield && item.inputtype === "label" ? (
                                <div className={`splitBox splitBox--mBMd${item.child === true ? ' haveChild' : ''}`}>
                                    <div className="">
                                        <div className="textLabel textLabel--type2 inputHeadline" dangerouslySetInnerHTML={{__html: item.label}}/>
                                    </div>
                                </div>
                            ) : ''}

                        </>
                    )
                } )}
                <div className="flexCC mT15">
                    <span className="btn btn--primaryG btn--capsule"   onClick={() => props.closeModal(6)} >{data[lang]['close']}</span>
                </div>

            </div>
        </>
    )


}

export default Editform6
