import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as authorizationAction from '../actions/Authorization'

import Logo from '../assets/images/BM_logo.png'
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import {PWA_JOURNEY_STEPS} from "../constants";

class Willingtononresident extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: getToken().token,
            lang: getToken().lang,
            cif: getToken().cif
        }
    }

    componentDidMount() {
        this.props.createAccessLog('Willing to update as non resident?' , 'Started', 'Page Launched', this.state.cif)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log(this.props.customerTypeData.custiddata)
        if(this.props.customerTypeData.custiddata !== undefined){
            if(this.props.customerTypeData.custiddata !==  prevProps.customerTypeData.custiddata){
                this.props.createAccessLog('Customer updated as non resident' , 'Updated', 'Updated as non resident', this.state.cif)
                //window.location = "/instructions" ;
                this.props.history.push('/' + PWA_JOURNEY_STEPS[1]);
            }
        }
    }
    updateCutomerType = () =>{
        this.props.createAccessLog('Yes selected' , 'Selected', 'Yes Option selected', this.state.cif)
        this.props.updateCustomerType('Non-Resident', this.state.token) ;

    }
    closeAccount = () =>{
        this.props.createAccessLog('No selected' , 'Selected', 'No Option selected', this.state.cif)
        //window.location = "/contact-branch" ;
        this.props.history.push('/contact-branch');
    }
    render(){
        return(
            <>
            <Header className="hasCrossOver hasCrossOver--lg">
            <Link className="logoLink">
            <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
            </Link>
            </Header>


            <div className={`wPanel ${this.state.lang === "Ar" ? 'ct-arabic' : ''}`}>
            <p className="alignCenter">{data[this.state.lang]['updateAsNonResident']}</p>
                <div className="buttomWrap">
                    <div className="flexCC">
                        <Link onClick={() => this.updateCutomerType()} className="btn btn--capsule btn--primaryG">{data[this.state.lang]['updateAsNonResidentOption1']}</Link>
                    </div>
                    <div className="flexCC">
                        <Link onClick={() => this.closeAccount()} className="btn btn--capsule btn--primaryOuter">{data[this.state.lang]['updateAsNonResidentOption2']}</Link>
                    </div>
                </div>
            </div>
            </>

        );
    }
}


const mapStateToProps = state => ({
    customerTypeData: state.CustomerReducer.customerTypeData
});


export default withRouter(connect(mapStateToProps, {
    updateCustomerType : authorizationAction.UpdateCustomerType,
    createAccessLog : authorizationAction.createAccessLog
})(Willingtononresident));
