import React, {useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Header from '../components/Header';
import {useDispatch, useSelector} from "react-redux";

//import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'
import Pdf from '@mikecousins/react-pdf';
import {kycStore} from "../reducers/KYCState";




import {
    updateNextLevel,
    getUploads,
    getFacematchscore,
    updateFacematchResult,
    updateCustomerLIDInfo,
    UpdateNonSTP,
    stringMatchScore,
    updateJourneyLevel,
    fetchUploads, createAccessLog, redoJourney, updateReason
} from "../actions/Authorization";
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import Loader from './Loader'
import Popup from "./Popup";



const Details = (props) => {
    const lang = getToken().lang;
    const custIdTp = getToken().idtype ;
    const redoAfterSuspecious = 1;
    const livenessstatus = getToken().livenessstatus;
    const [redo, setredo] = useState(false);
    const [nonstp, setNonstp] = useState(getToken().nonstp);
    const [hasError, setErrors] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [popupErrorMsg, setPopupErrorMsg] = useState('');
    const [custdata, setCustdata] = useState([]);
    const [agreeterms, setAgreeterms] = useState(false);
    const [journeyid] = useState(getToken().journeyid);
    const [renewType] = useState(getToken().renewType);
    const [loading, setLoading] = useState(true);
    const [extractFront, setExtractFront] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState('');

    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [idType, setIdtype] = useState('');
    const [idNumber, setIdNumber] = useState('');

    const [expDate, setExpDate] = useState('NA');
    const [gender, setGender] = useState('NA');
    const [ocrdep1, setOcrdep1] = useState(false);
    const [ocrdep2, setOcrdep2] = useState(false);
    const [legalIdType, setLegalIdType] = useState('');
    const [bankData, setBankData] =   useState({});
    const [ocrData, setOcrData] =   useState({});
    //const [facematchLimits, setFacematchLimits] =   useState({});



    const [birthDate, setBirthDate] = useState('');
    const [faceMatchScore, setFaceMatchScore] = useState('');

    const [facematchstatus, setFacematchstatus] = useState('');
    const [namematchstatus, setNamematchstatus] = useState('');
    const [custidtype] = useState(getToken().idtype);
    const [terms, setTerms] = useState(false);




    const [cif] = useState(getToken().cif);
    const customerdata = getToken().custinfo ;//console.log(customerdata)



    const uploaddata = useSelector(state => state.AppReducer.uploadRespdata) ;
    //let facematchdata = useSelector(state => state.AppReducer.fmatchScore) ;
    //let facematchdata = kycStore.getState().KYCState.facematchdata.result;// ? kycStore.getState().KYCState.facematchdata : facematchdata
    //const facematchdata =  kycStore.getState().KYCState.facematchdata ;
    //console.log(kycStore.getState().KYCState.facematchdata)
    //console.log(kycStore.getState().KYCState)
    //console.log(facematchdata)
    let namematchdata = useSelector(state => state.AppReducer.nmatchScore) ;
    namematchdata = kycStore.getState().KYCState.namematchdata ? kycStore.getState().KYCState.namematchdata : namematchdata
    const customerupdatestats = useSelector(state => state.AppReducer.clidInfoResp  ) ;

    let fetchUploadsData = useSelector(state => state.AppReducer.fetchUploadsData  ) ;

    const redoJourneyStatus = useSelector(state => state.AppReducer.redoJourneyStatus  ) ;

    let history = useHistory();
    const dispatch = useDispatch();
    const [highRisk, sethighRisk] = useState(false);
    const [nonSTP, setNonSTP] = useState(false);
    const [firstimage,setFirstimage] = useState('') ;
    const [secondimage,setSecondimage] = useState('') ;
    const [doctype,setDoctype] = useState('') ;
    const [calledStringMatch,setcalledStringMatch] = useState(false) ;
    const [facematchfrmdata,setFacematchfrmdata] = useState('') ;
    const[cid,setCid] = useState("") ;
    const [numPages, setNumPages] = useState(21);
    const [pageNumber, setPageNumber] = useState(1);
    const redoUserJourney = kycStore.getState().KYCState.redo_journey

    const updateRejectedReason = (reason) =>{
        //console.log(reason) ;
        dispatch(updateReason(reason)) ;
    }

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }
    function onDocumentLoadError(error) {
        //console.log(error)
    }

    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPDFPage() {
        changePage(-1);
    }

    function nextPDFPage() {
        changePage(1);
    }
    const checkConfig = () => {
        if(getToken().BM_PWA_API === ''){
            setTimeout(checkConfig, 100)
        }
    }
    useEffect(() => {//console.log(redoJourneyStatus)
        if(redoJourneyStatus === 'failure') {
            setLoading(false)
            setPopupErrorMsg(data[lang]['unabletocommunicate']);
        }
    }, [redoJourneyStatus]);
    useEffect(() => {
        dispatch(createAccessLog('Facematch Limits' , 'Success', 'Facematch limits ' + getToken().faceMatchAcceptedScore + ', ' + getToken().faceMatchLowScore + ' - Namematch limit ' + getToken().nameMatchAcceptedScore,cif));
        /*if(kycStore.getState().KYCState.fetchresult === '') {
            dispatch(fetchUploads(journeyid));
        } else {
            fetchUploadsData = kycStore.getState().KYCState.fetchresult;
        }*/
        const params = (new URL(document.location)).searchParams;
        let ref = params.get("ref");
        //console.log(inputValues)
        if (ref === 'otp') {
            if(kycStore.getState().KYCState.fetchresult === '') {
                dispatch(fetchUploads(journeyid));
            } else {
                fetchUploadsData = kycStore.getState().KYCState.fetchresult;
            }
        } else {
            fetchUploadsData = {};

            dispatch(fetchUploads(journeyid));
        }
    }, [journeyid]);



    const [htoken] = useState(getToken().token);
    useEffect(() => {
        //console.log(custIdTp + '---' + extractFront)
        if(extractFront === true) {
            dispatch(updateNextLevel(htoken, 3));
            let legalId = '';
            if (custIdTp === "National") {
                legalId = "NID";
            } else if (custIdTp === "Resident") {
                legalId = "CIVIL.ID";
            } else {
                legalId = "PASSPORT.ID";
            }
            //dispatch(createAccessLog('Legal Id', 'Set', 'custIdTp - ' + custIdTp + ' legalId - ' + legalId, cif));

            setLegalIdType(legalId);
        }

    }, [custIdTp, extractFront]);




   /* useEffect(() => {
        console.log(facematchdata)
        console.log(getToken())
        console.log(renewType)
        if(facematchdata !== undefined && (getToken().faceMatchAcceptedScore !== undefined && getToken().faceMatchAcceptedScore !== '' && getToken().faceMatchAcceptedScore !== null) && (getToken().faceMatchLowScore !== undefined && getToken().faceMatchLowScore !== '' && getToken().faceMatchLowScore !== null)  && renewType === 'KYC'){
            // console.log('facematchdata is defined - success')
            let userFacematchData = facematchdata;
            if(getToken().pt === 1){
                userFacematchData = {
                    message: "Success",
                    result: {
                        fvconfidence: 100
                    }
                };
            } else {
                if(facematchdata.fvconfidence) {
                    userFacematchData = {
                        message: "Success",
                        result: facematchdata
                    };
                } else {
                    userFacematchData = {
                        message: "",
                        result: facematchdata
                    };
                }
            }

            if(userFacematchData.message !== undefined){
                // console.log('facematchdata is defined, facematchdata.message defined - success')
                //console.log(userFacematchData)
                //console.log(userFacematchData.message)
                if(userFacematchData.message === "Success"){
                    //if(faceMatchScore === '') {
                        dispatch(createAccessLog('getFacematchscore', 'Success', 'Got face match score ' + userFacematchData.result.fvconfidence, cif));
                        //setLoaderMessage('Your face match score is ' + facematchdata.result.fvconfidence);
                        setFaceMatchScore(userFacematchData.result.fvconfidence)

                        if (userFacematchData.result.fvconfidence < getToken().faceMatchLowScore) {
                            dispatch(createAccessLog('setFacematchstatus', 'Success', 'Suspecious', cif));
                            setFacematchstatus('Suspecious');
                            //console.log('===== 1 ============')
                            //console.log('Suspecious')
                            //console.log(redoAfterSuspecious)
                            if (redoAfterSuspecious === 1 && redo === false) {

                                setLoading(false)
                                setredo(true)
                            }
                        } else if (userFacematchData.result.fvconfidence >= getToken().faceMatchAcceptedScore) {
                            dispatch(createAccessLog('setFacematchstatus', 'Success', 'Normal', cif));
                            setFacematchstatus('Normal');
                            kycStore.getState().KYCState.BM_facematchnstp = false;
                        } else {
                            kycStore.getState().KYCState.BM_facematchnstp = true;
                            dispatch(createAccessLog('setFacematchstatus', 'Success', 'Non STP', cif));
                            setFacematchstatus('Non STP');
                        }
                        /!*setFacematchstatus('Normal') ;*!/

                        var resp = {
                            "data": userFacematchData.result,
                            "status": "Success"
                        }
                        updateFacematchProgress(resp);
                    //}
                    }
                else{//console.log(userFacematchData.message)
                    dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match result failed',cif));
                    if(userFacematchData.result) {
                        /!*   setFacematchstatus('Suspecious') ; *!/
                        var resp = {
                            "data": userFacematchData.result,
                            "status": "Failure"
                        }
                        updateFacematchProgress(resp);
                        }
                    else {
                        //dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match result failed',cif));
                        setLoading(false)
                        setPopupErrorMsg(data[lang]['unabletocommunicate']);
                        }
                    }
                }
            else{
                // console.log('facematchdata is defined, facematchdata.message undefined')
                // dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match data defined, Face match message not defined',cif));
                }
            }
        else{
            // console.log('facematchdata undefined')
            // dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match data not defined',cif));
            }

    },[facematchdata, getToken().faceMatchLowScore, getToken().faceMatchAcceptedScore, renewType]);


    useEffect(() => {
        if(facematchdata !== undefined && (getToken().faceMatchAcceptedScore !== undefined && getToken().faceMatchAcceptedScore !== '' && getToken().faceMatchAcceptedScore !== null) && renewType === 'LID'){
            // console.log('facematchdata is defined - success')
            let userFacematchData = facematchdata;
            if(getToken().pt === 1){
                userFacematchData = {
                    message: "Success",
                    result: {
                        fvconfidence: 100
                    }
                };
            }
            if(userFacematchData.message !== undefined){
                // console.log('facematchdata is defined, facematchdata.message defined - success')

                if(userFacematchData.message === "Success"){
                    dispatch(createAccessLog('getFacematchscore' , 'Success', 'Got face match score ' + userFacematchData.result.fvconfidence,cif));
                    //setLoaderMessage('Your face match score is ' + facematchdata.result.fvconfidence);
                    setFaceMatchScore(userFacematchData.result.fvconfidence )

                    if(userFacematchData.result.fvconfidence < getToken().faceMatchAcceptedScore  ){
                        dispatch(createAccessLog('setFacematchstatus' , 'Success', 'Suspecious',cif));
                        setFacematchstatus('Suspecious') ;
                        //console.log('Suspecious')
                        //console.log(redoAfterSuspecious)
                        //console.log('===== 2 ============')
                        //console.log('Suspecious')
                        if(redoAfterSuspecious === 1 && redo === false) {

                            setLoading(false)
                            setredo(true)
                        }
                        }
                    else {
                        dispatch(createAccessLog('setFacematchstatus' , 'Success', 'Normal',cif));
                        setFacematchstatus('Normal') ;
                        }
                    /!*setFacematchstatus('Normal') ;*!/

                    var resp = {
                        "data" : userFacematchData.result,
                        "status" : "Success"
                    }
                    updateFacematchProgress(resp) ;
                    }
                else{
                    dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match result failed',cif));
                    if(userFacematchData.result) {
                        /!*   setFacematchstatus('Suspecious') ; *!/
                        var resp = {
                            "data": userFacematchData.result,
                            "status": "Failure"
                        }
                        updateFacematchProgress(resp);
                        }
                    else {
                        //dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match result failed',cif));
                        setLoading(false)
                        setPopupErrorMsg(data[lang]['unabletocommunicate']);
                        }
                    }
                }
            else{
                // console.log('facematchdata is defined, facematchdata.message undefined')
                // dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match data defined, Face match message not defined',cif));
                }
            }
        else{
            // console.log('facematchdata undefined')
            // dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match data not defined',cif));
            }

    },[facematchdata, getToken().faceMatchAcceptedScore, renewType]);*/

    useEffect(() => {
        if(getToken().nameMatchAcceptedScore !== undefined && getToken().nameMatchAcceptedScore !== '' && getToken().nameMatchAcceptedScore !== null && renewType === 'KYC') {
            let userNamematchData = namematchdata;
            if(getToken().pt === 1){
                userNamematchData = 100;
            }
            if (userNamematchData !== undefined && userNamematchData < getToken().nameMatchAcceptedScore) {
                dispatch(createAccessLog('Name Match', 'Error', 'Name match result is low ' + userNamematchData, cif));
                setNamematchstatus('low')
                sethighRisk(true);
                if(customerdata.sector_code !== '1002' ) {
                    setNonSTP(true);
                    dispatch(UpdateNonSTP(true));
                }
                updateRejectedReason(data['En']['ocrMatchingError'])
                dispatch(updateJourneyLevel(3, "Rejected", "namematch"));
                history.push('/contact-branch?ref=5');

            }
            if (userNamematchData !== undefined && userNamematchData >= getToken().nameMatchAcceptedScore) {
                setNamematchstatus('high')
                dispatch(createAccessLog('Name Match', 'Success', 'Name match result is high ' + userNamematchData, cif));
            }
        }

    },[namematchdata, getToken().nameMatchAcceptedScore]);

    useEffect(() => {
        if(getToken().nameMatchAcceptedScore !== undefined && getToken().nameMatchAcceptedScore !== '' && getToken().nameMatchAcceptedScore !== null && renewType === 'LID') {
            let userNamematchData = namematchdata;
            if(getToken().pt === 1){
                userNamematchData = 100;
            }
            if (userNamematchData !== undefined && userNamematchData < getToken().nameMatchAcceptedScore) {
                dispatch(createAccessLog('Name Match', 'Error', 'Name match result is low ' + userNamematchData, cif));
                setNamematchstatus('low')
                //setNonSTP(true);
                //dispatch(UpdateNonSTP(true));
                updateRejectedReason(data['En']['ocrMatchingError'])
                dispatch(updateJourneyLevel(3, "Rejected", "namematch"));
                history.push('/contact-branch?ref=5');
            }
            if (userNamematchData !== undefined && userNamematchData >= getToken().nameMatchAcceptedScore) {
                setNamematchstatus('high')
                dispatch(createAccessLog('Name Match', 'Success', 'Name match result is high ' + userNamematchData, cif));
            }
        }

    },[namematchdata, getToken().nameMatchAcceptedScore]);

    const updateFacematchProgress = (resp) =>{

        const myObjStr = JSON.stringify(resp);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
       /*  console.log(myObjStr) ;
        console.log(enc_myObjStr);  */
        setFacematchfrmdata(enc_myObjStr)  ;

    }

    useEffect(() => {
        if(facematchfrmdata !== ''){
            dispatch(updateFacematchResult(journeyid,facematchfrmdata)) ;
        }

    },[facematchfrmdata]);


    useEffect(() => {
        //console.log(extractFront)
        if(uploaddata !== undefined && extractFront === true){
           /*  console.log(uploaddata.result) ; */
            if(getToken().pt === 0) {
                if (uploaddata.message === 'Failure') {
                    dispatch(createAccessLog('OCRExtraction', 'Error', 'Error on extracting customer details from ID card', cif));
                    setLoading(false);
                    setPopupErrorMsg(data[lang]['unabletocommunicate'])
                }
            }
            if(uploaddata.result !== undefined){
                dispatch(createAccessLog('OCRExtraction' , 'Success', 'Extracting customer details from ID card',cif));
                //console.log(uploaddata)
                Object.keys(uploaddata.result).map(function(key, index) {
                    //console.log(key)
                    if(key === "IndiaPanFront"){
                        setDoctype(key) ;
                        let fimage = uploaddata.result.IndiaPanFront;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        setFirstimage('data:image/jpeg;base64,' + fimage);
                    }else if( key === "PassportFront"){
                        setDoctype(key) ;
                        let fimage = uploaddata.result.PassportFront;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        setFirstimage('data:image/jpeg;base64,' + fimage);
                    }else if( key === "OmanResidentIdFront" ){
                        setDoctype(key) ;
                        let fimage = uploaddata.result.OmanResidentIdFront;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        //console.log(fimage)
                        setFirstimage('data:image/jpeg;base64,' + fimage);
                    }else if(  key === "OmanNationalIdFront"  ){
                        setDoctype(key) ;
                        let fimage = uploaddata.result.OmanNationalIdFront;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        setFirstimage('data:image/jpeg;base64,' + fimage);
                    }else if(  key === "OmanNationalIdBack"  ){
                        setDoctype(key) ;
                        let fimage = uploaddata.result.OmanNationalIdBack;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        setSecondimage('data:image/jpeg;base64,' + fimage);
                    }else if( key === "OmanResidentIdBack" ){
                        setDoctype(key) ;
                        let fimage = uploaddata.result.OmanResidentIdBack;
                        fimage = fimage.replace("b'","");

                        fimage = fimage.substr(0,fimage.length -1) ;
                        setSecondimage('data:image/jpeg;base64,' + fimage);
                    }


                });
                setLoaderMessage(data[lang]['50completed'])
            }

        }

    },[uploaddata, extractFront]);






    useEffect(() => {
        if(fetchUploadsData !== undefined && journeyid !==undefined && cif !== undefined){
            //console.log(fetchUploadsData)
            let userCardData = fetchUploadsData;
            if(getToken().pt === 1){
                userCardData = {
                    message: "success",
                    flag: '',
                    result: {
                        name: "EDWIN SANTHAKUMAR",
                        idnumber: "77412473",
                        dob: "22/10/1973",
                        doe: "28/06/2025",
                        gender: "M",
                        type: "OmanResidentIdFront"
                    }
                };
            }
            if(userCardData.message  === "Failure"){
                dispatch(createAccessLog('Fetch ID Card' , 'Failure', 'Fetch API failed',cif));
                setLoading(false);
                setExtractFront(false);
                setPopupErrorMsg(data[lang]['unabletocommunicate'])
            }
            if(userCardData.message  === "error"){
                dispatch(createAccessLog('Fetch ID Card' , 'Error', userCardData.flag,cif));
                setLoading(false);
                setExtractFront(false);
                //setPopupErrorMsg(data[lang]['ocrDocExtractionError'])
                let userId = ''
                if (custIdTp === "National") {
                    userId = "National ID";
                } else if (custIdTp === "Resident") {
                    userId = "Civil ID";
                } else {
                    userId = "Passport";
                }
                setPopupErrorMsg(data[lang][userCardData.flag].replace('{idname}', data[lang][userId]))
                //dispatch(updateJourneyLevel(2, "Rejected", "fetch"));
            }
            if(userCardData.message  === "success"){
                //console.log('fetchUploadsData received - Success')
                //dispatch(createAccessLog('Fetch ID Card' , 'Success', 'Got the card data successfully',cif));
                setExtractFront(true);
                /*if(!custdata.status) {
                    dispatch(customerInfo(journeyid, cif));
                }*/
                setLoading(false);
               // setLoadingmsg(processFileOCRResp.message)


                 const result = userCardData.result ;

                setName(result['name'] || '');
                setSurname(result['surname'] || '');
                setExpDate(result['doe'] || '');
                setBirthDate(result['dob'] || '');
                let userGender = result['gender'] || '';
                if(userGender.toUpperCase() === 'M') {
                    userGender = 'Male';
                }
                if(userGender.toUpperCase() === 'F') {
                    userGender = 'Female';
                }

                setGender(userGender);
                //setNationality(result['nationality'] || '');
                setIdNumber(result['idnumber'] || '');
                //setIdtype(result['type']);
                if(result['type'] === "IndiaPanFront"){
                    setIdtype("PAN" || '');
                }else if(result['type'] === "IndiaAdharFront"){
                    setIdtype("Adhar" || '');
                }else if(result['type'] === "PassportFront"){
                    setIdtype("Passport" || '');
                }else if(result['type'] === "OmanResidentIdFront" || result['type'] === "OMANRESIDENTIDFRONT" || result['type'] === "OmanResidentIdBack" || result['type'] === "OMANRESIDENTIDBACK"){
                    setIdtype("Civil ID" || '');
                }else if(result['type'] === "OmanNationalIdFront" || result['type'] === "OMANNATIONALIDFRONT" || result['type'] === "OmanNationalIdBack" || result['type'] === "OMANNATIONALIDBACK"){
                    setIdtype("National ID" || '');
                }
                //console.log('Get facematchscore after fetchUploadsData received...')
                //dispatch(createAccessLog('Facematch score' , 'Success', 'Get facematchscore after fetchUploadsData received',cif));
                /*if(doctype !== undefined && doctype !== '') {
                    facematchscore('on_fetchUploadsData');
                }*/
                setOcrdep1(true)
                setOcrdep2(true)

                // facematch
                let facialMatch = result.facialMatch;
                //console.log(facialMatch)
                if(livenessstatus === 'OFF') {
                    setFacematchstatus('Normal');
                    kycStore.getState().KYCState.BM_facematchnstp = false;
                }
                if(facialMatch !== undefined && livenessstatus === 'ON'){
                    // console.log('facematchdata is defined, facematchdata.message defined - success')
                    //console.log(userFacematchData)
                    //console.log(userFacematchData.message)
                    if(facialMatch !== ""){
                        //if(faceMatchScore === '') {
                        dispatch(createAccessLog('getFacematchscore', 'Success', 'Got face match score ' + facialMatch.fvconfidence, cif));
                        //setLoaderMessage('Your face match score is ' + facematchdata.result.fvconfidence);
                        setFaceMatchScore(facialMatch.fvconfidence)

                        if (facialMatch.fvconfidence < getToken().faceMatchLowScore) {
                            dispatch(createAccessLog('setFacematchstatus', 'Success', 'suspicious', cif));
                            setFacematchstatus('Suspecious');
                            //console.log('===== 1 ============')
                            //console.log('Suspecious')
                            //console.log(redoAfterSuspecious)
                            if (redoAfterSuspecious === 1 && redo === false) {
                                updateRejectedReason(data['En']['facematchSuspeciousError'].replace(new RegExp('{idname}', 'g')))
                                setLoading(false)
                                setredo(true)
                            }
                        } else if (facialMatch.fvconfidence >= getToken().faceMatchAcceptedScore) {
                            dispatch(createAccessLog('setFacematchstatus', 'Success', 'Normal', cif));
                            setFacematchstatus('Normal');
                            kycStore.getState().KYCState.BM_facematchnstp = false;
                        } else {
                            kycStore.getState().KYCState.BM_facematchnstp = true;
                            dispatch(createAccessLog('setFacematchstatus', 'Success', 'Non STP', cif));
                            setFacematchstatus('Non STP');
                        }
                        /*setFacematchstatus('Normal') ;*/

                        var resp = {
                            "data": facialMatch,
                            "status": "Success"
                        }
                        updateFacematchProgress(resp);
                        //}
                    }
                    /*else{//console.log(userFacematchData.message)
                        dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match result failed',cif));
                        if(facialMatch) {
                            /!*   setFacematchstatus('Suspecious') ; *!/
                            var resp = {
                                "data": facialMatch,
                                "status": "Failure"
                            }
                            updateFacematchProgress(resp);
                        }
                        else {
                            //dispatch(createAccessLog('getFacematchscore' , 'Error', 'Face match result failed',cif));
                            setLoading(false)
                            setPopupErrorMsg(data[lang]['unabletocommunicate']);
                        }
                    }*/
                }
            }


           /*  generateReference() ; */
        }


    }, [fetchUploadsData, journeyid, cif]);










    useEffect(() => {
        if(extractFront === true && kycStore.getState().KYCState.frontimage === '') {
            fetchAllGioemDocs(journeyid);
            /* processFiles() ; */
        } else {
            setDoctype(kycStore.getState().KYCState.doctype)
            setFirstimage(kycStore.getState().KYCState.frontimage);
        }
    },[journeyid,cif, extractFront]);

    /*useEffect(() => {
        if(extractFront === true && doctype !== '') {
            facematchscore('on_fetchUploadsData');
            /!* processFiles() ; *!/
        }
    },[doctype, extractFront]);*/



    const  fetchAllGioemDocs = (jid) =>{
       /*  console.log(jid) ; */
        dispatch(getUploads(jid)) ;
        setLoaderMessage(data[lang]['waittofetch'])
    }

    const facematchscore = (call_event) =>{
        //dispatch(createAccessLog('getFacematchscore' , 'Started', 'Get face match score started - '+call_event,cif));
        //let formdata = new FormData();
        //formdata.append('userid', journeyid);
        //formdata.append('scrutinydoctype', doctype);
        //formdata.append('mode', call_event);
        //console.log(formdata)
        //setLoaderMessage(data[lang]['facialmatchProcessing'])
        //console.log(kycStore.getState().KYCState.facematchdata);
        //facematch commented 19/10/2022
        /*if(!kycStore.getState().KYCState.facematchdata.result && kycStore.getState().KYCState.BM_PT === 0) {
            dispatch(getFacematchscore(doctype));
        }*/



    }



    useEffect( () => {
        //console.log(ocrData);
       // console.log(bankData);
        if(extractFront === true) {

            const myObjStr1 = JSON.stringify(ocrData);
            const enc_myObjStr1 = new Buffer(myObjStr1).toString('base64');

            const myObjStr2 = JSON.stringify(bankData);
            const enc_myObjStr2 = new Buffer(myObjStr2).toString('base64');

            let matchData = 'doctype - ' + doctype + ' (OCR Name - ' + ocrData.name + ', OCR ID - ' + ocrData.id + ', T24 Name - ' + bankData.name + ', T24 ID - ' + bankData.id + ') ';

            // console.log('ocrData.name '+ocrData.name +' bankData.name: '+ bankData.name +' ocrData.id: '+ ocrData.id+  ' bankData.id:'+ bankData.id)
            //dispatch(createAccessLog('Name Match', 'Precheck', matchData, cif));

            if (ocrData &&
                bankData &&
                ocrData.name && bankData.name && ocrData.id && bankData.id &&
                ocrData.name !== '' && bankData.name !== '' && ocrData.id !== '' && bankData.id !== ''
                && doctype !== '' && calledStringMatch === false
            ) {
                let matchData = ' (OCR Name - ' + ocrData.name + ', OCR ID - ' + ocrData.id + ', T24 Name - ' + bankData.name + ', T24 ID - ' + bankData.id + ') ';
                dispatch(createAccessLog('Name Match', 'Started', 'Matching names from T24 and OCR ' + matchData, cif));
                setcalledStringMatch(true);
                setLoaderMessage(data[lang]['datamatchProcessing'])
                if(kycStore.getState().KYCState.namematchdata === '' && kycStore.getState().KYCState.BM_PT === 0) {
                    dispatch(stringMatchScore(enc_myObjStr1, enc_myObjStr2, doctype, cif));
                }
            }

        }

    }, [bankData, ocrData, doctype, extractFront])

    useEffect(() => {
        setCustdata(customerdata)

        if(getToken().customer_id !== undefined){

            setCid(getToken().customer_id) ; setOcrdep1(true) ;

           }
        //console.log(customerdata)
        //console.log(legalIdType)
        if(customerdata && legalIdType !== ''){
            //dispatch(createAccessLog('FetchCustomerDetails' , 'Success', 'Fetch customer details from bank',cif));
            let legalId = '';
            if(customerdata.LglDocDtl !== undefined){
                //console.log(customerdata.LglDocDtl) ;
                let lglDoc  = customerdata.LglDocDtl  ;
                /* Object.keys(lglDoc).map(function(key, index) {
                    console.log(key) ;
                }) ; */
               // console.log(lglDoc.length);


                if(Array.isArray(lglDoc)) {
                    lglDoc.map((docs, indx) => {
                        if (docs.BMLglDocName === legalIdType) {
                            legalId = docs.LegalDocId;
                        }
                        //console.log(docs.BMLglDocName + " " + docs.LegalDocId);
                    });
                } else {
                    if(lglDoc.BMLglDocName === legalIdType ){
                        legalId = lglDoc.LegalDocId ;
                    }
                }
            }
            //dispatch(createAccessLog('FetchCustomerDetails' , 'Bank Data', 'legalId - ' + legalId + ' name - ' + customerdata.name,cif));

            let bdata = {
                id: legalId,
                name: customerdata.name
            } ;
            //console.log(bdata)
            setBankData( bdata )
            return;

        } else if(customerdata.status === 'error' || customerdata.status === 'Failure') {

            dispatch(createAccessLog('FetchCustomerDetails' , 'Error', 'Error on Fetch customer details from bank',cif));
            setLoading(false)
            setPopupErrorMsg(data[lang]['unabletofetch'])
        } else if(customerdata.message && (customerdata.message === 'Insufficient Data')) {
            dispatch(createAccessLog('FetchCustomerDetails', 'Error', 'Insufficient Data', cif));
            setPopupErrorMsg(data[lang]['unabletofetch']);
        }





        /*if(mscore >= ascore){

        }else{
            sethighRisk(true);
            setNonSTP(true);
            dispatch(UpdateNonSTP(true));
        }*/
    },[customerdata, legalIdType]);


    useEffect(() => {

        if(ocrdep1 === true && ocrdep2 === true){
            let custname = name + ' ' + surname;
            if(idNumber !== '' && custname.trim() !== '') {
                let oData = {
                    id: idNumber,
                    name: custname.trim()
                }
                //console.log('setOcrData oData', oData);
                setOcrData(oData);
            }

            if(idNumber === '') {
                dispatch(createAccessLog('Fetch ID Card' , 'Error', 'Error in extracting your details.',cif));
                setLoading(false);
                setPopupErrorMsg(data[lang]['ocrDocExtractionError'])
            }

            /*if(cid !== '' && expDate !== '' &&  idNumber !== '' && namematchdata != undefined) {
                const today = new Date();
                today.setHours(0, 0, 0, 0);
                let doe = expDate;
                let doeParts = doe.split('/');
                if(new Date(doeParts[2] + '-' + doeParts[1] + '-' + doeParts[0]) < today) {
                    dispatch(createAccessLog('Matching card' , 'Error', 'Card has been expired. Expiry date is ' + doe,cif));
                    dispatch(updateJourneyLevel(3, "Rejected"));
                    history.push('/contact-branch?ref=8');
                }


            }*/
        }
    }, [ocrdep1, ocrdep2, surname, name, namematchdata, getToken().nameMatchAcceptedScore]);



    function nextPage(){
        setErrors(false);
        setErrorMsg('')
      //console.log(namematchstatus)  ;
      if(agreeterms) {
          let custname = name + ' ' + surname;
          let doe = expDate;
          let dob = birthDate;
          dispatch(createAccessLog('Extracted Details' , 'Success', 'custname - ' + custname.trim() + ', idNumber - ' + idNumber + ', expDate - ' + doe + ', birthDate - ' + dob ,cif));

          if(custname.trim() === '' ||  idNumber === '' || doe === '' || dob === '') {
              //console.log(custname.trim())
              history.push('/contact-branch?ref=7');
          } else if (facematchstatus === "Suspecious" || facematchstatus === "Non STP" || facematchstatus === "Normal") {
              //console.log(facematchstatus);
              //console.log(redoAfterSuspecious);

              if (facematchstatus === "Suspecious") {
                  /*if(redoAfterSuspecious === 0) {
                      dispatch(updateJourneyLevel(3, "Rejected", "facematch"));
                      history.push('/contact-branch?ref=4');
                  } else {
                      if(redo === false) {
                          setLoading(false)
                          setredo(true)
                      }
                  }*/
              } else {
                  updateJourneyLevel(3, "Completed");
                  if (facematchstatus === "Non STP") {
                        //console.log('dispatch UpdateNonSTP on click nextpage')
                      if(customerdata.sector_code !== '1002' ) {
                          setNonSTP(true);
                          dispatch(UpdateNonSTP(true));
                      }
                  }
                  if (renewType === "KYC") {
                      history.push('/customer-details');

                  } else {

                      const forminput = {
                          name: name + ' ' + surname,
                          cust_id_type: custidtype,
                          jid: journeyid
                      }

                      const myObjStr = JSON.stringify(forminput);
                      //console.log(cid + " " + myObjStr);
                      const enc_myObjStr = new Buffer(myObjStr).toString('base64');
                      dispatch(updateCustomerLIDInfo(cid, enc_myObjStr));
                      /*  history.push('/submit-otp'); */
                  }


              }
          } else {
              dispatch(createAccessLog('getFacematchscore' , 'Error', 'No Face match result ',cif));
              setLoading(false)
              setPopupErrorMsg(data[lang]['unabletocommunicate']);

          }



     } else {
          //dispatch(createAccessLog('AgreeTerms' , 'Error', 'Accept terms and conditions',cif));
          setErrors(true);
          setErrorMsg(data[lang]['agreeTermsError'])
      }
    }

    useEffect(() => {
        // console.log(customerupdatestats) ;
        if(customerupdatestats !== undefined){
            if(customerupdatestats.status === "success") {
                dispatch(createAccessLog('Update Customer Data' , 'Success', 'Updated customer status',cif));
                  history.push('/submit-otp');
            }
        }
    },[customerupdatestats]);
    function iOS() {
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }
    const viewTerms = () => {
        if(iOS()) {
            setTerms(true)
            setPageNumber(1)
        } else {
            //setTerms(true)
            window.open("https://www.bankmuscat.com/en/about/Documents/bmforms/individuals_TC.pdf", "_blank")
        }
        dispatch(createAccessLog('Terms & Conditions' , 'Clicked', 'https://www.bankmuscat.com/en/about/Documents/bmforms/individuals_TC.pdf',cif));
        //window.open("https://www.bankmuscat.com/en/about/Documents/bmforms/individuals_TC.pdf", "_blank")
        //window.open("https://www.irs.gov/pub/irs-pdf/fw8ben.pdf", "_blank")
    }
    const hideTerms = () => {
        setTerms(false)
    }
    const restartJourney = () => {
        setLoading(true)
        setLoaderMessage('&nbsp;')
        dispatch(redoJourney(history));
    }
/*    console.log(lidNameAcceptedScore) ;
    console.log(lidFaceAcceptedScore) ;
    console.log(OCRAcceptedScore) ;
    console.log(facematchAcceptedScore) ;
    console.log(facematchLowScore) ;

    console.log('renewType: ' + renewType) ;

 console.log(loading)
 console.log(facematchstatus)
 console.log(namematchstatus);
 console.log((renewType === 'KYC' && (OCRAcceptedScore === '' || facematchAcceptedScore === '' || facematchLowScore === '')));
 console.log((renewType === 'LID' && (lidNameAcceptedScore === '' || lidFaceAcceptedScore === '')));*/
    let doe = expDate;
    let dob = birthDate;
    let userId = ''
    if (custIdTp === "National") {
        userId = "National ID";
    } else if (custIdTp === "Resident") {
        userId = "Civil ID";
    } else {
        userId = "Passport";
    }
    return (
        <>
            {
                redo === true &&
                <>
                    <Popup messagehead={data[lang]['oops']} message={data[lang]['facematchSuspeciousError'].replace(new RegExp('{idname}', 'g'),  data[lang][userId])} returnToPage={true} closePopupModal={restartJourney}/>
                </>
            }
            {popupErrorMsg !== '' ?
                <Popup messagehead={data[lang]['oops']} message={popupErrorMsg}/>
                :
                <>
                {((renewType === 'KYC' && (getToken().nameMatchAcceptedScore === '' || getToken().faceMatchAcceptedScore === '' || getToken().faceMatchLowScore === '')) ||
                    (renewType === 'LID' && (getToken().nameMatchAcceptedScore === '' ||  getToken().faceMatchAcceptedScore === ''))) ?
                    <Loader message={data[lang]['fetchingDetails']}/>
                    :
                    <>
                        {(loading || firstimage === '' || name === '' || facematchstatus === '' || namematchstatus === '') &&
                        <Loader message={loaderMessage}/>
                        }
                    </>
                }
                </>
            }

            <Header userIn={true} headerTitle={renewType === 'KYC' ? data[lang]['kycDetails'] : data[lang]['lidDetails']} />
            <div className="contentBlock">
                <div className="headerBlock">
                    <h3>{data[lang]['identitycard']}</h3>
                </div>

                { (custdata.status === 'success') ?   (
                    <>
                        {  loading ? (

                            <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                                  <p>{data[lang]['extractingMessage']}</p>
                                {/*<div className="wPanel--loader"><img width={50} src={Loader} className="loader" alt="" /></div>*/}
                            </div>
                        ) : (
                    <div className={`wPanel  wPanel--detailPage ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                        <div className="imageHolder">
                            { firstimage !== '' ? ( <img className="imageHolder__img" src={`${firstimage}`} alt="idcard" />
                             ) : (<></>
                                /*<div className="wPanel--loader"> <img width={50} src={Loader} className="loader" alt="" /></div>*/
                            ) }
                        </div>
                        { name !== '' ? ( <h3>{name} {surname}</h3> ) : (<></>
                            /*<div className="wPanel--loader"><img width={50} src={Loader} className="loader" alt=""/>
                            </div>*/ ) }


                        <div className="splitBox">
                            <div className="splitBox__half">
                                <label className="textLabel">{data[lang][idType]}</label>
                            </div>
                            <div className="splitBox__half">
                                <label className="textContent">{idNumber}</label>
                            </div>
                        </div>
                        {(gender !== "NA" && gender !== '') ? (
                         <div className="splitBox">
                            <div className="splitBox__half">
                                <label className="textLabel">{data[lang]['gender']}</label>
                            </div>
                            <div className="splitBox__half">
                                <label className="textContent">{gender}</label>
                            </div>
                        </div>
                        ) : '' }
                        {doe !== "NA" ? (
                         <div className="splitBox">
                            <div className="splitBox__half">
                                <label className="textLabel">{data[lang]['expiryDate']}</label>
                            </div>
                            <div className="splitBox__half">
                                <label className="textContent">{doe}</label>
                            </div>
                        </div>
                        ) : '' }
                        <div className="splitBox">
                            <div className="splitBox__half">
                                <label className="textLabel">{data[lang]['dob']}</label>
                            </div>
                            <div className="splitBox__half">
                                <label className="textContent">{dob}</label>
                            </div>
                        </div>

                        <div className="splitBox">
                            <div className="splitBox__half">
                                <label className="textLabel">{data[lang]['mobileNo']}</label>
                            </div>
                            <div className="splitBox__half">
                                <label className="textContent">{custdata.mobile}</label>
                            </div>
                        </div>
                        { custdata.email !== null ? (
                        <div className="splitBox">
                            <div className="splitBox__half">
                                <label className="textLabel">{data[lang]['emailID']}</label>
                            </div>
                            <div className="splitBox__half">
                                <label className="textContent">{custdata.email}</label>
                            </div>
                        </div>

                            ): '' }

                    </div>
                            )}
                    </>

                ) : '' }


                {  (namematchstatus === 'high' && facematchstatus !== '' && custdata.status ) ? (
                    <>
                <div className={`csCheckboxWrap textCenter ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                    <div className="splitBox">
                    <label className="csCheckbox">
                        <input type="checkbox" checked={ agreeterms  === true ? 'checked' : '' } className="csCheckbox__input"  onClick={() => setAgreeterms(!agreeterms)} />
                        <span className="csCheckbox__placeholder" />
                        {/*<label className="csCheckbox__text" dangerouslySetInnerHTML={{__html:data[lang]['agreeTerms']}}></label>*/}
                        {lang === 'En' &&
                        <label className="csCheckbox__text">I agree to the <a className="primaryTextAnchor"  onClick={viewTerms} target="_blank" >terms and conditions</a></label>
                        }
                        {lang === 'Ar' &&
                        <label className="csCheckbox__text"> أوافق على <a className="primaryTextAnchor" onClick={viewTerms} target="_blank">الشروط والأحكام</a> </label>
                        }

                    </label>
                    </div>
                    <div className="splitBox">
                    <label className="csCheckbox__text"><span className="primaryTextAnchor error-msg">{errorMsg}</span> </label>
                    </div>
                </div>
                        {terms &&
                        <div className="csModal tncModal">
                            <span className="csModal__out" onClick={hideTerms}/>
                            <div className={`csModal__content pdfContainer`}>
                                {/*<iframe src={getToken().BM_BASE_URL + 'individuals_TC.pdf'} height={"100%"} width={"100%"}/>*/}
                                {/*<Document file={getToken().BM_BASE_URL + 'individuals_TC.pdf'} onLoadError={onDocumentLoadError} width={"100%"} onLoadSuccess={onDocumentLoadSuccess}>
                                    <Page pageNumber={pageNumber} />
                                </Document>*/}
                                <Pdf file={getToken().BM_BASE_URL + 'individuals_TC.pdf'} page={pageNumber} />
                                <div className="pageBlock">
                                    {/*<p className="pageNumber">
                                         {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                                    </p>*/}
                                    <button
                                        className="btnPrevPdf"
                                        type="button"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPDFPage}
                                    >
                                        &lt;
                                    </button>
                                    <button
                                        className="btnNextPdf"
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPDFPage}
                                    >
                                        &gt;
                                    </button>
                                </div>
                            </div>
                        </div>
                        }
                <div className="flexCC">
                   {/* <span></span>*/}
                    <button  onClick={nextPage}  className="btn btn--capsule btn--primaryG">{data[lang]['nextButton']}</button>
                </div>

                </>
                    ) : "" }
            </div>
        </>
    )
}

export default Details ;



