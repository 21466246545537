import React, {useEffect, useReducer, useState} from 'react';
import PreLoader from "../assets/images/loader_01.gif";
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import {useDispatch} from "react-redux";
import {createAccessLog} from "../actions/Authorization";
import Logo from '../assets/images/BM_logo.png'
import closeImage from "../assets/images/cancel-new.svg";



const Popup = (props) => {
    const [closer, setCloser] = useState(false)
    const [showImage, setShowImage] = useState(true)
    const [client] = useState(getToken().client);
    //const lang = getToken().lang;
    const cif = getToken().cif;
    const dispatch = useDispatch();
    //console.log(getToken())
    const closePopup = () =>{
        const ismobile = (client === 'MB');
        if(!ismobile) {
            if(props.sessionout === true) {
                //dispatch(createAccessLog('Close button' , 'Clicked', 'Calling window.parent.postMessage("logoutGieomOverlay", "*")',cif));
                //window.parent.logoutGieomOverlay();
                window.parent.postMessage("logoutGieomOverlay", "*");
            } else {
                //dispatch(createAccessLog('Close button' , 'Clicked', 'Calling window.parent.postMessage("gieomRedirectHome", "*")',cif));
                //window.parent.closeGieomOverlay();
                window.parent.postMessage("gieomRedirectHome", "*");
            }
        } else {
            if(props.sessionout === true) {
                //dispatch(createAccessLog('Close button' , 'Clicked', 'Adding UNIQUE_ID_CLOSER_SESSION_OUT',cif));
            } else {
                //dispatch(createAccessLog('Close button' , 'Clicked', 'Adding bom-pwa-close-btn',cif));
            }
            setCloser(true)
        }
    }
    const hidePopup = () =>{
        //dispatch(createAccessLog('OK button' , 'Clicked', 'OK button clicked',cif));
        props.closePopupModal()
    }
    const handleError = () => {
        setShowImage(false)
    }
    let lang = getToken().lang;
    if(props.lang) {
        lang = props.lang;
    }
    return (
        <>
            {closer === true &&
            <>
                {props.sessionout === true ?
                    <div id="UNIQUE_ID_CLOSER_SESSION_OUT"></div>
                    :
                    <div id="bom-pwa-close-btn"></div>
                }
            </>
            }
        <div className="csModal flexCol">

            <div className={`wPanel offBox ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                <div className="contentWrap">
                    <div className="shadowBlock">
                        <div className="shadowBlock__head">
                            <img src={Logo} className="shadowBlock__head__img" alt="bank-of-muscat"/>
                        </div>
                        <div className="flexInBlock">
                            <div className="flexInBlock__center">
                            {
                                (props.showIco !== 'F' && showImage === true) &&

                                    <img src={closeImage} className="tqImage smImage" alt={props.messagehead} onError={handleError}/>

                            }
                            </div>

                            <div className="flexInBlock__center">
                                <h5 className="flexInBlock__title alignCenter">{props.messagehead}</h5>
                                <p className="alignCenter" dangerouslySetInnerHTML={{__html: props.message}}/>
                            </div>
                        </div>
                    </div>
                    {
                        props.showButton !== 'F' &&
                        <div className="flexCC">
                            {(props.returnToPage && props.returnToPage === true) ?
                                <span onClick={hidePopup}
                                      className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['ok']}</span>
                                :
                                <span onClick={closePopup}
                                      className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['contactBranchOKButton']}</span>
                            }
                        </div>
                    }
                </div>


            </div>
        </div>

</>
    )
}

export default Popup
