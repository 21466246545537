import React, {useReducer} from 'react';
import SwitchInput from '../components/SwitchInput';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';

/*function ListItem(props) {
    return  <option>{props.option}</option> ;
}*/


const Editform7 = (props) => {
    const lang = getToken().lang;
    const [inputFormValues, setInputFormValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            accholding_reason1: props.inputValues.accholding_reason1,
            accholding_reason2: props.inputValues.accholding_reason2
        }
    );
    /*const updateDetails = (curstep) =>{
       // console.log("update step1: "+curstep) ;
    }*/

    const handleInput = (e) =>{
        let error = 0;

        let value = e.target.value;
        switch (e.target.name) {
            case 'accholding_reason1':
                if(value !== '' && (!value.match(/^[A-Za-z0-9-_& ]+$/) )){  error++; return false; }
                break;
            case 'accholding_reason2':
                if(value !== '' && (!value.match(/^[A-Za-z0-9-_& ]+$/) )){  error++; return false; }
                break;
            default:
                break;

        }
        if(error === 0) {
            setInputFormValues({[e.target.name]: e.target.value});
            props.onChange(e.target.name, e.target.value);
           // console.log(e.target.name + "  " + e.target.value);
        }
    }


    const handleSwitchChange = (name, value) =>{
        //console.log(name+" : "+value) ;
        props.onChange(name,  value) ;
    }

    return (
        <>
            <div className="formBlock">
                <h3>{props.boxtitle}</h3>

                { props.section && props.section.map(item => {
                    return (
                        <>

                            { (item.inputtype === "text" && item.show === true) ? (
                                <>
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>
                                    { item.fieldname === "accholding_reason1" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.accholding_reason1}    onChange={handleInput} className="formBlock__input" />
                                    ) :  '' }

                                    { item.fieldname === "accholding_reason2" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.accholding_reason2}    onChange={handleInput} className="formBlock__input" />
                                    ) :  '' }
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }
                            { item.inputtype === "select" ? (
                                <>
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>
                                    <select type="text"    name={item.fieldname}  onChange={handleInput}  className="formBlock__input formBlock__select">
                                        <option value="" >{data[lang]['select']}</option>
                                        { item.selectvalues &&  item.selectvalues.map(selectlabel => {
                                            return( <option  value={selectlabel.value} selected={selectlabel.value === item.value ? 'selected' : "" } > {selectlabel.option}</option>)

                                        })}
                                    </select>
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }
                            { item.inputtype === "switch" ? (
                                <div className="splitBox splitBox--mBMd">
                                    <div className="splitBox__w80Out">
                                        <label className="textLabel textLabel--type2">{item.label}</label>
                                    </div>
                                    <div className="splitBox__w80">
                                        <SwitchInput className=""  name={item.fieldname}   active={ item.value === "Yes" ? true : false }  onChange={handleSwitchChange} value={item.value} />
                                    </div>
                                </div>

                            ) : ''}

                        </>
                    )
                } )}
                <div className="flexCC mT15">
                    <span className="btn btn--primaryG btn--capsule"   onClick={() => props.closeModal(7)} >{data[lang]['close']}</span>
                </div>

            </div>
        </>
    )

}

export default Editform7

