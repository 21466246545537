import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

import Logo from '../assets/images/BM_logo.png'
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as authorizationAction from '../actions/Authorization'
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import {PWA_JOURNEY_STEPS} from "../constants";

class ResidentIdCheck extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lang : getToken().lang,
            cif : getToken().cif,
            sector_code: getToken().sector_code
        }

    }
    componentDidMount() {
        //this.props.createAccessLog('Do you have a valid resident id' , 'Started', 'Page Launched', this.state.cif)

    }
    closeAccount = () =>{
        //this.props.createAccessLog('No selected' , 'Selected', 'No Option selected', this.state.cif)
        //window.location = "/contact-branch" ;
        this.props.history.push('/contact-branch');
    }
    render(){
        return(

            <>
            <Header className="hasCrossOver hasCrossOver--lg">
            <Link className="logoLink">
            <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
            </Link>
            </Header>

            <div className={`wPanel redientCheckPanel ${this.state.lang === "Ar" ? 'ct-arabic' : ''}`}>
            <p className="alignCenter">{data[this.state.lang]['residentCheckHeadline']}</p>
            <div className="buttomWrap">
                <div className="flexCC">
                    <Link to={`/${PWA_JOURNEY_STEPS[1]}`}     className="btn btn--capsule btn--primaryG">{data[this.state.lang]['residentCheckOption1']}</Link>
                </div>
                {/*{(this.state.sector_code === '1002') ?
                    <div className="flexCC">
                        <Link onClick={() => this.closeAccount()}
                              className="btn btn--capsule btn--primaryOuter">{data[this.state.lang]['residentCheckOption2']}</Link>

                    </div>
                    :
                    <div className="flexCC">
                        <Link to="/willing-nonresident"
                              className="btn btn--capsule btn--primaryOuter">{data[this.state.lang]['residentCheckOption2']}</Link>

                    </div>
                }*/}
            </div>
            </div>
            </>

        );
    }
}


const mapStateToProps = state => ({
    customerTypeData: state.CustomerReducer.customerTypeData

});


export default withRouter(connect(mapStateToProps, {
    updateCustomerType : authorizationAction.UpdateCustomerType,
    createAccessLog : authorizationAction.createAccessLog

})(ResidentIdCheck));
