import React, { useState, useEffect, useRef } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { JEELIZFACEFILTER, NN_4EXPR } from 'facefilter'
import { JeelizThreeFiberHelper } from '../utilities/JeelizThreeFiberHelper'


const _maxFacesDetected = 1 // max number of detected faces
const _faceFollowers = new Array(_maxFacesDetected)
let _expressions = null

// This mesh follows the face. put stuffs in it.
// Its position and orientation is controlled by Jeeliz THREE.js helper
const FaceFollower = (props) => {
	// This reference will give us direct access to the mesh
	const objRef = useRef()

	useEffect(() => {
		const threeObject3D = objRef.current
		_faceFollowers[props.faceIndex] = threeObject3D
		})

	const mouthOpenRef = useRef()
	const mouthSmileRef = useRef()

	useFrame(() => {
		if (mouthOpenRef.current){
			const s0 = props.expression.mouthOpen
			mouthOpenRef.current.scale.set(s0, 1, s0)
			}

		if (mouthSmileRef.current){
			const s1 = props.expression.mouthSmile
			mouthSmileRef.current.scale.set(s1, 1, s1)
			}
		})

  // return('')
	return (
		<object3D ref={objRef}>
			<mesh name="mainCube">
				<boxBufferGeometry args={[1, 1, 1]} />
				<meshNormalMaterial />
			</mesh>

			<mesh ref={mouthOpenRef} rotation={[Math.PI/2,0,0]} position={[0, -0.2, 0.2]}>
				<cylinderGeometry args={[0.3,0.3, 1, 32]} />
				<meshBasicMaterial color={0xff0000} />
			</mesh>

			<mesh ref={mouthSmileRef} rotation={[Math.PI/2,0,0]} position={[0, -0.2, 0.2]}>
				<cylinderGeometry args={[0.5,0.5, 1, 32, 1, false, -Math.PI/2, Math.PI]} />
				<meshBasicMaterial color={0xff0000} />
			</mesh>
		</object3D>
	)
}

// fake component, display nothing
// just used to get the Camera and the renderer used by React-fiber:
let _threeFiber = null
const ThreeGrabber = (props) => {
	_threeFiber = useThree()
	useFrame(JeelizThreeFiberHelper.update_camera.bind(null, props.sizing, _threeFiber.camera))
	return null
	}

const compute_sizing = () => {
	// compute  size of the canvas:
	let height = window.innerHeight
	let wWidth = window.innerWidth
	let width = Math.min(wWidth, height)

	// compute position of the canvas:
	let top = 0
	let left = (wWidth - width ) / 2
	// console.log(width, height, top, left)


  let winWid = document.documentElement.clientWidth
  let winHgt = document.documentElement.clientHeight
  let ar = winWid/winHgt

  if(winWid > winHgt){
    // return {winWid, winWid*ar, (winWid-(winWid*ar))/2, 0}
    }
  else{
    // return {winWid, winWid/ar, 0, (winWid-(winWid/ar))/2}
    }
    width = width+100

    let newH = (height/width)*(width-100)
    top = (height-newH)/2

    if(ar > 1){
    	left = (winWid-height)/2
    	}
    // height = height+(100*ar)
// console.log(width, height, top, left)
  return {width, height, top, left, ar}

	}

const Liveness = (props) => {


	// init state:
	_expressions = []
	for (let i = 0; i<_maxFacesDetected; ++i){
		_expressions.push({
			mouthOpen: 0,
			mouthSmile: 0,
			eyebrowFrown: 0,
			eyebrowRaised: 0
			})
		}

	const [sizing, setSizing] = useState(compute_sizing())
	const [isInitialized] = useState(true)

  //const [direction, setDirection] = useState('')

	let _timerResize = null
	const handle_resize = () => {
		// do not resize too often:
		if (_timerResize){
			clearTimeout(_timerResize)
			}
		_timerResize = setTimeout(do_resize, 200)
		}

	const do_resize = () => {
		_timerResize = null
		const newSizing = compute_sizing()
		setSizing(newSizing)
		}

	useEffect(() => {
		if (!_timerResize) {
			JEELIZFACEFILTER.resize()
		}
		}, [sizing, _timerResize])

	const callbackReady = (errCode, spec) => {
		if (errCode){
			// console.log('AN ERROR HAPPENS. ERR =', errCode)
      props.onLivenessError(errCode)
			return
			}

    	// console.log('INFO: JEELIZFACEFILTER IS READY')
       props.onLivenessReady()
    	// there is only 1 face to track, so 1 face follower:
    	JeelizThreeFiberHelper.init(spec, _faceFollowers, callbackDetect)
		}

	const callbackTrack = (detectStatesArg) => {
    	// if 1 face detection, wrap in an array:
    	const detectStates = (detectStatesArg.length) ? detectStatesArg : [detectStatesArg]

    	// update video and THREE faceFollowers poses:
    	JeelizThreeFiberHelper.update(detectStates, _threeFiber.camera)

    	// render the video texture on the faceFilter canvas:
    	JEELIZFACEFILTER.render_video()

    	// get expressions factors:
	    detectStates.forEach((detectState, faceIndex) => {

        // let lr = Math.round(detectState.ry*100)
        // let tb = Math.round(detectState.rx*100)
        // let dir = ''

        // if(lr > 50){
        //   dir = 'left'
        //   }
        // if(lr < -50){
        //   dir = 'right'
        //   }
        // if(tb > 50){
        //   dir = 'bottom'
        //   }
        // if(tb < -50){
        //   dir = 'top'
        //   }

        // console.log(dir, direction)

        // if(dir !== direction){
        //   setDirection(dir)
        //   console.log(dir)
        //   }



        props.onFaceMove(detectState.rx, detectState.ry)
        // console.log(detectState.detected)
        // console.log(detectState.rx)
        // console.log(detectState.ry)
        // console.log(detectState.rz)
        // console.log('------------------')
        const exprIn = detectState.expressions
        const expression = _expressions[faceIndex]
        expression.mouthOpen = exprIn[0]
        expression.mouthSmile = exprIn[1]
        expression.eyebrowFrown = exprIn[2] // not used here
        expression.eyebrowRaised = exprIn[3] // not used here
	    	})
		}


	const callbackDetect = (faceIndex, isDetected) => {
	    if (isDetected) {
	    	// console.log('DETECTED')
        props.onFaceDetect()
	    	}
	    else {
	     	// console.log('LOST')
         props.onFaceLost()
	    	}
		}

	const faceFilterCanvasRef = useRef(null)
		useEffect(() => {
			window.addEventListener('resize', handle_resize)
			window.addEventListener('orientationchange', handle_resize)

			JEELIZFACEFILTER.init({
				canvas: faceFilterCanvasRef.current,
				NNC: NN_4EXPR,
				maxFacesDetected: 1,
				followZRot: true,
				callbackReady,
				callbackTrack
			})
		return JEELIZFACEFILTER.destroy
		}, [isInitialized])


	// console.log(sizing)
	return (
		<div className="liveness">
			<Canvas className='mirrorX' style={{ visibility: 'hidden' ,position: 'fixed', zIndex: 2, ...sizing }} gl={{ preserveDrawingBuffer: true }} updatedefaultcamera = {'false'}>
		        <ThreeGrabber sizing={sizing} />
		        <FaceFollower faceIndex={0} expression={_expressions[0]} />
			</Canvas>

			{/* Canvas managed by FaceFilter, just displaying the video (and used for WebGL computations) */}
			<canvas className='mirrorX test' ref={faceFilterCanvasRef} style={{transform: 'scaleX(-1)',  position: 'fixed', zIndex: 1, left:sizing.left, top: sizing.top, width: sizing.width-100}} width = {sizing.width} height = {sizing.height} />

		</div>
		)
	}

export default Liveness
