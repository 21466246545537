import React, {useReducer, useState} from 'react';
import Switch from '../components/Switch';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
//import SelectBox from '../components/SelectBox'

/*function ListItem(props) {
    return  <option>{props.option}</option> ;
}*/


const Editform1 = (props) => {

    const lang = getToken().lang;
    //const [showSelect, setShowSelect] = useState('')
    const [inputFormValues, setInputFormValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            country_birth: props.inputValues.country_birth,
            marital_status: props.inputValues.marital_status,
            name: props.inputValues.name ,
            email: props.inputValues.email,
            mobile : props.inputValues.mobile,
        }
    );
    /*const updateDetails = (curstep) =>{
        //console.log("update step1: "+curstep) ;
    }
    const toggleSelect = (e) => {
        if(showSelect === e.target.name) {
            setShowSelect('')
        } else {
            setShowSelect(e.target.name)
        }
    }
    const changeSelect = (name, value) => {
        props.onChange(name, value)
        setShowSelect('')
    }*/

    const handleInput = (e) =>{
        //console.log(e.target.name + "  " + e.target.value);
        let error = 0;
        let value = e.target.value;
        switch (e.target.name) {
            case 'name':
                if(value !== '' && (value.length > 35 || !value.match(/^[A-Za-z0-9 ]+$/))) { error++; return false; }
                break;
            default:
                break;

        }
        if(error === 0) {
            setInputFormValues({[e.target.name]: e.target.value});
            props.onChange(e.target.name, e.target.value);
            //console.log(e.target.name + "  " + e.target.value);
        }
    }
    const handleDropDown = (name, list) =>{
       props.openSearchList(name, list)
    }
    //let listVal = item.selectvalues.filter(a => a.value === item.value)[0];
    //listVal = (listVal && listVal.option) ? listVal.option : '';
   //console.log(props.section)
    return (

        <>
            <div className="formBlock">
                <h3>{props.boxtitle}</h3>

                { props.section && props.section.map((item, itemkey) => {
                    return (
                        <>

                            { item.inputtype === "text" ? (
                                <>
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`} key={`edit-1-${itemkey}`}>
                                    <legend>{item.label}</legend>
                                    { item.fieldname === "name" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.name}    onChange={handleInput} className="formBlock__input" />
                                    ) :  '' }
                                    { item.fieldname === "email" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.email}   onChange={handleInput} className="formBlock__input" />
                                    ) :  '' }
                                    { item.fieldname === "mobile" ?  (
                                        <input type="text" name={item.fieldname}   value={inputFormValues.mobile}   onChange={handleInput}  className="formBlock__input" />
                                    ) :  '' }
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }

                            { item.inputtype === "select" ? (
                                <>
                                <fieldset  className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`} key={`edit-1-${itemkey}`}>
                                    <legend>{item.label}</legend>

                                    <select type="text"    name={item.fieldname}  onChange={handleInput}  className="formBlock__input formBlock__select" value={item.value}>
                                        {(item.value === '' || item.selectvalues.filter((a) => a.value === item.value).length === 0 ) &&
                                        <option value="">{data[lang]['select']}</option>
                                        }
                                        { item.selectvalues &&  item.selectvalues.map((selectlabel,indx) => {
                                            return( <option key={`${item.fieldname}-${indx}`}  value={selectlabel.value ? selectlabel.value : selectlabel.option} > {selectlabel.option}</option>)

                                        })}
                                    </select>



                                    {/*<SelectBox
                                        name={item.fieldname}
                                        showSelect={showSelect === item.fieldname}
                                        options={item.selectvalues}
                                        value={item.selectvalues.filter(a => a.value === item.value)[0]['option']}
                                        onClick={toggleSelect}
                                        setInputFormValues={setInputFormValues} onChange={changeSelect}/>*/}

                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }
                            { item.inputtype === "searchlist" ? (
                                <>
                                <fieldset  className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`} key={`edit-1-${itemkey}`}>
                                    <legend>{item.label}</legend>

                                    <div name={item.fieldname} list={item.fieldname} className="formBlock__input formBlock__select" onClick={(e) => handleDropDown(item.fieldname, item.selectvalues)} autoComplete="off">{item.option}</div>
                                 </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                            ) : '' }
                            { item.inputtype === "switch" ? (
                                <div className="splitBox splitBox--mBMd" key={`edit-1-${itemkey}`}>
                                    <div className="splitBox__w80Out">
                                            <label className="textLabel textLabel--type2">{item.label}</label>
                                    </div>
                                    <div className="splitBox__w80">
                                        <Switch className="active"  values={item.selectvalues} selectedvalue={item.value} />
                                    </div>
                                </div>

                            ) : ''}

                        </>
                    )
                })}
                <div className="flexCC mT15">
                    <span className="btn btn--primaryG btn--capsule"   onClick={() => props.closeModal(1)} >{data[lang]['close']}</span>
                </div>

            </div>
        </>
    )
}

export default Editform1
