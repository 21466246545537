import React, {useEffect, useReducer, useState, useRef} from 'react';
import SwitchInput from '../components/SwitchInput';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import {createAccessLog, fetchSalaryInfo} from "../actions/Authorization";
import {useDispatch, useSelector} from "react-redux";
import { isBrowser, isMobile } from 'react-device-detect';
const Compress = require("compress.js").default;
const compress = new Compress();


/*function ListItem(props) {
    return  <option>{props.option}</option> ;
}*/


const Editform8 = (props) => {
    const lang = getToken().lang;
    //console.log('sizefile: ');
    const errorInvalidfile= data[lang]['invalidfile'];
    const errorInvalidfile1= data[lang]['invalidfile1'];
    const errorInvalidfile2= data[lang]['invalidfile2'];
    const errorSizefile = data[lang]['sizefile'];
    const errorUnsupportedfile = data[lang]['unsupportedfile'];
    const [address_proof_own_name, setAddress_proof_own_name] = useState(props.address_proof_own_name);
    const [salary_bom, setSalary_bom] = useState(props.salary_bom);
    const [URLArray, setURLArray] = useState('');
    const [fieldname, setFieldname] = useState("");
    const [filename, setFilename] = useState("");
    const [error, setError] = useState("");
    const [errorMsg, setErrorMsg] = useState("");
    const [bomSalError, setBomSalError] = useState(0);
    const [bomSalErrorMsg, setBomSalErrorMsg] = useState("");
    const [cif, setCif] = useState(getToken().cif);
    const [showCamGallery, setShowCamGallery] = useState('');

    const myRef = useRef(null)
    const executeScroll = () => {
        if(myRef.current !== null) {myRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })}
    }
    const dispatch = useDispatch();
    const customerSalaryData = useSelector(state => state.AppReducer.customerSalaryData  ) ;
    const [inputFormValues, setInputFormValues] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            nameofemployer: props.inputValues.nameofemployer,
            employersaddress: props.inputValues.employersaddress,
            pcCode: props.inputValues.pcCode,
            location: props.inputValues.location,
            presentposition: props.inputValues.presentposition
        }
    );
    useEffect(() => {
        if(props.errors.length > 0) {
            executeScroll()
        }
    }, [props.errors]);
   /* useEffect(() => {
        dispatch(fetchSalaryInfo(cif)) ;
    }, []);*/
    /* const [inputFormValues, setInputFormValues] = useReducer(
         (state, newState) => ({ ...state, ...newState }),
         {


         }
     ); */
    /*const updateDetails = (curstep) =>{
       // console.log("update step1: "+curstep) ;
    }*/
    const handleDropDown = (name, list) =>{
        props.openSearchList(name, list)
    }
    const handleInput = (e) =>{
     /*    setInputFormValues({[e.target.name]: e.target.value}) ; */


        if(e.target.name === "relatioshipaddrs") {
            let show;
            //if(e.target.value === "None of the above"){ show = true ;  }else{ show = false ; }
            if(e.target.value === "Other"){ show = true ;  }else{ show = false ; }
            props.ondepFieldChange("showauthorisation_letter",show);

        }

        if(e.target.name === "employmentStatus") {
            let show;
            if(e.target.value === "Self Employed"){ show = true ;  }else{ show = false ; }
            props.ondepFieldChange("showselfemployment_proof",show);
            if(e.target.value === "Self Employed") {
                props.onChange('salary_bom', 'No');
                props.ondepFieldChange("showemployment_proof", false);
                setBomSalError(0)
                setBomSalErrorMsg("")
                props.ondepFieldChange("showsalary_bom", false);
            }
            if(e.target.value === "Employed") {
                //props.onChange('salary_bom', 'No');
                props.ondepFieldChange("showemployment_proof", true);
                setBomSalError(0)
                setBomSalErrorMsg("")
                props.ondepFieldChange("showsalary_bom", true);
            }
            if(e.target.value === "Unemployed") {
                //props.onChange('salary_bom', 'No');
                props.ondepFieldChange("showemployment_proof", false);
                props.ondepFieldChange("showselfemployment_proof", false);
                props.ondepFieldChange("showsalary_bom", false);
                setBomSalError(0)
                setBomSalErrorMsg("")
            }
        }
        if(e.target.name === "nameofemployer" || e.target.name === "employersaddress" || e.target.name === "pcCode" || e.target.name === "location" || e.target.name === "presentposition") {
            if(e.target.value !== '' && (e.target.value.length > 35  || !e.target.value.match(/^[A-Za-z0-9/?:().,'+ ]+$/))){
                return false;
            } else {
                setInputFormValues({[e.target.name]: e.target.value}) ;
                props.onChange(e.target.name,  e.target.value) ;
            }
        } else {
            props.onChange(e.target.name, e.target.value);
        }
        //console.log(e.target.name+"  "+e.target.value) ;
    }

    const handleSwitchChange = (name, value) =>{
        //console.log(name+" : "+value) ;
        if(name === "address_proof_own_name"){
            let show;
            if(value === "Yes"){ show = false ;  }else{show = true ;}
            props.ondepFieldChange("showlistrelatioshipaddrs",show);
            if(show === false) {
                props.ondepFieldChange("showauthorisation_letter", show);
                props.ondepFieldChange("relatioshipaddrs", "");
            }
            props.onChange(name,  value) ;
        } else if (name === "salary_bom"){


                if (customerSalaryData != undefined) {
                    if (customerSalaryData.status === "success") {
                        let show = false;
                        /*if (value === "Yes") {
                            show = false;
                        } else {
                            show = true;
                        }*/
                        props.ondepFieldChange("showemployment_proof", show);
                        setBomSalError(0)
                        setBomSalErrorMsg("")
                        props.onChange('salary_bom', 'Yes');
                    } else {
                        setBomSalError(1)
                        setBomSalErrorMsg(data[lang]['unabletofetchsalary'])
                        props.onChange('salary_bom', 'No');
                    }
                }

        } else
        {


            props.onChange(name, value);
        }

    }

    useEffect(() => {

        if(filename !== "" && fieldname !== "" &&  URLArray !== ''){
             //console.log(filename+ " : "+fieldname+" :"+URLArray);
            props.handleFieChange(fieldname,  URLArray, filename );
            setFieldname('');
            setFilename('');
            setURLArray('')

        }

    },[filename,fieldname,URLArray]);



    const setPreviwSrc = (imgsrc) =>{
        if(URLArray !== imgsrc) {
            setURLArray(imgsrc);
        }
    }
    const removeFile = (name) => {
        props.onChange(name,  '') ;
        setFieldname(name);
        setFilename('');
        let selectedArray = [];
        props.handleFieChange(name,  selectedArray, '' );
    }
    const showCamGalleryOption = (fieldName) =>{
        //console.log('showCamGalleryOption', fieldName)
        setShowCamGallery(fieldName)
    }
    const openCamera = (fieldName) =>{
        //console.log('openCamera', fieldName)

        props.showCameraPanel(fieldName)

    }
    const clickGallery = (fieldName) =>{
        //console.log('clickGallery', fieldName)
        document.getElementsByClassName('file_'+fieldName)[0].click()
    }

    const closeCamGallery = (fieldName) =>{
        //console.log('closeCamGallery', fieldName)
        setShowCamGallery('')
    }
    useEffect(() => {

        if(props.compressedPhoto !== ''){
            // console.log('compressedPhoto - ', props.compressedPhoto)
            //console.log('Using compressed photo - ', showCamGallery)

            setError('')
            setErrorMsg('')
            setFieldname(showCamGallery);
            setFilename(new Date().getTime() + '.png');

            let newstate = URLArray;
            newstate = [props.compressedPhoto];
            setPreviwSrc(newstate);
            //console.log(newstate);

            setShowCamGallery('')
            }
    }, [props.compressedPhoto]);

    const fileSelect = (e) => {
        setError('')
        setErrorMsg('')
        //this.setState({uploadFile: [], uploadSuccessMsg: ''})
        setShowCamGallery('')
        let filetype = e.target.files[0].name.split('.').pop().toLowerCase();
        let FileExtension = ['pdf', 'jpeg', 'jpg', 'png'];
        let fileSize = e.target.files[0].size;
        const fSize = Math.round((fileSize / 1024));
        var num = e.target.files[0].name.match(/\./g).length;
        let fieldName = e.target.name;
        let fileName = e.target.files[0].name;
        let file = e.target.files[0];
        let sfiles = [...e.target.files];
        if (num === 1) {
            if (FileExtension.includes(filetype)) {
                //this.setState({businessDoc: e.target.files, docName: e.target.files[0].name})
                let img = new Image();
                var imgHeight = 0;
                var imgWidth = 0;
                if (["jpeg", "png", "jpg"].includes(filetype)) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onerror = function (err) {
                        //console.log('++++++++++++++++ 1')
                        props.onChange(fieldName, '');
                        setFieldname(fieldName);
                        setFilename('');
                        setError(e.target.name)
                        props.handleFieChange(fieldName,  [], '' );
                        setErrorMsg(errorInvalidfile1)
                    }
                    reader.onload = (evt) => {
                        img.src = window.URL.createObjectURL(file);

                        //console.log('imgHeight: ' + imgHeight)
                        //console.log('imgWidth: ' + imgWidth)
                        img.onload = () => {
                            //console.log('++++++++++++++++ 2')
                            imgHeight = img.height;
                            imgWidth = img.width;

                            if (imgHeight && imgWidth) {

                                if (fSize < 1024) {

                                    setError('')
                                    setErrorMsg('')
                                    setFieldname(fieldName);
                                    setFilename(fileName);
                                    let newstate = URLArray;
                                    let reader = new FileReader();
                                    reader.onload = (e) => {
                                        //newstate = [...newstate, e.target.result];
                                        //console.log(e.target.result)
                                        newstate = [e.target.result];
                                        //console.log(newstate)

                                        setPreviwSrc(newstate);
                                    };
                                    reader.readAsDataURL(file);
                                } else {

                                    setError('')
                                    setErrorMsg('')
                                    setFieldname(fieldName);
                                    //console.log('++++++++++++++++ 3')
                                    setFilename(fileName);

                                    compress
                                        .compress(sfiles, {
                                            size: 1,
                                            quality: 0.75,
                                            maxWidth: 1000,
                                            maxHeight: 1000,
                                            resize: true
                                        })
                                        .then((data) => {
                                            //console.log('compressed image-');
                                            //console.log(data);

                                            let newstate = URLArray;
                                            newstate = ['data:' + file.type + ';base64,' + data[0].data];
                                            setPreviwSrc(newstate);
                                            //console.log(newstate);
                                        });

                                    // props.onChange(fieldName,  '') ;
                                    // setFieldname(fieldName);
                                    // setFilename('');
                                    // setError(e.target.name)
                                    // setErrorMsg('Filesize cannot exceed 1MB')
                                }

                            }
                        }
                        setTimeout(function() {
                            if (imgHeight === 0 && imgWidth === 0) {
                                props.onChange(fieldName, '');
                                setFieldname(fieldName);
                                //console.log('++++++++++++++++ 4')
                                setFilename('');
                                props.handleFieChange(fieldName,  [], '' );
                                setError(e.target.name)
                                setErrorMsg(errorInvalidfile1)
                            }
                        }, 500);
                    }
                }
                if (["pdf"].includes(filetype)) {

                    let reader = new FileReader();
                    reader.readAsText(file.slice(0, 8));
                    // For faster loading time used slice and for checking the pdf file
                    reader.onload = (evt) => {
                        var data = evt.target.result.substr(0, 8);
                        var regex = new RegExp("%PDF-");

                        if (data.match(regex)) {
                            if (fSize < 1024) {
                                setError('')
                                setErrorMsg('')
                                setFieldname(fieldName);
                                //console.log('++++++++++++++++ 5')
                                setFilename(fileName);
                                let newstate = URLArray;
                                let reader = new FileReader();
                                reader.onload = (e) => {
                                    //newstate = [...newstate, e.target.result];
                                    newstate = [e.target.result];
                                    setPreviwSrc(newstate);
                                };
                                reader.readAsDataURL(file);
                            } else {
                                props.onChange(fieldName,  '') ;
                                setFieldname(fieldName);
                                //console.log('++++++++++++++++ 6')
                                setFilename('');
                                setError(fieldName)
                                props.handleFieChange(fieldName,  [], '' );
                                setErrorMsg(errorSizefile)
                            }
                        } else {
                            props.onChange(fieldName,  '') ;
                            setFieldname(fieldName);
                            //console.log('++++++++++++++++ 7')
                            setFilename('');
                            setError(fieldName)
                            props.handleFieChange(fieldName,  [], '' );
                            setErrorMsg(errorUnsupportedfile)
                        }

                    }
                }
            } else {
                props.onChange(fieldName,  '') ;
                setFieldname(fieldName);
                //console.log('++++++++++++++++ 8')
                setFilename('');
                setError(e.target.name)
                props.handleFieChange(fieldName,  [], '' );
                setErrorMsg(errorUnsupportedfile)
            }
        }else {
            props.onChange(fieldName,  '') ;
            setFieldname(fieldName);
            //console.log('++++++++++++++++ 9')
            setFilename('');
            setError(e.target.name)
            props.handleFieChange(fieldName,  [], '' );
            setErrorMsg(errorInvalidfile2)
        }

    }
/*    const  handleFieChange = (e) => {
        let type = e.target.files[0].type;console.log(type)
        let allowedType = ['image/jpeg', 'image/png', 'image/jpg', 'application/pdf'];console.log(type.indexOf(allowedType))
        let size =  (e.target.files[0].size / (1024*1024)).toFixed(2);
        //console.log(e.target.files[0])
        if(allowedType.indexOf(type) > -1 && size <= 1) {
            setError('')
            setErrorMsg('')
            setFieldname(e.target.name);
            setFilename(e.target.files[0].name);
            let newstate = URLArray;
            let reader = new FileReader();
            reader.onload = (e) => {
                //newstate = [...newstate, e.target.result];
                newstate = [e.target.result];
                setPreviwSrc(newstate);
            };
            reader.readAsDataURL(e.target.files[0]);
        } else {
            props.onChange(e.target.name,  '') ;
            setFieldname(e.target.name);
            setFilename('');
            setError(e.target.name)
            if(allowedType.indexOf(type) > -1 && size <= 1) {
                setErrorMsg('Only pdf, jpeg, jpg or png files of maximum size 1MB')
            }else if(allowedType.indexOf(type) <= -1) {
                setErrorMsg('Only pdf, jpeg, jpg or png files are accepted')
            }else if(size >= 1) {
                setErrorMsg('Your file is larger than 1MB')
            }
        }
    }*/

   /*  useEffect(() => {
        if(address_proof_own_name === "Yes"  ){
            props.ondepFieldChange("showW9",true);
        }else{
            props.ondepFieldChange("showW9",false);

        }
        if(salary_bom === "Yes"  ){
            props.ondepFieldChange("showW9",true);
        }else{
            props.ondepFieldChange("showW9",false);

        }


    },[address_proof_own_name]); */

//console.log(props)
    function covertDate(str) {
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let m = str.substr(4,1) === '0' ? str.substr(5,1) : str.substr(4,2);

        return str.substr(0, 4) + ' ' + months[m];
    }
//console.log(URLArray)
    return (
        <>
            <div className="formBlock">
                <h3>{props.boxtitle}</h3>
                <p className="infoText">{data[lang]['uploadformat']}</p>
                { props.section && props.section.map(item => {
                    return (
                        <>

                            { item.inputtype === "select" ? (
                                <>
                                    { item.showfield ? (
                                        <>
                                        <div className={`uploaded-doc-label`}  dangerouslySetInnerHTML={{__html: item.instruction }} ></div>

                                        <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`} ref={props.errors.indexOf(item.fieldname) === 0 ? myRef : null}>
                                                {item.label !== '' &&
                                                <legend>{item.label}</legend>
                                                }
                                                <select type="text"    name={item.fieldname}  onChange={handleInput}  className="formBlock__input formBlock__select" disabled={item.readonly}>
                                                    {(item.value === '' || item.selectvalues.filter((a) => a.value === item.value).length === 0 ) &&
                                                    <option value="">{data[lang]['select']}</option>
                                                    }
                                                    { item.selectvalues &&  item.selectvalues.map(selectlabel => {
                                                        return( <option  value={selectlabel.value} selected={selectlabel.value === item.value ? 'selected' : "" } > {selectlabel.option}</option>)

                                                    })}
                                                </select>
                                            </fieldset>
                                            {props.errors.indexOf(item.fieldname) > -1 &&
                                            <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                            }
                                            </>
                                        ) : '' }
                                        </>
                            ) : '' }
                            { item.inputtype === "searchlist" ? (
                                <>
                                    { item.showfield ? (
                                        <>
                                <fieldset  className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`}>
                                    <legend>{item.label}</legend>

                                    <div name={item.fieldname} list={item.fieldname} className="formBlock__input formBlock__select" onClick={(e) => handleDropDown(item.fieldname, item.selectvalues)}>{item.value}</div>
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                                        ) : '' }
                                    </>
                            ) : '' }
                            { item.inputtype === "switch" ? (
                                <>
                                    { item.showfield ? (
                                        <>
                                <div id={item.fieldname} className="splitBox splitBox--mBMd">
                                    <div className="splitBox__w80Out">
                                            <label className="textLabel textLabel--type2">{item.label}</label>
                                    </div>
                                    <div className="splitBox__w80">
                                        <SwitchInput className=""  name={item.fieldname}   active={ item.value === "Yes" ? true : false }  onChange={handleSwitchChange} value={item.value} readonly={item.readonly}/>

                                    </div>

                                </div>
                                    {(item.fieldname === 'salary_bom' && bomSalError === 1 && bomSalErrorMsg !== '') &&
                                        <span className="primaryTextAnchor error-msg">{bomSalErrorMsg}</span>
                                    }
                                    {/*{(item.fieldname === 'salary_bom' && props.salarydata.length > 0) &&
                                    <div className="salaryDtls">
                                        <div className="uploaded-doc-label">Account No: {props.salarydata[0]['SalaryAccNo']}</div>
                                        <table className="salaryTable" style={{width: "90%"}}>
                                            <thead>
                                            <tr>
                                                <td>Date</td>
                                                <td>Salary</td>

                                            </tr>
                                            </thead>
                                            {props.salarydata.map((monthSalary, indx) =>
                                             <tr>
                                                 <td>{covertDate(monthSalary.PrevSalaryDate)}</td>
                                                 <td style={{textAlign: "right"}}>{monthSalary.PrevSalary}</td>

                                             </tr>

                                            )}
                                        </table>
                                    </div>
                                    }*/}
                                    </>
                                    ) : '' }
                                </>
                            ) : ''}

                            { (item.inputtype === "file" &&item.showfield) ? (
                                <>
                                    <div className={`buttomWrap ${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`} ref={props.errors.indexOf(item.fieldname) === 0 ? myRef : null}>

                                    { item.showfield  ? (
                                        <>
                                            <div className={`uploaded-doc-label`}  dangerouslySetInnerHTML={{__html: item.instruction }} ></div>

                                            {item.selectedfilename != "" ? (
                                                <div className="file-cntnr">
                                                <label  className="uploaded-doc-highlightbox"  >
                                                    {item.selectedfilename}
                                                    <span className="csModal__out remove-file" onClick={() => removeFile(item.fieldname)}></span>
                                                </label>

                                                    </div>
                                            ) : "" }
                                            {(error === item.fieldname && errorMsg !== '') &&
                                            <span className="primaryTextAnchor error-msg">{errorMsg}</span>
                                            }
                                            <label className="csBtnFile flexCC ">

                                                {
                                                    isBrowser &&

                                                    <input type="file" onChange={fileSelect} name={item.fieldname} className="csBtnFile___inputIn" value=""/>
                                                }
                                                <span className="btn btn--capsule btn--primaryG">
                                                {/*<span className="iconIn iconIn--tick"/>*/}
                                                      {item.label}
                                                </span>

                                                {
                                                    isMobile &&

                                                    <div className="csBtnFile___inputIn" onClick={() => showCamGalleryOption(item.fieldname)} >{item.label}</div>
                                                }



                                            </label>
                                            {
                                                showCamGallery !== '' && showCamGallery === item.fieldname &&

                                                <div className="wPanel camera-gallery-pop" style={{position: 'absolute', zIndex: 20}} >

                                                        <span className="csModal__out remove-file" onClick={closeCamGallery} style={{top: 10}} ></span>

                                                        <div style={{marginTop: 20, width: '100%', display: 'flex',justifyContent: 'space-around'}} >
                                                            <input type="file" onChange={fileSelect} name={item.fieldname} className={"btn btn--primaryG btn--capsule file_"+ item.fieldname} value=""  style={{display: 'none'}} />

                                                            <span className="btn btn--primaryG btn--capsule"   onClick={() => openCamera(item.fieldname)} style={{width: 'auto', minWidth: 'unset'}} >{data[lang]['camera']}</span>
                                                            <span className="btn btn--primaryG btn--capsule" onClick={() => clickGallery(item.fieldname)} style={{width: 'auto', minWidth: 'unset'}} >{data[lang]['gallery']}</span>

                                                        </div>


                                                </div>
                                            }
                                       </>

                                    ) : '' }

                                    </div>
                                    {props.errors.indexOf(item.fieldname) > -1 &&
                                    <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                    }
                                </>
                            ) : '' }
                            { (item.inputtype === "text" && item.showfield === true) && (
                                <>
                                <fieldset className={`${props.errors.indexOf(item.fieldname) > -1 ? 'error' : ''}`} ref={props.errors.indexOf(item.fieldname) === 0 ? myRef : null}>
                                    <legend>{item.label}</legend>


                                    {item.fieldname === "nameofemployer" &&
                                    <input type="text" name={item.fieldname} value={inputFormValues.nameofemployer}
                                           onChange={handleInput} className="formBlock__input" autoComplete="off"/>
                                    }
                                    {item.fieldname === "employersaddress" &&
                                    <input type="text" name={item.fieldname} value={inputFormValues.employersaddress}
                                           onChange={handleInput} className="formBlock__input" autoComplete="off"/>
                                    }
                                    {item.fieldname === "pcCode" &&
                                    <input type="text" name={item.fieldname} value={inputFormValues.pcCode}
                                           onChange={handleInput} className="formBlock__input" autoComplete="off"/>
                                    }
                                    {item.fieldname === "location" &&
                                    <input type="text" name={item.fieldname} value={inputFormValues.location}
                                           onChange={handleInput} className="formBlock__input" autoComplete="off"/>
                                    }
                                    {item.fieldname === "presentposition" &&
                                    <input type="text" name={item.fieldname} value={inputFormValues.presentposition}
                                           onChange={handleInput} className="formBlock__input" autoComplete="off"/>
                                    }
                                </fieldset>
                                {props.errors.indexOf(item.fieldname) > -1 &&
                                <label className={`error-msg-red`}>{data[lang]['mandatory']}</label>
                                }
                                </>
                                )
                            }
                        </>
                    )
                })}

                {/*<div className="buttomWrap">*/}
                {/*       <p className="textCenter">Upload Additional Documents. Upload documents only in 'pdf', 'jpg', 'jpeg', 'png' format.</p>*/}
                {/*</div>*/}


                <div className="flexCC mT25">
                    <span className="btn btn--primaryG btn--capsule"   onClick={() => props.closeModal(8)} >{data[lang]['close']}</span>
                </div>



            </div>


        </>
    )
}

export default Editform8;
