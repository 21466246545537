import React, {Component, useState, useRef, useEffect} from 'react'
import {Link, useHistory} from 'react-router-dom';
import {getToken} from "../utilities/AuthService";
import decode from "jwt-decode";
import {connect, useDispatch} from "react-redux";
import {withRouter} from 'react-router';
import * as authorizationAction from '../actions/Authorization'
import Livenesstestvideo from '../pages/Livenesstestvideo'
import LivenesstestvideoVersion1 from '../pages/LivenesstestvideoVersion1'
import {UpdateNextLevel} from "../actions/Authorization";
import Popup from "./Popup";
import data from '../constants/data.json';

class Identification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recording: false,
            lookLeftStatus: false,
            instruction: '',
            eyeBlinks: [],
            headLeftTilt: [],
            headRightTilt:[],
            exectimeraction: (getToken().osName === 'Android' && parseInt(getToken().osVersion) <= 8) ? [] : ['test'],
            journeyid: getToken().journeyid,
            idtype: getToken().idtype,
            htoken: getToken().token,
            cif: getToken().cif,
            blob: "",
            livenessresponse: [],
            videorepsubmitted: false,
            videostring: "",
            errorMessage: ''
        }
    }



    startRecording = () =>{
        this.setState({"recording" : true })
    }

    componentDidMount() {
        //this.props.createAccessLog('Liveness' , 'Launched', 'Liveness  page launched', this.state.cif)

        this.props.updateNextLevel(this.state.htoken, 2);
        if(getToken().osName === 'Android' && parseInt(getToken().osVersion) <= 8) {
            this.props.livenessTestCheck(getToken().journeyid)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        

        if(this.props.customerTypeData.livenessInstdata !== undefined){
            if(this.props.customerTypeData.livenessInstdata !== prevProps.customerTypeData.livenessInstdata){
//console.log(this.props.customerTypeData.livenessInstdata)
                if(this.props.customerTypeData.livenessInstdata.message && this.props.customerTypeData.livenessInstdata.message.indexOf('Error while generating gestures') > -1){
                    //console.log('Error while generating gestures <Error>')
                    this.props.createAccessLog('Liveness' , 'Error', 'Error while generating gestures', this.state.cif)
                    this.setState({errorMessage: data[this.state.lang]['somethingwrong']});
                    return
                    }

                if(this.props.customerTypeData.livenessInstdata.result === undefined){
                    //console.log('Error while generating gestures <Error>')
                    this.props.createAccessLog('Liveness' , 'Error', 'Error from OCR - Unable to get gestures', this.state.cif)
                    this.setState({errorMessage: data[this.state.lang]['somethingwrong']});
                    return
                    }
                if(this.props.customerTypeData.livenessInstdata.result.length === 0){
                    //console.log('Error while generating gestures <Error>')
                    this.props.createAccessLog('Liveness' , 'Error', 'Error from OCR - Unable to get gestures', this.state.cif)
                    this.setState({errorMessage: data[this.state.lang]['somethingwrong']});
                    return
                    }

            
                if(this.props.customerTypeData.livenessInstdata === 'Failure') {
                    this.props.createAccessLog('Liveness' , 'Error', 'Error on getting liveness gestures', this.state.cif)
                    this.setState({errorMessage: data[this.state.lang]['unabletocommunicate']});
                    } 
                else {
                    var exectimer = [];
                    var exectimeraction = [];
                    this.setState({errorMessage: ''})
                    this.setState({"instruction": this.props.customerTypeData.livenessInstdata.result})
                    if (this.props.customerTypeData.livenessInstdata.result["eye-blinks"] !== undefined) {
                        this.setState({"eyeBlinks": this.props.customerTypeData.livenessInstdata.result["eye-blinks"]})
                        var ecount1 = this.props.customerTypeData.livenessInstdata.result["eye-blinks"].length;
                        for (var i = 0; i < ecount1; i++) {
                            var acount = this.props.customerTypeData.livenessInstdata.result["eye-blinks"][i] - 1;
                            exectimer.push(acount);
                            exectimeraction[acount] = 'eye-blinks';
                        }
                    }
                    if (this.props.customerTypeData.livenessInstdata.result["head-left-tilt"] !== undefined) {
                        this.setState({"headLeftTilt": this.props.customerTypeData.livenessInstdata.result["head-left-tilt"]})
                        var ecount2 = this.props.customerTypeData.livenessInstdata.result["head-left-tilt"].length;
                        for (var i = 0; i < ecount2; i++) {
                            var acount = this.props.customerTypeData.livenessInstdata.result["head-left-tilt"][i] - 1;
                            exectimer.push(acount);
                            exectimeraction[acount] = 'head-left-tilt';
                        }
                    }
                    if (this.props.customerTypeData.livenessInstdata.result["head-right-tilt"] !== undefined) {
                        this.setState({"headRightTilt": this.props.customerTypeData.livenessInstdata.result["head-right-tilt"]})
                        var ecount3 = this.props.customerTypeData.livenessInstdata.result["head-right-tilt"].length;
                        for (var i = 0; i < ecount3; i++) {
                            var acount = this.props.customerTypeData.livenessInstdata.result["head-right-tilt"][i] - 1;
                            exectimer.push(acount);
                            exectimeraction[acount] = 'head-left-tilt';
                        }
                    }
                    this.setState({"exectimeraction": exectimeraction})
                    this.props.createAccessLog('Liveness' , 'Success', 'Liveness gestures got successfully', this.state.cif)
                    }
                }
            }
        if(this.state.blob !== prevState.blob){
        }
    }


    nextStep = () =>{
       this.setState({'videorepsubmitted': true});
        this.props.history.push("/scan-ID-instruction") ;
    }

    changeBlob = (resp) =>{
        if(!this.state.videorepsubmitted) {
            this.setState({"livenessresponse" : resp}) ;
            this.nextStep() ;
        }
    }

    contactBranch = () =>{
        this.props.history.push("/contact-branch?ref=1") ;
    }


render(){
        return (
            <>
                {this.state.instruction.result}
                {this.state.errorMessage !== '' ?
                    <Popup messagehead={data[this.state.lang]['oops']} message={this.state.errorMessage}/>
                    :

                    <>
                        {(getToken().osName === 'Android' && parseInt(getToken().osVersion) <= 8) ?
                            <LivenesstestvideoVersion1 journeyid={this.state.journeyid} contactbranch={this.contactBranch}
                                               changeblob={this.changeBlob}
                                               livenessresponse={this.state.livenessresponse}
                                               instruction={this.state.instruction}
                                               eyeBlinks={this.state.eyeBlinks} headLeftTilt={this.state.headLeftTilt}
                                               headRightTilt={this.state.headRightTilt}
                                               exectimeraction={this.state.exectimeraction}
                                               createAccessLog={this.props.createAccessLog}
                                               cif={this.state.cif}/>
                            :

                            <Livenesstestvideo journeyid={this.state.journeyid} contactbranch={this.contactBranch}
                                               changeblob={this.changeBlob}
                                               livenessresponse={this.state.livenessresponse}
                                               instruction={this.state.instruction}
                                               eyeBlinks={this.state.eyeBlinks} headLeftTilt={this.state.headLeftTilt}
                                               headRightTilt={this.state.headRightTilt}
                                               exectimeraction={this.state.exectimeraction}
                                               createAccessLog={this.props.createAccessLog}
                                               cif={this.state.cif}/>
                        }

                    </>
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    customerTypeData: state.CustomerReducer.customerTypeData
});


export default withRouter(connect(mapStateToProps, {
    livenessTestCheck : authorizationAction.LivenessTestCheck,
    livenessTestResult : authorizationAction.livenessTestResult,
    updateNextLevel : authorizationAction.updateNextLevel,
    createAccessLog : authorizationAction.createAccessLog
})(Identification));

