import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import decode from 'jwt-decode';
import {getToken} from "../utilities/AuthService";
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import * as authorizationAction from '../actions/Authorization'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

import Logo from '../assets/images/BM_logo.png'
import data from '../constants/data.json';
import webcam from '../assets/images/webcam.svg'
import ceilinglamp from '../assets/images/ceiling-lamp.svg'
import nocap from '../assets/images/no-cap.svg'
import readglasses from '../assets/images/read-glasses.svg'
import head from '../assets/images/head.svg'

class Instructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: getToken().token,
            idtype: getToken().idtype,
            lang: getToken().lang,
            cif: getToken().cif,
            custinfo: decode(getToken().token),
            showproceed: true,
            livenessstatus: [],
            journeyid: getToken().journeyid,
            instr: 1
        }
    }

    componentDidMount() {
        /* this.props.generateJourneyId() ; */
        //this.props.createAccessLog('Liveness instructions' , 'Started', 'Page Launched', this.state.cif)
        //this.props.createAccessLog('LivenessTestStatus' , 'Started', 'Checking liveness & ID scan status of journey', this.state.cif)
        this.props.LivenessTestStatus(this.state.journeyid,this.state.idtype) ;

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log(this.props.customerTypeData.journeydata) ;
        if(this.props.customerTypeData.journeydata !== undefined){
            if(this.props.customerTypeData.journeydata !== prevProps.customerTypeData.journeydata){
                if(this.props.customerTypeData.journeydata.message === "Success"){
                    this.setState({"showproceed": !this.state.showproceed })

                }

            }
        }


        if(this.props.customerTypeData.livenessStatus  !== undefined){
            if(this.props.customerTypeData.livenessStatus !== prevProps.customerTypeData.livenessStatus) {

                const curstatus = this.props.customerTypeData.livenessStatus ;
                //this.props.createAccessLog('LivenessTestStatus' , 'Finished', 'Status - '+curstatus.livenesstest_status + ', Attempts - ' + curstatus.livenesstest_attempts + ', Maxretries - '+ curstatus.maxcount_livenesstest, this.state.cif)

                this.setState({"livenessstatus" : this.props.customerTypeData.livenessStatus })
                this.checkStatus() ;
            }
        }



    }
    nextPage = () => {
        this.setState({instr: 2})
    }
    checkStatus = () =>{
        //console.log(this.state.livenessstatus) ;
        const curstatus = this.state.livenessstatus ;

        if(curstatus.livenesstest_status === "Completed"){
            this.props.history.push("/scan-ID-instruction") ;
        }else{
            if((curstatus.livenesstest_attempts +1) < curstatus.maxcount_livenesstest){
                this.props.history.push("/contact-branch") ;
            }
        }

    }


    checkLivenessTest = () =>{
        this.props.history.push('/identification')  ;
    }


    render(){

        return (
            <>
                <Header className="hasCrossOver hasCrossOver--lg">
                    <Link className="logoLink">
                        <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
                    </Link>
                </Header>

                <div className={`wPanel ${this.state.lang === "Ar" ? 'ct-arabic' : ''}`}>


                    <div className={`panelWrapper ${this.state.instr === 1 ? 'show' : 'hide'}`}>
                        <h3 className="liteHead alignCenter">{data[this.state.lang]['livenessTestMessage']}</h3>
                        <p>{data[this.state.lang]['instructionsHeadline']}</p>

                        <div className="tileGrid">
                            <div className="tileGrid__in">
                                <div className="tileGrid__in__img">
                                    <img src={webcam} className="imageIn" alt="webcam" />
                                </div>
                                <div className="tileGrid__in__content">
                                    <p>{data[this.state.lang]['livenessTestInstruction1']}</p>
                                </div>
                            </div>

                            <div className="tileGrid__in">
                                <div className="tileGrid__in__img">
                                    <img src={ceilinglamp} className="imageIn" alt="ceiling-lamp" />
                                </div>
                                <div className="tileGrid__in__content">
                                    <p>{data[this.state.lang]['livenessTestInstruction2']}</p>
                                </div>
                            </div>

                            <div className="tileGrid__in">
                                <div className="tileGrid__in__img">
                                    <img src={nocap} className="imageIn" alt="no-cap" />
                                </div>
                                <div className="tileGrid__in__content">
                                    <p>{data[this.state.lang]['livenessTestInstruction3']}</p>
                                </div>
                            </div>

                            <div className="tileGrid__in">
                                <div className="tileGrid__in__img">
                                    <img src={readglasses} className="imageIn" alt="read-glasses" />
                                </div>
                                <div className="tileGrid__in__content">
                                    <p>{data[this.state.lang]['livenessTestInstruction4']}</p>
                                </div>
                            </div>
                        </div>

                        <div className="flexCC">
                            <span className="btn btn--capsule btn--primaryG btn--contentWrap" onClick={this.nextPage}>{data[this.state.lang]['nextButton']}</span>
                        </div>
                    </div>






                    <div className={`panelWrapper ${this.state.instr === 2 ? 'show' : 'hide'}`}>
                        <h3 className="liteHead alignCenter">{data[this.state.lang]['livenessTestMessage']}</h3>
                        <p>{data[this.state.lang]['instructionsHeadline']}</p>

                        <div className="shadowBlock">
                            <div className="flexInBlock">
                                <div className="flexInBlock__center">
                                    <img src={head} className="animativeHead" alt="head" />
                                </div>
                                <div className="flexInBlock__center">
                                    <p>{data[this.state.lang]['livenessTestInstruction5']}
                                        <span className="textBlock">{data[this.state.lang]['livenessTestInstruction5a']}</span>
                                        <span className="textBlock">{data[this.state.lang]['livenessTestInstruction5b']}</span>
                                        <span className="textBlock">{data[this.state.lang]['livenessTestInstruction5c']}</span>
                                        {/*<span className="textBlock">{data[this.state.lang]['livenessTestInstruction5d']}</span>*/}
                                    </p>
                                    {!isMobile &&
                                    <p>{data[this.state.lang]['useMobileMessage']}</p>
                                    }
                                </div>
                            </div>
                        </div>


                        { isMobile ? (
                            <div className="flexCC">
                                <span onClick={() => this.checkLivenessTest()}  className={`btn btn--capsule btn--primaryG btn--contentWrap ${this.state.showproceed === false ? 'hide' : 'show' }`} >{data[this.state.lang]['nextButton']}</span>
                            </div>
                        ) : (
                            ''

                        )}
                    </div>






                    <div style={{display: "none"}}>
                        <img key={`1 + ${new Date().getTime()}`} src={`./assets/images/Head-TR-5x-1.gif`} />
                        <img key={`2 + ${new Date().getTime()}`} src={`./assets/images/Head-TR-5x-2.gif`} />
                        <img key={`3 + ${new Date().getTime()}`} src={`./assets/images/Head-TR-5x-3.gif`} />
                        <img key={`4 + ${new Date().getTime()}`} src={`./assets/images/Head-TR-5x-4.gif`} />
                        <img key={`5 + ${new Date().getTime()}`} src={`./assets/images/Head-TL-5x-1.gif`} />
                        <img key={`6 + ${new Date().getTime()}`} src={`./assets/images/Head-TL-5x-2.gif`} />
                        <img key={`7 + ${new Date().getTime()}`} src={`./assets/images/Head-TL-5x-3.gif`} />
                        <img key={`8 + ${new Date().getTime()}`} src={`./assets/images/Head-TL-5x-4.gif`} />
                        <img key={`9 + ${new Date().getTime()}`} src={`./assets/images/Head-UP-5x-1.gif`} />
                        <img key={`10 + ${new Date().getTime()}`} src={`./assets/images/Head-UP-5x-2.gif`} />
                        <img key={`11 + ${new Date().getTime()}`} src={`./assets/images/Head-UP-5x-3.gif`} />
                        <img key={`12 + ${new Date().getTime()}`} src={`./assets/images/Head-UP-5x-4.gif`} />
                        <img key={`13 + ${new Date().getTime()}`} src={`./assets/images/liveness-overlay-pass.png`} />
                        <img key={`14 + ${new Date().getTime()}`} src={`./assets/images/liveness-oval-black.png`} />
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    customerTypeData: state.CustomerReducer.customerTypeData
});


export default withRouter(connect(mapStateToProps, {
    LivenessTestStatus : authorizationAction.LivenessTestStatus,
    createAccessLog : authorizationAction.createAccessLog
})(Instructions));


