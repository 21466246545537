import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import * as handshakeAction from '../actions/Authorization'
import {connect} from "react-redux";
import {withRouter} from 'react-router';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import { osVersion, osName, browserName, browserVersion } from 'react-device-detect';
import Logo from '../assets/images/BM_logo.png'
import Loader from "./Loader";
import Popup from "./Popup";
import {parseJwt} from "../utilities/AuthService"
import {getAllMasters} from "../actions/Authorization";

class Onboarding extends Component {
    //static contextType = SessionContext;
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            webtoken:'',
            lang : getToken().lang,
            errorMessage: '',
            custStatMessage: '',
            proceed: !(osName.toUpperCase() === 'IOS' && parseFloat(osVersion) < 15.2)
            }
        this.checkConfig = this.checkConfig.bind(this)

    }
    checkConfig(){
        if(getToken().BM_BASE_URL === ''){
            setTimeout(this.checkConfig, 100)
            }
        else{
            let params = new URLSearchParams(this.props.location.search);
            let webtoken = params.get("ref") ? params.get("ref") : '';
            this.setState({webtoken: webtoken}) ;
            let user = parseJwt(webtoken);

            let lang = user['lang'] || 'en';
            let cif = user['cif'] || '';
            lang = lang.toUpperCase() === 'EN' ? 'En' : 'Ar';
            if(this.state.proceed) {
                this.props.initiateJourney(webtoken)
            } else {
                this.setState({ loading: false });
                this.setState({"errorMessage": data[lang]['needVersionUpdate'].replace('{version}', osVersion), "lang": lang});
            }
            }
        }
    componentDidMount() {
        this.props.getConfig();
        setTimeout(this.checkConfig, 100)

    }

    componentDidUpdate(prevProps, prevState, snapshot){
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
            this.timerHandle = 0;
        }

        if(this.props.handshakeData.apiToken !== undefined) {
            //console.log(this.props.handshakeData)
            if (this.props.handshakeData.apiToken !== prevProps.handshakeData.apiToken) {
                if(this.props.handshakeData.apiToken === 'Failure') {
                    this.props.createAccessLog('OnBoarding' , 'Error', 'Customer handshake verification.', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"errorMessage": data[getToken().lang]['unabletocommunicate']});
                } else {

                        this.handShake();

                }
            }
        }


        if(this.props.handshakeData.customerData !== undefined) {
            //console.log(this.props.handshakeData.customerData)
            if (this.props.handshakeData.customerData !== prevProps.handshakeData.customerData) {
                this.props.createAccessLog('OnBoarding' , 'PWA Version:' + getToken().version + ' Lang: ' + (this.props.handshakeData.customerData ? this.props.handshakeData.customerData.lang: ''), 'INFY TOKEN: ' + this.state.webtoken, this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                let cStat = this.props.handshakeData.customerData.customer_status ? this.props.handshakeData.customerData.customer_status : ''
                cStat = cStat === null ? '' : cStat;
                let stopJourney = (cStat === 'Pending' || cStat === 'Forward') ? true : false;// || cStat === 'On Hold'
                if(stopJourney) {
                    this.props.createAccessLog('OnBoarding' , 'Error', 'Application is under processing. Status: ' + cStat, this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"custStatMessage": data[getToken().lang]['applicationunderprocessing']});
                    return
                    }

                if(getToken().renewType !== 'LID') {
                    // Code to stop journey for PEP or FATCA is on
                    let customerdata = this.props.handshakeData.customerData;
                    let pep = customerdata.current_former_pep || '';
                    pep = pep.toUpperCase() === 'YES' ? 1 : 0;

                    let usfatca = parseInt(customerdata.usfatca) || 0;
                    let ustax_resident = customerdata.ustax_resident || 'No';
                    ustax_resident = ustax_resident === "" ? 'No' : ustax_resident;
                    let usaddress_tel = customerdata.usaddress_tel || 'No';
                    usaddress_tel = usaddress_tel === "" ? 'No' : usaddress_tel;
                    let us_nationality = customerdata.us_nationality || 'No';
                    us_nationality = us_nationality === "" ? 'No' : us_nationality;
                    let us_payments = customerdata.us_payments || 'No';
                    us_payments = us_payments === "" ? 'No' : us_payments;


                    let fatca = (ustax_resident.toUpperCase() === 'YES' ||
                        usaddress_tel.toUpperCase() === 'YES' ||
                        us_nationality.toUpperCase() === 'YES' ||
                        us_payments.toUpperCase() === 'YES') ? 1 : 0;
                    let stopFatcaPepUser = (pep === 1 || fatca === 1) ? true : false;
                    if (stopFatcaPepUser) {
                        this.props.createAccessLog('OnBoarding', 'Error', 'Pep : ' + pep + ' Fatca : ' + fatca, this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                        this.setState({"custStatMessage": data[getToken().lang]['stopFatcaPepUser']});
                        return
                    }
                    // End of Code to stop journey for PEP or FATCA is on

                }
                if(this.props.handshakeData.jstatus === 'Failure') {
                    this.setState({ loading: false })
                    this.props.createAccessLog('OnBoarding' , 'Failure', 'Customer handshake verification.', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"errorMessage": data[getToken().lang]['unabletocommunicate']});
                } else if(this.props.handshakeData.jstatus === 'Token Expired') {
                    this.setState({ loading: false })
                    this.props.createAccessLog('OnBoarding' , 'Error', 'Token Expired', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"errorMessage": data[getToken().lang]['sessionexpired']});
                }  else if(this.props.handshakeData.jstatus === 'Insufficient Data') {
                    this.setState({ loading: false })
                    this.props.createAccessLog('OnBoarding' , 'Error', 'Insufficient Data', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"errorMessage": data[getToken().lang]['unabletofetch']});
                }  else if(getToken().journeyid === null || getToken().journeyid === '') {
                    this.setState({ loading: false })
                    this.props.createAccessLog('OnBoarding' , 'Error', 'Journey Id null', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.setState({"errorMessage": data[getToken().lang]['unabletocommunicate']});
                } else if(this.props.handshakeData.jstatus === 'success') {
                    //this.props.createAccessLog('OnBoarding' , 'Success', 'Got init journey api success', this.props.handshakeData.customerData ? this.props.handshakeData.customerData.cif : '')
                    this.props.getAllMasters();
                    this.props.history.push('/generalinstructions');
                }
            }
        }
    }

    handShake = () =>{
        this.timerHandle = setTimeout(() => this.setState({ loading: false }), 10);
    }

    //static sessionState = SessionContext;
    render() {
        //console.log('On:')
        //console.log(browserName + ' - ' + browserVersion)


    return(
            <>
                {(this.state.errorMessage === '' && this.state.custStatMessage === '') &&
                <Loader/>
                }


                {this.state.errorMessage !== '' &&
                    <Popup messagehead={data[this.state.lang]['oops']} message={this.state.errorMessage} lang={this.state.lang}/>

                }

                {this.state.custStatMessage !== '' &&
                    <Popup messagehead="" message={this.state.custStatMessage} />

                }

            <Header className="hasCrossOver hasCrossOver--lg" idleTimeOut={this.props.idleTimeOut}>
                <Link className="logoLink" to="">
                <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
                </Link>
                {/*<div className="titleBlock">
                <h1>{data[this.state.lang]['welcomeHeadline']}</h1>
                <p>{data[this.state.lang]['welcomeSubHeadline1']}</p>
                </div>*/}
            </Header>



            </>
    );
    }
}


const mapStateToProps = state => ({
    handshakeData: state.HandshakingReducer.handshakeData
});



export default withRouter(connect(mapStateToProps, {
    createAccessLog : handshakeAction.createAccessLog,
    getConfig: handshakeAction.getConfig,
    initiateJourney: handshakeAction.initiateJourney,
    getAllMasters: handshakeAction.getAllMasters
})(Onboarding));
