import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import Switch from '../components/Switch';
import {customerInfo} from "../actions/Authorization";
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';

const ListCustInfo = (props) => {
    const lang = getToken().lang;

    return (
        <>
            {props.inputtype === 'input-full' ? (
                    <div className={`splitBox-${props.parent ? ' mb20' : ''}`}>
                        {props.fieldlabel !== '' &&
                        <div className="splitBox__half-">
                            <label className="textLabel">{props.fieldlabel}</label>
                        </div>
                        }
                        <div className="splitBox__half-">
                            <label className="textContent" dangerouslySetInnerHTML={{__html: props.inputvalue}}/>

                        </div>

                    </div>
                ) :(
                <>
                    {props.inputtype === 'input-half' ? (
                        <div className={`splitBox-${props.parent ? ' mb20' : ''} input-half`}>
                            {props.fieldlabel !== '' &&
                            <div className="splitBox__half-">
                                <label className="textLabel">{props.fieldlabel}</label>
                            </div>
                            }
                            <div className="splitBox__half-">
                                <label className="textContent" dangerouslySetInnerHTML={{__html: props.inputvalue}}/>

                            </div>

                        </div>
                    ) :
                    (
                        <>
                            {props.inputtype === "input" ? (
                                <div className={`splitBox${props.parent ? ' mb20' : ''}`}>
                                    {props.fieldlabel !== '' &&
                                    <div className="splitBox__half">
                                        <label className="textLabel">{props.fieldlabel}</label>
                                    </div>
                                    }
                                    <div className="splitBox__half">
                                        <label className="textContent">
                                            {props.inputvalue}
                                            {/*<span className="editable__icon"/>*/}
                                        </label>
                                    </div>

                                </div>
                            ) : (
                                <>
                                    {props.inputtype === "switch" ? (
                                        <div className={`splitBox splitBox--mBMd${props.child === true ? ' haveChild' : ''}`}>
                                            <div className="splitBox__w80Out">
                                                <label className="textLabel textLabel--type2">{props.fieldlabel}</label>
                                            </div>
                                            <div className="splitBox__w80 disabled">
                                                {/*<Switch className=""
                                                        active={props.inputvalue && props.inputvalue.toUpperCase() === "YES" ? true : false}
                                                        values={props.values} selectedvalue={props.inputvalue}/>*/}
                                                <label className="textContent">
                                                    {props.inputvalue && props.inputvalue.toUpperCase() === 'YES' ? data[lang]['yes'] : data[lang]['no']}
                                                    {/*<span className="editable__icon"/>*/}
                                                </label>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {props.inputtype === "file" ? (

                                                <div className="splitBox">
                                                    <div className="splitBox__half">
                                                        <label className="textLabel">{props.fieldlabel}</label>
                                                    </div>

                                                    <div className="splitBox__half">
                                                        {props.inputvalue !== "" ? (
                                                            <label className="uploaded-doc-highlightbox">
                                                                {props.inputvalue}
                                                                {/*<span className="editable__icon" data-html2canvas-ignore="true"/>*/}
                                                            </label>

                                                        ) : ''}
                                                    </div>

                                                </div>


                                            ) : (
                                                <>
                                                {props.inputtype === "file-full" ? (

                                                        <div className="splitBox-">
                                                            <div className="splitBox__half-">
                                                                <label className="textLabel">{props.fieldlabel}</label>
                                                            </div>

                                                            <div className="splitBox__half-">
                                                                {props.inputvalue !== "" ? (
                                                                    <label className="uploaded-doc-highlightbox">
                                                                        {props.inputvalue}
                                                                       {/* <span className="editable__icon" data-html2canvas-ignore="true"/>*/}
                                                                    </label>

                                                                ) : ''}
                                                            </div>

                                                        </div>


                                                    ):(
                                                        <>
                                                    {props.inputtype === "label-list" && (
                                                    <div className="splitBox">
                                                        <div className="textLabel textLabel--type2" dangerouslySetInnerHTML={{__html: props.pepListLabels}}/>
                                                    </div>
                                                    ) }
                                                    </>
                                            )}
                                                    </>
                                                )}
                                        </>

                                    )}
                                </>
                            )}

                        </>
                    )}
                    </>
                    )
            }</>
    )
}

export default ListCustInfo
