import React from 'react';
import { connect } from 'react-redux'
import Liveness from '../components/Liveness';
import {getRandomInt, getToken} from "../utilities/AuthService";
import {IcoGestureArrowRight, IcoGestureArrowLeft} from '../components/Icons';
import data from "../constants/data.json"

import LeftGesture from "./LeftGesture";
import RightGesture from "./RightGesture";
import UpGesture from "./UpGesture";
import * as handshakeAction from "../actions/Authorization";

const gesturesArr = ['head-right-tilt', 'head-left-tilt', 'head-top-tilt', 'head-bottom-tilt'];


class LivenessGestures extends React.Component{
	constructor(props) {
		super(props)

		this.state = {
			currentGesture: '-',
			showLocalLivenessCam: false,
			showLocalWebCam: false,
			faceDetected: false,
			doGestureMonitor: false,
			noOfGestures: '',
			instructedGesture: '',
			gestureInstructTime: '',
			userGesture: '',
			openUserGestureRegister: false,
			userGestureResult: [],
			direction: '',
			lang: getToken().lang,
			cif: getToken().cif,
			waitingmsg: data[getToken().lang]['waitforgestures'],
			gestureCnt: 0,
			livenesspass: false
			}

		this.setGesture = this.setGesture.bind(this)
		this.onFaceMove = this.onFaceMove.bind(this)
		this.onLivenessError = this.onLivenessError.bind(this)
	    this.onLivenessReady = this.onLivenessReady.bind(this)
	    this.onFaceLost = this.onFaceLost.bind(this)
	    this.onFaceDetect = this.onFaceDetect.bind(this)
		this.registerUserGesture = this.registerUserGesture.bind(this)
		this.stopGestureMonitor = this.stopGestureMonitor.bind(this)
		this.checkIfUserGestureCaptured = this.checkIfUserGestureCaptured.bind(this)
		this.clearGestureInstruction = this.clearGestureInstruction.bind(this)
		}

	stopGestureMonitor(){
		//this.props.createAccessLog('Liveness Gestures' , 'stopGestureMonitor', '', this.state.cif);
		this.setState({doGestureMonitor: false})
		let corrcnt = 0;
		//let res = ''

		//console.log(this.state.userGestureResult)

		for(let gs in this.state.userGestureResult){
			let item = this.state.userGestureResult[gs]
			//console.log('ig: '+item.ig+' - ug: '+item.ug)
			//this.props.createAccessLog('Liveness Gestures' , 'Geustore Check', item.ig + ' - ' + item.ug, this.state.cif);
			if(item.ig === item.ug){
				corrcnt = corrcnt+1
			}
		}


		// console.log(JSON.stringify(this.state.userGestureResult))
		//this.props.createAccessLog('Liveness Gestures' , 'Corrected Gestures', corrcnt, this.state.cif);
		if(corrcnt === this.state.noOfGestures || corrcnt === (this.state.noOfGestures-1)){
			this.props.createAccessLog('Liveness Gestures' , 'Passed Liveness', 'Success', this.state.cif);
			//res = 'pass'
			//console.log('Liveness PASS')
			this.setState({livenesspass: true})
			let obj=this;
			setTimeout(function() {obj.props.livenessResult('Success')}, 2000)

			//to facematch
			//interactions.navigateTo('')
			//interactions.showStatus('Liveness check successfull')

			//setTimeout(authService.getFacematch, 1000)

		}
		else{
			this.props.createAccessLog('Liveness Gestures' , 'Failed Liveness', 'Anti-spoof check: Response gestures inadequate/delayed. Please re-take the test.', this.state.cif);
			//res = 'fail'
			//console.log('Liveness FAILED')
			this.props.livenessResult('Anti-spoof check: Response gestures inadequate/delayed. Please re-take the test.')
			/*if(this.props.kycstate.bypass === true){
				console.log('Bypassing to getFacematch...')
				authService.getFacematch()
				return
			}*/

			//interactions.navigateTo('liveness-error')
		}

		//console.log(this.state.userGestureResult)
		//console.log(res)
	}

	checkIfUserGestureCaptured(){
		//console.log(this.state.instructedGesture, this.state.userGesture)
		let cntr = this.state.gestureCntr

		let arr = this.state.userGestureResult
		arr.push({ig: this.state.instructedGesture, ug: this.state.userGesture})
		this.setState({userGestureResult: arr, openUserGestureRegister: true, gestureCntr: cntr+1})
		//this.props.createAccessLog('Liveness Gestures' , 'Geustore Cnt-' + cntr + ' Total - ' + (this.state.noOfGestures-1), '', this.state.cif);
		if(cntr < this.state.noOfGestures-1){
			//console.log('===================')
			setTimeout(this.setGesture, 1000, gesturesArr[getRandomInt(3)], 1)
		}
		else{
			this.setState({openUserGestureRegister: false})
			this.stopGestureMonitor()
		}
	}
	registerUserGesture(g){
		if(this.state.openUserGestureRegister === false){
			return
		}
		let userGTime = new Date().getTime()

		let timetaken = userGTime - this.state.gestureInstructTime



		if(timetaken > 4000){
			//console.log('timeout')
			this.setState({userGesture: '', openUserGestureRegister: false})
		}
		else{
			//console.log('userGesture: ', g, 'time: ', userGTime, 'timeTaken: ', timetaken)
			//this.props.createAccessLog('Liveness Gestures' , 'UserGesture', g, this.state.cif);
			this.setState({ userGesture: g, openUserGestureRegister: false})
		}




		// let arr = this.state.userGestureResult
		// arr.push({ig: this.state.instructedGesture, ug: g, it: this.state.gestureInstructTime, ut: userGTime})
		// this.setState({userGestureResult: arr, openUserGestureRegister: false})


	}

	onFaceLost(){

		this.setState({waitingmsg: data[this.state.lang]['positionFace']})

		// console.log('onFaceLost')
		 //console.log('Face lost' + this.state.lang)
	}
	onLivenessReady(){
		console.log('onLivenessReady')
		//console.log('Liveness ready...')
	}
	onLivenessError(){
		console.log('onLivenessError')
		//console.log('Liveness error')
	}
	onFaceMove(rx, ry){
		if(this.state.doGestureMonitor === false){
			return
		}

		let lr = Math.round(ry*100)
		let tb = Math.round(rx*100)


		if(lr > 50){
			if(this.state.direction !== 'left'){
				 //console.log('left detected')

				this.registerUserGesture('head-left-tilt')
				this.setState({direction: 'left'})
			}
		}
		else if(lr < -50){
			if(this.state.direction !== 'right'){
				 //console.log('right detected')

				this.registerUserGesture('head-right-tilt')
				this.setState({direction: 'right'})
			}
		}
		else if(tb > 50){
			if(this.state.direction !== 'bottom'){
				 //console.log('bottom detected')

				this.registerUserGesture('head-bottom-tilt')
				this.setState({direction: 'bottom'})
			}
		}
		else if(tb < -50){
			if(this.state.direction !== 'top'){
				 //console.log('top detected')

				this.registerUserGesture('head-top-tilt')
				this.setState({direction: 'top'})
			}
		}
		else {
			if(this.state.direction !== 'straight'){
				// console.log('straight detected')
				this.setState({direction: 'straight'})
			}
		}
	}

	clearGestureInstruction(){
		this.setState({currentGesture : 'blank'})
		setTimeout(this.checkIfUserGestureCaptured, 1000)
	}
	setGesture(g, t){
		if(this.state.showCam === false && this.state.showLocalLivenessCam === false){
			return
		}

		if(g !== '-' && g !== 'blank'){
			//this.props.createAccessLog('Liveness Gestures' , 'instructedGesture', g, this.state.cif);
			//console.log('Gesture type: ' + g)
			this.setState({instructedGesture: g, userGesture: '', openUserGestureRegister: true, gestureInstructTime: new Date().getTime(), gestureCnt: this.state.gestureCnt + 1})

			//console.log('instructedGesture: ', g, 'time: ', t)
			setTimeout(this.clearGestureInstruction, 2000)
		}

		this.setState({currentGesture : g})
	}

	onFaceDetect(){



		if(this.state.faceDetected === false){
			//console.log('onFaceDetect')
			//console.log('Face detected')

			let noofgestures = getRandomInt(2)+3
			//console.log('noofgestures', noofgestures)
			//console.log('No of gestures - '+ noofgestures)

			this.setState({faceDetected: true, doGestureMonitor: true, noOfGestures: noofgestures, gestureCntr:0})
			//console.log('+++++++++++++++++')
			setTimeout(this.setGesture, 1000, gesturesArr[getRandomInt(3)], 1)
			// setTimeout(this.checkIfUserGestureCaptured, 4000)
			// setTimeout(this.setGesture, 3000, 'blank', 1)

			// setTimeout(this.setGesture, 1000, gesturesArr[getRandomInt(4)], 1)
			// setTimeout(this.setGesture, 2000, '-', 1)

			// setTimeout(this.setGesture, 4000, gesturesArr[getRandomInt(4)], 1)
			// setTimeout(this.setGesture, 5000, '-', 1)

			// setTimeout(this.setGesture, 7000, gesturesArr[getRandomInt(4)], 1)
			// setTimeout(this.setGesture, 8000, '-', 1)

			// if(noofgestures === 4){
			// 	setTimeout(this.setGesture, 10000, gesturesArr[getRandomInt(4)], 1)
			// 	setTimeout(this.setGesture, 11000, '-', 1)

			// 	setTimeout(this.stopGestureMonitor, 13000)
			// 	}
			// else{
			// 	setTimeout(this.stopGestureMonitor, 10000)
			// 	}


		}

	}



	componentDidMount(){

		//kycStore.getState().KYCState.livenessVideoResponse = false
		//kycStore.getState().KYCState.livenessFaceCaptureResponse = false

		}

	render() {
		let lang = getToken().lang
		let islandscape = document.documentElement.clientWidth > document.documentElement.clientHeight ? true : false
		let currentGesture = this.state.currentGesture
		let cameraview = this.props.cameraview
		let showLocalWebCam = this.props.showLocalWebCam




		let overlayimg = islandscape === true? 'liveness-oval-black-horizontal' : 'liveness-oval-black'

		let overlay = 'url(./assets/images/'+(this.state.livenesspass === true ? 'liveness-overlay-pass' : overlayimg )+'.png)'

		let overlayStyle = {backgroundImage: overlay, backgroundSize: 'cover'}

		//console.log(this.state.livenesspass)
		//console.log(overlay)
		return (
			<>
				<div className={`cameraView  liveness-camera ${cameraview === true ? 'show' : 'hide'}`}>
					<div className="cameravideoPosition">

						{
							showLocalWebCam === true &&

							<Liveness
								onFaceMove={this.onFaceMove}
								onLivenessReady={this.onLivenessReady}
								onLivenessError={this.onLivenessError}
								onFaceDetect={this.onFaceDetect}
								onFaceLost={this.onFaceLost} />
						}
					</div>
				</div>

				<div className="scanner-overlay liveness-gestures" style={overlayStyle} >
					<div className="gesture-ins-cont" >



						{
							currentGesture === '-' &&

							<div className="gesture-ins" >{this.state.waitingmsg}</div>

						}

						{
							currentGesture === 'head-right-tilt' &&

							<>
								<div className="gesture-ins" >{data[lang]['lookRightMsg']}</div>
								<div className="gesture-arrow" ><IcoGestureArrowLeft /></div>
							</>
						}

						{
							currentGesture === 'head-left-tilt' &&

							<>
								<div className="gesture-ins" >{data[lang]['lookLeftMsg']}</div>
								<div className="gesture-arrow" ><IcoGestureArrowRight /></div>
							</>
						}

						{
							currentGesture === 'head-top-tilt' &&

							<>
								<div className="gesture-ins" >{data[lang]['gestop']}</div>
								<div className="gesture-arrow gesture-arrow-top" ><IcoGestureArrowRight /></div>
							</>
						}

						{
							currentGesture === 'head-bottom-tilt' &&

							<>
								<div className="gesture-ins" >{data[lang]['gesbottom']}</div>
								<div className="gesture-arrow gesture-arrow-bottom" ><IcoGestureArrowRight /></div>
							</>
						}

					</div>

					<div className="gesture-ins-anim" >


						{currentGesture === '-' &&

							<div className={`gesture-ins-anim-bg`} >
								<img src="./assets/images/Head-Straight-5x.gif" alt=""/>
							</div>

						}

						{
							currentGesture === 'head-right-tilt' &&

							<RightGesture  gestureCnt={this.state.gestureCnt}/>
						}

						{
							currentGesture === 'head-left-tilt' &&

							<LeftGesture gestureCnt={this.state.gestureCnt}/>
						}
						{
							currentGesture === 'head-top-tilt' &&

							<UpGesture gestureCnt={this.state.gestureCnt}/>
						}



					</div>

				</div>
			</>
			)
		}
	}

const mapStateToProps = (state, ownProps) => {
	return {
		kycstate: state.KYCState
	}
}

export default (connect(mapStateToProps, {
	createAccessLog : handshakeAction.createAccessLog,
})(LivenessGestures));
