import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

import Logo from '../assets/images/BM_logo.png'
import thankyouImage from '../assets/images/Success_Icon.svg'
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import {useDispatch} from "react-redux";
import {createAccessLog} from "../actions/Authorization";

const Thankyou = () => {

    const lang = getToken().lang;
    const cif = getToken().cif;
    const [message, setMessage] = useState('');
    const [closer, setCloser] = useState(false)
    const [client] = useState(getToken().client);
    const params = (new URL(document.location)).searchParams;
    const id = params.get("ref") ? params.get("ref") :'';
    const refno = params.get("refno") ? params.get("refno") :'';
    const dispatch = useDispatch();
    const redirctIBMB = () =>{
        console.log("close Iframe and do callback action on IBMB")
    }

    useEffect(() => {
        if(id === '11') {
            setMessage(data[lang]['kycUpdated']);
        }else if(id === '12'){
            let msg = '<p>' + data[lang]['yourreferenceno'].replace('{refno}', refno) + '</p>';
            setMessage(client === 'MB' ? data[lang]['kycNonstpUpdatedMB'] + msg : data[lang]['kycNonstpUpdatedIB'] + msg);
        }else if(id === '13'){
            let msg = '<p>' + data[lang]['yourreferenceno'].replace('{refno}', refno) + '</p>';
            setMessage(data[lang]['legalIDUpdated'] + msg);
        }
    },[id]);


    const closePopup = () =>{
        const ismobile = (client === 'MB');
        if(!ismobile) {
            dispatch(createAccessLog('Close button' , 'Clicked', 'Calling window.parent.postMessage("gieomRedirectHome", "*");',cif));
            //window.parent.closeGieomOverlay();
            window.parent.postMessage("gieomRedirectHome", "*");
        } else {
            dispatch(createAccessLog('Close button' , 'Clicked', 'Adding bom-pwa-close-btn',cif));
            setCloser(true)
        }
    }

    const timeOut = () =>{
        window.parent.postMessage("GieomSystemTimeOut ", "*") ;
    }

    const refreshPwa = () =>{
        window.parent.postMessage("refreshGieomWindow  ", "*") ;
    }

    return (
        <>
            <Header className="hasCrossOver hasCrossOver--lg">
                {closer === true &&
                <div id="bom-pwa-close-btn"></div>
                }
                <Link className="logoLink">
                    <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
                </Link>
            </Header>

            <div className={`wPanel offBox paddingBonly ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                <div className="contentWrap">
                    <div className="shadowBlock">
                        <div className="shadowBlock__head">
                            <img src={Logo} className="shadowBlock__head__img" alt="bank-of-muscat"/>
                        </div>
                        <div className="flexInBlock">
                            <div className="flexInBlock__center">
                                <img src={thankyouImage} className="tqImage" alt={data[lang]['thankYou']}/>
                            </div>
                            <div className="flexInBlock__center">
                                <h5 className="flexInBlock__title alignCenter">{data[lang]['thankYou']}</h5>
                                <p className="alignCenter" dangerouslySetInnerHTML={{__html: message.replace('{refno}', refno)}}/>
                                {/*{refno !== '' &&
                                <p className="alignCenter">{data[lang]['yourreferenceno'].replace('{refno}', refno)} </p>
                                }*/}
                            </div>
                        </div>
                    </div>
                    <div className="flexCC">
                        <span onClick={closePopup} className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['done']}</span>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Thankyou
