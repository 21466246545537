import React, {Component, useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../components/Header';
import {getToken, parseJwt, setReference, updateIdtype, updateLevel} from "../utilities/AuthService";
import Logo from '../assets/images/BM_logo.png'
import QRCode from "react-qr-code";
import data from '../constants/data.json';
import {HeartBeat, customerJourneyLevel, switchedBacktoDesktop, continueOnDesktop} from "../actions/Authorization";
import {useDispatch, useSelector} from "react-redux";
import CustomerReducer from "../reducers/CustomerReducer";
import {PWA_JOURNEY_STEPS} from "../constants";
import * as device from 'react-device-detect';
import mobileImg from "../assets/images/90_mobile.svg";
import {kycStore} from "../reducers/KYCState";
const Generateqrcode = (props) => {
    const lang = getToken().lang;
    const livenessstatus = getToken().livenessstatus;
    const [idtype, setIdtype] = useState(getToken().idtype);
    const [curpage, setCurpage] = useState('');
    const [journeyid, setJourneyid] = useState(getToken().journeyid);
    const [token, setToken] = useState(getToken().token);
    const [qrStatus, setQrStatus] = useState('show');
    const [level, setLevel] = useState('show');
    const [levelStatus, setLevelStatus] = useState('');
    const [userdata] = useState(getToken());
    let history = useHistory();
    let [refresh, setRefresh] = useState(false);
    let [error, setError] = useState('');
    let [switchMessage, setSwitchMessage] = useState(getToken().livenessstatus === 'ON' ? data[lang]['scannedtomobile'] : data[lang]['scannedtomobileLivenessOff']);
    const dispatch = useDispatch();
    const journeyResponsedata = useSelector(state => state.CustomerReducer.customerTypeData  ) ;
    const swichedBackDesktop = useSelector(state => state.AppReducer.swichedBackDesktop  ) ;
    let interval;
    const [client] = useState(getToken().client);

   /* setTimeout(() => {
        setRefresh(true);
    }, 300000);*/
/*    useEffect(() => {
console.log(kycStore.getState().KYCState.redo_journey)
    if(kycStore.getState().KYCState.redo_journey === 1) {

        setQrStatus('show');
    }
    }, [kycStore.getState().KYCState.redo_journey]);*/
    const refreshPage = () => {
        setError('');
        dispatch(customerJourneyLevel(token)) ;
    }
    useEffect(() => {
        //const ismobile = (client == 'MB');
        //if(!ismobile) {
            interval = setInterval(() => {
                if(getToken().token && getToken().token !== '' && getToken().journeyid !== '') {
                    dispatch(switchedBacktoDesktop());
                }
            }, 20000);
            return () => clearInterval(interval);
       // }

    }, [dispatch]);
    useEffect(() => {
        const ismobile = (client === 'MB');
        let livenessstatus = getToken().livenessstatus;
        if(!ismobile && swichedBackDesktop !== undefined) {//console.log(swichedBackDesktop)
            let switched = swichedBackDesktop.status === 'success' ? swichedBackDesktop.result : 0
            let code = swichedBackDesktop.code !== undefined ? swichedBackDesktop.code : 0
            let level = swichedBackDesktop.level !== undefined ? swichedBackDesktop.level : 0
            let cust_id_type = swichedBackDesktop.cust_id_type !== undefined ? swichedBackDesktop.cust_id_type : ''
            let levelStatus = swichedBackDesktop.level_status !== undefined ? swichedBackDesktop.level_status : ''
            setLevel(level);
            setLevelStatus(levelStatus);
            let showQr = swichedBackDesktop.qr_status;
            setQrStatus(showQr);
            if(switched !== undefined && switched === '1' && code === '1') {
                /*if(idtype === 'Resident' && cust_id_type === 'Non-Resident') {
                    updateIdtype('Non-Resident')
                }*/
                //refreshPage()

                const ismobile = device.isMobile;
                //console.log(this.state.idtype)
                //console.log(level)
                //console.log(levelStatus)
                var nextstep = '';
                if(level !==  '' && parseInt(level) < 2 && livenessstatus === 'ON') {
                    setError(data[lang]['notCompletedLivenss']);
                } else if(level !==  undefined && level !==  ''){
                    dispatch(continueOnDesktop());
                    //updateLevel(journeyResponsedata.level, journeyResponsedata.status);
                    updateLevel(level, levelStatus);
                    let step = getToken().step;
                    let stepStatus = getToken().stepstatus;
                    let otp_locked = getToken().otp_locked;
                    let ocr_attempts = JSON.parse(getToken().ocr_attempts);
                    let more_info = getToken().more_info;
                    if(ocr_attempts.livenesstest_attempts !== undefined) {
                        if (step === '2' && stepStatus === 'Rejected' && parseInt(ocr_attempts.livenesstest_attempts) < parseInt(getToken().livenessmaxretries)) {
                            stepStatus = 'Inprogress';
                        }
                    }
                    if(ocr_attempts.idscan_attempts !== undefined) {
                        if (step === '1' && stepStatus === 'Rejected' && parseInt(ocr_attempts.idscan_attempts) < parseInt(getToken().scanidmaxretries)) {
                            stepStatus = 'Inprogress';
                        }
                    }
                    //console.log(step)
                    if(otp_locked === "true" || otp_locked === true) {
                        //window.location.href = '/contact-branch?ref=15';
                        setReference(15)
                        props.history.push('/contact-branch');
                    } else {

                        if(getToken().renewType === 'LID' && step === '4' && stepStatus === 'Inprogress') {
                            step = '3';
                        }
                        if(getToken().renewType === 'LID' && step === '3' && stepStatus === 'Completed') {
                            stepStatus = 'Inprogress';
                        }
                        let livenessstatus = getToken().livenessstatus;
                        let stepAfterIDScan = PWA_JOURNEY_STEPS[2];
                        if(livenessstatus === 'OFF') {
                            stepAfterIDScan = PWA_JOURNEY_STEPS[3];
                        }

                        switch (step) {
                            case '1':
                                if (stepStatus === 'Inprogress') {
                                    if (ismobile) {
                                        //props.history.push('/instructions');
                                        props.history.push(nextstep);
                                    }else {
                                        props.history.push('/generate-qrcode');
                                    }
                                } else if (stepStatus === 'Completed') {
                                    if (ismobile) {
                                        props.history.push('/' + stepAfterIDScan);
                                    }else {
                                        if(livenessstatus === 'ON') {
                                            props.history.push('/generate-qrcode');
                                        } else {
                                            props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                                        }
                                    }
                                } else if (stepStatus === 'Rejected') {
                                    //props.history.push('/contact-branch?ref=1');
                                    setReference(2)
                                    props.history.push('/contact-branch');
                                }
                                break;
                            case '2':
                                if (stepStatus === 'Inprogress') {
                                    if (ismobile) {
                                        props.history.push('/' + stepAfterIDScan);
                                    }else {
                                        if(livenessstatus === 'ON') {
                                            props.history.push('/generate-qrcode');
                                        } else {
                                            props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                                        }
                                    }
                                } else if (stepStatus === 'Completed') {
                                    props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                                } else if (stepStatus === 'Rejected') {
                                    setReference(1)
                                    props.history.push('/contact-branch');

                                }
                                break;
                            case '3':
                                if (stepStatus === 'Inprogress') {
                                    props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                                } else if (stepStatus === 'Completed') {
                                    props.history.push('/' + PWA_JOURNEY_STEPS[4]);
                                } else if (stepStatus === 'Rejected') {
                                    //window.location.href = '/contact-branch?ref=5' ;
                                    if(more_info === 'namematch') {
                                        setReference(5)
                                    } else if(more_info === 'facematch') {
                                        setReference(4)
                                    }
                                    props.history.push('/contact-branch');
                                }
                                break;
                            case '4':
                                if (stepStatus === 'Inprogress') {
                                    props.history.push('/' + PWA_JOURNEY_STEPS[4]);
                                } else if (stepStatus === 'Completed') {
                                    props.history.push('/thank-you');
                                } else if (stepStatus === 'Rejected') {
                                    //window.location.href = '/contact-branch?ref=6' ;
                                    setReference(6)
                                    props.history.push('/contact-branch');
                                }
                                break;
                            default:
                                props.history.push(nextstep)
                        }
                    }
                }






            }
            if(switched !== undefined && switched === '0' && code === '2') {
                setSwitchMessage(data[lang]['decidetocontinuemobile'])

            }
        }

    }, [swichedBackDesktop]);
    /*useEffect(() => {//console.log(parseInt(journeyResponsedata.level))
        const ismobile = device.isMobile;
        //console.log(this.state.idtype)
        console.log(level)
        console.log(levelStatus)
        var nextstep = '';
        if(refresh === true && journeyResponsedata.level !==  '' && parseInt(journeyResponsedata.level) < 2) {
            setError(data[lang]['notCompletedLivenss']);
        } else if(journeyResponsedata.level !==  undefined && journeyResponsedata.level !==  ''){
            //updateLevel(journeyResponsedata.level, journeyResponsedata.status);
            updateLevel(level, levelStatus);
            let step = getToken().step;
            let stepStatus = getToken().stepstatus;
            let otp_locked = getToken().otp_locked;
            let ocr_attempts = JSON.parse(getToken().ocr_attempts);
            let more_info = getToken().more_info;
            if(ocr_attempts.livenesstest_attempts !== undefined) {
                if (step === '2' && stepStatus === 'Rejected' && parseInt(ocr_attempts.livenesstest_attempts) < parseInt(getToken().livenessmaxretries)) {
                    stepStatus = 'Inprogress';
                }
            }
            if(ocr_attempts.idscan_attempts !== undefined) {
                if (step === '1' && stepStatus === 'Rejected' && parseInt(ocr_attempts.idscan_attempts) < parseInt(getToken().scanidmaxretries)) {
                    stepStatus = 'Inprogress';
                }
            }
            console.log(step)
            if(otp_locked === "true" || otp_locked === true) {
                //window.location.href = '/contact-branch?ref=15';
                setReference(15)
                props.history.push('/contact-branch');
            } else {
                switch (step) {
                    case '1':
                        if (stepStatus === 'Inprogress') {
                            if (ismobile) {
                                //props.history.push('/instructions');
                                props.history.push(nextstep);
                            }else {
                                props.history.push('/generate-qrcode');
                            }
                        } else if (stepStatus === 'Completed') {
                            if (ismobile) {
                                props.history.push('/' + PWA_JOURNEY_STEPS[2]);
                            }else {
                                props.history.push('/generate-qrcode');
                            }
                        } else if (stepStatus === 'Rejected') {
                            //props.history.push('/contact-branch?ref=1');
                            setReference(2)
                            props.history.push('/contact-branch');
                        }
                        break;
                    case '2':
                        if (stepStatus === 'Inprogress') {
                            if (ismobile) {
                                props.history.push('/' + PWA_JOURNEY_STEPS[2]);
                            }else {
                                props.history.push('/generate-qrcode');
                            }
                        } else if (stepStatus === 'Completed') {
                            props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                        } else if (stepStatus === 'Rejected') {
                            setReference(1)
                            props.history.push('/contact-branch');

                        }
                        break;
                    case '3':
                        if (stepStatus === 'Inprogress') {
                            props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                        } else if (stepStatus === 'Completed') {
                            props.history.push('/' + PWA_JOURNEY_STEPS[4]);
                        } else if (stepStatus === 'Rejected') {
                            //window.location.href = '/contact-branch?ref=5' ;
                            if(more_info === 'namematch') {
                                setReference(5)
                            } else if(more_info === 'facematch') {
                                setReference(4)
                            }
                            props.history.push('/contact-branch');
                        }
                        break;
                    case '4':
                        if (stepStatus === 'Inprogress') {
                            props.history.push('/' + PWA_JOURNEY_STEPS[4]);
                        } else if (stepStatus === 'Completed') {
                            props.history.push('/thank-you');
                        } else if (stepStatus === 'Rejected') {
                            //window.location.href = '/contact-branch?ref=6' ;
                            setReference(6)
                            props.history.push('/contact-branch');
                        }
                        break;
                    default:
                        props.history.push(nextstep)
                }
            }
        }
    },[journeyResponsedata, setError, updateLevel]);*/



    const proceed = () =>{

        var nextstep = '';
        if(idtype === "National"){
            nextstep = '/instructions';
        }else if(idtype === "Resident"){
            nextstep = '/resident-id-check';
        }else{
            nextstep = '/instructions';
        }
        history.push(nextstep) ;

    }
    const base64url = (source) => {
        // Encode in classical base64
        let encodedSource = window.CryptoJS.enc.Base64.stringify(source);

        // Remove padding equal characters
        encodedSource = encodedSource.replace(/=+$/, '');

        // Replace characters according to base64url specifications
        encodedSource = encodedSource.replace(/\+/g, '-');
        encodedSource = encodedSource.replace(/\//g, '_');

        return encodedSource;
    }
    const generateQR = () => {

        let userInfo = parseJwt(token);
        let header = token.split('.')[0];
        let signature = token.split('.')[2];
        let payload = token.split('.')[1];
        /*var header = {
            "alg": "HS256",
            "typ": "JWT"
        };*/

       // var stringifiedHeader = window.CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        //var encodedHeader = base64url(stringifiedHeader);

        //userInfo.expiry = 1653921634;
        userInfo.expiry = 1642931740;//parseInt(new Date().getTime()/1000)+300;

        var stringifiedData = window.CryptoJS.enc.Utf8.parse(JSON.stringify(userInfo));
        var encodedData = base64url(stringifiedData);

        let newtoken = header + "." + encodedData + '.' + signature;
        let path = getToken().BM_BASE_URL+"switch-device?ref="+newtoken+"&userid="+journeyid ;
        //console.log(path)
        //console.log(newtoken)
        setCurpage(path)
        setTimeout(generateQR, 300000)
    }


    useEffect(() => {
        //console.log('BM_BASE_URL - ',getToken().BM_BASE_URL)
        let path = getToken().BM_BASE_URL+"switch-device?ref="+token+"&userid="+journeyid ;
        //console.log('path - ', path) ;
        setCurpage(path)  ;
       // generateQR()
    },[]);
        //console.log(qrStatus) ;
        return (
            <>
                <Header className="hasCrossOver hasCrossOver--lg" disableIdleTime={true}>
                    <Link className="logoLink">
                        <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
                    </Link>
                </Header>

                <div className={`wPanel offBox ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                    <div className="contentWrap">
                        <div className="shadowBlock">
                            {/*<div className="shadowBlock__head">
                                <img src={Logo} className="shadowBlock__head__img" alt="bank-of-muscat"/>
                            </div>*/}
                            <div className="flexInBlock">

                                <div className="flexInBlock__center">
                                    {qrStatus === 'show' ?
                                        <div className="qrCodeWrapper">
                                            <QRCode value={curpage}/>
                                        </div>
                                        :
                                        <img src={mobileImg} className="tqImage" alt="thank-you"/>
                                    }
                                </div>

                                <div className="flexInBlock__center">
                                    {qrStatus === 'show' ?
                                        <>
                                            <h5 className="flexInBlock__title qrhead">{data[lang]['scanQRCodeHead']}</h5>
                                            <p>{livenessstatus === 'ON' ? data[lang]['scanQRCodeSubHead'] : data[lang]['scanQRCodeSubHeadLivenessOff']}</p>
                                            <ul className="qrInstructions">
                                            <li>{data[lang]['scanQRCodeDetails1']}</li>
                                            <li>{data[lang]['scanQRCodeDetails2']}</li>
                                            </ul>

                                        </>
                                        :
                                        <p dangerouslySetInnerHTML={{__html: switchMessage}}></p>
                                    }
                                </div>

                            </div>
                        </div>
                        {refresh && (
                        <div className="flexCC">
                            {error !== '' &&
                            <p className="textRed">{error}</p>
                            }
                        </div>)
                        }
                    </div>
                </div>
            </>
        );
}

export default Generateqrcode ;






