import React, { useState, useEffect, useCallback }  from "react";
import Webcam from 'react-webcam'
import {Link} from 'react-router-dom';
import Header from "../components/Header";
import {useDispatch, useSelector} from "react-redux";
import { useHistory } from 'react-router-dom';
import imageCompression from "browser-image-compression";
import {
    livenessTestResult,
    updatelivenessTestResult,getFacematchscore,
    updateNextLevel, createAccessLog, continueOnMobileRequest, switchedBackToDesktopRequest, fetchSwitchedToMobile, updateReason
} from '../actions/Authorization'

import data from '../constants/data.json';
import {getToken} from "../utilities/AuthService";

import Loader from "./Loader";
import Logo from "../assets/images/BM_logo.png";
import videoCircle from "../assets/images/video-circle.png";
import {IcoGestureArrowLeft, IcoGestureArrowRight} from "../components/Icons";
import {kycStore} from "../reducers/KYCState";
import {PWA_JOURNEY_STEPS} from "../constants";
import {authService} from "../services/auth.services";
import {Buffer} from "buffer";
import closeImage from "../assets/images/cancel-new.svg";
import thankyouImage from '../assets/images/Success_Icon.svg'

const isBlob = true;
const videoDuration = 6 ;
function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
//const timer = 5



const LivenesstestvideoVersion1 = (props) => {
    //console.log(lang)
    const lang = getToken().lang;
    const lookleftmsg = data[lang]['lookLeftMsg'];
    const lookrightmsg = data[lang]['lookRightMsg'];
    const blinkeyesmsg = data[lang]['blinkEyesMsg'];
    const positionface = data[lang]['positionFace'];
    const capturingface = data[lang]['capturingFace'];
    //console.log(data[lang]['positionFace'])
    const videoConstraints = {
        height: 480, //set pic resolution //720
        width: 640, //set pic resolution //1280
        facingMode: "user" //use back camera
    };
    const facecapture = true
    const [webCamWid, setwebCamWid] = React.useState(1280);
    const [webCamHgt, setwebCamHgt] = React.useState(720);
    /*const videoConstraints = {
        height: facecapture === true ? webCamHgt : 480, //set pic resolution
        width: facecapture === true ? webCamWid : 640, //set pic resolution
        facingMode: "user" //use back camera
    };*/

    //const lang = getToken().lang;
    const client = getToken().client;
    const cif = getToken().cif;
    const ocr_attempts = JSON.parse(getToken().ocr_attempts);
    const dispatch = useDispatch();
    const [seconds, setSeconds] = React.useState(0);
    const [attempts, setAttempts] = React.useState(ocr_attempts.livenesstest_attempts ? parseInt(ocr_attempts.livenesstest_attempts) : 0);
    const [maxretries, setMaxretries] = getToken().livenessmaxretries;
    const [messagepopulate, setMessagepopulate] = React.useState(props.exectimeraction);
    //const webcamRef = React.useRef(null);
    let mediaRecorderRef = React.useRef(null);
    const [capturing, setCapturing] = React.useState(false);
    const [stopcapturing, setStopcapturing] = React.useState(false);
    const [lookLeftStatus, setLookLeftStatus] = useState(false);
    const [lookRightStatus, setLookRightStatus] = useState(false);
    const [eyeBlinkStatus, setEyeBlinkStatus] = useState(false);
    const [recordedChunks, setRecordedChunks] = React.useState([]);
    const [videoblob, setVideoblob] = React.useState('');
    const [cameraview, setCameraview] = React.useState(true);
    const [finishcapturing, setFinishcapturing] = React.useState(false);
    const [submitvideo, setSubmitvideo] = React.useState(false);
    const [videosubmitted, setVideosubmitted] = React.useState(false);
    const [videostsmessage, setVideostsmessage] = React.useState('recorded');
    const [progressMsg, setProgressMsg] = React.useState('');
    const [maxretriesMsg, setMaxretriesMsg] = React.useState('');
    const [progressSts, setProgressSts] = React.useState('');
    const [responseData, setResponseData] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [loaderMessage, setLoaderMessage] = React.useState('');
    const [cameraReady, setCameraReady] = React.useState(false);
    const [htoken] = useState(getToken().token);
    const [optionToMobile, setOptionToMobile] = React.useState(0);
    const [optionToDesktop, setOptionToDesktop] = React.useState(0);
    const [redirectDesktop, setRedirectDesktop] = React.useState(false);
    let timer = 3;
    let webcamRef = React.useRef(null);
    let captureArr = []
    let captureSizeArr = []

    //const [captureArr, setcaptureArr] = React.useState([]);
        //const [captureSizeArr, setcaptureSizeArr] = React.useState([]);
    const [currentGesture, setcurrentGesture] = React.useState('');
    const [showCam, setshowCam] = React.useState(true);
    const [recorder, setrecorder] = React.useState('');
    const [videoChunks, setvideoChunks] = React.useState([]);


    const [isFaceCaptureDone, setisFaceCaptureDone] = React.useState(false);
    const [recordStoppedOnce, setrecordStoppedOnce] = React.useState(false);
    let history = useHistory()
    const livenessResponsedata = useSelector(state => state.AppReducer.livenessResponsedata  ) ;

    const swichToMobile = useSelector(state => state.AppReducer.swichToMobile  ) ;
    const swichToDesktop = useSelector(state => state.AppReducer.swichToDesktop  ) ;
    const continueOnMobile = useSelector(state => state.AppReducer.continueOnMobile  ) ;
    const [startTimer, seStartTimer] = React.useState(3);
    //const showCam = true;
    const [winWid, setWinWid] =  React.useState(document.documentElement.clientWidth);
    const [winHgt, setWinHgt] =  React.useState(document.documentElement.clientHeight);
    const livenessFaceCaptureResponse = kycStore.getState().KYCState.livenessFaceCaptureResponse
    const facematchdata = useSelector(state => state.AppReducer.fmatchScore) ;
    const [btnClick, setBtnClick] = React.useState('');

    const updateRejectedReason = (reason) =>{
        //console.log(reason) ;
        dispatch(updateReason(reason)) ;
    }
    const captureVideo = () => {
        setcurrentGesture('-')

       /* for(let gi in this.props.kycstate.gestureResponse){
            let gestyp = gi
            let timings = this.props.kycstate.gestureResponse[gi]

            for(let t in timings){
                let time = Number(timings[t])

                setTimeout(this.setGesture, time*1000, gestyp, time)
                setTimeout(this.setGesture, (time+2)*1000, '-', time)
            }

        }*/
        startRecord()
        //();
    }
    function startRecord(){
        // console.log(RecordRTC.WebAssemblyRecorder)
       // console.log('Liveness start video record...')

        //let {width, height} = webcamRef.current.stream.getTracks()[0].getSettings()

        //console.log('Video dimension: '+ width + 'x' + height)

        // let mimeType = 'video/webm'
        // if(isMobile === true && isChrome === true && isIOS === true){
        // 	mimeType = 'video/mp4'
        // 	}
        // let recorder = RecordRTC(this.webcamRef.stream, { type: 'video', mimeType: mimeType, width: width, height: height, frameRate: 30 });
        // recorder.startRecording();

        let recorder
        let videoChunks = []
        try {
            //console.log('Set mimeType - video/webm')
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, { mimeType: 'video/webm' });
        }
        catch (err1) {
            //console.log(err1)
            try {
                // Fallback for iOS
                //console.log('Set mimeType - video/mp4')
                mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, { mimeType: 'video/mp4' });
            }
            catch (err2) {
                // If fallback doesn't work either. Log / process errors.
                //console.log(err2)
                //console.error({err1});
                //console.error({err2})
            }
        }

        //console.log(mediaRecorderRef)
        setrecorder(mediaRecorderRef);
        setvideoChunks(videoChunks)


        //recorder.ondataavailable = onGetVideoChunk
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            onGetVideoChunk
        );
        mediaRecorderRef.current.start();
        //recorder.onstop = onRecordStop

        //recorder.start();

        setTimeout(stopRecord, 6000)

    }
    function onGetVideoChunk(c){
        let arr = videoChunks
        arr.push(c.data)
        setvideoChunks(arr)
        //console.log(c)
    }
    function stopRecord(){
       // console.log(mediaRecorderRef)
        if(showCam === false){
            return
        }

        //props.createAccessLog('LivenessRecording' , 'Stopped', 'Liveness  recording stopped', props.cif)
        //console.log(mediaRecorderRef)
        mediaRecorderRef.current.stop();

        setStopcapturing(true) ;
        //setCameraview(false) ;
        setTimeout(
            function() {
                uploadVideo();
                setCapturing(false);
                setCameraview(false)
            }, 500);
        // this.state.recorder.stopRecording(function(blob) {
        //     // console.log()
        //     this.state.recorder.getDataURL(this.onGetRecordData)
        //     }.bind(this));

    }
    function onRecordStop(eve){

        if(showCam === false){
            return
        }

        if(recordStoppedOnce === true){
            return
        }

        setrecordStoppedOnce(true)

        //console.log('onRecordStop called')
        let blob = new Blob(videoChunks, { 'type' : 'video/mp4;', 'id': new Date().getTime() });
         //console.log(blob)
        //authService.submitVideo(blob)
    }
    function setGesture(g, t){
        if(showCam === false){
            return
        }

        if(g !== '-'){
            //console.log('Gesture type: ' + g + ' ,time: ' + t)
        }

        setcurrentGesture(g)
    }
    const captureFace = () => {
        let capImg = webcamRef.current.getScreenshot()
        // interactions.appendDebug('Filesize - '+(Math.round(new Buffer(capImg, 'base64').length/1024))+' KB')

        var options = {
            maxSizeMB: iOS() ?  0.2 : 0.5,
            // maxWidthOrHeight: 200,
            useWebWorker: true,
            fileType: "image/jpeg"
        };

        imageCompression
            .getFilefromDataUrl(capImg)
            .then((file) => imageCompression(file, options))
            .then(toBase64)
            .then((compImg) => {
                let arr = captureArr
                arr.push(compImg)
                captureArr = arr;
                let sizearr = captureSizeArr
                sizearr.push((Math.round(new Buffer(compImg, 'base64').length/1024))+' KB')
                captureSizeArr = sizearr
                //console.log(arr)
                if(arr.length === 10){
                    dispatch(createAccessLog('Liveness' , 'Face capture completed', 'Capture frames size -' + captureSizeArr.toString(),props.cif));
                    kycStore.getState().KYCState.livenessFaceCaptureResponse = '';
                    authService.submitFaceCapture(arr)
                    setwebCamWid(530);
                    setwebCamHgt(300)
                    setisFaceCaptureDone(true)
                    setTimeout(captureVideo, 100)
                }
                else{
                    setTimeout(captureFace, 200)
                }

            })
            .catch(function (error) {
                //console.log(error.message);
            });




    }
    const startFaceCapture = () => {
        setCapturing(true)
        captureArr = []
        captureSizeArr = []
        //dispatch(createAccessLog('Liveness' , 'Face Capture', 'Start face image capture...',props.cif));
        setTimeout(captureFace, 100)
        setcurrentGesture('facecapture')
    }

    const dcrTimer = () => {
        //console.log('dcrTimer')
        if(showCam === true){
            let t_ = timer - 1
            timer = t_
            seStartTimer(t_)
            //console.log(timer)
            if(timer === 0){//console.log(isFaceCaptureDone)
                if(facecapture === true){
                    startFaceCapture()
                }
                else {
                    //handleStartCaptureClick()
                }
            }
            else{
                setTimeout(dcrTimer, 1000)
            }
        }

    }

    const updateDimensions = () => {
        setWinWid(document.documentElement.clientWidth);
        setWinHgt(document.documentElement.clientHeight);
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        dispatch(fetchSwitchedToMobile());
    }, []);


    useEffect(() => {
        const isIbChannel = (client === 'IB');
        if(swichToMobile !== undefined && swichToMobile === '1'   && isIbChannel){
            setOptionToMobile(swichToMobile) ;
        }
    },[swichToMobile]);

    useEffect(() => {
        const isIbChannel = (client === 'IB');
        if(swichToDesktop !== undefined && swichToDesktop === 1  && isIbChannel && btnClick === 'nextToDesk'){
            setOptionToDesktop(swichToDesktop) ;

            setTimeout(redirectToHome, 5000)
        }
    },[swichToDesktop]);
    useEffect(() => {
        if(continueOnMobile !== undefined && continueOnMobile === 1  && btnClick === 'nextToMob'){
            dispatch(createAccessLog('ProceedOnMobile' , 'Finished', 'Journey continued on mobile',cif));
            history.push(PWA_JOURNEY_STEPS[3]);
        }
    },[continueOnMobile]);


    useEffect(() => {
        if(livenessResponsedata.message !==  undefined && livenessFaceCaptureResponse !== ''){
            //setProgressMsg(data[lang][livenessResponsedata.message] || livenessResponsedata.message) ;

            if(livenessResponsedata.message.indexOf("Success") >= 0 && livenessFaceCaptureResponse === true){
                setProgressMsg(data[lang][livenessResponsedata.message] || livenessResponsedata.message) ;
                props.createAccessLog('SubmitVideo', 'Success', 'Submitted liveness video successfully', props.cif)
                setLoader(false)
                setLoaderMessage('')
                setProgressSts("Success");
                setMaxretriesMsg('');
                var resp = {
                    "data": livenessResponsedata,
                    "status": "Success",
                    "count": attempts
                }
                updateLivenessprogress(resp);

            } else {
                let msg = ''
                let logmsg = ''
                if(livenessResponsedata.message.indexOf("Success") < 0) {
                   logmsg = livenessResponsedata.message;
                   msg = data[lang][livenessResponsedata.message];
                    updateRejectedReason(data['En'][livenessResponsedata.message])
                }
                if(livenessFaceCaptureResponse !== true) {
                    logmsg = logmsg + ' ' + livenessFaceCaptureResponse;
                    msg = msg + ' ' + livenessFaceCaptureResponse;
                }

                setProgressMsg(msg);
                props.createAccessLog('SubmitVideo', 'Error', logmsg, props.cif)
                setLoader(false)
                setLoaderMessage('')
                if(parseInt(getToken().livenessmaxretries) > attempts){
                    let rem = parseInt(getToken().livenessmaxretries) - parseInt(attempts);
                    setMaxretriesMsg(data[lang]['livenessMaxRetriesMsg'].replace('{cnt}', attempts).replace('{maxcnt}', rem))
                    //props.createAccessLog('SubmitVideo Maxretries' , 'Success', 'Retries remaining', props.cif)
                    setProgressSts("Failure") ;
                    var resp = {
                        "data" : livenessResponsedata,
                        "status" : "Failure",
                        "count" : attempts
                    }
                    updateLivenessprogress(resp) ;
                }else{
                    setMaxretriesMsg('');
                    var resp = {
                        "data" : livenessResponsedata,
                        "status" : "Failure",
                        "count" : attempts
                    }
                    updateLivenessprogress(resp) ;
                    props.createAccessLog('SubmitVideo Maxretries' , 'Error', 'Maximum Retries Reached', props.cif)
                    contactBranch();
                }
            }
            setResponseData(livenessResponsedata) ;


        }else{
           /*  props.changeblob(livenessResponsedata); */
        }
    },[livenessResponsedata, setProgressSts, setProgressMsg, setResponseData, livenessFaceCaptureResponse]);

    /*useEffect(() => {
        if(livenessFaceCaptureResponse === true){
            let idtype = getToken().idtype;
            let doctype = ''
            if( idtype === "National" ) {
                doctype = 'OmanNationalIdFront'
            } else if(idtype === "Resident") {
                doctype = 'OmanResidentIdFront'
            } else {
                doctype = 'PassportFront'
            }
            setTimeout(
            dispatch(getFacematchscore(doctype)), 2000);
        }
    },[livenessFaceCaptureResponse]);*/

    useEffect(() => {
        if(facematchdata !== undefined && facematchdata.message === "Success"){
            kycStore.getState().KYCState.facematchdata = facematchdata
        }

    },[facematchdata]);

    const updateLivenessprogress = (resp) =>{
        const myObjStr = JSON.stringify(resp);
        const enc_myObjStr = new Buffer(myObjStr).toString('base64');
        dispatch(updatelivenessTestResult(props.journeyid,enc_myObjStr)) ;
    }

    const handleStartCaptureClick = React.useCallback(() => {
        props.createAccessLog('LivenessRecording' , 'Started', 'Liveness  start recording', props.cif)
        var nav = window.navigator;
        var ua = nav.userAgent;
        var isSafari =  /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        setCapturing(true);
        /*mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: isSafari ? "video/mp4;codecs:h264" : "video/webm"
        });*/
        try {
            mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, { mimeType: 'video/webm' });
            //console.log('try-----------------')
        }
        catch (err1) {//console.log('catch-----------------')
            try {
                // Fallback for iOS
                mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, { mimeType: 'video/mp4' });
            }
            catch (err2) {
                // If fallback doesn't work either. Log / process errors.
                //console.error({err1});
                //console.error({err2})
            }
        }

        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );


        mediaRecorderRef.current.start();


    }, [setCapturing, mediaRecorderRef]);




    React.useEffect(() => {
        if(isFaceCaptureDone){
            if (seconds <= videoDuration) {
                setTimeout(() => setSeconds(seconds + 1), 1000);
                setEyeBlinkStatus(false);
                setLookLeftStatus(false);
                setLookRightStatus(false);
                if(props.exectimeraction[seconds] !== undefined || props.exectimeraction[seconds-1] !== undefined){

                     if(props.exectimeraction[seconds] === "eye-blinks" || props.exectimeraction[seconds-1] === "eye-blinks"){
                         //console.log(seconds + " eye-blinks")
                         setEyeBlinkStatus(true);
                     }
                    if(props.exectimeraction[seconds] === "head-left-tilt" || props.exectimeraction[seconds-1] === "head-left-tilt"){
                        //console.log(seconds + " head-left-tilt")
                        setLookLeftStatus(true);
                    }

                    if(props.exectimeraction[seconds] === "head-right-tilt" || props.exectimeraction[seconds-1] === "head-right-tilt"){
                        //console.log(seconds + " head-right-tilt")
                        setLookRightStatus(true);
                    }
                }
            } else {
                setFinishcapturing(true) ;
                //handleStopCaptureClick();
            }
        }

        if(submitvideo){
            handleChangeBlob() ;
        }



        function handleChangeBlob() {
            if(!videosubmitted) {

                let isios = iOS();
                props.createAccessLog('SubmitVideo' , 'Params Details', 'userid - ' + props.journeyid + ', isios - ' + isios + ', livevideo - ' + videoblob + ', video size - ' + videoblob.size , props.cif);
                let formdata = new FormData();
                formdata.append('userid', props.journeyid);
                formdata.append('isios', isios);
                formdata.append('livevideo', videoblob);

                //console.log(formdata)
                //console.log(videoblob.src)
                //console.log(videoblob.size)

                //{'userid': props.journeyid, 'isios': isios, 'livevideo': videoblob}
                //props.apiRequestLog('LivenessCheck' , videoblob  , props.cif, props.journeyid);

                if(isBlob === true) {
                    let postData = {isios: iOS(), livevideo: videoblob}
                    //console.log(res); // res is base64 now
                    //console.log(postData)

                    if (!videosubmitted) {
                        setVideosubmitted(true);
                        dispatch(livenessTestResult(postData, isBlob));
                        setProgressMsg(data[lang]['progressMsg']);
                        setAttempts(attempts + 1);
                    }
                } else {
                    blobToBase64(videoblob).then(videoBase64 => {
                        //console.log(videoBase64)
                        //(videoBase64);

                        let postData = {isios: iOS(), livevideo: videoBase64}
                        //console.log(res); // res is base64 now
                        //console.log(postData)

                        if (!videosubmitted) {
                            setVideosubmitted(true);
                            dispatch(livenessTestResult(postData, isBlob));
                            setProgressMsg(data[lang]['progressMsg']);
                            setAttempts(attempts + 1);
                        }
                    })

                }
            }
        }
    });


    const handleDataAvailable = React.useCallback(
        ({ data }) => {//console.log(data)
            if (data.size > 0) {
                setRecordedChunks((prev) => prev.concat(data));
            }
        },
        [setRecordedChunks]
    );

    const handleStopCaptureClick = React.useCallback(() => {
        props.createAccessLog('LivenessRecording' , 'Stopped', 'Liveness  recording stopped', props.cif)
        //console.log(mediaRecorderRef)
        mediaRecorderRef.current.stop();
        setCapturing(false);
        setStopcapturing(true) ;
        setCameraview(false) ;

    }, [mediaRecorderRef, setCapturing]);
    const uploadVideo = () => {
        props.createAccessLog('SubmitVideo' , 'Started', 'Submitting liveness video', props.cif);
        handleDownload();
    }
    const blobToBase64 = blob => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    };

    const handleDownload = React.useCallback((props) => {

        setLoader(true)
        setLoaderMessage('Submitting your video. Please wait')

        if (videoChunks.length) {
            const blob = new Blob(videoChunks, {
                type: "video/mp4"
            });
            const url = URL.createObjectURL(blob);
            //console.log(blob)
            setVideoblob(blob);




            /*   const a = document.createElement("a");
               document.body.appendChild(a);
               a.style = "display: none";
               a.href = url;
               a.download = "react-webcam-stream-capture.webm";
               a.click();
               window.URL.revokeObjectURL(url); */

            setSubmitvideo(true);
            setvideoChunks([]);
        }
    }, [videoChunks]);


    const proceedNext = (btn) =>{
        if(btn === 'skip') {
            props.createAccessLog('SkipAndProceed', 'Success', 'Skip And Proceed button clicked', props.cif)
        } else {
            props.createAccessLog('Next', 'Success', 'Next button clicked', props.cif)
        }
        props.changeblob(responseData);
    }


    const contactBranch = () =>{
        props.contactbranch() ;
    }

    const retakeVideo = () => {
        seStartTimer(3)

        if(parseInt(getToken().livenessmaxretries) > attempts){
            props.createAccessLog('RetakeVideo' , 'Started', 'Clicked on retake video', props.cif)
            setSeconds(0);
            setMessagepopulate(0) ;
            setCapturing(false) ;
            captureSizeArr = []
            captureArr = []
            setvideoChunks([])
            setcurrentGesture('')
            webcamRef.current.value = "";
            //setTimeout(dcrTimer, 1000)
            setisFaceCaptureDone(false)
            setMessagepopulate(props.exectimeraction);
            setCapturing(false);
            setStopcapturing(false);
            setLookLeftStatus(false) ;
            setLookRightStatus(false);
            setEyeBlinkStatus(false) ;
            setRecordedChunks([]);
            setVideoblob('') ;
            setCameraview(true);
            setFinishcapturing(false);
            setSubmitvideo(false);
            setVideosubmitted(false);
            setVideostsmessage('recorded');
            setMaxretriesMsg('');
            setProgressMsg('');
            setProgressSts('');
            setResponseData('');
            setTimeout(dcrTimer, 1000)
        }

    }
    const backToInstructions = () => {
        history.push('/instructions');
    }
    const nextStep = () =>{
        setBtnClick('next');
        history.push('/' + PWA_JOURNEY_STEPS[3]);
    }
    const nextStepToMobile = () =>{
        setBtnClick('nextToMob');
        dispatch(createAccessLog('ProceedOnMobile' , 'Started', 'Selected to continue journey on mobile',cif));
        dispatch(continueOnMobileRequest())
    }
    const nextStepToDesktop = () =>{
        setBtnClick('nextToDesk');
        dispatch(createAccessLog('ProceedToDesktop' , 'Started', 'Selected to continue journey on desktop',cif));
        dispatch(switchedBackToDesktopRequest())
        setRedirectDesktop(true)
        setTimeout(redirectToHome, 5000)
    }
    const redirectToHome = () =>{
        // history.push('/');
        // console.log('https://www.bankmuscat.com/'+lang);
        window.location = 'https://www.bankmuscat.com/'+lang;
    }

    function onLiveCameraReady(e){
        console.log("onLiveCameraReady: ")
         //console.log(e)
        setCameraReady(true)
        setTimeout(dcrTimer, 1000)
        }
    function onLiveCameraError(err){
        console.log("onLiveCameraError: "+err)
        }

    const onFaceCameraReady = (e) => {
        //console.log(facecapture)
        if(facecapture === true){
            //console.log(isFaceCaptureDone)
            if(isFaceCaptureDone === true){

                //interactions.appendDebug(kycStore.getState().KYCState.debugSpacer)
                //console.log('Liveness video camera ready')

                setTimeout(captureVideo, 100)
            }
            else{
                //interactions.appendDebug(kycStore.getState().KYCState.debugSpacer)
                //console.log('Liveness facecapture camera ready')

                setCameraReady(true)
                setTimeout(dcrTimer, 1000)
            }

        }
        else{
            //interactions.appendDebug(kycStore.getState().KYCState.debugSpacer)
            //console.log('Liveness video camera ready')
            setCameraReady(true)
            setTimeout(dcrTimer, 1000)
        }


    }
    const onFaceCameraError = (err) => {
        console.log("onScanCameraError: "+err)
        //interactions.appendDebug('Liveness camera error - '+err)
    }

        //console.log(props.exectimeraction)
    let islandscape = winWid > winHgt ? true : false
    //console.log(kycStore.getState().KYCState)
    return (

            <>
                {props.exectimeraction.length > 0 ?
                    <>
                <div className={`cameraView  liveness-camera ${cameraview === true ? 'show' : 'hide'}`}>
                    <div className="cameravideoPosition">

                        <Webcam
                            ref={webcamRef}
                            audio={false}
                            screenshotQuality={1} // set the Quality of camera (0-1)
                            forceScreenshotSourceSize
                            screenshotFormat="image/jpeg"
                            videoConstraints={videoConstraints}
                            onUserMedia={(e) => onFaceCameraReady(e)}
                            onUserMediaError={(e) => onFaceCameraError(e)}
                        />


                    </div>
                </div>
                    <div  className={`scanner-overlay liveness-gestures ${cameraview === true ? 'show': 'hide'}`}  style={{backgroundImage: islandscape === true? 'url(./assets/images/liveness-oval-black-horizontal.png)' : 'url(./assets/images/liveness-oval-black.png)', backgroundSize: 'cover'}} >


                {/* <div className="scanner-overlay" style={{background: 'url(./assets/images/liveness-overlay.png)', backgroundSize: 'cover'}} >*/}
                    <div className="gesture-ins-cont" >

                {
                    //this.state.currentGesture === '' &&
                    !capturing &&
                    <>
                    <div className="gesture-ins" >{positionface}</div>

                {
                    cameraReady === true &&

                    <div className="gesture-timer" >00:{startTimer < 10 ? ('0'+startTimer) : startTimer}</div>
                }
                    </>
                }
                {
                    currentGesture === 'facecapture' &&

                    <div className="gesture-ins" >{capturingface}</div>
                }

                {
                    //this.state.currentGesture === 'head-right-tilt' &&
                    lookRightStatus &&

                    <>
                    <div className="gesture-ins">{lookrightmsg}</div>
                    <div className="gesture-arrow" ><IcoGestureArrowLeft /></div>
                    </>
                }

                {
                    //this.state.currentGesture === 'head-left-tilt' &&
                    lookLeftStatus &&
                    <>
                    <div className="gesture-ins" >{lookleftmsg}</div>
                    <div className="gesture-arrow" ><IcoGestureArrowRight /></div>
                    </>
                }

                {
                    //this.state.currentGesture === 'eye-blinks' &&
                    eyeBlinkStatus &&

                    <div className="gesture-ins" >{blinkeyesmsg}</div>
                }


                    </div>
                    <div className="gesture-ins-anim" >

                {
                    //this.state.currentGesture === '' &&
                    !capturing &&
                    <div className="gesture-ins-anim-bg" >
                {/*<GestureHeadStraight />*/}
                    <img src="./assets/images/Head-Straight-5x.gif" />
                    </div>

                }

                {
                    //this.state.currentGesture === 'head-right-tilt' &&
                    lookRightStatus &&
                    <div className="gesture-ins-anim-bg" >
                    <img key={new Date().getTime()} src="./assets/images/Head-TR-5x.gif" />
                    </div>
                }

                {
                    //this.state.currentGesture === 'head-left-tilt' &&
                    lookLeftStatus &&
                    <div className="gesture-ins-anim-bg" >
                    <img key={new Date().getTime()} src="./assets/images/Head-TL-5x.gif" />
                    </div>
                }

                {
                    //this.state.currentGesture === 'eye-blinks' &&
                    eyeBlinkStatus &&
                    <div className="gesture-ins-anim-bg" >
                    <img key={new Date().getTime()} src="./assets/images/Head-Blink-5x.gif" />
                    </div>
                }

                    </div>
                    </div>
                    <div className="cameraOverlay__option__ButtonSet">
                {
                    capturing ? (
                    <React.Fragment>
                {/*{ finishcapturing ? (
                                            <Link className="btn btn--primaryG btn--sm btn--capsule" onClick={handleStopCaptureClick} >
                                                <span className="iconIn iconIn--stop" />
                                                {data[lang]['finishRecording']}
                                            </Link>
                                        ) : (
                                             <React.Fragment></React.Fragment>
                                        )}*/}
                    </React.Fragment>
                    ) : (
                    <React.Fragment>
                {stopcapturing && (
                    <React.Fragment> </React.Fragment>

                    ) }
                    </React.Fragment>
                    )
                }





                    </div>
                        </>
                    :
                    <Loader/>
                }
                {/*</div>*/}

                {loader &&
                <Loader message={progressMsg}/>
                }

                    <Header   className={`hasCrossOver hasCrossOver--lg  ${cameraview === false ? 'show': 'hide'}`}  >
                        <div className="navHead">
                            {/*<Link className="navHead__back">
                                <span className="iconIn iconIn--back" />
                            </Link>*/}
                            <p className="navHead__text">{data[lang]['identityVerification']}</p>
                        </div>
                    </Header>
                {(stopcapturing && !loader) &&
                <div
                    className={`wPanel offBox paddingBonly ${cameraview === false ? 'show' : 'hide'} ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                    <div className="contentWrap">
                        <div className="shadowBlock">
                            <div className="shadowBlock__head">
                                <img src={Logo} className="shadowBlock__head__img" alt="bank-of-muscat"/>
                            </div>
                            <div className="flexInBlock">
                                <div className="flexInBlock__center">
                                    {progressSts.toLowerCase() === 'success' ?
                                        <img src={thankyouImage} className="tqImage"
                                             alt={data[lang]["Success"]}/>
                                        :
                                        <img src={closeImage} className="tqImage smImage"
                                             alt={data[lang]['oops']}/>
                                    }
                                </div>
                                <div className="flexInBlock__center">
                                    {(progressMsg === '' && progressSts !== 'Failure') ?
                                        <p className="iconMessageBox__text">{data[lang]['recordedMessage']}</p>
                                        :
                                        <>
                                            <p>{progressMsg}</p>
                                            {maxretriesMsg !== '' &&
                                            <p>{maxretriesMsg}</p>
                                            }
                                        </>
                                    }
                                    {progressSts === "Success" &&
                                        <>
                                        <p className="iconMessageBox__text">{data[lang]['livenessCompleted']}</p>
                                            {optionToMobile === 0 ? (
                                                <></>
                                            ) : (
                                                <>
                                                    {optionToDesktop === 0 ? (
                                                        <p className="iconMessageBox__text">{data[lang]['liketocontinueondesktop']}</p>
                                                    ) : (
                                                        <p className="iconMessageBox__text">
                                                            {data[lang]['redirectedtodesktop']}</p>
                                                    )}
                                                </>
                                            )
                                            }
                                        </>
                                    }


                                </div>
                            </div>
                        </div>
                        {progressSts === "Failure" &&
                        <div className="buttomWrap">
                            <div className="flexCC">
                                <span onClick={retakeVideo} className="btn btn--capsule btn--primaryG btn--contentWrap">
                                    {data[lang]['retakeVideo']}
                                </span>
                            </div>
                        </div>
                        }

                        {progressSts === "Success" &&
                            <>
                                <div className="buttomWrap">
                                    {optionToMobile === 0 ? (
                                        <div className="flexCC">
                                            <span onClick={nextStep}
                                                    className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['proceedButton']}
                                            </span>
                                        </div>
                                    ) : (
                                        <>
                                            {optionToDesktop === 0 ? (
                                                <>
                                                    <div className="flexCC">
                                                        <span onClick={nextStepToDesktop}
                                                                className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['proceedtodesktop']}
                                                        </span>
                                                    </div>
                                                    <div className="flexCC">
                                                        <span onClick={nextStepToMobile}
                                                                className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['proceedtomobile']}
                                                        </span>
                                                    </div>
                                                </>
                                            ) : ('')}
                                        </>
                                    )
                                    }
                                </div>
                            </>
                        }
                    </div>






                    {stopcapturing ? (
                        <div className="buttomWrap">

                            {videoChunks.length > 0 && (
                                <React.Fragment>
                                    <div className="flexCC">
                                        <button onClick={uploadVideo}
                                                className="btn btn--capsule btn--primaryG ">{data[lang]['submitVideo']}
                                        </button>
                                    </div>
                                    <div className="flexCC">
                                        <button onClick={retakeVideo}
                                                className="btn btn--capsule btn--primaryOuter ">{data[lang]['retakeVideo']}
                                        </button>
                                    </div>
                                </React.Fragment>

                            )}

                        </div>

                    ) : (<React.Fragment></React.Fragment>)
                    }

                </div>
                }

            </>

    );
};

export default LivenesstestvideoVersion1;
