import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import Header from '../components/Header';
import {useDispatch, useSelector} from "react-redux";
import Logo from '../assets/images/BM_logo.png'
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import {
    generateOTP,
    verifyOTP,
    getOTPMaxRetries,
    getOTPLimitMaxRetries, updateMaxOTPRetries, createAccessLog, getConfig, resetLIDUpdate
} from "../actions/Authorization";
import Loader from "./Loader";
import Popup from "./Popup";
import otpIcon from "../assets/images/05_OTP.svg";

const Submitotp = () => {
    const lang = getToken().lang;
    const [renewType, setRenewType] = useState(getToken().renewType);
    const [seconds, setSeconds] = useState(0);
    const [maxretries, setMaxretries]  = useState(3);
    const [maxOTPsubmit, setMaxOTPsubmit]  = useState(3);
    const [submitotpcount,setSubmitotpcount ] = useState(0);
    const [sendotpcount,setSendotpcount ] = useState(0);
    const [otp, setOtp] = useState('');
    const [journeyid, setJourneyid] = useState(getToken().journeyid);
    const [cif, setCif] = useState(getToken().cif);
    const [verifyotp, setVerifyotp] = useState(false);
    const [nonstp, setNonstp] = useState(getToken().nonstp);
    const [opterror, setOtperror] = useState('');
    const [submitoptstatus, setSubmitoptstatus] = useState('');
    const [loader, setLoader] = React.useState(false);
    const [loaderMessage, setLoaderMessage] = React.useState('');
    const [errorMsg, setErrorMsg] = React.useState('');

    let history = useHistory();
    const dispatch = useDispatch();

    const otpRespdata = useSelector(state => state.AppReducer.otpResp  ) ;
    const otpverifyRespdata = useSelector(state => state.AppReducer.otpverifyResp  ) ;
    const otpMaxRetries = useSelector(state => state.AppReducer.otpMaxRetries  ) ;
    const otpSubmitMaxRetries = useSelector(state => state.AppReducer.otpSubmitMaxRetries  ) ;

    const checkConfig = () => {
        if(getToken().BM_BASE_URL === ''){
            setTimeout(checkConfig, 100)
        }
    }

    useEffect(() => {
        dispatch(getConfig());
        setTimeout(checkConfig, 100);
        //console.log('test')

        setLoader(true);
        //dispatch(createAccessLog('MaxRetries' , 'Started', 'Fetching MaxRetries limits',cif));
        dispatch(getOTPMaxRetries()) ;
        dispatch(getOTPLimitMaxRetries()) ;
    }, []);

    useEffect(() => {
        //dispatch(getOTPLimitMaxRetries()) ;
    }, []);

    useEffect(() => {
        if(otpMaxRetries != undefined ){
            //dispatch(createAccessLog('MaxRetries' , 'Success', 'Fetching MaxRetries limits ' + otpMaxRetries,cif));
            setMaxretries(otpMaxRetries) ;
        }
    },[otpMaxRetries]);

    useEffect(() => {//console.log(otpSubmitMaxRetries)
        if(otpSubmitMaxRetries != undefined ){
            //dispatch(createAccessLog('MaxRetries' , 'Success', 'Fetching Submit MaxRetries limits ' + otpSubmitMaxRetries,cif));
            setMaxOTPsubmit(otpSubmitMaxRetries) ;
        }
    },[otpSubmitMaxRetries]);

    /* React.useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            resendOTP();
        }
    });  */

    const resendOTP = (e) =>{
        //setLoader(true)
        setLoader(true)
        setSubmitoptstatus('');
        setLoaderMessage(data[lang]['sendingotp'])
        setOtperror('');
        setVerifyotp(false);
        //console.log(maxretries+" : "+sendotpcount);
        //setSendotpcount(sendotpcount+1) ;
        //alert('resendOTP : ' + maxretries + '-' + sendotpcount)
        if(maxretries > sendotpcount){
            //dispatch(createAccessLog('resendOTP', 'Started', 'Send OTP to Customer', cif));
            dispatch(generateOTP(journeyid,cif));
            setSendotpcount(sendotpcount+1) ;
        }else{
            history.push("/contact-branch?ref=3") ;
        }
    }
    const sendOTP = (e) =>{

        setLoader(true);

        if(maxretries > sendotpcount){
            //dispatch(createAccessLog('sendOTP', 'Started', 'Send OTP to Customer', cif));
            setSubmitoptstatus('');
            setLoaderMessage(data[lang]['sendingotp'])
            dispatch(generateOTP(journeyid,cif));
            setSendotpcount(sendotpcount+1) ;
        }else{
            history.push("/contact-branch?ref=3") ;
        }

    }

    useEffect(() => {
      /*   */
       // console.log(otpRespdata) ;
        if(otpRespdata !== undefined) {
            let status = otpRespdata.status;
            if (status === "success") {
                setLoader(false)
                //dispatch(createAccessLog('send OTP', 'Success', 'Sent OTP Successfully', cif));
                setErrorMsg('')
                setSeconds(30);
                setSubmitoptstatus('1')
            }
            if (status === "error") {
                setLoader(false)
                dispatch(createAccessLog('send OTP', 'Error', 'Sent OTP failed', cif));
                setSubmitoptstatus('0')
            }
            if (status === "otpLimitReached") {
                setLoader(false)
                dispatch(createAccessLog('send OTP', 'otpLimitReached', 'otpLimitReached - error from api', cif));
                setSubmitoptstatus('0')
                history.push("/contact-branch?ref=3") ;
            }
            if (otpRespdata.message === "Failure") {
                setLoader(false)
                dispatch(createAccessLog('send OTP', 'Error', 'Sent OTP failed', cif));
                setErrorMsg(data[lang]['unabletoconfirm2'])
            }

        }
    }, [otpRespdata]);

    useEffect(() => {
        //console.log(otpverifyRespdata) ;
        let ref   ;     //stp


        if(renewType === "KYC"){
            if(nonstp === true || nonstp === 'true'){
               ref = 12 ;   //nonstp
            }else{
                ref = 11
            }
        }else{
             ref = 13 ;
        }
       // console.log(otpverifyRespdata.status)

        if(otpverifyRespdata.status === "success") {
            dispatch(createAccessLog('VerifyOTP' , 'Success', 'Verified OTP',cif));
            //setLoader(false)
            //console.log('/thank-you?ref=' + ref + '&refno=' + otpverifyRespdata.refno)
            history.push('/thank-you?ref=' + ref + '&refno=' + otpverifyRespdata.refno);
        } else if(otpverifyRespdata.status === "failure") {
            dispatch(createAccessLog('VerifyOTP' , 'Failure', otpverifyRespdata.message,cif));
            setSubmitotpcount(submitotpcount - 1);
            setLoader(false)
            setOtperror('failure');
            setErrorMsg(data[lang]['unabletoconfirm2'])
            setVerifyotp(false)
        } else if(otpverifyRespdata.status === "error" && otpverifyRespdata.refno && otpverifyRespdata.refno !== null) {
            if(otpverifyRespdata.isNAU && parseInt(otpverifyRespdata.isNAU) === 1) {
                dispatch(createAccessLog('VerifyOTP', 'Failure', 'T24 error isNAU. Ref No: ' + otpverifyRespdata.refno, cif));
                setSubmitotpcount(submitotpcount - 1);
                setLoader(false)
                setOtperror('failure');
                setErrorMsg(data[lang]['isnauError'])
                setVerifyotp(false)
            } else {
                dispatch(createAccessLog('VerifyOTP', 'Failure', 'Error, but reference number returned, T24 may fail. Ref No: ' + otpverifyRespdata.refno, cif));
                setSubmitotpcount(submitotpcount - 1);
                setLoader(false)
                setOtperror('failure');
                setErrorMsg(data[lang]['unabletoconfirm2'])
                setVerifyotp(false)
            }
        } else if(otpverifyRespdata.status === "error"  && (otpverifyRespdata.refno === null || otpverifyRespdata.refno === '')) {
            setVerifyotp(false)
            dispatch(createAccessLog('VerifyOTP' , 'Error', 'Error on OTP Verification',cif));
            setLoader(false)
            if(submitotpcount >= maxOTPsubmit) {
                dispatch(createAccessLog('VerifyOTP Maxretries' , 'Error', 'Maximum retries reached on OTP Verification',cif));
                dispatch(updateMaxOTPRetries())
                history.push("/contact-branch?ref=15")
            } else {

                setOtperror('error');
            }
        }
    }, [otpverifyRespdata]);


    useEffect(() => {
        if(otpMaxRetries != undefined && otpSubmitMaxRetries !== undefined && sendotpcount === 0) {

            //dispatch(generateOTP(journeyid,cif));
            sendOTP();
        }
    }, [sendotpcount, otpMaxRetries, otpSubmitMaxRetries]);

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        }

    }, [seconds]);


    const handleInput = (e) =>{
        let value = e.target.value;
        if(value !== '' && (!/^[0-9]+$/.test(value) || value.length > 4 )){return false; }
        //console.log(e.target.value) ;
        setOtp(e.target.value) ;
        setOtperror('');
        setVerifyotp(false)
    }

    useEffect(() => {
        //console.log(verifyotp) ;
        if(verifyotp){
            dispatch(createAccessLog('verifyOTP' , 'Started', 'Calling Verify OTP otp:' + otp + ', cif: ' + cif + ', journeyid: ' + journeyid,cif));
            dispatch(verifyOTP(otp,cif,journeyid));
        }

    }, [verifyotp]);

    const onSubmitOtp = (e) => {
        if(otp !== '') {
            //dispatch(createAccessLog('submitOTP', 'Started', 'Submit OTP ', cif));
            setLoader(true)
            setLoaderMessage(data[lang]['verifyingotp'])
            setSubmitotpcount(submitotpcount + 1);

            setOtperror('');
            setVerifyotp(true)
        }
    }
    const backToPreview = (e) => {


        if(getToken().renewType === 'LID') {
            setLoader(true);
            setLoaderMessage('')
            dispatch(resetLIDUpdate());
            //console.log(getToken().renewType)
            setTimeout(function (){history.push('/details/?ref=otp');}, 1000)
            //console.log('/details/?ref=otp');
        } else {
            history.push('/customer-details/?ref=otp');
        }
    }

    //console.log(opterror) ;

    return (
        <>
            {loader &&
            <Loader message={loaderMessage}/>
            }
            {errorMsg !== '' &&
            <Popup messagehead={data[lang]['oops']} message={errorMsg}/>
            }
            <Header className="hasCrossOver hasCrossOver--lg">
                <Link className="logoLink">
                    <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
                </Link>
            </Header>

            <div className={`wPanel paddingBonly offBox ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                <div className="contentWrap">
                    <div className="shadowBlock">
                        <div className="shadowBlock__head">
                            <span className="block_headline">{data[lang]['otpheadline']}</span>
                           {/* <img src={Logo} className="shadowBlock__head__img" alt="bank-of-muscat"/>*/}
                        </div>
                        <div className="flexInBlock">
                            <div className="flexInBlock__center">
                                {submitoptstatus === '1' &&
                                <img src={otpIcon} className="tqImage" alt="thank-you"/>
                                }
                            </div>
                            <div className="flexInBlock__center">
                                {submitoptstatus === '1' &&
                                <>
                                    <p className="otp-sent-msg">{data[lang]['otpSent']}</p>
                                    <fieldset className="no-border">

                                        <input name="otp" value={otp} maxLength="4" onChange={handleInput} placeholder={data[lang]['enterOTP']}
                                               type="tel" pattern="[0-9]*"
                                               className="formBlock__input otpInput" autocomplete="one-time-code"/>
                                    </fieldset>
                                    { submitoptstatus === '1' && maxretries >  sendotpcount ? (

                                            <p onClick={resendOTP} className={`regenerate-otp ${seconds === 0 ? 'active' : 'disabled'}`}>{data[lang]['regenerateOTP']}</p>

                                    ) : '' }
                                    {seconds > 0 ? (
                                        <p className="otp-timer">{data[lang]['expireOTP'].replace('{seconds}', seconds)}</p>
                                    ) : ''}
                                    {otp !== '' && opterror === 'error' ? (
                                        <p className="primaryTextAnchor">{data[lang]['errorOTP'].replace('{cnt}', submitotpcount).replace('{maxcnt}', maxOTPsubmit)}</p>
                                    ) : ''}
                                </>
                                }
                                { submitoptstatus === '0' ? (
                                    <p>{data[lang]['unabletosendotp']}</p>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                    <div className="buttomWrap">
                        {submitoptstatus === '1' &&
                        <div className="flexCC desktopBtn">
                            <span className="btn btn--primaryG btn--capsule btn--primaryOuter" onClick={backToPreview} style={{margin: '10px'}}>
                                {data[lang]['cancel']}
                            </span>
                            <span onClick={onSubmitOtp}
                                  className={`btn btn--capsule btn--primaryG btn--contentWrap ${otp === '' ? 'disabled' : ''}`} style={{margin: '10px'}}>{data[lang]['submit']}</span>
                        </div>
                        }

                        {submitoptstatus === '0' &&
                        <div className="flexCC">
                            <span onClick={sendOTP}
                                  className={`btn btn--capsule btn--primaryG btn--contentWrap`}>{data[lang]['sendOTP']}</span>
                        </div>
                        }

                    </div>
                </div>




            </div>
        </>
    )
}

export default Submitotp
