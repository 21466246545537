import React from 'react';

class LeftGesture extends React.Component{

	render() {
		return (
			<div className="gesture-ins-anim-bg" key={new Date().getTime()} >
				<img key={new Date().getTime()} src={`./assets/images/Head-TL-5x-${this.props.gestureCnt}.gif`} alt=""/>
			</div>
			)
		}
	}



export default LeftGesture;