import {kycStore} from '../reducers/KYCState';

export  function handshake(data) {
    /* const user = jwt(webtoken);
    console.log(user) ;
    setToken(webtoken,user)
    console.log(webtoken);
    setToken(webtoken) ;  */

   /*  const user = decode(webtoken);
    console.log(user) ;
    console.log(user.cif) ;
    setToken(webtoken,user)
    */
   //console.log(data) ;
    setToken(data)
    //return Promise.resolve(data.htoken);


}
export function setClient(client) {
    kycStore.getState().KYCState.BM_client = client
}
export function setLang(lang) {
    kycStore.getState().KYCState.BM_lang = lang
}



export  function setUserToken(token) {
    //console.log("user token: "+token.token) ;
    updateUserToken(token.token)
    return Promise.resolve(token.token);
}

function setToken(data) {
    //console.log(data) ;
    //localStorage.setItem('BM_token', data.htoken)
    //localStorage.setItem('BM_apitoken', data.token)
    /* localStorage.setItem('BM_custinfo', data.custinfo ) */
    //localStorage.setItem('BM_custname', data.custinfo.custName)
    //localStorage.setItem('BM_mobile', data.custinfo.mobile)
    var idtype = '';
    let legalId = '';
    let ctype  = data.custinfo.ctype.toUpperCase() ;
    if(ctype === "RESIDENT" || ctype === "INDIVIDUAL" || ctype === "DIPLOMATS" ||  ctype === "DSU.INDIVIDUAL"  || ctype === "SHABABI" || ctype === "PREPAID.INDIVIDUAL" ){
        ctype  = "Resident" ;
    }else if(ctype === "OMANI" ){
        ctype  = "Omani" ;
    }else if(ctype === "NON-RESIDENT" || ctype === "NONRESIDENT" ||  ctype === "NON RESIDENT" ){
        ctype  = "Non-Resident" ;
    }
    if(ctype === "Omani"){
        idtype = 'National' ;
        legalId = "NID";
    }else if(ctype === "Resident"){
        idtype = 'Resident' ;
        legalId = "CIVIL.ID";
    }else{
        idtype = 'Passport' ;
        legalId = "PASSPORT.ID";
    }




    let renew = data.custinfo.renew.toUpperCase();
    renew = (renew === 'LID' || renew === 'LEGAL') ? 'LID' : renew;
    let lang = data.custinfo.lang.charAt(0).toUpperCase() + data.custinfo.lang.slice(1);
    let bwayparam = '';
    if(data.custinfo.client === "IB"){
        bwayparam = (data.custinfo.bway1.trim()) + (data.custinfo.bway2.trim()) + (data.custinfo.bway3.trim()) ;
    }else if(data.custinfo.client === "MB"){
        bwayparam = data.custinfo.bway ? data.custinfo.bway.trim() : '' ;
    }
    if(data.custinfo.name) {
        data.custinfo.status = 'success';
    }
    //data.custinfo.typeFromScore = 'STP'
    //data.custinfo.employed_cur_status = 'SELF EMPLOYED';
    console.log(data)
    kycStore.getState().KYCState.BM_token = data.htoken
    kycStore.getState().KYCState.BM_apitoken = data.token
    kycStore.getState().KYCState.BM_custname = data.custinfo.custName
    kycStore.getState().KYCState.BM_custrisk = data.custinfo.custRisk === null ? '' : data.custinfo.custRisk
    kycStore.getState().KYCState.BM_mobile = data.custinfo.mobile
    kycStore.getState().KYCState.BM_idtype = idtype
    kycStore.getState().KYCState.BM_journeyid = data.custinfo.journey_id
    kycStore.getState().KYCState.BM_cif = data.custinfo.cif
    kycStore.getState().KYCState.BM_nonstp = data.custinfo.NonStp
    kycStore.getState().KYCState.BM_renewType = renew
    kycStore.getState().KYCState.BM_lang = lang
    kycStore.getState().KYCState.BM_jsessionid = data.custinfo.jsessionid
    kycStore.getState().KYCState.BM_bway = bwayparam
    kycStore.getState().KYCState.BM_step = data.custinfo.current_step
    kycStore.getState().KYCState.BM_step_status = data.custinfo.current_step_status
    kycStore.getState().KYCState.BM_client = data.custinfo.client
    kycStore.getState().KYCState.BM_otp_locked = data.custinfo.otp_locked
    kycStore.getState().KYCState.BM_sector_code = data.custinfo.sector_code || ''
    kycStore.getState().KYCState.BM_livenessmaxretries = data.custinfo.livenessmaxretries || ''
    kycStore.getState().KYCState.BM_scanidmaxretries = data.custinfo.scanidmaxretries || ''
    kycStore.getState().KYCState.BM_faceMatchAcceptedScore = data.custinfo.faceMatchAcceptedScore || ''
    kycStore.getState().KYCState.BM_faceMatchLowScore = data.custinfo.faceMatchLowScore || ''
    kycStore.getState().KYCState.BM_nameMatchAcceptedScore = data.custinfo.nameMatchAcceptedScore || ''
    kycStore.getState().KYCState.BM_ocr_attempts = JSON.stringify(data.custinfo.ocr_attempts)
    kycStore.getState().KYCState.BM_more_info = data.custinfo.more_info || ''
    kycStore.getState().KYCState.BM_custinfo = data.custinfo || ''
    kycStore.getState().KYCState.BM_customer_id = data.customer_id || ''
    kycStore.getState().KYCState.BM_legalIdType = legalId
    kycStore.getState().KYCState.BM_facematchnstp = false
    kycStore.getState().KYCState.BM_livenessstatus = data.custinfo.liveness_test || true
    kycStore.getState().KYCState.BM_addressproofstatus = data.custinfo.address_proof || 'ON'
}

export function getToken() {
    var user=[];
    user['BM_PWA_API'] = kycStore.getState().KYCState.BM_PWA_API;//localStorage.getItem("BM_token")
    user['BM_GM_API'] = kycStore.getState().KYCState.BM_GM_API;//localStorage.getItem("BM_token")
    user['BM_BASE_URL'] = kycStore.getState().KYCState.BM_BASE_URL;//localStorage.getItem("BM_token")
    user['osName'] = kycStore.getState().KYCState.osName;//localStorage.getItem("BM_token")
    user['osVersion'] = kycStore.getState().KYCState.osVersion;//localStorage.getItem("BM_token")
    user['pt'] = kycStore.getState().KYCState.BM_PT;//localStorage.getItem("BM_token")
    user['token'] = kycStore.getState().KYCState.BM_token;//localStorage.getItem("BM_token")
    user['token'] = kycStore.getState().KYCState.BM_token;//localStorage.getItem("BM_token")
    user['custname'] =  kycStore.getState().KYCState.BM_custname;//localStorage.getItem("BM_custname")
    user['custrisk'] =  kycStore.getState().KYCState.BM_custrisk;//localStorage.getItem("BM_custrisk")
    user['mobile'] =  kycStore.getState().KYCState.BM_mobile;//localStorage.getItem("BM_mobile")
    user['idtype'] =  kycStore.getState().KYCState.BM_idtype;//localStorage.getItem("BM_idtype")
    user['journeyid'] =  kycStore.getState().KYCState.BM_journeyid;//localStorage.getItem("BM_journeyid")
    user['apitoken'] =  kycStore.getState().KYCState.BM_apitoken;//localStorage.getItem("BM_apitoken")
    user['cif'] =  kycStore.getState().KYCState.BM_cif;//localStorage.getItem("BM_cif")
    user['nonstp'] =  kycStore.getState().KYCState.BM_nonstp;//localStorage.getItem("BM_nonstp")
    user['renewType'] =  kycStore.getState().KYCState.BM_renewType;//localStorage.getItem("BM_renewType")
    user['lang'] =  kycStore.getState().KYCState.BM_lang || 'En';//localStorage.getItem("BM_lang") || 'En'
    user['jsessionid'] =  kycStore.getState().KYCState.BM_jsessionid;//localStorage.getItem("BM_jsessionid")
    user['bway'] =  kycStore.getState().KYCState.BM_bway;//localStorage.getItem("BM_bway")
    user['step'] =  kycStore.getState().KYCState.BM_step;//localStorage.getItem("BM_step")
    user['stepstatus'] =  kycStore.getState().KYCState.BM_step_status;//localStorage.getItem("BM_step_status")
    user['client'] =  kycStore.getState().KYCState.BM_client;//localStorage.getItem("BM_client")
    user['otp_locked'] =  kycStore.getState().KYCState.BM_otp_locked;//localStorage.getItem("BM_otp_locked")
    user['sector_code'] =  kycStore.getState().KYCState.BM_sector_code;//localStorage.getItem("BM_sector_code")
    user['ocr_attempts'] =  kycStore.getState().KYCState.BM_ocr_attempts;//JSON.parse(localStorage.getItem("BM_ocr_attempts"))
    user['livenessmaxretries'] =  kycStore.getState().KYCState.BM_livenessmaxretries;//JSON.parse(localStorage.getItem("BM_ocr_attempts"))
    user['scanidmaxretries'] =  kycStore.getState().KYCState.BM_scanidmaxretries;//JSON.parse(localStorage.getItem("BM_ocr_attempts"))
    user['faceMatchAcceptedScore'] =  kycStore.getState().KYCState.BM_faceMatchAcceptedScore;
    user['faceMatchLowScore'] =  kycStore.getState().KYCState.BM_faceMatchLowScore;
    user['nameMatchAcceptedScore'] =  kycStore.getState().KYCState.BM_nameMatchAcceptedScore;
    user['more_info'] =  kycStore.getState().KYCState.BM_more_info;
    user['version'] =  kycStore.getState().KYCState.version;
    user['custinfo'] =  kycStore.getState().KYCState.BM_custinfo;
    user['customer_id'] =  kycStore.getState().KYCState.BM_customer_id;
    user['legalIdType'] =  kycStore.getState().KYCState.BM_legalIdType;
    user['facematchnstp'] =  kycStore.getState().KYCState.BM_facematchnstp;
    user['livenessstatus'] =  kycStore.getState().KYCState.BM_livenessstatus;
    user['addressproofstatus'] =  kycStore.getState().KYCState.BM_addressproofstatus;
    //console.log(user)
    return user
}
export function clearToken() {
    kycStore.getState().KYCState.BM_token = ''
    kycStore.getState().KYCState.BM_apitoken = ''
    kycStore.getState().KYCState.BM_custname = ''
    kycStore.getState().KYCState.BM_mobile = ''
    kycStore.getState().KYCState.BM_idtype = ''
    kycStore.getState().KYCState.BM_journeyid = ''
    //kycStore.getState().KYCState.BM_cif = ''
    kycStore.getState().KYCState.BM_nonstp = ''
    kycStore.getState().KYCState.BM_renewType = ''
    kycStore.getState().KYCState.BM_lang = 'En'
    kycStore.getState().KYCState.BM_jsessionid = ''
    kycStore.getState().KYCState.BM_bway = ''
    kycStore.getState().KYCState.BM_step = ''
    kycStore.getState().KYCState.BM_step_status = ''
    //kycStore.getState().KYCState.BM_client = ''
    kycStore.getState().KYCState.BM_otp_locked = ''
    kycStore.getState().KYCState.BM_ocr_attempts = ''

}
export function updateLevel(level, status) {
    //localStorage.setItem('BM_step', level)
    //localStorage.setItem('BM_step_status', status)
    kycStore.getState().KYCState.BM_step = level
    kycStore.getState().KYCState.BM_step_status = status
}

export function updateIdtype(newidtype){
    //localStorage.setItem('BM_idtype',newidtype )
    kycStore.getState().KYCState.BM_idtype = newidtype
}

export function updateJourneyIdService(jid){
    //localStorage.setItem('BM_journeyid',jid)
    kycStore.getState().KYCState.BM_journeyid = jid
}

export function updateUserToken(token){
    //localStorage.setItem('BM_apitoken',token)
    kycStore.getState().KYCState.BM_apitoken = token
}

export function updateNonstp(sts){
    //localStorage.setItem('BM_nonstp',sts)
    kycStore.getState().KYCState.BM_nonstp = sts
}
export function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export function getRandomAESKey(length=80) {
    // let length=80
    var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for ( var i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return btoa(result);
}
export function setReference(ref) {
    kycStore.getState().KYCState.location = ref
}
export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}