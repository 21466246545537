import {combineReducers} from 'redux';


const customerTypeData =  (state = {custiddata:[],journeydata:[],custjourneyDevice: [], processFileResp:[], custjourneyStatus:[], livenessInstdata:[], livenessStatus:[] ,uploadResponsedata:[], level: '', status: ''}, action) => {
    switch (action.type) {
        case "IDTYPE_UPDATED":
            return {
                ...state,
                custiddata: action.newidtype
            };
            break;
        case "START_JOURNEY":
            return {
                ...state,
                journeydata: action.custjourney
            };
            break;
        case "UPDATE_JOURNEY":
            return {
                ...state,
                custjourneyStatus: action.custjourneyStatus
            };
            break;
        case "NONSTP_UPDATED":
            return {
                ...state,
                nonstp: action.nonstp
            };
            break;
        case "UPDATE_JOURNEY_MOBILE":
            return {
                ...state,
                custjourneyDevice: action.custjourneyDevice
            };
            break;
        case "LIVENESS_CHECK":
            return {
                ...state,
                livenessInstdata: action.livenessInst
            };
            break;
        case "LIVENESS_STATUS":
            return {
                ...state,
                livenessStatus: action.livenessStatus
            };
            break;
        case "UPLOAD_ID":
            return {
                ...state,
                uploadResponsedata: action.uploadResponsedata
            };
            break;
        case "PROCESS_FILE":
            return {
                ...state,
                processFileResp: action.processFileResp
            };
        case "JOURNEY_LEVEL":
            return {
                ...state,
                level: action.level,
                status: action.status
            };
            break;



        default:
            return state;
    }
};



export const CustomerReducer = combineReducers({
    customerTypeData
});

export default CustomerReducer;
