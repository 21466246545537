import React, {Component} from 'react';
import Header from '../components/Header';
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import Logo from '../assets/images/BM_logo.png'
import Popup from "./Popup";


class NotFound extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        return(
            <>
            <Popup messagehead={data[getToken().lang]['oops']} message={data[getToken().lang]['networkfailure']}/>
            <Header className="hasCrossOver hasCrossOver--lg">
                <div className="logoLink">
                <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
                </div>
            </Header>
            </>
        );
    }
}

export default NotFound;