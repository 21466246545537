import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import data from '../constants/data.json';
import {getToken} from "../utilities/AuthService";

const VerificationComplete = () => {
    const lang = getToken().lang;
    return (
        <>
            <Header className="hasCrossOver hasCrossOver--lg">
                <div className="navHead">
                    <Link className="navHead__back">
                        <span className="iconIn iconIn--back" />
                    </Link>
                    <p className="navHead__text">{data[lang]['identityVerification']}</p>
                </div>
            </Header>

            <div className={`wPanel ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                <div className="iconMessageBox">
                    <span className="iconMessageBox__icon iconIn iconIn--tickRound" />
                    {/*<h2 className="iconMessageBox__title">{data[lang]['done']}</h2>*/}
                    {/*<p className="iconMessageBox__text">{data[lang]['recordedMessage']}</p>*/}
                </div>
                <div className="buttomWrap">
                    <Link to="/scan-ID-instruction"  className="btn btn--capsule btn--primaryG btn--inFull">{data[lang]['submitVideo']}</Link>
                    <Link to="/identification"  className="btn btn--capsule btn--primaryOuter btn--inFull">{data[lang]['retakeVideo']}</Link>
                </div>
            </div>
        </>
    )
}

export default VerificationComplete
