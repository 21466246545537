import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';

import Logo from '../assets/images/BM_logo.png'
import {getToken} from "../utilities/AuthService";
import data from '../constants/data.json';
import {createAccessLog} from "../actions/Authorization";
import {useDispatch} from "react-redux";

import {kycStore} from "../reducers/KYCState";
import closeImage from "../assets/images/cancel-new.svg";
    const Contactbranch = () => {
    const [message, setMessage] = useState('');
    const [icon, setIcon] = useState('tickRound');
    const [messagehead, setMessagehead] = useState('');
    const lang = getToken().lang;
    const cif = getToken().cif;
    const custIdTp = getToken().idtype;
    const params = (new URL(document.location)).searchParams;
    let id = kycStore.getState().KYCState.location.toString() ;
        //const location = useLocation();
    //console.log(id)
    if(params.get("ref")) {
        id = params.get("ref")
    };
    const [closer, setCloser] = useState(false)
    const [client] = useState(getToken().client);
    const dispatch = useDispatch();
        let legalId = '';
        if (custIdTp === "National") {
            legalId = "National ID";
        } else if (custIdTp === "Resident") {
            legalId = "Civil ID";
        } else {
            legalId = "Passport";
        }
    useEffect(() => {
        if(id === "1") {
            setIcon('crossRound')
            setMessage(data[lang]['maxRetriesLivenessError']);
            setMessagehead(data[lang]['oops']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['maxRetriesLivenessError'],cif));
        } else if(id === "2"){
            setIcon('crossRound')
            setMessage(data[lang]['maxRetriesIDError'].replace(new RegExp('{idname}', 'g'), data[lang][legalId]));
            setMessagehead(data[lang]['oops']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['maxRetriesIDError'].replace('{idname}', data[lang][legalId]),cif));
        } else if(id === "3"){
            setIcon('crossRound')
            setMessage(data[lang]['maxOTPRetriesError']);
            setMessagehead(data[lang]['oops']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['maxOTPRetriesError'],cif));
        }else if(id === "4"){
            setIcon('crossRound')
            setMessage(data[lang]['facematchSuspeciousError'].replace(new RegExp('{idname}', 'g'), data[lang][legalId]));
            setMessagehead(data[lang]['oops']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['facematchSuspeciousError'],cif));
        }else if(id === "5"){
            setIcon('crossRound')
            setMessage(data[lang]['ocrMatchingError']);
            setMessagehead(data[lang]['oops']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['ocrMatchingError'],cif));
        }else if(id === "6"){
            setIcon('crossRound')
            setMessage(data[lang]['customerDetailsError']);
            setMessagehead(data[lang]['oops']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['customerDetailsError'],cif));
        }else if(id === "7"){
            setIcon('crossRound')
            setMessage(data[lang]['ocrExtractionError']);
            setMessagehead(data[lang]['oops']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['ocrExtractionError'],cif));
        }else if(id === "8"){
            setIcon('crossRound')
            setMessage(data[lang]['cardExpiredError']);
            setMessagehead(data[lang]['oops']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['cardExpiredError'],cif));
        }else if(id === "15"){
            setIcon('crossRound')
            setMessage(data[lang]['maxOTPMaxRetriesError']);
            setMessagehead(data[lang]['oops']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['maxOTPMaxRetriesError'],cif));
        }else{
            setIcon('tickRound')
            setMessage(data[lang]['contactBranchMessage']);
            setMessagehead(data[lang]['contactBranchMessageHead']);
            dispatch(createAccessLog('Contact Branch' , 'Launched', data['En']['contactBranchMessage'],cif));
        }

    },[setMessage,id, lang]);


    const closePopup = () =>{
        const ismobile = (client === 'MB');
        if(!ismobile) {
            dispatch(createAccessLog('Close button' , 'Clicked', 'Calling window.parent.postMessage("gieomRedirectHome", "*");',cif));
            //window.parent.gieomRedirectHome();
            window.parent.postMessage("gieomRedirectHome", "*");
        } else {
            dispatch(createAccessLog('Close button' , 'Clicked', 'Adding bom-pwa-close-btn',cif));
            setCloser(true)
        }
    }
    return (
        <>
            <Header className="hasCrossOver hasCrossOver--lg">
                {closer === true &&
                <div id="bom-pwa-close-btn"></div>
                }
                <Link className="logoLink">
                    <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
                </Link>
            </Header>

            <div className={`wPanel paddingBonly offBox ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                <div className="contentWrap">
                    <div className="shadowBlock">
                        <div className="shadowBlock__head">
                            <img src={Logo} className="shadowBlock__head__img" alt="bank-of-muscat"/>
                        </div>
                        <div className="flexInBlock">
                            <div className="flexInBlock__center">
                                <img src={closeImage} className="tqImage smImage" alt={messagehead}/>
                            </div>
                            <div className="flexInBlock__center">
                                <h5 className="flexInBlock__title alignCenter">{messagehead}</h5>
                                <p className="alignCenter">{message}</p>

                            </div>
                        </div>
                    </div>
                    <div className="flexCC">
                        <span onClick={closePopup} className="btn btn--capsule btn--primaryG btn--contentWrap">{data[lang]['contactBranchOKButton']}</span>
                    </div>
                </div>



                {/*<div className="iconMessageBox">
                    <span className={`iconMessageBox__icon iconIn iconIn--${icon}`} />
                    <h2 className="iconMessageBox__title">{messagehead}</h2>
                    <p>{message}</p>
                    <div className="buttomWrap">
                        <div className="flexCC">
                        <span  onClick={closePopup} className="btn btn--capsule btn--primaryG">{data[lang]['contactBranchOKButton']}</span>
                        </div>
                    </div>
                </div>*/}
            </div>
        </>
    )
}

export default Contactbranch
