import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import Header from '../components/Header';
import data from '../constants/data.json';
import {getToken} from "../utilities/AuthService";
import Logo from '../assets/images/BM_logo.png'
import {useDispatch} from "react-redux";
import {HeartBeat, updateNextLevel, createAccessLog} from "../actions/Authorization";
import Loader from "./Loader";

const InstructionID = () => {
    const lang = getToken().lang;
    const dispatch = useDispatch();
    const [htoken] = useState(getToken().token);
    const [cif] = useState(getToken().cif);
    const [stopVideo, setstopVideo] = useState(true)
    const [loader, setloader] = useState(false)
    //document.getElementById('scanInstructions').addEventListener('ended',myHandler,false);
    function videoHandler(e) {
        setstopVideo(true)
    }
    function stopLoader(e) {
        setloader(false)
    }
    useEffect(() => {
        //dispatch(createAccessLog('ScanIDInstructions' , 'Launched', 'Scan ID Instructions Page Launched',cif));
        dispatch(updateNextLevel(htoken, 1)) ;
    }, []);
    return (
        <>
            {loader === true &&
            <Loader/>
            }
            {/*{stopVideo === false &&
            <video controls autoplay="autoplay" width="100%" id="scanInstructions" onEnded={videoHandler} onCanPlay={stopLoader}>
                <source src="./assets/images/scan.mp4" type="video/mp4"/>

                Your browser does not support the video tag.
            </video>
            }*/}
            {stopVideo === true &&
            <>
                <Header className="hasCrossOver hasCrossOver--lg">
                    <Link className="logoLink">
                        <img src={Logo} className="logoLink__in" alt="bank-of-muscat"/>
                    </Link>
                </Header>

                <div className={`wPanel ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                    {/*<div className="iconMessageBox">

                    <p className="iconMessageBox__text">{data[lang]['livenessCompleted']}</p>
                </div>*/}
                    <div className="">
                        <h2>{data[lang]['scanIDInstructionsHead']}</h2>
                        <ol className="numberList">
                            <li>{data[lang]['scanIDInstructions1']}</li>
                            <li>{data[lang]['scanIDInstructions2']}</li>
                            <li>{data[lang]['scanIDInstructions3']}</li>
                            <li>{data[lang]['scanIDInstructions4']}
                                <div className="listInBox">
                                    <p className="arrowText">{data[lang]['scanIDInstructions4a']}</p>
                                    <p className="arrowText">{data[lang]['scanIDInstructions4b']}</p>
                                </div>
                            </li>
                        </ol>
                        <div className="buttomWrap">
                            <div className="flexCC">
                                <Link to="/id-scan"
                                      className="btn btn--capsule btn--primaryG">{data[lang]['scanIDButton']}</Link>
                            </div>
                            {/* <div className="flexCC">
                                <Link to="/id-upload"  className="btn btn--capsule btn--primaryG">{data[lang]['uploadIDButton']}</Link>
                            </div>*/}
                        </div>
                    </div>
                    <div style={{display: "none"}}>
                        <img key={`1 + ${new Date().getTime()}`} src={`./assets/images/card-front.svg`}/>
                        <img key={`2 + ${new Date().getTime()}`} src={`./assets/images/card-back.svg`}/>
                        <img key={`3 + ${new Date().getTime()}`} src={`./assets/images/card-outline.png`}/>
                        <img key={`4 + ${new Date().getTime()}`} src={`./assets/images/id-card-back.png`}/>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default InstructionID
