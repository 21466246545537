import React, {useEffect, useState} from 'react';
import { osVersion, osName } from 'react-device-detect';
import { Link, useHistory } from 'react-router-dom';
import Header from '../components/Header';
import {useDispatch, useSelector} from "react-redux";
import Logo from '../assets/images/BM_logo.png'
import {getToken, setReference} from "../utilities/AuthService";
import data from '../constants/data.json';
import otpIcon from "../assets/images/05_OTP.svg";
import {
    generateOTP,
    verifyOTPQRcode,
    getOTPMaxRetries,
    getOTPLimitMaxRetries, updateMaxOTPRetries, createAccessLog
} from "../actions/Authorization";
import Loader from "./Loader";
import Popup from "./Popup";
import {PWA_JOURNEY_STEPS} from "../constants";

const QRSubmitotp = (props) => {
    const lang = getToken().lang;
    const [renewType, setRenewType] = useState(getToken().renewType);
    const [seconds, setSeconds] = useState(0);
    const [maxretries, setMaxretries]  = useState(3);
    const [maxOTPsubmit, setMaxOTPsubmit]  = useState(3);
    const [submitotpcount,setSubmitotpcount ] = useState(0);
    const [sendotpcount,setSendotpcount ] = useState(0);
    const [otp, setOtp] = useState('');
    const [journeyid, setJourneyid] = useState(getToken().journeyid);
    const [cif, setCif] = useState(getToken().cif);
    const [verifyotp, setVerifyotp] = useState(false);
    const [nonstp, setNonstp] = useState(getToken().nonstp);
    const [opterror, setOtperror] = useState('');
    const [submitoptstatus, setSubmitoptstatus] = useState('');
    const [loader, setLoader] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');
    const [loaderMessage, setLoaderMessage] = React.useState('');
    const [versionStatus, setVersionStatus] = React.useState(false);

    let history = useHistory();
    const dispatch = useDispatch();

    const otpRespdata = useSelector(state => state.AppReducer.otpResp  ) ;
    const qrOtpverifyResp = useSelector(state => state.AppReducer.qrOtpverifyResp  ) ;
    const otpMaxRetries = useSelector(state => state.AppReducer.otpMaxRetries  ) ;

    const otpSubmitMaxRetries = useSelector(state => state.AppReducer.otpSubmitMaxRetries  ) ;

    useEffect(() => {
        setLoader(true);
        dispatch(createAccessLog('QR Scanned' , 'Device Info', 'osName - ' + osName + ' osVersion - ' + osVersion, cif));
        //dispatch(createAccessLog('MaxRetries' , 'Started', 'Fetching MaxRetries limits',cif));
        dispatch(getOTPMaxRetries()) ;

        dispatch(getOTPLimitMaxRetries()) ;
    }, []);

    useEffect(() => {
        //dispatch(getOTPLimitMaxRetries()) ;
    }, []);

    useEffect(() => {
        if(otpMaxRetries != undefined ){
            //dispatch(createAccessLog('MaxRetries' , 'Success', 'Fetching OTP MaxRetries limits ' + otpMaxRetries,cif));
            setMaxretries(otpMaxRetries) ;
        }
    },[otpMaxRetries]);



    useEffect(() => {//console.log(otpSubmitMaxRetries)
        if(otpSubmitMaxRetries != undefined ){
            //dispatch(createAccessLog('MaxRetries' , 'Success', 'Fetching OTP Submit MaxRetries limits ' + otpSubmitMaxRetries,cif));

            setMaxOTPsubmit(otpSubmitMaxRetries) ;
        }
    },[otpSubmitMaxRetries]);

    /* React.useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            resendOTP();
        }
    });  */

    const resendOTP = (e) =>{
        //setLoader(true)
        setLoader(true)
        setSubmitoptstatus('');
        setLoaderMessage(data[lang]['sendingotp'])
        setOtperror('');
        setVerifyotp(false);
        //console.log(maxretries+" : "+sendotpcount);
        //setSendotpcount(sendotpcount+1) ;
        //alert('resendOTP : ' + maxretries + '-' + sendotpcount)
        if(maxretries > sendotpcount){
            //dispatch(createAccessLog('resendOTP', 'Started', 'Send OTP to Customer', cif));
            dispatch(generateOTP(journeyid,cif));
            setSendotpcount(sendotpcount+1) ;
        }else{
            history.push("/contact-branch?ref=3") ;
        }
    }
    const sendOTP = (e) =>{

        setLoader(true);

        if(maxretries > sendotpcount){
            //dispatch(createAccessLog('sendOTP', 'Started', 'Send OTP to Customer', cif));
            setSubmitoptstatus('');
            setLoaderMessage(data[lang]['sendingotp'])
            dispatch(generateOTP(journeyid,cif));
            setSendotpcount(sendotpcount+1) ;
        }else{
            history.push("/contact-branch?ref=3") ;
        }

    }

    useEffect(() => {
        /*   */
        //console.log(otpRespdata) ;
        if(otpRespdata !== undefined) {
            let status = otpRespdata.status;
            if (status === "success") {
                setLoader(false)
                //dispatch(createAccessLog('send OTP', 'Success', 'Sent OTP Successfully', cif));
                setErrorMsg('')
                setSeconds(30);
                setSubmitoptstatus('1')
            }
            if (status === "error") {
                setLoader(false)
                dispatch(createAccessLog('send OTP', 'Error', 'Sent OTP failed - error from api', cif));
                setSubmitoptstatus('0')
            }
            if (status === "otpLimitReached") {
                setLoader(false)
                dispatch(createAccessLog('send OTP', 'otpLimitReached', 'otpLimitReached - error from api', cif));
                setSubmitoptstatus('0')
                history.push("/contact-branch?ref=3") ;
            }
            if (otpRespdata.message === "Failure") {
                setLoader(false)
                dispatch(createAccessLog('send OTP', 'Failure', 'Sent OTP failed', cif));
                setErrorMsg(data[lang]['unabletoconfirm2'])
            }

        }
    }, [otpRespdata]);

    useEffect(() => {
        //console.log(qrOtpverifyResp) ;
        let ref   ;     //stp


        if(renewType === "KYC"){
            if(nonstp){
               ref = 12 ;   //nonstp
            }else{
                ref = 11
            }
        }else{
             ref = 13 ;
        }
        //console.log(qrOtpverifyResp)
        if(qrOtpverifyResp && qrOtpverifyResp.status === "success") {
            dispatch(createAccessLog('Verify OTP' , 'Success', 'Verified OTP',cif));
            setLoader(false)
           // history.push('/thank-you?ref=' + ref);
            moveNext();
        } else if(qrOtpverifyResp && qrOtpverifyResp.status === "failure") {
            dispatch(createAccessLog('Verify OTP' , 'Error', 'Failed OTP Verification',cif));
            setSubmitotpcount(submitotpcount - 1);
            setLoader(false)
            setOtperror('failure');
            setErrorMsg(data[lang]['unabletoconfirm2'])
            setVerifyotp(false)
        } else if(qrOtpverifyResp && qrOtpverifyResp.status === "error") {
            setVerifyotp(false)
            dispatch(createAccessLog('Verify OTP' , 'Error', 'Error on OTP Verification',cif));
            setLoader(false)
            if(submitotpcount >= maxOTPsubmit) {
                dispatch(createAccessLog('Verify OTP Maxretries: ' , 'Error', 'Maximum retries reached on OTP Verification',cif));
                dispatch(updateMaxOTPRetries())
                history.push("/contact-branch?ref=15")
            } else {

                setOtperror('error');
            }
        }
    }, [qrOtpverifyResp]);


    useEffect(() => {
        if(journeyid !== undefined && otpMaxRetries != undefined && otpSubmitMaxRetries !== undefined && sendotpcount === 0) {

            //dispatch(generateOTP(journeyid,cif));
            sendOTP();
        }
    }, [sendotpcount, otpMaxRetries, otpSubmitMaxRetries, journeyid]);

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        }

    }, [seconds]);


    const handleInput = (e) =>{
        let value = e.target.value;
        if(value !== '' && (!/^[0-9]+$/.test(value) || value.length > 4 )){
            return false;
        }
        //console.log(e.target.value) ;
        setOtp(e.target.value) ;
        setOtperror('');
        setVerifyotp(false)
    }

    useEffect(() => {
        //console.log(verifyotp) ;
        if(verifyotp){
            dispatch(verifyOTPQRcode(otp,cif,journeyid));
        }

    }, [verifyotp]);

    const onSubmitOtp = (e) => {
        if(otp !== '') {
            //dispatch(createAccessLog('submitOTP', 'Started', 'Submit OTP', cif));
            setLoader(true)
            setLoaderMessage(data[lang]['verifyingotp'])
            setSubmitotpcount(submitotpcount + 1);

            setOtperror('');
            setVerifyotp(true)
        } else {
            setOtperror('Please enter OTP');
        }
    }
    const moveNext = () =>{
        const idtype = getToken().idtype ;
        let step = getToken().step;
        let stepStatus = getToken().stepstatus;
        let otp_locked = getToken().otp_locked;
        let sector_code = getToken().sector_code;
        var nextstep = '';
        //props.history.push(PWA_JOURNEY_STEPS[2]);return;
        if(idtype === "National"){
            nextstep = '/' + PWA_JOURNEY_STEPS[1];
        }else if(idtype === "Resident"){
            if(sector_code === '1002') {
                nextstep = '/' + PWA_JOURNEY_STEPS[1];
            } else {
                nextstep = '/resident-id-check';
            }
        }else{
            nextstep = '/' + PWA_JOURNEY_STEPS[1];
        }

        let ocr_attempts = JSON.parse(getToken().ocr_attempts);
        let more_info = getToken().more_info;
        if(ocr_attempts.livenesstest_attempts !== undefined) {
            if (step === '2' && stepStatus === 'Rejected' && parseInt(ocr_attempts.livenesstest_attempts) < parseInt(getToken().livenessmaxretries)) {
                stepStatus = 'Inprogress';
            }
        }
        if(ocr_attempts.idscan_attempts !== undefined) {
            if (step === '1' && stepStatus === 'Rejected' && parseInt(ocr_attempts.idscan_attempts) < parseInt(getToken().scanidmaxretries)) {
                stepStatus = 'Inprogress';
            }
        }

        //alert(step+" "+stepStatus) ;
        if(otp_locked === "true" || otp_locked === true) {
            //window.location.href = '/contact-branch?ref=15';
            setReference(15)
            props.history.push('/contact-branch');
        } else {
            if(getToken().renewType === 'LID' && step === '4' && stepStatus === 'Inprogress') {
                step = '3';
            }
            if(getToken().renewType === 'LID' && step === '3' && stepStatus === 'Completed') {
                stepStatus = 'Inprogress';
            }

            let livenessstatus = getToken().livenessstatus;
            let stepAfterIDScan = PWA_JOURNEY_STEPS[2];
            if(livenessstatus === 'OFF') {
                stepAfterIDScan = PWA_JOURNEY_STEPS[3];
            }

            switch (step) {
                case '1':
                    if (stepStatus === 'Inprogress') {
                        //props.history.push('/instructions');
                        props.history.push(nextstep);
                    } else if (stepStatus === 'Completed') {

                        props.history.push('/' + stepAfterIDScan);

                    } else if (stepStatus === 'Rejected') {
                        //window.location.href = '/contact-branch?ref=1';
                        setReference(2)
                        props.history.push('/contact-branch');
                    }
                    break;
                case '2':
                    if (stepStatus === 'Inprogress') {
                        props.history.push('/' + stepAfterIDScan);

                    } else if (stepStatus === 'Completed') {
                        props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                    } else if (stepStatus === 'Rejected') {
                        //window.location.href = '/contact-branch?ref=2';
                        setReference(1)
                        props.history.push('/contact-branch');
                    }
                    break;
                case '3':
                    if (stepStatus === 'Inprogress') {
                        props.history.push('/' + PWA_JOURNEY_STEPS[3]);
                    } else if (stepStatus === 'Completed') {
                        props.history.push('/' + PWA_JOURNEY_STEPS[4]);
                    } else if (stepStatus === 'Rejected') {
                        //window.location.href = '/contact-branch?ref=5' ;
                        if(more_info === 'namematch') {
                            setReference(5)
                        } else if(more_info === 'facematch') {
                            setReference(4)
                        }
                        props.history.push('/contact-branch');
                    }
                    break;
                case '4':
                    if (stepStatus === 'Inprogress') {
                        props.history.push('/' + PWA_JOURNEY_STEPS[4]);
                    } else if (stepStatus === 'Completed') {
                        props.history.push('/thank-you');
                    } else if (stepStatus === 'Rejected') {
                        //window.location.href = '/contact-branch?ref=6' ;
                        setReference(6)
                        props.history.push('/contact-branch');
                    }
                    break;
                default:
                    props.history.push(nextstep)
            }
        }
    }
    //console.log(opterror) ;

    return (
        <>
            {loader &&
            <Loader message={loaderMessage}/>
            }
            {errorMsg !== '' &&
            <Popup messagehead={data[lang]['oops']} message={errorMsg}/>
            }
            <Header className="hasCrossOver hasCrossOver--lg">
                {versionStatus === true &&
                <span className="version">{getToken().version}</span>
                }
                <Link className="logoLink" onClick={() => setVersionStatus(true)}>
                    <img src={Logo} className="logoLink__in" alt="bank-of-muscat" />
                </Link>
            </Header>

            <div className={`wPanel paddingBonly offBox ${lang === "Ar" ? 'ct-arabic' : ''}`}>
                <div className="contentWrap">
                    <div className="shadowBlock">
                        <div className="shadowBlock__head">
                            <span className="block_headline">{data[lang]['otpheadline']}</span>
                            {/*<img src={Logo} className="shadowBlock__head__img" alt="bank-of-muscat"/>*/}
                        </div>
                        <div className="flexInBlock">
                            <div className="flexInBlock__center">
                                {submitoptstatus === '1' &&
                                <img src={otpIcon} className="tqImage" alt="thank-you"/>
                                }
                            </div>
                            <div className="flexInBlock__center">
                                {submitoptstatus === '1' &&
                                    <>
                                        <p className="otp-sent-msg">{data[lang]['otpSent']}</p>
                                        <fieldset className="no-border">

                                            <input  name="otp" value={otp} maxLength="4" onChange={handleInput} placeholder={data[lang]['enterOTP']}  type="tel" pattern="[0-9]*"
                                                    className="formBlock__input otpInput" autocomplete="one-time-code"/>
                                        </fieldset>
                                        { submitoptstatus === '1' && maxretries !==  sendotpcount ? (

                                                <p onClick={resendOTP} className={`regenerate-otp ${seconds === 0 ? 'active' : 'disabled'}`}>{data[lang]['regenerateOTP']}</p>


                                        ) : '' }
                                        {seconds > 0 ? (
                                            <p className="otp-timer">{data[lang]['expireOTP'].replace('{seconds}', seconds)}</p>
                                        ) : ''}
                                        {otp !== '' && opterror === 'error' ? (
                                            <p className="primaryTextAnchor">{data[lang]['errorOTP'].replace('{cnt}', submitotpcount).replace('{maxcnt}', maxOTPsubmit)}</p>
                                        ) : ''}

                                    </>
                                }
                                { submitoptstatus === '0' ? (
                                    <p className="alignCenter">{data[lang]['unabletosendotp']}</p>
                                ) : ''}
                            </div>
                        </div>
                    </div>
                    <div className="buttomWrap">


                            {submitoptstatus === '1' &&
                            <div className="flexCC">
                                <span onClick={onSubmitOtp}
                                      className={`btn btn--capsule btn--primaryG btn--contentWrap ${otp === '' ? 'disabled' : ''}`}>{data[lang]['submit']}</span>
                            </div>

                            }

                            {submitoptstatus === '0' &&
                            <div className="flexCC">
                                <span onClick={sendOTP}
                                      className={`btn btn--capsule btn--primaryG btn--contentWrap`}>{data[lang]['sendOTP']}</span>
                            </div>
                            }

                    </div>
                </div>



            </div>
        </>
    )
}

export default QRSubmitotp
